import { v4 as guid } from "uuid";
import * as React from "react";
import { default as RootStore } from "../stores/root-store";

export type DialogContentType<T> = React.ReactElement<Partial<T>> | React.FC<Partial<T>> | React.ComponentClass<any>;

export class Dialog<T> {
  id: string;
  title: string | undefined;
  closable: boolean = true;
  content?: DialogContentType<T>
  buttons: Array<{
    text: string;
    onClick: (state: Partial<T>, fnClose: () => void) => void;
    isEnabled?: (state: Partial<T>) => boolean;
  }> = [];
  fullScreen: boolean = false;

  constructor() {
    this.id = guid();
  }

  static OkDialog(title: string, content: DialogContentType<{}>, onClose?: (close: () => void) => void) {
    const d = new Dialog<{}>();
    d.title = title;
    d.content = content;
    d.closable = true;
    d.buttons = [{
      onClick: (state, fnClose) => onClose == null ? fnClose() : onClose(fnClose),
      text: "OK"
    }];
    return d;
  }

  static OkDialogWithMessage(title: string, content: string, onClose?: (close: () => void) => void) {
    return Dialog.OkDialog(title, React.createElement("p", null, content), onClose);
  }

  static OkCancelDialog(title: string, content: string, buttonText: string, onClick: (close: () => void) => void) {
    const dialog = new Dialog<{}>();
    dialog.title = title;
    dialog.content = React.createElement("p", null, content);
    dialog.closable = true;
    dialog.buttons = [
      {
        onClick: (state, fnClose) => { onClick(fnClose); },
        text: buttonText
      },
      {
        onClick: (state, fnClose) => fnClose(),
        text: "Avbryt"
      }
    ];
    return dialog;
  }

  static Show = (dialog: Dialog<any>) => RootStore.UIStore.addDialog(dialog)

}