import { observable } from "mobx";
import { observer } from "mobx-react";
import { DataStore2 } from "../../stores/data-store";
import { PageAlertEditModel } from "../../models/shared/PageAlertEditModel";
import classNames from "classnames";
import * as React from "react";
import { PageAlertType } from "../../models/shared/PageAlertType";
import AlertIcon from "svg/exclamation.svg";
import InformationIcon from "svg/info.svg";
import CloseIcon from "svg/menuClose.svg";
import "./PageAlertsBar.tsx.scss";

interface Props { }

@observer
export class PageAlertsBar extends React.Component<Props> {

  store: DataStore2<Array<PageAlertEditModel>>;
  @observable closedAlerts: Array<number>;

  constructor(props: Props) {
    super(props);

    this.store = new DataStore2("/api/pagealerts/getActiveAlerts", (ch, data) => ch.entityName === "PageAlertEntity");
    this.closedAlerts = JSON.parse(window.localStorage.getItem("closedAlerts") ?? "[]") as Array<number>; 
  }

  componentWillUnmount() {
    this.store.dispose();
  }

  close = (id: number) => {
    this.closedAlerts.push(id);
    window.localStorage.setItem("closedAlerts", JSON.stringify(this.closedAlerts));
  }

  render() {

    if (this.store.data == null || this.store.data.length == 0)
      return null;

    return <ul className="alert-bar">
      {this.store.data.filter(a => this.closedAlerts.indexOf(a.Id!) === -1).map(m =>
        <li key={m.Id} className={classNames("alert-bar-item", `alert-bar-type-${m.AlertType}`)}>
          <AlertIconComponent icon={m.AlertType} />
          <div className="alert-bar-content">
            <span className="alert-bar-title">{m.Title}</span>
            <span className="alert-bar-message">{m.Message}</span>
          </div>
          {m.Removable && <CloseIcon className="action icon" onClick={() => this.close(m.Id!)} />}
        </li>
      )}
    </ul>;
  }
}

const AlertIconComponent: React.FC<{ icon?: PageAlertType }> = ({ icon }) => {
  if (icon === PageAlertType.FirstUse)
    return null;
  else if (icon === PageAlertType.Information)
    return <InformationIcon className="icon" />
  else
    return <AlertIcon className="icon" />
}