import * as moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Network } from "../../../api/network";
import { FormActions } from "../../../components/forms/FormActions";
import { DateField } from "../../../components/forms/fields/DateField";
import { InputField } from "../../../components/forms/fields/InputField";
import { SelectField } from "../../../components/forms/fields/SelectField";
import { Form } from "../../../components/forms/form";
import { AddSalaryModel } from "../../../models/shared/AddSalaryModel";
import { HourType } from "../../../models/shared/HourType";
import { FormStore } from "../../../stores/form-store";

export interface TripletexEmployee {
  Id: number;
  Name: string;
  IsMonthly: boolean;
  HasCustomHours: boolean;
  IsPartTime: boolean;
}

interface AddEventDialogProps {
  start: Date;
  end: Date;
  onClose: () => void;
}

export const AddEventDialog: React.FC<AddEventDialogProps> = ({ start, end, onClose }) => {
  const [employees, setEmployees] = useState<Array<TripletexEmployee>>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();
  const [selectedType, setSelectedType] = useState<number | null>(null)

  const loadEmployees = async () => {
    const date = moment(start).format("YYYY-MM-DD");
    const network = new Network();
    const result = await network.get<Array<TripletexEmployee>>(`/api/salary/employees/${date}`);
    if (result)
      setEmployees(result);
  }

  useEffect(() => {
    loadEmployees();
  }, []);

  const [store] = useState(new FormStore<AddSalaryModel>(
    {},
    {
      Start: moment(start).format("YYYY-MM-DD"),
      End: moment(end).add(-1, "days").format("YYYY-MM-DD"),
    }, (field, value) => {
      if (field === "EmployeeId")
        setSelectedEmployeeId(value);
      else if (field === 'Type')
        setSelectedType(value);
    }));

  const selectedEmployee = employees.find(e => e.Id === selectedEmployeeId);
  const partTime = selectedEmployee?.IsPartTime ?? false;
  const customHours = selectedEmployee?.HasCustomHours ?? false;
  const isMonthly = selectedEmployee?.IsMonthly ?? false;
  const visTimer = selectedType === HourType.Overtime || selectedType === HourType.LeaveWithPay || selectedType === HourType.LeaveWithoutPay || selectedType === HourType.Work || selectedType === HourType.SelfCertification || selectedType === HourType.Overtime100 || selectedType === HourType.ExtraHour;

  return <div>
    <Form
      name="Timeføring"
      cancelable={true}
      onSubmit={async (router) => {
        await store.postForm(`/api/salary`, false);
        onClose();
      }}
      onCancel={() => onClose()}
      store={store}>
      <SelectField<AddSalaryModel>
        field="EmployeeId"
        label="Ansatt"
        helpText="Velg ansatt">
        <option value="">-- Velg ansatt --</option>
        {employees.map(e => <option key={e.Id} value={e.Id}>{e.Name}</option>)}
      </SelectField>
      <SelectField<AddSalaryModel>
        field="Type"
        label="Type føring"
        helpText="Velg type">
        <option value="">-- Velg type --</option>
        <option value={HourType.Overtime}>Overtid</option>
        <option value={HourType.Overtime100}>Overtid 100%</option>
        {!partTime && <option value={HourType.LeaveWithPay}>Annet fravær med lønn</option>}
        {!partTime && <option value={HourType.LeaveWithoutPay}>Fravær uten lønn</option>}
        {!partTime && !isMonthly && <option value={HourType.VacationDay}>Ferie</option>}
        {(partTime || customHours) && <option value={HourType.Work}>Arbeid</option>}
        {!(partTime || customHours) && <option value={HourType.ExtraHour}>Arbeid utenfor normaltid</option>}
        {!partTime && <option value={HourType.SickLeave}>Sykemeldt</option>}
        {!partTime && <option value={HourType.SelfCertification}>Egenmelding</option>}
        {!partTime && <option value={HourType.SickChild}>Fravær Sykt barn</option>}
        {!partTime && <option value={HourType.Suspended}>Permitert</option>}
      </SelectField>
      <DateField<AddSalaryModel>
        field="End"
        label="Sluttdato"
        numberOfMonths={2}
        helpText="Her kan du spesifisere en sluttdato f.eks ved permitering"
        openToDate={end}
        showIf={v => !visTimer && v.Type != null}
      />
      {visTimer && <InputField<AddSalaryModel>
        field="Hours"
        label="Antall timer"
        inputMode="numeric"
        postfix="timer"
        step="0.5"
        type="number"
        helpText="Velg antall timer" />}
      <FormActions asFooter={true} />
    </Form>
  </div>
}

