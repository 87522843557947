import { RouterStore } from "mobx-react-router";
import { observer, inject, Observer } from "mobx-react";
import * as React from "react";
import { FormConsumer } from "./form";
import PendingIcon from "svg/pending.svg";
import classnames from "classnames";

interface ActionButton {
  text: string;
  default: boolean;
  onClick: () => void;
}

interface Props {
  showUndo?: boolean;
  submitText?: string;
  /// Sets the styling for the component to emulate the buttons at the bottom of a dialog
  asFooter?: boolean;
  right?: boolean;
}


export class FormActions extends React.Component<React.PropsWithChildren<Props>> {

  render() {

    const showUndo = this.props.showUndo == undefined ? true : this.props.showUndo;
    const submitText = this.props.submitText;

    return (
      <FormConsumer>
        {({ store, cancelable, onCancel }) => {
          return (
            <Observer>{
              () => {
                const isEdit = store.isEdit;
                const isPending = store.isPending;
                return (<div className={classnames("buttons", { "footer": this.props.asFooter, "right": this.props.right })}>

                  <button
                    type="submit"
                    className="button"
                    disabled={store.hasValidationErrors || !store.canReset || isPending}>
                    {isPending ? <PendingIcon className="svg-pending" /> : null}
                    {submitText ? submitText : isEdit ? "Oppdater" : "Opprett"}
                  </button>

                  {showUndo && <button
                    type="button"
                    className="button"
                    disabled={!store.canReset || isPending}
                    onClick={e => store.reset()}>
                    {isEdit ? "Angre" : "Tøm"}
                  </button>}

                  {cancelable && <button
                    type="button"
                    className="button"
                    disabled={isPending}
                    onClick={e => onCancel()}>Avbryt
                  </button>}

                  {this.props.children}
                </div>);
              }
            }
            </Observer>
          )
        }
        }
      </FormConsumer>);
  }
}