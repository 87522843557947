import * as React from "react";
import "./detailsaction.scss";
export class DetailsAction extends React.Component<React.PropsWithChildren> {  
  static typeName = "DetailsAction";
  render() {
    return (
      <div className="details-action">
        {this.props.children}
      </div>)
  }
}