import * as React from "react"
import { CustomerSelector } from "../../../components/shared/CustomerSelector"
import { toJS } from "mobx";
import { useReducer } from "react";
import { SimpleWrappedField } from "../../../components/shared/SimpleWrappedField";

interface P {
  userId: number;
}
interface PState {
  targetCustomer: number | undefined;
}

type Props = P & {
  contentState: Partial<PState>
  onCloseFn: () => void;
}

const _MoveUserToCustomerDialog: React.FC<Props> = ({ contentState }) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const setCustomer = (id: number | undefined) => {
    contentState.targetCustomer = id;
    forceUpdate();
  }

  return <div className="move-user-to-customer-dialog">
    <p>Her kan du flytte en bruker til en ny kunde.</p>
    <div>
      <form className="edit-form">
        <SimpleWrappedField label="Kunde" help="Velg kunden du vil flytte brukeren til.">
          <CustomerSelector setValue={setCustomer} value={toJS(contentState)?.targetCustomer ?? undefined} showAlphabeth={false } />
        </SimpleWrappedField>
      </form>
    </div >
  </div >
}

export const MoveUserToCustomerDialog: React.FC<P> = _MoveUserToCustomerDialog as any;