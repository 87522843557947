import { computed, observable } from "mobx";
import { Role } from "ts/models/shared/Role";
import { IRootStore } from "./root-store";

interface UserProfileModel {
  Id: number;
  Name?: string;
  CustomerId?: number;
  Role: Role;
  HasPhone: boolean;
}

export class UserStore {

  @observable requiresAuthentication: boolean | undefined;
  @observable isConnected: boolean;
  @observable userProfile?: UserProfileModel;

  constructor() {
    this.isConnected = false;
    this.refresh();
  }

  public async initialize(root: IRootStore) { }

  public refresh() {
    this.requiresAuthentication = window.flytSettings.user == null;
    if (!this.requiresAuthentication) {
      this.userProfile = {
        Id: window.flytSettings.user!.id,
        CustomerId: window.flytSettings.user?.customerId,
        Role: window.flytSettings.user?.role as Role,
        Name: window.flytSettings.user?.name,
        HasPhone: window.flytSettings.user?.hasPhone!
      }
    } else {
      this.userProfile = undefined;
    }
  }
  @computed public get name() {
    return this.userProfile ? this.userProfile.Name : undefined;
  }

  @computed public get userId() {
    return this.userProfile?.Id;
  }

  @computed public get isCustomer() {
    return this.userProfile ? this.userProfile.Role == Role.Customer || this.userProfile.Role == Role.CustomerAdministrator : false;
  }

  @computed public get isEmployee() {
    return this.userProfile ? this.userProfile.Role == Role.Employee || this.userProfile.Role == Role.EmployeeAdmin : false;
  }

  @computed public get isAdministrator() {
    return this.userProfile ? this.userProfile.Role == Role.Administrator : false;
  }

  @computed public get isEmployeeAdministrator() {
    return this.userProfile ? (this.isAdministrator || this.userProfile.Role == Role.EmployeeAdmin) : false;
  }

  @computed public get customerId() {
    return this.userProfile ? this.userProfile.CustomerId : undefined;
  }

  @computed public get role() {
    return this.userProfile ? this.userProfile.Role : undefined;
  }

  @computed public get hasPhone() {
    return this.userProfile ? this.userProfile.HasPhone : false;
  }
}