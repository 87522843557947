import * as React from "react";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { RouteComponentProps } from "react-router";
import { AddressForm } from "./AddressForm";
import { AddressList } from "./AddressList";

interface Props {
  route: RouteComponentProps<{}>;
}

export class AddressPage extends React.Component<Props> {
  render() {
    return <WorkSpace
      singular="adresse"
      plural="adresser"
      routeBasePath={this.props.route.location.pathname.split("/")[1]}
      {...this.props.route}
      editComponent={<AddressForm/>}
      listComponent={<AddressList/>}
    />
  }
}