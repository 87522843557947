import React from "react";
import { BaseEditModel } from "../../../models/shared/BaseEditModel";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import classNames from "classnames";
import { SE as SwedenFlag } from 'country-flag-icons/react/1x1'
import "./PhoneNumberField.tsx.scss";
interface OwnProps {
}

type P = PropsFromWrapperToField<string> & OwnProps;

const PhoneNumberField: React.FC<P> = ({ disabled, value, setValue, id, setTouched, autoFocus }) => {

  const isSweden = value?.startsWith("+46") === true;
  const phoneNumber = value?.substring(3);

  const setPhoneNumber = (value: string) => {
    setTouched();
    setValue(`${isSweden ? "+46" : "+47"}${value}`);
  }

  const setCountryCode = (countryCode: string) => {
    if (phoneNumber == null)
      setValue(undefined);
    else {
      setTouched();
      setValue(`${countryCode}${phoneNumber}`);
    }
  }

  return (<div className={classNames("field-phonenumber", "flex", { "input-with-fix--disabled": disabled })}>
    <select className="pre-input-designator short-select" onChange={v => setCountryCode(v.target.value)}>
      <option value="+47">+47</option>
      <option value="+46">+46</option>
    </select>
    <input className="input-with-pre-designator short-input" value={phoneNumber ?? ""} onChange={v => setPhoneNumber(v.target.value)} inputMode="numeric" id={id} autoFocus={autoFocus} />
  </div>);
}

const component = Wrap<P, string>(PhoneNumberField);

export {
  component as PhoneNumberField
};