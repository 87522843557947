import * as React from "react";
import { Switch, Route, RouteComponentProps } from "react-router";

type C = JSX.Element;

export interface PageRoutesProps {
  routeBasePath: string;
  listComponent?: C;
  editComponent?: C;
  detailsComponent?: C;
  additionalRoutes?: Array<{
    path: string,
    component: React.ComponentClass<any> | React.FC<any>
  }>
}

export class PageRoutes extends React.PureComponent<PageRoutesProps> {

  render() {
    const { routeBasePath, listComponent, editComponent, detailsComponent, additionalRoutes } = this.props;
    return (
      <Switch>
        {additionalRoutes && additionalRoutes.map(route =>
          <Route key={route.path} path={route.path} exact render={e => React.createElement(route.component, { ...e, ...e.match.params })} />)}
        {listComponent && <Route path={`/${routeBasePath}`} exact render={e => listComponent} />}
        {editComponent && <Route path={`/${routeBasePath}/create/:customerId?`}>
          <>
            {editComponent}
          </>
        </Route>}
        {editComponent && <Route path={`/${routeBasePath}/:id/edit`} exact render={e =>
          React.cloneElement(editComponent, { id: parseInt(e.match.params.id!) })} />}
        {detailsComponent && <Route path={`/${routeBasePath}/:id`} exact render={e =>
          React.cloneElement(detailsComponent, { key: parseInt(e.match.params.id!) })} />}
      </Switch>
    )
  }
}