import * as React from "react";
import { FilterProps } from "../IListDefinition";
import { observer } from "mobx-react";
import { BaseModel } from "../../../models/shared/BaseModel";
import { Lookup } from "../../../stores/lookup-store";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import { default as RootStore } from "../../../stores/root-store";

interface OwnProps extends React.PropsWithChildren {
  lookup?: Lookup
}

type P = FilterProps<BaseModel> & OwnProps;

@observer
class SelectFilter extends React.Component<P> {

  render() {
    const { store, field, lookup } = this.props;
    const value: string = store.getFilterValue(field) || "";

    let _options: Array<BaseLookupModel> = [];
    if (lookup)
      _options = RootStore.LookupStore.getList(lookup)

    return <select
      onChange={e => store.setFilter(field, e.target.value)}
      value={value}><>
        {this.props.children}
        {_options.map(o => <option key={o.Id} value={o.Id}>{o.Name}</option>)}
      </>
    </select>
  }
}

const A = SelectFilter as any as React.ComponentClass<OwnProps>;

export {
  A as SelectFilter
};