import * as React from "react";
import { FilterProps } from "../IListDefinition";
import { observer } from "mobx-react";
import { CustomerSelector } from "../../shared/CustomerSelector";
import { BaseModel } from "../../../models/shared/BaseModel";

type P = FilterProps<BaseModel>;

@observer
class CustomerFilter extends React.Component<P> {
  render() {
    const { store, field } = this.props;
    const value = store.getFilterValue<Array<number>>(field);
    return <CustomerSelector setValue={c => store.setFilter(field, c == undefined ? undefined : [c])} value={value ? value[0] : undefined} />
  }
}

const A = CustomerFilter as any as React.ComponentClass<{}>;

export { A as CustomerFilter };
