import { IListDefinition, Alignment } from "ts/components/lists/IListDefinition";
import { ArticleGroupModel } from "ts/models/shared/ArticleGroupModel";
import * as React from "react";
import { Link } from "react-router-dom";
import { toDate } from "ts/core/dateHelpers";
import CopyIcon from "svg/copy.svg";
import { SortDirection } from "ts/models/shared/SortDirection";
import { List } from "ts/components/lists/List";
import { ListStore } from "ts/stores/list-store";
import { ArticleGroupPriceModel } from "ts/models/shared/ArticleGroupPriceModel";

type ListType = ArticleGroupModel & { Id: number, Created: string };

interface Props {
  articleId: number;
}

const getListConfig = (articleId: number): IListDefinition<ListType> => ({
  id: "prices",
  singular: "pris",
  editLink: (row) => `/articles/${articleId}/prices/${row.Id}/edit`,
  createLink: _ => `/articles/${articleId}/prices/create`,  
  baseUrl: `/api/articlegroups/${articleId}`,
  columns: [
    {
      field: "Id",
      sortable: true,
      title: "Id",
      render: (row, value) => row.Deleted == null ? <td><Link to={`/articles/${articleId}/prices/${row.Id}/edit`}>{value}</Link></td> : <td>{value}</td>
    }, {
      title: "Gjelder fra",
      field: "Start",
      sortable: false,
      render: (row, value) => <td className="no-wrap">{toDate(value)}</td>
    }, {
      title: "Prices",
      field: "Prices",
      sortable: false,
      render: (row, value) => <td>{getPriceList(value)}</td>
    }, {
      sortable: false,
      title: "Kopier",
      alignment: Alignment.Center,
      render: (row, value) => <td className="center cell-action"><Link to={`/articles/${articleId}/prices/create/${row.Id}`} title="Kopier"><CopyIcon /></Link></td>
    }
  ],
  filters: [],
  presets: [
    {
      default: true,
      sort: [{
        field: "Start",
        direction: SortDirection.Desc
      }],
      filters: [],
      title: "Nyeste priser"
    }
  ]
})

export class ArticlePrices extends React.Component<Props> {

  private _config: IListDefinition<ListType>;
  private _store: ListStore<ListType>;

  constructor(props: Props) {
    super(props);
    this._config = getListConfig(props.articleId);
    this._store = new ListStore<ListType>(this._config);
  }

  componentWillUnmount() {
    this._store.release();
  }

  render() {
    return (
      <List configuration={this._config} store={this._store} />
    )
  }
}

function getPriceList(prices?: Array<ArticleGroupPriceModel>) {
  if (prices == null || prices.length == 0)
    return "Ikke satt";
  else if (prices.length == 1)
    return `${prices[0].Price.toFixed(2)} kr`;
  else return prices.map(p => `${Number(p.Price).toFixed(2)} kr (≥${p.MinimumWeight} kg)`).join(" | ");
}
