import * as React from "react";
import { DataStore2 } from "../../stores/data-store";
import { TransportOrderListModel } from "../../models/shared/TransportOrderListModel";
import { Pending } from "./Pending";
import { toDate } from "../../core/dateHelpers";
import { NumberWithPostfix } from "./display";
import { observer } from "mobx-react";
import TrashIcon from "svg/trash.svg";
import { Network } from "../../api/network";
import { Dialog } from "../../core/dialog";
import { default as RootStore } from "ts/stores/root-store";
import { Enums } from "../../stores/lookup-store";
import { observable } from "mobx";
import type { IObservableArray } from "mobx";
import { BaseLookupModel } from "../../models/shared/BaseLookupModel";

interface Props {
  orderId?: number;
  workOrderId?: number;
  isCustomer: boolean;
}

@observer
export class TransportOrderList extends React.Component<Props> {

  list: DataStore2<Array<TransportOrderListModel>> | null | undefined = undefined;
  @observable transportTypes: IObservableArray<BaseLookupModel>;

  constructor(props: Props) {
    super(props);

    let url = null;
    if (props.orderId != null)
      url = `/api/transportOrders/byOrder/${props.orderId}`;
    else if (props.workOrderId != null)
      url = `/api/transportOrders/byWorkOrder/${props.workOrderId}`

    if (url == null)
      this.list = null;
    else
      this.list = new DataStore2<Array<TransportOrderListModel>>(url, (ch, data) => {
        return ch.entityName == "TransportOrderEntity";
      });

    this.transportTypes = observable([]);
  }

  async componentDidMount() {
    const result = await RootStore.LookupStore.getEnums(Enums.TransportRequestType);
    if (result)
      this.transportTypes.replace(result);
  }

  delete = async (id: number) => {
    var dialog = Dialog.OkCancelDialog(
      "Slette bestilling",
      "Er du sikker på at du vil slette denne transportbestillingen?",
      "Slett",
      async close => {
        var network = new Network();
        await network.getSimple(`/api/transportOrders/delete/${id}`);
        close();
      }
    );
    Dialog.Show(dialog);
  }

  render() {
    const { isCustomer, workOrderId } = this.props;

    if (this.list == undefined)
      return <Pending />
    else if (this.list == null || this.list.data == null)
      return null;
    else if (this.list.data.length == 0)
      return <p>{this.props.orderId != null ? "Ingen transportbestillinger har blitt registrert for denne bestillingen." : "Ingen transportbestillinger er registrert for denne arbeidsordren."}</p>
    else return <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Transportør</th>
            {workOrderId && <th>Type</th>}
            <th>Fraktbrev</th>
            <th>Referanse</th>
            <th className="right">Dato</th>
            {!isCustomer && <th className="right">Avtalt pris</th>}
            {!isCustomer && <th className="center">Viderefaktureres</th>}
            <th>Informasjon</th>
            <th>Bestilt av</th>
            <th className="center">Slett</th>
          </tr>
        </thead>
        <tbody>
          {this.list.data.map((u, i) => <tr key={i}>
            <td>{u.CarrierName ?? "Annen - se info"}</td>
            {workOrderId && <td>{this.transportTypes.find(r => r.Id == u.TransportType)?.Name}</td>}
            <td>{u.WayBillId}</td>
            <td>{u.CarrierReference}</td>
            <td className="right">{toDate(u.TransportationDate)}</td>
            {!isCustomer && <td className="right"><NumberWithPostfix post="kr" empty="" number={u.AgreedPrice} /></td>}
            {!isCustomer && <td className="center">{u.ReInvoice ? "Ja" : "Nei"}</td>}
            <td className="wrap">{u.Information}</td>
            <td>{u.UserName}</td>
            <td className="center action"><span onClick={_ => this.delete(u.Id)}><TrashIcon /></span></td>
          </tr>)}
        </tbody>
      </table>
    </div>
  }

  componentWillUnmount() {
    this.list?.dispose();
  }
}