import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import { SelectFieldInternal } from "./SelectField";
import { default as RootStore } from "../../../stores/root-store";
import { Enums } from "../../../stores/lookup-store";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";

interface OwnProps {
  enumType: Enums;
  sort: boolean;
  required: boolean;
  initialText?: string
  useCheckBoxes?: boolean
}

interface State {
  options?: Array<BaseLookupModel>;
}

type P = PropsFromWrapperToField<number | string> & OwnProps;

class EnumField extends React.PureComponent<P, State> {
  state: State = { options: undefined };

  async componentDidMount() {
    const { enumType, sort } = this.props;
    const options = await RootStore.LookupStore.getEnums(enumType, sort == null ? true : sort);
    this.setState({ options });
  }


  render() {
    const options = this.state.options;
    const { value, required, initialText, useCheckBoxes, id, setValue} = this.props;
    if (options == undefined)
      return null;

    const hasValue = value != null && options.some(o => o.Id == value);
    const text = initialText ?? "-- Velg et alternativ --";

    if (useCheckBoxes === true) {
      return <div id={id} className="field-boolean-radio">
        {options.map(o => <label htmlFor={id + "-" + o.Id.toString()} key={o.Id}>
          <input
            type="radio"
            radioGroup={id}
            id={id + "-" + o.Id.toString()}
            onChange={() => setValue(o.Id)}
            checked={value != null && value == o.Id} />
          {o.Name}</label>
        )}
      </div>
    } else {

      return <SelectFieldInternal {...this.props}>
        {(!hasValue || !required) && <option value="">{required ? text : initialText ?? ""}</option>}
        {options.map(o => <option key={o.Id} value={o.Id}>{o.Name}</option>)}
      </SelectFieldInternal>
    }
  }
}

const wrapped = Wrap<P, number | string>(EnumField);

export {
  wrapped as EnumField
}