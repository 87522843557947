import * as React from "react";
import { FormStore, IValidationRules } from "../../../stores/form-store";
import { observer } from "mobx-react";
import { Form } from "../../../components/forms/form";
import { FormActions } from "../../../components/forms/FormActions";
import { InputField } from "../../../components/forms/fields/InputField";
import { default as RootStore } from "../../../stores/root-store";
import { EnumField } from "ts/components/forms/fields/EnumField";
import { Enums } from "ts/stores/lookup-store";
import { ArticleEditModel } from "ts/models/shared/ArticleEditModel";
import { trace } from "mobx";
import { EntityType } from "../../../core/stringHelpers";

interface Props {
  id?: number
}
@observer
export class ArticleForm extends React.Component<Props> {
  store: FormStore<ArticleEditModel>;
  isCustomer: boolean;

  constructor(props: Props) {
    super(props);

    const validationRules: IValidationRules<ArticleEditModel> = {
    }

    this.isCustomer = RootStore.UserStore.isCustomer;

    this.store = new FormStore(validationRules, {});

    if (this.props.id)
      this.store.loadModel("articles", this.props.id);

  }

  render() {
    const { id } = this.props;

    if (id && !this.store.modelHasLoaded)
      return null;

    return (
      <div className="panel form-container">
        <Form
          store={this.store}
          name="Artikkel"
          entityType={EntityType.Article}
          redirectToList={true}
          className="edit-form edit-form-padding">
          <h3>Artikkel</h3>

          <InputField<ArticleEditModel>
            field="Title"
            helpText="Navn på artikke."
            label="Navn" />
          <InputField<ArticleEditModel>
            field="Description"
            helpText="Tekst som vises på faktura."
            label="Fakturatekst" />
          <EnumField<ArticleEditModel>
            field="ArticleType"
            sort={true}
            required={true}
            enumType={Enums.ArticleType}
            helpText="Indikerer om artikkelen skal være knyttet til utveiing."
            label="Type" />
          <EnumField<ArticleEditModel>
            field="Unit"
            sort={true}
            required={true}
            enumType={Enums.ArticleUnit}
            helpText="Enhet for artikkel."
            label="Enhet" />
          <FormActions />
        </Form>
      </div>
    )
  }
}