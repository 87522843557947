import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import "./booleanField.tsx.scss";

interface OwnProps {
  options?: [string, string];
}

type P = PropsFromWrapperToField<boolean> & OwnProps;

class BooleanField extends React.Component<P> {

  render() {
    const { id, autoFocus, setTouched, setValue, value, options } = this.props;

    if (options != null) {
      return <div id={id} className="field-boolean-radio">
        <label>
          <input
            type="radio"
            name={id}
            onChange={e => e.target.checked ? setValue(true) : null}
            onBlur={e => setTouched()}
            checked={value === true} />
          {options[0]}
        </label>
        <label>
          <input
            type="radio"
            name={id}
            onChange={e => e.target.checked ? setValue(false) : null}
            onBlur={e => setTouched()}
            checked={value === false} />
          {options[1]}
        </label>
      </div>
    } else {
      return <input
        id={id}
        type="checkbox"
        onChange={e => setValue(e.target.checked)}
        onBlur={e => setTouched()}
        checked={value || false}
        autoFocus={autoFocus} />
    }

  }

}

const wrapped = Wrap<P, boolean>(BooleanField);

export {
  wrapped as BooleanField
}