import * as React from "react";
import { DynamicPageTitle } from "../../../components/shared/DynamicPageTitle";
import { BreadCrumb } from "../../../components/shared/BreadCrumb";
import { RouteComponentProps } from "react-router";
import { EditUnit } from "./EditUnit";

type P = RouteComponentProps<{ workOrderId: string, id?: string }>;

export class EditUnitPage extends React.Component<P> {
  render() {

    const { workOrderId, id } = this.props.match.params;

    return <>
      <h1>Kolli</h1>
      <BreadCrumb
        workSpaceType="details"
        singular="Arbeidsordre"
        plural="Arbeidsordre"
        id={workOrderId}
        additionalLinks={[{ title: id ? "Rediger kolli" : "Opprett kolli", path: this.props.location.pathname }]}
        routeBasePath="workorders" />
      <EditUnit workOrderId={parseInt(workOrderId)} id={id ? parseInt(id) : undefined} />
    </>
  }
}