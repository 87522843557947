import * as React from "react";
import { ListProps } from "./List";
import { BaseModel } from "../../models/shared/BaseModel";
import { observer } from "mobx-react";

type P = ListProps<BaseModel>;

@observer
export class PageSize extends React.Component<P> {
  render() {
    const { store } = this.props;
    if (store.values == null || store.values.Result?.length == 0)
      return null;
    return (
      <div>
        <span>Vis</span>&nbsp;
        <select value={store.pageSize} onChange={(e) => store.setPageSize(parseInt(e.target.value))} aria-label="Antall tabellrader">
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
          <option>250</option>
        </select>
        &nbsp;<span>Linjer</span>
      </div>)
  }
}