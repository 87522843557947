import * as React from "react"
import {  CustomBreadCrumb } from "../../../../components/shared/BreadCrumb"
import { KeyFigure } from "./kf-godsreserve";
import styled from "styled-components"
import { KeyFigureDueWeight } from "./keyfigurebase";
import { KeyFigureVariableCost } from "./kf-variablecost";

const KeyFigureContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 10px;
`;


const kiloFormatter: (v: number) => string = v => `${Math.round(v).toLocaleString("nb-NO")} kg`;
const antallFormatter: (v: number) => string = v => `${Math.round(v).toLocaleString("nb-NO")} stk`;
const kroneFormatter: (v: number) => string = v => `${Math.round(v).toLocaleString("nb-NO")} kr`;

export const KeyFiguresPage: React.FC = ({ }) => {
  return <div className="page-keyfigures">
    <h1>Nøkkeltall</h1>
    <CustomBreadCrumb links={[{ title: "Nøkkeltall", path: "/statistics/keyfigures" }]} />

    <KeyFigureContainer>
      <KeyFigure title="Godsreserve" formatter={kiloFormatter} url="/api/statistics/keyGodsreserve" />
      <KeyFigure title="Mottatt" formatter={kiloFormatter} url="/api/statistics/keyState/100" />
      <KeyFigure title="Hengt opp" formatter={kiloFormatter} url="/api/statistics/keyState/130" />
      <KeyFigure title="Utveid" formatter={kiloFormatter} url="/api/statistics/keyState/320" />
      <KeyFigure title="Hentet" formatter={kiloFormatter} url="/api/statistics/keyState/330" />
      <KeyFigure title="Over leveringstid vekt" formatter={kiloFormatter} url="/api/statistics/keyOverdue/weight" />
      <KeyFigure title="Over leveringstid antall" formatter={antallFormatter} url="/api/statistics/keyOverdue/count" />
      <KeyFigureDueWeight />
      <KeyFigure title="Fakturering" formatter={kroneFormatter} url="/api/statistics/keySales" />
      <KeyFigure title="Dupp" formatter={antallFormatter} url="/api/statistics/keyDupps" />
    </KeyFigureContainer>

  </div>
}