import * as React from "react";
import DeleteIcon from "svg/trash.svg";
import { WorkOrderDetailsModel } from "ts/models/shared/WorkOrderDetailsModel";
import { GenericDataStore } from "ts/stores/genericData-store";
import { Form } from "ts/components/forms/form";
import { AddUnitWeightModel } from "ts/models/shared/AddUnitWeightModel";
import { FormStore } from "ts/stores/form-store";
import { SelectField } from "ts/components/forms/fields/SelectField";
import { InputField } from "ts/components/forms/fields/InputField";
import { default as RootStore } from "ts/stores/root-store";
import { ArticleType } from "ts/models/shared/ArticleType";
import { FormActions } from "ts/components/forms/FormActions";
import { observer } from "mobx-react";
import { add } from "../../../core/util";
import { NumberWithPostfix } from "../../../components/shared/display";
import { Direction } from "../../../models/shared/Direction";
import type { IObservableArray } from "mobx";
import { observable } from "mobx";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import { Network } from "../../../api/network";
import { ArticleListModel } from "../../../models/shared/ArticleListModel";
import { BooleanField } from "../../../components/forms/fields/BooleanField";

interface Props {
  unitId: number;
  store: GenericDataStore<WorkOrderDetailsModel>
}

interface State {
  showAll: boolean;
}

@observer
export class WeightDialog extends React.Component<Props, State> {

  formStore: FormStore<AddUnitWeightModel>;
  availableArtiles: number[];
  state: State = { showAll: false };
  @observable articles: IObservableArray<BaseLookupModel>;

  constructor(props: Props) {
    super(props);
    this.formStore = new FormStore<AddUnitWeightModel>({
      Weight: [
        (value: any) => value == null || value == "" ? "Du må oppgi en vekt" : null

      ]
    }, {
      UnitId: this.props.unitId,
      IsDoubleDip: false
    });

    // Set available articles;
    const units = this.props.store.entity!.Units || [];
    const articles = units
      .filter(u => u.Direction == Direction.Incoming)
      .flatMap(u => u.AvailableArticles);
    this.availableArtiles = [...new Set(articles)]; // distinct
    this.articles = observable([]);
  }

  async componentDidMount() {
    const network = new Network();
    const result = await network.get<Array<ArticleListModel>>(`/api/articles/ArticlesForWorkOrder/${this.props.store.entity?.Id}`);
    if (result)
      this.articles.replace(
        result
          .filter(article => article.ArticleType == ArticleType.ZinkArticle && article.Id != 25)
          .sort((a, b) => a.Id - b.Id)
          .map<BaseLookupModel>(a => ({ Id: a.Id, Name: a.Title }))
      );
  }

  removeUnitWeight = async (weightId: number) => {
    const { store } = this.props;
    await store.removeUnitWeight(weightId);
  }

  render() {

    const { unitId, store } = this.props;
    const unit = store.entity!.Units.find(u => u.Id == unitId)!;

    const isAdministrator = RootStore.UserStore.isAdministrator;

    return (<div>
      <div>
        <h3>Legg til ny vekt på kolli</h3>
        <Form
          cancelable={false}
          store={this.formStore}
          name="addUnitWeight"
          onSubmit={async () => {
            const { ArticleId, Weight, IsDoubleDip } = this.formStore.values;
            if (ArticleId && Weight) {
              const success = await store.addUnitWeight(unit.Id, ArticleId, Weight, IsDoubleDip ?? false);
              if (success)
                this.formStore.reset();
            }
          }}>
          <SelectField<AddUnitWeightModel>
            field="ArticleId"
            label="Artikkel"
            helpText="Her velger du type stål som har blitt varmforsinket.">
            <option value="">-- Velg produkttype --</option>
            {this.articles.map(article => <option
              key={article.Id}
              disabled={!this.state.showAll && this.availableArtiles.length > 0 && !this.availableArtiles.some(u => u == article.Id)}
              value={article.Id}>{article.Name}</option>)}
          </SelectField>

          {isAdministrator && this.availableArtiles.length > 0 && <div className="form-field">
            <div className="form-field-descripion"></div>
            <div className="form-field-component">
              <label><input type="checkbox" defaultChecked={this.state.showAll} onChange={e => this.setState({ showAll: e.target.checked })} />&nbsp;Vis alle artikler</label>
            </div>
          </div>}

          <InputField<AddUnitWeightModel>
            field="Weight"
            label="Vekt"
            postfix="kg"
            type="number"
          />
          <BooleanField<AddUnitWeightModel>
            field="IsDoubleDip"
            label="Dimensjonstillegg"
            helpText="Indikerer om denne vekten skal ha tillegg for dubbeldupp."
          />
          <FormActions submitText="Legg til" right />
        </Form>
      </div>
      <div className="table-container">
        <h3>Vekter</h3>
        <table className="no-side-padding">
          <thead>
            <tr>
              <th>Type</th>
              <th className="right">Vekt</th>
              <th className="center">Dimensjon</th>
              <th className="action">Fjern</th>
            </tr>
          </thead>
          <tbody>
            {unit.ProductionWeights!.map(w => <tr key={w.Id}>
              <td>{w.Article}</td>
              <td className="right"><NumberWithPostfix number={w.Weight} post="kg" /></td>
              <td className="center">{w.IsDoubleDip ? "Ja" : "Nei"}</td>
              <td className="action"><div onClick={e => this.removeUnitWeight(w.Id)}><DeleteIcon className="icon" /></div></td>
            </tr>)}
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td className="right"><NumberWithPostfix number={unit.ProductionWeights!.map(u => u.Weight).reduce(add, 0)} post="kg" /></td>
              <td />
            </tr>
          </tfoot>
        </table>
      </div>
    </div>)
  }
}