import * as React from "react";
import { RouteComponentProps } from "react-router";
import { debounce } from "lodash-es";
import { NavLink, Link } from "react-router-dom";
import { Pending } from "../../../components/shared/Pending";
import { Network } from "../../../api/network";
import { SearchResultItem } from "../../../models/shared/SearchResultItem";
import { toDateWithHM } from "../../../core/dateHelpers";
import "./SearchPage.tsx.scss";

interface State {
  phrase: string;
  results?: Array<SearchResultItem>;
}

type P = RouteComponentProps<{ phrase?: string }>;

class SearchPage extends React.Component<P, State> {

  network = new Network();
  controller = new AbortController();
  lastPhrase: string | null = null;

  constructor(props: P) {
    super(props);
    this.state = { phrase: props.match.params.phrase! };
    this.fn();
  }

  fn = debounce(async () => {
    const phrase = this.props.match.params.phrase!;
    if (this.lastPhrase != phrase) {
      this.lastPhrase = phrase;
      await this.doSearch(phrase);
    }
  }, 250);


  async doSearch(phrase: string) {
    this.setState({ phrase: '', results: undefined });

    // abort any pending request
    this.controller.abort();

    // create new abort controller
    this.controller = new AbortController();

    const results = await this.network.get<Array<SearchResultItem>>(`/api/search/query/${encodeURI(phrase)}`, this.controller);
    if (results) {
      this.setState({ phrase, results });
    }
  }

  componentDidUpdate() {
    this.fn();
  }

  render() {
    const { phrase, results } = this.state;
    return <>
      <h1>Søk</h1>
      <ol className="breadcrumb" aria-label="Brødsmulesti" role="navigation">
        <li><NavLink to="/">RåLink</NavLink></li>
        <li><span>Søk</span></li>
        {this.state.phrase.length > 0 && <li><span>{this.state.phrase}</span></li>}
      </ol>
      <div className="panel panel-padding search-page">
        {results == null && <Pending />}
        {results != null && results.length == 0 && <p>Ingen treff</p>}
        {results != null && results.length > 0 && <ul>
          <li className="result-header">
            <span className="result-title">Type</span>
            <span className="result-link">Id</span>
            <span className="result-hit">Treff</span>
            <span className="result-date">Opprettet</span>
          </li>
          {results.map((r, i) => <ResultItem key={i} result={r} />)}
        </ul>}
      </div>
    </>
  }
}

export { SearchPage };

const ResultItem: React.FC<{ result: SearchResultItem }> = ({ result }) => {
  const { TypeName, Id, Created, SerialId, Url, Actions, CustomValues, Hit } = result;

  return <li className="result-item">
    <span className="result-title-link-container">
      <span className="result-title">{TypeName}</span>
      <Link className="result-link" to={Url!}>{SerialId}</Link>
    </span>
    <span className="result-hit">{Hit}</span>
    <span className="result-date">{toDateWithHM(Created)}</span>
    {CustomValues.map((v, i) => <span className="result-value" key={i}>{v}</span>)}
    {Actions?.map(a => <Link className="result-action" key={a.Url} to={a.Url!}>{a.Title}</Link>)}
  </li>
}