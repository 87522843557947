import { ApexOptions } from "apexcharts"
import React from "react"
import ReactApexChart from "react-apexcharts"
import { Pending } from "../../../../components/shared/Pending"
import { useDataStore } from "../../../../core/hooks/useDataStore"
import { sum } from "lodash-es"

const options: ApexOptions = {
  title: {
    text: 'Leveranser siste 12 mnd',
    offsetY: 30,
    offsetX: 7,
    style: {
      fontFamily: 'AR One Sans',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    }
  },
  tooltip: {
    enabled: false
  },
  chart: {
    type: 'bar',
    toolbar: {
      show: true,
      tools: {
        download: true,
        pan: false,
        reset: false,
        zoom: false,
        zoomin: false,
        zoomout: false
      }
    },
    defaultLocale: 'nb',
    locales: [{
      "name": "nb",
      "options": {
        "months": [
          "Januar",
          "Februar",
          "Mars",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Desember"
        ],
        "shortMonths": [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Des"
        ],
        "days": [
          "Søndag",
          "Mandag",
          "Tirsdag",
          "Onsdag",
          "Torsdag",
          "Fredag",
          "Lørdag"
        ],
        "shortDays": [
          "Sø",
          "Ma",
          "Ti",
          "On",
          "To",
          "Fr",
          "Lø"
        ],
        "toolbar": {
          "exportToSVG": "Last ned SVG",
          "exportToPNG": "Last ned PNG",
          "exportToCSV": "Last ned CSV",
          "download": "Last ned",
          "selection": "Velg",
          "selectionZoom": "Zoom: Velg",
          "zoomIn": "Zoome inn",
          "zoomOut": "Zoome ut",
          "pan": "Skyving",
          "reset": "Start på nytt"
        }
      }
    }]
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 'dataPoints',
    tickPlacement: 'between',
    labels: {
      format: 'MMM',
      style: {
        fontWeight: 'normal'
      }
    }
  },
  yaxis: {
    labels: {
      formatter: (value) => value == null ? '' : `${value.toLocaleString('nb')} kg`
    }
  }  
}

export const GraphLeveranser: React.FC = () => {

  const customerId = window.flytSettings.user!.customerId;

  const { data: leveranser, isError, isLoading, isSuccess } = useDataStore<any>(`/api/customers/statistics/${customerId}/monthly`, { entityName: 'WorkOrderEntity' });
  const values = (leveranser?.Series[0].Data ?? []).map((v: any, i: any) => {
    return [v[0], Math.max(v[1], leveranser.Series[1].Data[i][1])]
  })

  const series: ApexOptions["series"] = [{
    name: 'Denne måned', data: values
  }];

  if (isError)
    return null;

  return <div className="chart">
    {isLoading && <div className="flex-center"><Pending /></div>}
    {isSuccess && <ReactApexChart options={options} series={series} height={450} type={"bar"} />}
  </div>
}