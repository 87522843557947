import * as React from "react";
import { FilterProps } from "../IListDefinition";
import { observer } from "mobx-react";
import { v4 as guid } from "uuid";
import * as moment from "moment";
import "./daterangefilter.tsx.scss";
import { BaseModel } from "../../../models/shared/BaseModel";
import { DateSelector } from "../../shared/DatePicker";
import { format, parseISO, getWeek } from "date-fns";

interface OwnProps { }
interface FilterValue {
  from?: string;
  to?: string;
}

type P = FilterProps<BaseModel> & OwnProps;

interface OwnState {
  activePreset?: string;
  offset: number;
}

@observer
class DateRangeFilter extends React.Component<P, OwnState> {
  fromId = guid();
  toId = guid();
  state: OwnState = { activePreset: undefined, offset: 0 };

  setFromValue = (date: Date | null) => {
    const { store, field } = this.props;
    const old = store.getFilterValue<FilterValue>(field) || { from: undefined, to: undefined };
    const from = date == null ? undefined : format(date, "yyyy-MM-dd");
    store.setFilter(field, { ...old, from });
    this.setState({ activePreset: undefined, offset: 0 });
  }

  setToValue = (date: Date | null) => {
    const { store, field } = this.props;
    const old = store.getFilterValue<FilterValue>(field) || { from: undefined, to: undefined };
    const to = date == null ? undefined : format(date, "yyyy-MM-dd");
    store.setFilter(field, { ...old, to });
    this.setState({ activePreset: undefined, offset: 0 });
  }

  clear = () => {
    this.setState({ offset: 0, activePreset: undefined });
    this.props.store.setFilter(this.props.field, undefined);
  }

  setPeriod = (period: string) => {
    const { store, field } = this.props;
    let { activePreset, offset } = this.state;

    if (period === "D")
      if (activePreset === "D") {
        offset++;
        const from = moment().subtract(offset, "day");
        store.setFilter(field, { from: from.format("YYYY-MM-DD"), to: from.add(1, "day").format("YYYY-MM-DD") });
      } else {
        offset = 0;
        const from = moment();
        store.setFilter(field, { from: from.format("YYYY-MM-DD"), to: from.add(1, "day").format("YYYY-MM-DD") });
      }

    if (period === "U")
      if (activePreset === "U") {
        offset++;
        const from = moment().subtract(offset, "week").startOf('week');
        store.setFilter(field, { from: from.format("YYYY-MM-DD"), to: from.add(6, "days").format("YYYY-MM-DD") });
      } else {
        offset = 0;
        const from = moment().startOf('week');
        store.setFilter(field, { from: from.format("YYYY-MM-DD"), to: from.add(6, "days").format("YYYY-MM-DD") });
      }

    if (period === "M")
      if (activePreset === "M") {
        offset++;
        const from = moment().subtract(offset, "month").startOf('month');
        store.setFilter(field, { from: from.format("YYYY-MM-DD"), to: from.add(1, "month").subtract(1, "day").format("YYYY-MM-DD") });
      } else {
        offset = 0;
        const from = moment().startOf('month');
        store.setFilter(field, { from: from.format("YYYY-MM-DD"), to: from.add(1, "month").subtract(1, "day").format("YYYY-MM-DD") });
      }

    if (period === "Å")
      if (activePreset === "Å") {
        offset++;
        const from = moment().subtract(offset, "year").startOf('year');
        store.setFilter(field, { from: from.format("YYYY-MM-DD"), to: from.add(1, "year").subtract(1, "day").format("YYYY-MM-DD") });
      } else {
        offset = 0;
        const from = moment().startOf('year');
        store.setFilter(field, { from: from.format("YYYY-MM-DD"), to: from.add(1, "year").subtract(1, "day").format("YYYY-MM-DD") });
      }

    this.setState(s => ({ activePreset: period, offset }));
  }

  render() {
    const { store, field } = this.props;
    const activePreset = this.state.activePreset;
    const value = store.getFilterValue<FilterValue>(field) || { from: undefined, to: undefined };
    let weekNumber = "";
    if (value?.from && activePreset === "U")
      weekNumber = ` (Viser uke: ${getWeek(parseISO(value.from), { weekStartsOn: 1, firstWeekContainsDate: 4 }).toString()})`;

    return (
      <div className="filter-date-range">
        <ul className="quick-date-selector">
          <span onClick={_ => this.setPeriod("D")} title={activePreset === "D" ? "I går" : "I dag"}>D</span>
          <span onClick={_ => this.setPeriod("U")} title={`${(activePreset === "U" ? "Forrige uke" : "Denne uke")}${weekNumber}`}>U</span>
          <span onClick={_ => this.setPeriod("M")} title={activePreset === "M" ? "Forrige måned" : "Denne måned"}>M</span>
          <span onClick={_ => this.setPeriod("Å")} title={activePreset === "Å" ? "Forrige år" : "I år"}>Å</span>
          <span onClick={_ => this.clear()} title="Nullstill">X</span>
        </ul>
        <div className="input">
          <label htmlFor={this.fromId}>Fra:</label>
          <DateSelector
            id={this.fromId}
            onChange={this.setFromValue}
            dateFormat="dd. MMM yyyy"
            showWeekNumbers
            showYearDropdown
            title="Fra og med"
            minDate={new Date(2010, 0, 1)}
            maxDate={value == null || value.to == null ? new Date() : parseISO(value.to)}
            selected={value == null || value.from == null ? null : parseISO(value.from)} />
        </div>
        <div className="input">
          <label htmlFor={this.toId}>Til:</label>
          <DateSelector
            id={this.toId}
            onChange={this.setToValue}
            showWeekNumbers
            dateFormat="dd. MMM yyyy"
            showYearDropdown
            title="Til og med"
            minDate={value == null || value.from == null ? new Date(2010, 0, 1) : parseISO(value.from)}
            maxDate={new Date()}
            selected={value == null || value.to == null ? null : parseISO(value.to)} />
        </div>
      </div>
    )
  }
}

const A = DateRangeFilter as any as React.ComponentClass<OwnProps>;

export { A as DateRangeFilter };