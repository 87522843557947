import { AddressWithNameAndContact } from "../../models/shared/AddressWithNameAndContact";
import * as React from "react";
import { toCountry } from "../../core/util";

const AddressView: React.FC<{ address?: AddressWithNameAndContact, title: string }> = ({ address, title }) => {
  if (address && address.PostalCode) {
    return (
      <div className="addressView">
        {title && <h3>{title}</h3>}
        <div className="address-display">
          {address.Name && <span>{address.Name}</span>}
          {address.Line1 && <span>{address.Line1}</span>}
          {address.Line2 && <span>{address.Line2}</span>}
          {address.PostalCode && <span>{address.PostalCode} {address.City}</span>}
          {address.Country && <span>{toCountry(address.Country)}</span>}          
          {address.ContactName && <><br /><span>Kontakt: {address.ContactName} ({address.ContactPhone})</span></>}          
          {address.Information && <><br /><span>Informasjon:<br/>{address.Information}</span></>}
        </div>
      </div>
    )
  }

  return null;
}

export { AddressView };