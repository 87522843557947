import * as React from "react";
import { Form } from "../../../components/forms/form";
import { FormStore, IValidationRules } from "../../../stores/form-store";
import { UnitEditModel } from "../../../models/shared/UnitEditModel";
import { observer } from "mobx-react";
import { FormActions } from "../../../components/forms/FormActions";
import { TextAreaField } from "../../../components/forms/fields/TextAreaField";
import { BooleanField } from "../../../components/forms/fields/BooleanField";
import { InputField } from "../../../components/forms/fields/InputField";
import { FilesField } from "../../../components/forms/fields/FilesField";
import { VolumeField } from "../../../components/forms/fields/VolumeField";
import { CheckBoxField } from "ts/components/forms/fields/CheckBoxField";
import { ArticleType } from "ts/models/shared/ArticleType";
import { InputWithFix } from "../../../components/shared/InputWithFix";
import { Volume } from "../../../models/shared/Volume";
import { UnitState } from "../../../models/shared/UnitState";
import { Direction } from "../../../models/shared/Direction";
import queryString from "query-string";
import { StateField } from "../../../components/forms/fields/StateField";
import { ArticleListModel } from "../../../models/shared/ArticleListModel";
import { Network } from "../../../api/network";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import { RepeatField } from "../../../components/forms/fields/RepeatField";
import { UnitWeightModel } from "../../../models/shared/UnitWeightModel";
import { FormErrors } from "../../../components/forms/FormErrors";
import { PalletField } from "./PalletField";
import { SelectField } from "../../../components/forms/fields/SelectField";
import { EntityType } from "../../../core/stringHelpers";

interface Props {
  workOrderId: number;
  id?: number;
}

type P = Props;

const validationRules: IValidationRules<UnitEditModel> = {
  Volume: [
    (val: Volume) => {
      if (val != null) {
        const parts = [val.Height, val.Length, val.Width];
        if (parts.every(v => Number.isInteger(v)) || parts.every(v => isNaN(v)))
          return null;
        else
          return "Du må oppgi mål for alle eller ingen av feltene i lengde, bredde og høyde.";
      }
      return null;
    }
  ]
}

@observer
export class EditUnit extends React.Component<P> {

  store: FormStore<UnitEditModel>;
  direction: Direction;

  constructor(props: P) {
    super(props);

    var q = queryString.parse(location.search);
    if (q["direction"] == "outgoing")
      this.direction = Direction.Outgoing;
    else
      this.direction = Direction.Incoming;

    this.store = new FormStore<UnitEditModel>(validationRules, {
      WorkOrderId: this.props.workOrderId,
      Direction: this.direction,
      CurrentState: this.direction == Direction.Incoming ? UnitState.Received : UnitState.Packaging,
    });

    if (this.props.id)
      this.store.loadModel("units", this.props.id);
  }


  render() {
    const { id, workOrderId } = this.props;
    if (id && !this.store.modelHasLoaded)
      return null;

    const direction = id ? this.store.values.Direction! : this.direction;


    return (
      <div className="panel form-container">
        <Form
          store={this.store}
          name="Arbeidsordre"
          entityType={EntityType.Unit}
          className="edit-form edit-form-padding"
          onCancel={(router) => router.goBack()}
          onSubmit={async router => {
            const id = await this.store.postForm(`/api/units/${this.store.isEdit ? "update" : "add"}`);
            if (id)
              router.push(`/workorders/${workOrderId}`, { unitId: id });
          }}>
          {direction == Direction.Incoming && <IncomingUnitComponent workOrderId={workOrderId} />}
          {direction == Direction.Outgoing && <OutgoingUnitComponent workOrderId={workOrderId} />}
          <FormErrors type="Kolliet" />
          <FormActions />
        </Form>
      </div>
    )
  }
}

const OutgoingUnitComponent: React.FC<{ workOrderId: number }> = ({ workOrderId }) => {

  const [articles, setArticles] = React.useState<Array<BaseLookupModel>>([]);
  React.useEffect(() => {

    const loadData = async () => {
      const network = new Network();
      const result = await network.get<Array<ArticleListModel>>(`/api/articles/ArticlesForWorkOrder/${workOrderId}`);
      if (result)
        setArticles(
          result
            .filter(l => l.ArticleType == ArticleType.ZinkArticle)
            .filter(l => l.Id != 25)
            .sort((a, b) => a.Id - b.Id)
            .map<BaseLookupModel>(a => ({ Id: a.Id, Name: a.Title }))
        );
    }

    loadData();
  }, [workOrderId]);

  return (<>
    <h3>Utgående kolli</h3>
    <h4>Informasjon</h4>
    <StateField<UnitEditModel>
      field="CurrentState"
      label="Status"
      direction={Direction.Outgoing} />
    <h4>Vekt og mål</h4>
    <VolumeField<UnitEditModel>
      field="Volume"
      helpText="Angi målene på dette kolli i centimeter - 'Lengde' * 'Bredde' * 'Høyde' - eks: 300*120*100 = 3,0m langt 1,2m bredt og 1,0m høyt"
      label="Volum"
    />
    <BooleanField<UnitEditModel>
      field="MissingPallet"
      helpText="Marker hvis europall mangler"
      label="Mangler pall"
    />
    <PalletField<UnitEditModel>
      field="PalletWeight"
      helpText="Benyttes dersom vekt inkluderer vekten av en pall"
      label="Paller"
    />
    <h4>Leveringskontroll</h4>
    <FilesField<UnitEditModel>
      field="Images"
      label="Bilder pakket gods" />
    <FilesField<UnitEditModel>
      field="ErrorImages"
      label="Bilder av problemer" />
    <FilesField<UnitEditModel>
      field="ExtraWorkImages"
      label="Bilder av ekstraarbeid" />
    <h4>Leveringskontroll</h4>
    <RepeatField<UnitEditModel>
      field="AfterWeights"
      label="Vekter"
      addText="Legg til vekt"
      container={repeater => <table><thead><tr><th>Artikkel</th><th>Vekt</th></tr></thead><tbody>{repeater}</tbody></table>}
      helpText="Her kan du legge til en eller fler vekter for kolliet.">
      {(item: UnitWeightModel, onChange: (field: keyof UnitWeightModel, value: any) => void, addComponent, index) => (
        <tr key={index}>
          <td><select value={item.ArticleId} onChange={e => onChange("ArticleId", e.target.value)}>
            <option value="">-- Velg artikkel --</option>
            {articles.map(a => <option key={a.Id} value={a.Id}>{a.Name}</option>)}
          </select></td>
          <td><InputWithFix type="number" value={item.Weight} onChange={e => onChange("Weight", e.target.value)} postfix="kg" /></td>
          <td>{addComponent}</td>
        </tr>
      )}
    </RepeatField>
  </>);
}

const IncomingUnitComponent: React.FC<{ workOrderId: number }> = ({ workOrderId }) => {

  const [articles, setArticles] = React.useState<Array<BaseLookupModel>>([]);
  React.useEffect(() => {

    const loadData = async () => {
      const network = new Network();
      const result = await network.get<Array<ArticleListModel>>(`/api/articles/ArticlesForWorkOrder/${workOrderId}`);
      if (result)
        setArticles(
          result
            .filter(l => l.ArticleType == ArticleType.ZinkArticle)
            .filter(l => l.Id != 25)
            .sort((a, b) => a.Id - b.Id)
            .map<BaseLookupModel>(a => ({ Id: a.Id, Name: a.Title }))
        );
    }

    loadData();
  }, [workOrderId]);

  return (<>
    <h3>Inkommende kolli</h3>
    <h4>Vekt og mål</h4>
    <InputField<UnitEditModel>
      field="IncomingWeight"
      type="number"
      inputMode="numeric"
      formatter={v => v != null && parseInt(v) == 0 ? "" : v}
      postfix="kg"
      autoFocus
      label="Svart vekt" />
    <VolumeField<UnitEditModel>
      field="Volume"
      helpText="Angi målene på dette kolli i centimeter - 'Lengde' * 'Bredde' * 'Høyde' - eks: 300*120*100 = 3,0m langt 1,2m bredt og 1,0m høyt"
      label="Volum"
    />
    <PalletField<UnitEditModel>
      field="PalletWeight"
      helpText="Benyttes dersom vekt inkluderer vekten av en pall"
      label="Paller"
    />
    <h4>Mottakskontroll</h4>
    <FilesField<UnitEditModel>
      field="Images"
      label="Bilder mottatt gods" />
    <BooleanField<UnitEditModel>
      field="MissingAttachmentHoles"
      helpText="Her har vi ikke noe å henge detaljen opp i. Alle deler må kunne henges opp i riktig vinkel for et godt resultat. Deler med lengde over 2 meter krever hull for oppheng i begge ender."
      label="Mangler hull å henge delen i på riktig måte" />
    <BooleanField<UnitEditModel>
      field="MissingDrainageHoles"
      helpText="Dette medfører luftlommer og ubelagte områder, eller sinkansamlinger der sinken ikke dreneres ut. Husk at vi senker dette i flytende metall i ca 45 graders vinkel - og at sinken må få strømme inn og luften slippe ut slik at alle flater blir eksponert."
      label="Mangler dreneringshull" />
    <BooleanField<UnitEditModel>
      field="AcidPocket"
      helpText="Hvis to flater er lagt mot hverandre og det ikke er hel-sveiset rundt, oppstår det en syrelomme mellom delene. Det er ikke tillatt å sveise to flater større enn 70 cm<sup>2</sup> uten røntgentest eller bevisst drenering . Dette pga trykk mellom flater under høy temperatur."
      label="Syrelomme" />
    <BooleanField<UnitEditModel>
      field="DoubleDip"
      helpText="Lengder over 7,2 meter må dobbeltduppes. Noen deler kan kreve ekstraarbeid, og vil medføre ekstrakostnad - det er kundens oppgave å varsle hvis slike kostnader ikke er akseptert."
      label="Skal dobbelduppes" />
    <BooleanField<UnitEditModel>
      field="RemovePaint"
      helpText="Kryss av hvis det er maling på noen av delene. Maling må fjernes før behandling i saltsyre - saltsyre fjerner ikke maling og dette resulterer i områder som ikke blir sinkbelagt."
      label="Maling må fjernes" />
    <BooleanField<UnitEditModel>
      field="ContainsZink"
      helpText="Kryss av hvis vi mottar deler med sink på. Sink i saltsyren kan fordoble avgiftene på deponering av saltsyre."
      label="Sinkavbrenning" />
    <BooleanField<UnitEditModel>
      field="ConstructionError"
      helpText="Deler er feil konstruert og kan deformeres i prosessen."
      label="Feil konstruksjon" />
    <BooleanField<UnitEditModel>
      field="Damaged"
      helpText="Kryss av hvis deler kommer til oss skadet."
      label="Skadet" />
    <BooleanField<UnitEditModel>
      field="Degrease"
      helpText="Kryss av hvis deler krever avfetting."
      label="Avfetting" />
    <BooleanField<UnitEditModel>
      field="Refurbish"
      helpText="Kryss av hvis det kreves rehabilitering."
      label="Rehabilitering" />
    <BooleanField<UnitEditModel>
      field="Sandblast"
      helpText="Deler må sendes til Sandblåsing."
      label="Skal sandblåses" />
    <BooleanField<UnitEditModel>
      field="AcidOverNight"
      helpText="Marker hvis godset skal syres av over natten"
      label="Syres over natt"
    />
    <TextAreaField<UnitEditModel>
      field="SandblastDescription"
      showIf={(values) => values.Sandblast === true}
      helpText="Beskriv delene som skal sandblåses; antall kolli med lengde, bredde, høyde og vekt. Dette sendes direkte til sandblåser etter ordren er registrert."
      label="Sandblåsningsbeskrivelse" />
    <BooleanField<UnitEditModel>
      field="Other"
      helpText="Kolli har andre feil eller mangler"
      label="Andre feil" />
    <TextAreaField<UnitEditModel>
      field="OtherDescription"
      showIf={(values) => values.Other === true}
      helpText="Beskriv hva annet som er galt med kolli."
      label="Annen feil beskrivelse" />
    <FilesField<UnitEditModel>
      field="ErrorImages"
      label="Bilder av problemer" />
    <FilesField<UnitEditModel>
      field="ExtraWorkImages"
      label="Bilder av ekstraarbeid" />
    <BooleanField<UnitEditModel>
      field="MissingPallet"
      helpText="Marker hvis europall mangler"
      label="Mangler pall"
    />
    <SelectField<UnitEditModel>
      field="AcidTime"
      label="Syretid"
      helpText="Hvor lenge kolli skal syrebehandles">
      <option value=""></option>
      <option value="15">Kort syretid - 15 min</option>
      <option value="30">Kort syretid - 30 min</option>
      <option value="120">Vanlig syretid - 2 timer</option>
      <option value="240">Lengresyretid - 4 timer</option>
      <option value="480">Over natt</option>
    </SelectField>
    <BooleanField<UnitEditModel>
      field="CheckBeforeDipp"
      label="Kontroll før gryte"
      helpText="Indikerer at kolli må verifiseres før dupping"
    />
    <h4>Leveringskontroll</h4>
    <CheckBoxField<UnitEditModel>
      field="AvailableArticles"
      label="Artikler"
      helpText="Kryss av for hvilke artikler som skal være mulig å veie ut."
      options={articles}
    />
    <h4>Informasjon</h4>
    <StateField<UnitEditModel>
      field="CurrentState"
      label="Status"
      direction={Direction.Incoming} />
  </>);
}
