import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../../forms/wrapField";
import { InputWithFix } from "../../shared/InputWithFix";
import ralCodes from "json/RalCodes.json";
import "./ralColorField.tsx.scss";

interface OwnProps {
}

interface OwnState {
  color?: string;
  name?: string;
}

type P = PropsFromWrapperToField<string> & OwnProps;

class RalColorField extends React.PureComponent<P, OwnState> {

  constructor(props: P) {
    super(props);

    if (props.value != undefined && (ralCodes as any).hasOwnProperty(props.value)) {
      const clr = (ralCodes as any)[props.value];
      this.state = {
        color: clr.hex,
        name: clr.name
      };
    }
    else
      this.state = { name: undefined, color: undefined };
  }

  onChange(value?: string) {
    if (value && (ralCodes as any).hasOwnProperty(value))
      this.setState({
        color: (ralCodes as any)[value].hex,
        name: (ralCodes as any)[value].name
      });
    else
      this.setState({ name: undefined, color: undefined });

    this.props.setValue(value);
  }


  render() {
    const { value, setTouched, id, autoFocus } = this.props;
    const { name, color } = this.state;

    const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
      id,
      type: "text",
      pattern: "^\\d{4}$",
      onChange: e => this.onChange(e.target.value),
      value: value || "",
      title: "Firesifret RAL kode",
      inputMode: "numeric",
      onBlur: _ => setTouched()
    }

    return (<div className="field-ral-color">
      <InputWithFix prefix="RAL" {...inputProps} />
      {color && <div className="field-ral-color-info flex"><span className="field-ral-color-info-color" style={{ backgroundColor: color }} /><span>{name}</span></div>}
    </div>);
  }
}

const A = Wrap<P, string>(RalColorField)
export { A as RalColorField };

export function isValidRalColor(value: string) {
  return ralCodes.hasOwnProperty(value);
}