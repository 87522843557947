import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import { SelectFieldInternal } from "./SelectField";
import { default as RootStore } from "../../../stores/root-store";
import { Enums } from "../../../stores/lookup-store";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import { Direction } from "../../../models/shared/Direction";

interface OwnProps {
  direction: Direction;
}

interface State {
  options?: Array<BaseLookupModel>;
}

type P = PropsFromWrapperToField<number | string> & OwnProps;

class StateField extends React.PureComponent<P, State> {
  state: State = { options: undefined };

  async componentDidMount() {
    const options = await RootStore.LookupStore.getEnums(Enums.UnitState, false);
    this.setState({ options });
  }

  render() {
    const options = this.state.options;
    if (options == undefined)
      return null;

    const { direction } = this.props;
    const availableOptions = direction == Direction.Outgoing ? options.filter(o => o.Id > 250) : options.filter(o => o.Id < 300);

    return <SelectFieldInternal {...this.props}>
      {availableOptions.map(o => <option key={o.Id} value={o.Id}>{o.Name}</option>)}
    </SelectFieldInternal>
  }
}

const wrapped = Wrap<P, number | string>(StateField);

export {
  wrapped as StateField
}