import { observable, computed, action } from "mobx"
import { Dialog } from "../core/dialog";
import { IRootStore } from "./root-store";
import { getScreenSize } from "../core/util";

export class UIStore {

  @observable private networkCallsCount: number = 0;
  @observable private dialogs: Array<Dialog<any>> = [];
  @observable private _screenWidth: number = 0;
  @observable menuIsOpen: boolean = document.documentElement != null && document.documentElement.clientWidth >= 1365;

  private focusElement?: HTMLElement;

  public async initialize(root: IRootStore) {
    window.addEventListener("resize", () => {
      this._screenWidth = window.innerWidth;
    });

    this._screenWidth = window.innerWidth;
    return Promise.resolve()
  }

  @computed public get hasActiveNetworkCalls() {
    return this.networkCallsCount > 0;
  }

  @computed public get getDialogs() {
    return this.dialogs;
  }
  @computed public get hasDialog() {
    return this.dialogs.length > 0;
  }

  @action.bound
  public addNetworkCall() {
    this.networkCallsCount++;
  }

  @action.bound
  public reduceNetworkCall() {
    this.networkCallsCount--;
  }

  @action.bound
  public addDialog(dialog: Dialog<any>) {
    if (this.dialogs.some(d => d.id == dialog.id))
      return;

    if (this.focusElement == undefined)
      this.focusElement = document.activeElement as HTMLElement;
    this.dialogs.push(dialog);
  }

  @action.bound
  public closeDialog(dialogId: string) {
    this.dialogs = this.dialogs.filter(dlg => dlg.id != dialogId);
    if (!this.hasDialog && this.focusElement) {
      this.focusElement.focus();
      this.focusElement = undefined;
    }
  }

  @computed public get getScreenSize() {
    return getScreenSize(this._screenWidth);
  }
}