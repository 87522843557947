import { faAngleDown, faAngleUp, faChartLine, faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { max, sum } from "lodash-es";
import * as moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Legend, Bar } from "recharts";
import styled from "styled-components";
import { Network } from "../../../../api/network";
import { Pending } from "../../../../components/shared/Pending";
import { NotificationHub } from "../../../../core/signalR";

interface KeyFigureProp {
  title: string;
  valueFormatter: (value: number) => string;
  values: Array<{ Key: string; Value: number; }> | undefined;
  isLoading: boolean;
  period: number;
  setPeriod: (p: number) => void;
}

export const KeyFigureBox = styled.div`
    background-color: white;
    border: 1px solid #c5c5c5;
`;

export const KeyFigureContent = styled.div`
  padding: 10px 20px;    
  color: #5d5d5d;
  white-space: nowrap;
`;


export const KeyFigureTitle = styled.span`
  padding: 7px 13px;
  font-variant: small-caps;
  color: #fff;
`

export const FlexCenter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const A = styled.span`
  display: flex;
  align-items: center;
`

export const KeyFigureValue = styled.span`    
  font-size: 32px;    
  font-weight: bold;    
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const CustomToolTip = styled.div`
  margin: 0px; 
  padding: 10px; 
  background-color: rgb(255, 255, 255); 
  border: 1px solid rgb(204, 204, 204); 
  white-space: nowrap;
`

const IconComponent = styled(FontAwesomeIcon)`
  margin-left: 10px;
  margin-right: 3px;
`
const Text = styled.span`
  font-size: 0.9rem;
`
const KpiIcon: React.FC<{ change: "Up" | "None" | "Down", percentage: number }> = ({ change, percentage }) => {
  const icon = change == "Up" ? faAngleUp : change == "Down" ? faAngleDown : faChartLine;

  const Container = styled.div`
    color: ${change == "Up" ? "green" : change == "Down" ? "red" : "black"};
  `
  return <Container><IconComponent icon={icon} /><Text>{percentage.toFixed(percentage > 10 || percentage < -10 ? 0 : 2)} %</Text></Container>
}

const Period = styled.button`
  border-radius: 15px;
  background: white;
  padding: 4px;
  text-align: center;
  border: 1px solid #000;
  margin-right: 5px;
  font-size: 13px;
min-width: 24px;
cursor: pointer;
`;

export const KeyFigureHeader = styled(FlexCenter)`
  background-color: #616161;
  width: 100%;
`;

const Icon = styled.span`
color: #fff;
padding-right: 10px;
    margin-left: 2px;
    cursor: pointer;
`

export const KeyFigureComponent: React.FC<KeyFigureProp> = ({ title, valueFormatter, isLoading, values, setPeriod, period }) => {

  const wrapper = (content: React.ReactElement | null) => <KeyFigureBox><KeyFigureHeader><KeyFigureTitle>{title}</KeyFigureTitle></KeyFigureHeader><KeyFigureContent><FlexCenter>{content}</FlexCenter></KeyFigureContent></KeyFigureBox>;
  const [isExpanded, setIsExpanded] = useState(false);
  if (values == undefined)
    return wrapper(<Pending />);
  else if (values.length == 0)
    return wrapper(null);

  const value = values[0].Value;
  const lastValue = values.length > 1 ? values[1].Value : 0;
  const average = (sum(values.map(v => v.Value)) / values.length);

  const change = value === lastValue ? "None" : value > lastValue ? "Up" : "Down";
  const percentage = (100 / lastValue * value) - 100;
  const graphData = values.map(v => ({ x: v.Key, y: v.Value })).reverse();

  return <KeyFigureBox className={isExpanded ? "expanded" : ""}>
    <KeyFigureHeader>
      <KeyFigureTitle>{title}</KeyFigureTitle>
      <FlexCenter>
        <Period type="button" onClick={e => setPeriod(260)} className={classNames("p-260", { "selected": period === 260 })}>365</Period>
        <Period type="button" onClick={e => setPeriod(50)} className={classNames("p-50", { "selected": period === 50 })}>60</Period>
        <Period type="button" onClick={e => setPeriod(25)} className={classNames("p-25", { "selected": period === 25})}>30</Period>
        <Period type="button" onClick={e => setPeriod(5)} className={classNames("p-5", { "selected": period === 5 })}>7</Period>
        <Icon onClick={() => setIsExpanded(!isExpanded)}><FontAwesomeIcon icon={isExpanded ? faCompress : faExpand} /></Icon>
      </FlexCenter>
    </KeyFigureHeader>
    <KeyFigureContent>
      <FlexCenter>
        <A>
          <KeyFigureValue title="I dag">{valueFormatter(value)}</KeyFigureValue>
          <KpiIcon change={change} percentage={percentage} />
        </A>
        <FlexColumn>
          <span title="Siste dag">{valueFormatter(lastValue)}</span>
          <span title="Siste 5 dager">{valueFormatter(average)}</span>
        </FlexColumn>
      </FlexCenter>
      <div>
        <ResponsiveContainer width="100%" height={120}>
          <LineChart data={graphData} margin={{ top: 12, left: 3, right: 3, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" interval="preserveStartEnd" tickFormatter={v => {
              if (graphData.length > 7)
                return moment(v).format("DD.M")
                else
              return moment(v).format("dd")
            }
            } />
            <YAxis dataKey="y" hide={true} />
            <Tooltip content={({ active, payload, label }) => {
              if (active && payload && payload.length && graphData.length < 8) {
                return <CustomToolTip className="recharts-default-tooltip">{valueFormatter(parseInt(payload[0].value?.toString() ?? ""))}</CustomToolTip>
              }
              return null;
            }} />
            <Line type="monotone" dataKey="y" stroke="#8884d8" dot={graphData.length > 7 ? false: true} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </KeyFigureContent>
  </KeyFigureBox>
}

type DueWeightType = { Date: string, Weight: number; Produced: number };

export const KeyFigureDueWeight: React.FC<{}> = () => {

  const title = "Forfall";
  const valueFormatter = (v: number) => `${Math.round(v).toLocaleString("nb-NO")} kg`;

  const wrapper = (content: React.ReactElement | null) => <KeyFigureBox><div><KeyFigureTitle>{title}</KeyFigureTitle></div><KeyFigureContent><FlexCenter>{content}</FlexCenter></KeyFigureContent></KeyFigureBox>;

  const [values, setValues] = useState<Array<DueWeightType>>([]);

  const loadData = async () => {
    const network = new Network();
    const result = await network.get<Array<DueWeightType>>(`/api/statistics/keyDue`);
    if (Array.isArray(result))
      setValues(result);
  }

  useEffect(() => {
    const dispose = NotificationHub.registerListener(m => {
      if (m.some(m => m.entityName == "WorkOrderEntity"))
        setTimeout(() => loadData(), Math.random() * 3000);
    });
    loadData();
    return () => NotificationHub.removeListener(dispose);
  }, []);


  if (values == undefined)
    return wrapper(<Pending />);
  else if (values.length == 0)
    return wrapper(null);

  const total = sum(values.map(m => max([m.Weight, m.Produced])));

  const graphData = values.map(m => ({
    Date: m.Date,
    Weight: max([m.Weight - m.Produced, 0]),
    Produced: m.Produced,
    Total: max([m.Weight, m.Produced])
  }))

  return <KeyFigureBox>
    <KeyFigureHeader>
      <KeyFigureTitle>{title}</KeyFigureTitle>
    </KeyFigureHeader>
    <KeyFigureContent>
      <FlexCenter>
        <A>
          <KeyFigureValue title="Totalt">{valueFormatter(total)}</KeyFigureValue>
        </A>
        <FlexColumn>
        </FlexColumn>
      </FlexCenter>
      <div>
        <ResponsiveContainer width="100%" height={120}>
          <BarChart data={graphData} margin={{ top: 12, left: 3, right: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Date" interval="preserveStartEnd" tickFormatter={v => moment(v).format("dd")} />
            <YAxis tickFormatter={v => `${Math.round(v).toLocaleString("nb-NO")}`} orientation="right" width={45} />
            <Tooltip content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return <CustomToolTip className="recharts-default-tooltip">{valueFormatter(payload[0].payload.Weight)} ({valueFormatter(payload[0].payload.Total)})</CustomToolTip>
              }
              return null;
            }} />
            <Bar dataKey="Produced" stackId="a" fill="#82ca9d" />
            <Bar dataKey="Weight" stackId="a" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </KeyFigureContent>
  </KeyFigureBox>
}