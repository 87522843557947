import * as React from "react";
import "./MoveUnitDialog.tsx.scss";

export interface MoveUnitModelState {
  useExsting: boolean;
  workOrderId?: number;
}

interface MoveUnitModelProps {
  dialogState: Partial<MoveUnitModelState>;
}

export class MoveUnitDialogComponent extends React.Component<MoveUnitModelProps> {

  constructor(props: MoveUnitModelProps) {
    super(props);
    props.dialogState.useExsting = false;
  }

  render() {
    const { dialogState } = this.props;

    return (
      <div className="dialog-move-unit-content">
        <div className="dialog-move-unit-content-options">
          <label><input
            type="radio"
            name="move-unit-type"
            checked={!dialogState.useExsting!}
            onChange={e => e.target.checked ? dialogState.useExsting = false : null} />
            Opprett ny arbeidsordre</label>
          <label><input
            type="radio"
            name="move-unit-type"
            checked={dialogState.useExsting!}
            onChange={e => e.target.checked ? dialogState.useExsting = true : null} />
            Flytt til existerende arbeidsordre</label>
        </div>
        {dialogState.useExsting! &&
          <div className="dialog-move-unit-input">
            <span>Eksisterende arbeidsordre id:</span>
            <input
              type="number"
              inputMode="numeric"
              autoFocus
              value={dialogState.workOrderId || ""}
              onChange={e => dialogState.workOrderId = parseInt(e.target.value)} />
          </div>}
      </div>
    );
  }
}
