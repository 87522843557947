import { createPopper, Instance } from "@popperjs/core";
import * as React from "react";
import { useEffect, useRef } from "react";
import { DialogList } from "../shared/messages/MessageDialog";
import "./DialogListPopupDialog.tsx.scss";

interface P {
  id: number;
  element: HTMLElement | null;
  type: string;
}

export const DialogListPopupDialog: React.FC<P> = ({ id, element, type = "WorkOrderEntity" }) => {

  const popupRef = useRef<HTMLDivElement | null>(null);
  const [popper, setPopper] = React.useState<Instance | null>(null);

  useEffect(() => {
    let popper: Instance | null = null;

    if (element && popupRef.current) {
      popper = createPopper(element, popupRef.current, {
        placement: 'right',
        modifiers: [
          { name: 'offset', options: { offset: [0, 10] } },
          { name: 'preventOverflow' },
        ]
      });
      popupRef.current.style.display = "block";
      setPopper(popper);
    }
    return () => popper ? popper.destroy() : undefined;
  }, []);

  const onUpdate = () => {
    popper?.update();
  }

  return <div ref={popupRef} className="wo-popup-dialog">
    <DialogList type={type} id={id} onUpdate={onUpdate} />
  </div>;
}