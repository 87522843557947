import * as React from "react";
import { useEffect, useState } from "react";
import { Network } from "../../../../api/network";
import { ChangeType, NotificationHub } from "../../../../core/signalR";
import { EntityType } from "../../../../core/stringHelpers";
import { KeyFigureComponent } from "./keyfigurebase"

type Data = {
  Key: string;
  Value: number;
}

interface KeyFigureProps {
  title: string;
  formatter: (v: number) => string;
  url: string;  
}

export const KeyFigure: React.FC<KeyFigureProps> = ({ formatter, title, url }) => {

  const [values, setValues] = useState<Array<Data>>([]);
  const [period, setPeriod] = useState(6);

  const loadData = async () => {
    const network = new Network();
    const result = await network.get<Array<Data>>(`${url}?period=${period}`);
    if (Array.isArray(result))
      setValues(result);
  }
  
  useEffect(() => {
    const dispose = NotificationHub.registerListener(m => {
      if (m.some(m => m.entityName == "WorkOrderEntity"))
        setTimeout(() => loadData(), Math.random() * 3000);
    });
    loadData();
    return () => NotificationHub.removeListener(dispose);
  }, [period]);

  return <KeyFigureComponent title={title} valueFormatter={formatter} isLoading={false} values={values} period={period} setPeriod={setPeriod}/>
}
