/**  */
export enum GalvanizingStandard {
  
  /** */
  ISO1461 = 1,  
  /** */
  ISO1461_115 = 2,  
  /** */
  ISO1461_165 = 3,  
  /** */
  ISO1461_215 = 4,  
  /** */
  ISO1461_B = 5,  
  /** */
  ISO1461_B_C = 6,  
  /** */
  NORSOK = 7,  
  /** */
  None = 0
}

