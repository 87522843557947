import * as React from "react"
import { useDataStore } from "../../../core/hooks/useDataStore";
import { NavigationData } from "../../../models/shared/NavigationData";
import { formatDate, since, toDateWithHM } from "../../../core/dateHelpers";
import { Link } from "react-router-dom";
import { UAParser } from 'ua-parser-js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-regular-svg-icons";
import { faSun } from "@fortawesome/free-solid-svg-icons";

interface P {
  userId: number;
}

export const NavigationHistoryList: React.FC<P> = ({ userId }) => {

  const { data } = useDataStore<NavigationData[]>("/api/users/navigationHistory/" + userId, { entityName: "NavigationData", entityId: userId });

  return <div className="table-container navigation-history-list">
    <table>
      <thead>
        <tr>
          <th>Dato</th>
          <th>Side</th>
          <th>IP-adresse</th>
          <th>Nettleser</th>
          <th>Opeativsystem</th>
          <th>Språk</th>
          <th className="center">Dark-mode</th>
        </tr>
      </thead>
      <tbody>
        {data?.map(d => {
          const { browser, cpu, device, os } = UAParser(d.Agent);
          return (
            <tr key={d.Created}>
              <td><span title={since(d.Created)}>{formatDate(d.Created, "DD MMMM YYYY HH:mm:ss")}</span></td>
              <td><Link to={{ pathname: d.Pathname, search: d.Search, state: d.State }}>{d.Pathname}</Link></td>
              <td><a href={`https://iplocation.io/ip/${d.Host}`} target="_blank">{d.Host}</a></td>
              <td><span title={d.Agent}>{browser.name} {browser.version}</span></td>
              <td>{os.name} {device.vendor} {device.model}</td>
              <td>{d.Language}</td>
              <td className="center"><FontAwesomeIcon icon={d.DarkMode ? faMoon : faSun} /></td>
            </tr>
          )})}
      </tbody>
    </table>
  </div>
}