import * as React from "react";
import { CustomerFilter } from "../../../components/lists/FilterComponents/CustomerFilter";
import { DateRangeFilter } from "../../../components/lists/FilterComponents/DateRangeFilter";
import { WeightListModel } from "../../../models/shared/WeightListModel";
import { WeightListRequestModel } from "../../../models/shared/WeightListRequestModel";
import { observer } from "mobx-react";
import { ListStore } from "../../../stores/list-store";
import { BaseModel } from "../../../models/shared/BaseModel";
import { IListDefinition, Alignment, FilterType } from "../../../components/lists/IListDefinition";
import { Link } from "react-router-dom";
import { toDate, since } from "../../../core/dateHelpers";
import { NumberWithPostfix } from "../../../components/shared/display";
import { SortDirection } from "../../../models/shared/SortDirection";
import * as moment from "moment";
import { List } from "../../../components/lists/List";
import { isNumber, sum } from "lodash-es";
import classNames from "classnames";
import "./CustomerWeights.tsx.scss";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { ArticleType } from "../../../models/shared/ArticleType";

interface State {
  data?: Array<WeightListModel>;
  query?: WeightListRequestModel;
}

type ListModel = WeightListModel & BaseModel;
type P = {}

const listConfiguration: IListDefinition<ListModel> = {
  id: "weightlist",
  singular: "Vektliste",
  createLink: _ => null,
  columns: [
    {
      title: "Id",
      field: "Id",
      alignment: Alignment.Right,
      sortable: true,
      render: (row, value, index, user) => <td className="right">{toSerialNumber(EntityType.Customer, value)}</td>
    },
    {
      title: "Kunde",
      field: "CustomerName",
      sortable: true,
      render: (row, value, index, user) => <td><Link to={`/customers/${row.Id}`}>{value}</Link></td>
    },
    {
      title: "Første ordre",
      field: "FirstDate",
      alignment: Alignment.Right,
      sortable: true,
      render: (row, value, index, user) => <td className="right">{value && <span title={since(value)}>{toDate(value)}</span>}</td>
    },
    {
      title: "Siste ordre",
      field: "LastDate",
      alignment: Alignment.Right,
      sortable: true,
      render: (row, value, index, user) => {
        if (value == null)
          return <td />;
        const days = moment().diff(moment(value), "days");

        return <td className={classNames("right", { "past-90": days > 90, "past-year": days > 365 })}>{value && <span title={since(value)}>{toDate(value)}</span>}</td>
      }
    },
    {
      title: "Antall ordre",
      field: "WorkOrders",
      alignment: Alignment.Right,
      sortable: true,
      render: (row, value, index, user) => <td className="right">{value && value.toLocaleString("nb-NO")}</td>
    },
    {
      title: "Mottatt Vekt",
      field: "IncomingWeight",
      alignment: Alignment.Right,
      sortable: true,
      render: (row, value, index, user) => <td className="right">{isNumber(value) && <NumberWithPostfix post="kg" number={value} empty="" />}</td>
    },
    {
      title: "Utveid Vekt",
      field: "OutgoingWeight",
      alignment: Alignment.Right,
      sortable: true,
      render: (row, value, index, user) => <td className="right">{isNumber(value) && <NumberWithPostfix post="kg" number={value} empty="" />}</td>
    },
    {
      title: "Inntekt",
      field: "Price",
      alignment: Alignment.Right,
      sortable: true,
      sortField: "#Price",
      hidden: (user, filters) => !user.isAdministrator,
      render: (row, value, index, user) => <td className="right">{isNumber(value) && <NumberWithPostfix post="kr" number={value} decimals={0} empty="" />}</td>
    },
    {
      title: "Prisliste",
      field: "RetailPrice",
      alignment: Alignment.Right,
      sortable: true,
      sortField: "#RetailPrice",
      hidden: (user, filters) => !user.isAdministrator,
      render: (row, value, index, user) => <td className="right">{isNumber(value) && <NumberWithPostfix post="kr" number={value} decimals={0} empty="" />}</td>
    },
    {
      title: "Rabatt",
      field: "Discount",
      alignment: Alignment.Right,
      sortable: true,
      sortField: "#Discount",
      hidden: (user, filters) => !user.isAdministrator,
      render: (row, value, index, user) => <td className="right">{isNumber(value) && <NumberWithPostfix post="kr" number={value} decimals={0} empty="" />}</td>
    },
    {
      title: "Pris per kilo",
      field: "PricePerKilo",
      alignment: Alignment.Right,
      sortable: true,
      sortField: "#PricePerKilo",
      hidden: (user, filters) => !user.isAdministrator,
      render: (row, value, index, user) => <td className="right">{isNumber(value) && <NumberWithPostfix post="kr" number={value} decimals={2} empty="" />}</td>
    }
  ],
  filters: [
    {
      title: "Periode",
      field: "Period",
      component: <DateRangeFilter />,
      type: FilterType.DateRange
    },
    {
      title: "Kunde",
      field: "Id",
      component: <CustomerFilter />,
      type: FilterType.Lookup,
      hidden: user => user.isCustomer
    }
  ],
  presets: [
    {
      title: "Siste 30 dager",
      default: true,
      filters: [
        {
          field: "Period",
          value: {
            from: moment().add(-30, "days").format("YYYY-MM-DD")
          },
          filterType: FilterType.DateRange
        }
      ],
      sort: [{ field: "OutgoingWeight", direction: SortDirection.Desc }]
    },
    {
      title: "Størst i år",
      default: false,
      filters: [
        {
          field: "Period",
          value: {
            from: moment().startOf("year").format("YYYY-MM-DD")
          },
          filterType: FilterType.DateRange
        }
      ],
      sort: [{ field: "OutgoingWeight", direction: SortDirection.Desc }]
    }
  ],
  showDelete: _ => false,
  baseUrl: `/api/customers/weights`,
  showCollapsableContentForRow: (row, user) => user.isAdministrator && (row.Articles?.length ?? 0) > 0,
  collapsableContent: (row) =>
    <div className="customer-weight-details css-table">
      <div className="customer-weight-details-header css-table-header">
        <span>Artikkel</span>
        <span className="right">Antall</span>
        <span className="right">Salg</span>
        <span className="right">Prisliste</span>
        <span className="right">Rabatt</span>
      </div>
      {row.Articles?.map(a =>
        <div className="customer-weight-details-item" key={a.Article}>
          <span>{a.Article}</span>
          <span className="right">{a.Amount.toLocaleString("nb-NO")}</span>
          <span className="right"><NumberWithPostfix post="kr" number={a.Price} decimals={0} /></span>
          <span className="right"><NumberWithPostfix post="kr" number={a.RetailPrice} decimals={0} /></span>
          <span className="right"><NumberWithPostfix post="kr" number={a.RetailPrice - a.Price} decimals={0} /></span>
        </div>)}
    </div>,
  footer: (set, user) => {
    const outgoingWeights = set.map(r => r.OutgoingWeight).filter(v => isNumber(v));
    const price = set.map(r => r.Price).filter(v => isNumber(v));
    const invoicedWeights = set.flatMap(s => s.Articles).filter(s => s?.Type === ArticleType.ZinkArticle).map(s => s?.InvoicedWeight).filter(v => isNumber(v));
    const average = sum(price) / sum(invoicedWeights);

    return <tr><td colSpan={5} />
      <td className="right">{sum(set.map(s => s.WorkOrders ?? 0)).toLocaleString("nb-NO")}</td>
      <td className="right"><NumberWithPostfix post="kg" number={sum(set.map(r => r.IncomingWeight ?? 0))} empty="" /></td>
      <td className="right"><NumberWithPostfix post="kg" number={sum(outgoingWeights)} empty="" /></td>
      {user.isAdministrator && <td className="right"><NumberWithPostfix post="kr" number={sum(price)} empty="" decimals={0} /></td>}
      {user.isAdministrator && <td className="right"><NumberWithPostfix post="kr" number={sum(set.map(r => r.RetailPrice ?? 0))} empty="" decimals={0} /></td>}
      {user.isAdministrator && <td className="right"><NumberWithPostfix post="kr" number={sum(set.map(r => r.Discount ?? 0))} empty="" decimals={0} /></td>}
      {user.isAdministrator && <td className="right"><NumberWithPostfix post="kr" number={average} empty="" decimals={2} /></td>}
    </tr>
  }
}


@observer
export class CustomerWeights extends React.Component<P, State> {

  store: ListStore<ListModel>;

  constructor(props: P) {
    super(props);
    this.store = new ListStore(listConfiguration);
  }

  render() {

    return <List
      configuration={listConfiguration}
      store={this.store}
      wrapInPanel={true} />
  }
}