import * as React from "react";
import classNames from "classnames";
import { Dialog } from "../../../core/dialog";
import { MessageListModel } from "../../../models/shared/MessageListModel";
import { DataStore2 } from "../../../stores/data-store";
import ChatIcon from "svg/chat.svg";
import { Network } from "../../../api/network";
import { useState } from "react";
import { MessageEditModel } from "../../../models/shared/MessageModel";
import { observer } from "mobx-react";
import "./MessageDialog.tsx.scss";
import { since } from "../../../core/dateHelpers";
import { IObservable, IObservableObject, IObservableValue, observable } from "mobx";

interface DialogListProps {
  type: string;
  id: number;
}

interface DialogListProps2 extends DialogListProps {
  onUpdate?: () => void;
}

interface MessageProps extends DialogListProps {
  hasMessages: boolean;
}

interface MessagePropsWithState<S> extends DialogListProps {
  state: { message?: string };
}

export const MessageListCell: React.FC<MessageProps> = props => {

  const onClick = () => {
    const dialog = new Dialog<{ message: string }>()
    dialog.buttons = [{
      text: "Lagre melding",
      onClick: async (s, c) => {
        const network = new Network();
        await network.post<MessageEditModel, number>(`/api/messages/add`, {
          EntityId: props.id,
          EntityType: props.type,
          Text: s.message,
          VisibleForCustomer: false
        }, true);
        c();
      },
      isEnabled: s => (s?.message?.length ?? 0) > 0
    }, { text: 'Avbryt', onClick: (s, c) => c() }];
    dialog.closable = true;
    dialog.title = "Dialog"
    dialog.content = observableState => <MessageDialogContent {...props} state={observableState} />
    Dialog.Show(dialog)
  }

  return <div onClick={e => onClick()} className={classNames("cell-action dialog-cell", { "dialog-has-messages": props.hasMessages })}><span><ChatIcon /></span></div>;
};

@observer
export class ChatPageList extends React.Component<DialogListProps > {

  @observable
  s: { message: undefined | string } = { message: undefined };

  onSubmit = async () => {
    const network = new Network();
    await network.post<MessageEditModel, number>(`/api/messages/add`, {
      EntityId: this.props.id,
      EntityType: this.props.type,
      Text: this.s.message,
      VisibleForCustomer: false
    }, true);

    this.s.message = "";
  }

  render() {
    return <div className="chat-page-list">
      <MessageDialogContent {...this.props} state={this.s} />
      <div className="buttons">
        <button className="button" onClick={this.onSubmit}>Lagre melding</button>
      </div>
    </div>
  }

}

export const MessageDialogContent: React.FC<MessagePropsWithState<string>> = props => {

  return <div>
    <DialogList type={props.type} id={props.id} />
    <SendMessage {...props} />
  </div>;
};

@observer
export class DialogList extends React.Component<DialogListProps2> {

  private store;

  constructor(props: DialogListProps2) {
    super(props);
    const { type, id } = this.props;
    this.store = new DataStore2<Array<MessageListModel>>(`/api/messages/${type}/${id}`, ch =>
      (ch.entityName == "MessageEntity"),
    );    
  }

  componentDidUpdate() {
    this.props.onUpdate?.();
  }

  render() {

    const list = this.store.data;
    if (list == null)
      return null;

    return <ul className="dialog">
      {list.map(s => <li key={s.Id} className="dialog-entry">
        <div className="dialog-entry-header">
          <span>{s.UserName}</span>
          <span className="date">{since(s.Created)}</span>
        </div>
        <div className="dialog-triangle" />
        <div className="dialog-entry-text">
          <span>{s.Text}</span>
        </div>
      </li>)}
    </ul>
  }

  componentWillUnmount() {
    this.store.dispose();
  }
}

export const SendMessage: React.FC<MessagePropsWithState<string>> = p => {

  return <><hr /><div>
    <form className="edit-form">
      <div className="form-field">
        <div className="form-field-descripion">
          <label className="form-field-label">Melding</label>
          <div className="form-field-help-container">
            <span className="form-field-help-text"><span>Informativ tekst</span></span>
          </div>
        </div>
        <div className="form-field-component">
          <textarea value={p.state?.message} onChange={e => p.state.message = e.target.value} autoFocus={true} />
        </div>
      </div>
    </form>
  </div></>
};