/**  */
export enum CardUsageType {
  
  /** */
  Driftsmidler = 1,  
  /** */
  PersonalKostnad = 2,  
  /** */
  Representasjon = 3,  
  /** */
  Sponsing = 4,  
  /** */
  Overtidsmat = 5,  
  /** */
  Rekvesita = 6,  
  /** */
  Disel = 7,  
  /** */
  IngenKvittering = 8,  
  /** */
  KredittkortPrivat = 9,  
  /** */
  Reisekostnader = 10,  
  /** */
  ReisekostnaderMat = 11
}

