import * as React from "react";
import { IListDefinition, Alignment, FilterType } from "../../../components/lists/IListDefinition";
import { List } from "../../../components/lists/List";
import { default as RootStore } from "../../../stores/root-store";
import { Link } from "react-router-dom";
import { CustomerFilter } from "ts/components/lists/FilterComponents/CustomerFilter";
import { TextFilter } from "ts/components/lists/FilterComponents/TextFilter";
import { AddressListModel } from "ts/models/shared/AddressListModel";
import { since, toDateWithHM } from "ts/core/dateHelpers";
import { AddressPage } from "./AddressPage";
import { AddressType } from "../../../models/shared/AddressType";

const config: IListDefinition<AddressListModel> = {
  id: "addresses",
  singular: "Addresse",
  editLink: (row) => `/addresses/${row.Id}/edit`,
  createLink: _ => "/addresses/create",
  columns: [
    {
      field: "Id",
      sortable: true,
      title: "Id",
      render: (row, value) => row.Deleted == null ? <td><Link to={`/addresses/${row.Id}/edit`}>{value}</Link></td> : <td>{value}</td>
    }, {
      title: "Kunde",
      field: "CustomerName",
      sortable: true,
      render: (row) => <td><Link to={`/customers/${row.CustomerId}`}>{row.CustomerName}</Link></td>,
      hidden: user => user.isCustomer
    }, {
      title: "Tittel",
      field: "Name",
      sortable: false,
      render: (row, value) => <td className="no-wrap">{value}</td>
    }, {
      title: "Type",
      field: "AddressType",
      sortable: true,
      render: (row, value) => <td className="no-wrap">{value == AddressType.Delivery ? "Levering" : "Hente"}</td>
    }, {
      field: "Line1",
      sortable: true,
      title: "Linje 1",
      render: (row, value) => <td className="no-wrap">{value}</td>
    }, {
      field: "Line2",
      sortable: true,
      title: "Linje 2",
      render: (row, value) => <td className="no-wrap">{value}</td>
    }, {
      field: "PostalCode",
      sortable: true,
      title: "Postnummer",
      render: (row, value) => <td className="no-wrap">{value}</td>
    }, {
      field: "City",
      sortable: true,
      title: "Sted",
      render: (row, value) => <td className="no-wrap">{value}</td>
    }, {
      field: "Country",
      sortable: false,
      title: "Land",
      render: (row, value) => <td className="no-wrap">{value == "no" ? "Norge" : value == "se" ? "Sverige" : "Danmark"}</td>
    }, {
      title: "Opprettet",
      field: "Created",
      sortable: true,
      alignment: Alignment.Right,
      render: (row) => <td className="right"><span title={since(row.Created)}>{toDateWithHM(row.Created)}</span></td>
    }
  ],
  filters: [
    {
      field: "CustomerId",
      title: "Kunde",
      component: <CustomerFilter />,
      type: FilterType.Lookup,
      hidden: user => user.isCustomer
    },
    {
      field: "Line1",
      title: "Linje 1",
      component: <TextFilter />,
      type: FilterType.Lookup
    },
  ],
  presets: []
}

export class AddressList extends React.Component {
  render() {
    return (
      <List configuration={config} store={RootStore.getListStore(config)} />
    )
  }
}

