import * as React from "react";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { RouteComponentProps } from "react-router";
import { CustomerList } from "./CustomersList";
import { CustomerEdit } from "./CustomerEdit";
import { CustomerDetailsPage } from "./CustomerDetails";
import { CustomerWeights } from "./CustomerWeights";
import { CustomerMap } from "./CustomerMap";

interface Props {
  route: RouteComponentProps<{}>;
}

export class Customers extends React.Component<Props> {
  render() {
    return <WorkSpace
      singular="kunde"
      plural="kunder"
      additionalRoutes={[
        { path: "/customers/weight", component: CustomerWeights },
        { path: "/customers/map", component: CustomerMap },
      ]}
      routeBasePath={this.props.route.location.pathname.split("/")[1]}
      {...this.props.route}
      editComponent={<CustomerEdit />}
      listComponent={<CustomerList />}
      detailsComponent={<CustomerDetailsPage /> }
    />
  }
}