import { useEffect } from "react";
import { NotificationHub } from "../signalR";

/**
 * Registrers an eventlistner for changes to entites and executes action
 * @param entityNames List of entity names to listen for changes
 * @param action The action to perform when change is detected
 */
export const useOnEntityChange = (entityNames: string | string[], action: () => void) => {
  useEffect(() => {
    const obj = NotificationHub.registerListener(messages => {
      if (messages.some(m => Array.isArray(entityNames) ? entityNames.some(en => en === m.entityName) : entityNames === m.entityName))
        action();
    });
    return () => NotificationHub.removeListener(obj);
  });
}