import * as React from "react";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { RouteComponentProps } from "react-router";
import { PageAlertList } from "./PageAlertList";
import { PageAlertEditForm } from "./PageAlertEditForm";

interface Props {
  route: RouteComponentProps<{}>;
}

export class PageAlertsContainer extends React.Component<Props> {
  render() {
    return <WorkSpace
      singular="sidevarsel"
      plural="sidevarsler"
      routeBasePath={this.props.route.location.pathname.split("/")[1]}
      {...this.props.route}
      editComponent={<PageAlertEditForm />}
      listComponent={<PageAlertList />}
    />
  }
}