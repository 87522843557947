import * as React from "react";
import { IListDefinition, Alignment, FilterType } from "../../../components/lists/IListDefinition";
import { List } from "../../../components/lists/List";
import { default as RootStore } from "../../../stores/root-store";
import { Link } from "react-router-dom";
import { toDateWithHM, since } from "../../../core/dateHelpers";
import { TextFilter } from "../../../components/lists/FilterComponents/TextFilter";
import { SortDirection } from "../../../models/shared/SortDirection";
import { UserListModel } from "../../../models/shared/UserListModel";
import { CustomerFilter } from "../../../components/lists/FilterComponents/CustomerFilter";
import EmployeeIcon from "svg/employee.svg";
import CustomerIcon from "svg/customer.svg";
import ContactIcon from "svg/user-with-notification.svg";
import { formatPhoneNr, toSerialNumber, EntityType, isNullOrWhitespace } from "../../../core/stringHelpers";
import { SelectFilter } from "../../../components/lists/FilterComponents/SelectFilter";
import { PhoneLink } from "../../../components/shared/display";
import { Dialog } from "../../../core/dialog";
import { Network } from "../../../api/network";

const config: IListDefinition<UserListModel> = {
  id: "users",
  singular: "Bruker",
  editLink: (row, user) => {
    if (!user.isCustomer || user.userId == row.Id)
      return `/users/${row.Id}/edit`;
    else
      return null;
  },
  createLink: () => RootStore.UserStore.isCustomer ? null : `/users/create`,
  columns: [
    {
      title: "",
      render: (row, value) => <td>
        <CustomerTypeIcon isEmployee={row.CustomerId == null || row.CustomerId == 0} canLogOn={row.CanLogOn} />
      </td>
    },
    {
      field: "Id",
      title: "Id",
      sortable: true,
      render: (row, value) => <td>{toSerialNumber(EntityType.User, row.Id)}</td>
    },
    {
      field: "Name",
      sortable: true,
      title: "Navn",
      render: (row, value) => row.Deleted == null ? <td className="no-wrap">
        <Link to={`/users/${row.Id}`}>{value}</Link>
      </td> : <td className="no-wrap">{value}</td>
    },
    {
      field: "CustomerName",
      sortable: true,
      title: "Kunde",
      render: (row) => <td><Link to={`/customers/${row.CustomerId}`}>{row.CustomerName}</Link></td>,
      hidden: _ => RootStore.UserStore.isCustomer
    },
    {
      field: "Title",
      title: "Tittel",
      sortable: false,
      alignment: Alignment.Left,
      render: (row, value) => <td>{value}</td>
    },
    {
      field: "Email",
      title: "Epost",
      render: (row, value) => <td className="no-wrap"><a href={`mailto:${value}`}>{value}</a></td>
    },
    {
      field: "PhoneNumber",
      alignment: Alignment.Right,
      title: "Mobilnummer",
      render: (row, value) => <td className="right no-wrap"><PhoneLink number={value} /></td>
    },
    {
      field: "Created",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(value)}>{toDateWithHM(value)}</span></td>,
      sortable: true,
      title: "Opprettet"
    },
    {
      field: "LastOn",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(value)}>{toDateWithHM(value)}</span></td>,
      sortable: true,
      title: "Sist pålogget"
    },
    {
      field: "CanLogOn",
      alignment: Alignment.Center,
      render: (row, value) => {
        const canConvert = !row.CanLogOn && row.CustomerId > 0 && !isNullOrWhitespace(row.Email);
        if (canConvert)
          return <td className="no-wrap"><a href="#" onClick={e => activateLogon(e, row)}>Aktiver pålogging</a></td>
        else
          return <td />
      },
      sortable: false,
      hidden: (user) => user.isCustomer,
      title: ""
    },
  ],
  filters: [
    {
      field: "Name",
      title: "Navn",
      focus: true,
      component: <TextFilter />,
      type: FilterType.String
    },
    {
      title: "Kunde",
      field: "CustomerId",
      component: <CustomerFilter />,
      type: FilterType.Lookup,
      hidden: () => RootStore.UserStore.isCustomer
    },
    {
      title: "Type",
      field: "#Type",
      component: <SelectFilter>
        <option value="">Alle</option>
        <option value="0">Administratorer</option>
        <option value="1">Ansatte</option>
        <option value="2">Kunde med pålogging</option>
        <option value="3">Kundekontakt</option>
      </SelectFilter>,
      type: FilterType.String,
      hidden: () => RootStore.UserStore.isCustomer
    }
  ],
  presets: [
    {
      default: true,
      title: "Opprettet",
      filters: [],
      sort: [{
        field: "Created",
        direction: SortDirection.Desc
      }]
    }
  ]
}

export class UserList extends React.Component {
  render() {
    return <List configuration={config} store={RootStore.getListStore(config)} />
  }
}

const CustomerTypeIcon: React.FC<{ isEmployee: boolean, canLogOn: boolean }> = ({ isEmployee, canLogOn }) => {
  if (isEmployee)
    return <span title="Ansatt"><EmployeeIcon /></span>
  else if (canLogOn)
    return <span title="Kunde med pålogging"><CustomerIcon /></span>;
  else
    return <span title="Kontakt"><ContactIcon /></span>
}

function activateLogon(ev: React.MouseEvent, user: UserListModel) {
  ev.preventDefault();

  const { Id, Name, CustomerName } = user;

  const dialog = Dialog.OkCancelDialog(
    "Aktiver pålogging",
    `Er du sikker på at du vil gi ${Name} påloggingsrettighter for ${CustomerName}?`,
    "Aktiver",
    async (fnClose) => {
      // attemt to delete the entity.
      await new Network().get(`/api/users/convertToLoginUser/${Id}`)

      // Close the dialog no matter what. If an error occured, another dialog will show error message.
      fnClose();
    }
  );

  RootStore.UIStore.addDialog(dialog);
}