import * as React from "react";
import * as moment from "moment";
import { Network } from "../../../../api/network";
import { BreadCrumb } from "../../../../components/shared/BreadCrumb";
import { Pending } from "../../../../components/shared/Pending";
import { DayNavigator } from "../dayView/DayNavigator";
import "./Earnings.tsx.scss";
import { NotificationHub } from "../../../../core/signalR";
import { Link } from "react-router-dom";
import { EntityType, toSerialNumber } from "../../../../core/stringHelpers";
import { sum } from "lodash-es";
import { NumberWithPostfix } from "../../../../components/shared/display";
import { WorkOrderPopupDetails } from "../WorkOrderPopupDetails";
import { PerDayModel } from "../../../../models/shared/PerDayModel";
import { ArticleType } from "../../../../models/shared/ArticleType";
import styled from "styled-components";

export const Earnings = () => {
  const [day, setDay] = React.useState<Date>(moment().startOf('day').toDate());
  const [data, setData] = React.useState<Array<any> | null>(null);

  const loadData = async () => {
    const network = new Network();
    const result = await network.get<Array<PerDayModel>>(`/api/invoices/ByDate/${moment(day).format("YYYY-MM-DD")}`);
    if (result) {
      setData(result as any);
    }
  }

  React.useEffect(() => {
    setData(null);
    loadData();
  }, [day]);

  React.useEffect(() => {
    const obj = NotificationHub.registerListener(messages => {
      if (messages.some(m => m.entityName === "WorkOrderEntity"))
        loadData();
    });
    return () => NotificationHub.removeListener(obj);
  });

  return <div className="page-workorders-invoices">
    <h1>Fakturert dagsoversikt</h1>
    <BreadCrumb
      workSpaceType="list"
      singular="Dagsoversikt"
      plural="Arbeidsordre"
      additionalLinks={[{ title: "Fakturert dagsoversikt", path: "" }]}
      routeBasePath="workorders" />
    <div className="panel">
      <div className="day-view-header">
        <DayNavigator day={day} onChange={setDay} />
      </div>
      <div className="page-workorders-invoices-grid-container">
        <div className="page-workorders-invoices-grid" >
          <div className="page-workorders-invoices-header">
            <span>Id</span>
            <span>Kunde</span>
            <span className="right">Vekt</span>
            <span className="right">Pris pr kg</span>
            <span className="right">Varmforsinking</span>
            <span className="right">Sink</span>
            <span className="right">Energi</span>
            <span className="right">Lakkering</span>
            <span className="right">Transport</span>
            <span className="right">Tjenester</span>
          </div>
          {data == null && <div className="flex-center"><Pending /></div>}
          {data && data.map((d, i) => <InvoiceRow key={i} row={d} />)}
          {data && <SummaryRow data={data} />}
        </div>
      </div>
    </div>
  </div>
}

const SummaryRow: React.FC<{ data: Array<PerDayModel> }> = ({ data }) => {

  const varmforsinking = sum(data.flatMap(i => i.LineItems!.filter(f => f.ArticleType == ArticleType.ZinkArticle).map(v => v.Amount * v.Price)))
  const regulering_zink = sum(data.flatMap(i => i.LineItems!.filter(f => f.ArticleType == ArticleType.ZinkAdjustment).map(v => v.Amount * v.Price)))
  const regulering_energy = sum(data.flatMap(i => i.LineItems!.filter(f => f.ArticleType == ArticleType.ElectricityAdjustment).map(v => v.Amount * v.Price)))
  const lakkering = sum(data.flatMap(i => i.LineItems!.filter(f => f.ArticleType == ArticleType.Paint).map(v => v.Amount * v.Price)))
  const transport = sum(data.flatMap(i => i.LineItems!.filter(f => f.ArticleType == ArticleType.Transport).map(v => v.Amount * v.Price)))
  const total = sum(data.flatMap(i => i.LineItems!.map(v => v.Amount * v.Price)));
  const tjenester = total - varmforsinking - regulering_zink - regulering_energy - lakkering - transport;
  const weight = sum(data.map(d => d.Weight));

  return <div className="page-workorders-invoices-total" >
    <NumberWithPostfix number={weight} post="kg" decimals={0} className="right" />
    <span/>
    <NumberWithPostfix number={varmforsinking} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={regulering_zink} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={regulering_energy} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={lakkering} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={transport} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={tjenester} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={total} post="kr" decimals={0} className="right total" />
  </div>
}

const OfferIndicator = styled.span`
    background: #0cd30c;
    color: #fff;
    padding: 0px 7px 1px 5px;
    display: inline-block;
    margin: 1px;
    font-size: 0.75rem;
    border-radius: 3px;
    margin-left: 5px;
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: -0.5px
`;

const InvoiceRow: React.FC<{ row: PerDayModel }> = ({ row }) => {
  const { WorkOrderId, CustomerName } = row;
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const onEnter: React.MouseEventHandler<HTMLElement> = (ev) => setIsMouseOver(true);
  const onLeave: React.MouseEventHandler<HTMLElement> = (ev) => setIsMouseOver(false);

  const varmforsinking = sum(row.LineItems!.filter((r: { ArticleType: number; }) => r.ArticleType === 1).map((i: { Amount: number; Price: number; }) => i.Amount * i.Price));
  const regulering_zink = sum(row.LineItems!.filter((r: { ArticleType: number; }) => r.ArticleType === ArticleType.ZinkAdjustment).map((i: { Amount: number; Price: number; }) => i.Amount * i.Price));
  const regulering_energy = sum(row.LineItems!.filter((r: { ArticleType: number; }) => r.ArticleType === ArticleType.ElectricityAdjustment).map((i: { Amount: number; Price: number; }) => i.Amount * i.Price));
  const lakkering = sum(row.LineItems!.filter((r: { ArticleType: number; }) => r.ArticleType === 7).map((i: { Amount: number; Price: number; }) => i.Amount * i.Price));
  const transport = sum(row.LineItems!.filter((r: { ArticleType: number; }) => r.ArticleType === 8).map((i: { Amount: number; Price: number; }) => i.Amount * i.Price));
  const total = sum(row.LineItems!.map((i: { Amount: number; Price: number; }) => i.Amount * i.Price))
  const tjenester = total - varmforsinking - regulering_energy - regulering_zink - lakkering - transport;

  const pricePrKg = varmforsinking / Math.max(row.Weight, 1);

  return <><div className="page-workorders-invoices-item" >
    <Link to={`/workorders/${WorkOrderId}`}>{toSerialNumber(EntityType.WorkOrder, WorkOrderId)}</Link>
    <div onMouseEnter={onEnter} onMouseLeave={onLeave} className="preview-container flex-center-items" ref={ref}>
      <span>{CustomerName}</span>
      {row.LineItems!.some(l => l.IsOffer) && <OfferIndicator>Tilbud</OfferIndicator>}
      {isMouseOver && <WorkOrderPopupDetails workorder={{ Id: WorkOrderId }} element={ref.current} />}
    </div>
    <NumberWithPostfix number={row.Weight} post="kg" decimals={0} className="right" />
    <NumberWithPostfix number={pricePrKg} post={<><sup>kr</sup>/<sub>kg</sub></>} decimals={2} className="right" />
    <NumberWithPostfix number={varmforsinking} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={regulering_zink} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={regulering_energy} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={lakkering} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={transport} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={tjenester} post="kr" decimals={0} className="right" />
    <NumberWithPostfix number={total} post="kr" decimals={0} className="right total" />
  </div>
  </>
}