import React from "react"
import { NumberWithPostfix } from "../../../../components/shared/display"
import { useDataStore } from "../../../../core/hooks/useDataStore";
import { Pending } from "../../../../components/shared/Pending";

export const DataSummary: React.FC = () => {

  const customerId = window.flytSettings.user!.customerId;
  const { data: statistikk, isError, isFetching, isSuccess } = useDataStore<any>(`/api/customers/statistics/${customerId}/summary`, { entityName: 'WorkOrderEntity' });
  
  return <div className="box w2">
    <h2>Din statistikk</h2>
    {isError && <p>Feil ved henting av bestillinger</p>}
    {isFetching && <div className="flex-center"><Pending /></div>}
    {isSuccess && statistikk != null &&
        <table>
          <thead>
            <tr>
              <th />
              <th className="right">Antall</th>
              <th className="right">Vekt</th>
            </tr>
          </thead>
          <tbody>
            <tr><td>Siste 30 dager</td><td className="right"><NumberWithPostfix post="stk" decimals={0} number={statistikk.Month.Antall} /></td><td className="right"><NumberWithPostfix post="kg" decimals={0} number={statistikk.Month.Weight} /></td></tr>
            <tr><td>I år</td><td className="right"><NumberWithPostfix post="stk" decimals={0} number={statistikk.Year.Antall} /></td><td className="right"><NumberWithPostfix post="kg" decimals={0} number={statistikk.Year.Weight} /></td></tr>
          </tbody>
        </table>}
  </div>
}
