import * as React from "react";
import { IListDefinition } from "./IListDefinition";
import { ListStore } from "../../stores/list-store";
import { BaseModel } from "../../models/shared/BaseModel";
import { Filters } from "./Filters";
import { Table } from "./Table";
import { Pagination } from "./Pagination";
import { PageSize } from "./PageSize";
import { Summary } from "./Summary";
import { ListAddItemButton } from "./ListAddItemButton";
import "./list.scss";
import classNames from "classnames";
import { default as RootStore } from "../../stores/root-store";


export interface ListProps<T extends BaseModel> {
  configuration: IListDefinition<T>;
  store: ListStore<T>
  wrapInPanel?: boolean;
}

type P = ListProps<BaseModel & any>;
const List = class extends React.Component<P> {

  constructor(props: P) {
    super(props);
    const { store } = this.props;
    store.refresh();

    const history = RootStore.RouterStore.history;
    const state = history.location.state as { filters: { [key: string]: any } };
    if (state?.filters != null) {
      store.clearFilters();
      for (let [key, value] of Object.entries(state.filters)) {
        store.setFilter(key, value);
      }
    }
  }

  render() {
    const { configuration } = this.props;
    const wrapInPanel = this.props.wrapInPanel ?? true;

    return (
      <div className={classNames("list", `list-${configuration.id}`)}>
        {configuration.filters.length > 0 && <Filters {...this.props} />}
        <div className={classNames("list-area", { "panel": wrapInPanel })}>
          <ListAddItemButton url={this.props.configuration.createLink} singular={this.props.configuration.singular} />
          <div className="table-container">
            <Table {...this.props} />
          </div>
          <div className="table-footer">
            <Pagination {...this.props} />
            <PageSize {...this.props} />
            <Summary {...this.props} />
          </div>
          <ListAddItemButton url={this.props.configuration.createLink} singular={this.props.configuration.singular} />
        </div>
      </div>)
  }
} as React.ComponentClass<ListProps<BaseModel & any>>

export { List as List }

