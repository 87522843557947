import * as React from "react";
import { OfferListModel } from "../../../models/shared/OfferListModel";
import { IListDefinition, Alignment, FilterType } from "../../../components/lists/IListDefinition";
import { Link } from "react-router-dom";
import { since, toDateWithHM, toDate } from "../../../core/dateHelpers";
import { CustomerFilter } from "../../../components/lists/FilterComponents/CustomerFilter";
import { SortDirection } from "../../../models/shared/SortDirection";
import { List } from "../../../components/lists/List";
import { default as RootStore } from "../../../stores/root-store";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { Lookup } from "../../../stores/lookup-store";
import { ScreenSize } from "../../../core/util";
import { Network } from "../../../api/network";
import { NumberWithPostfix } from "../../../components/shared/display";

const config: IListDefinition<OfferListModel> = {
  id: "offers",
  singular: "Tilbud",
  editLink: (row) => `/offers/${row.Id}/edit`,
  createLink: _ => "/offers/create",
  rowCssClass: (row, user) => row.IsActive ? "active" : "inactive",
  columns: [
    {
      field: "Id",
      sortable: true,
      title: "Id",
      render: (row, value) => row.Deleted == null ? <td>
        <Link to={`/offers/${row.Id}/Edit`}>{toSerialNumber(EntityType.Offer, value)}</Link></td> : <td>{toSerialNumber(EntityType.Offer, value)}</td>
    },
    {
      field: "Created",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(value)}>{toDateWithHM(value)}</span></td>,
      sortable: true,
      title: "Opprettet"
    }, {
      title: "Kunde",
      field: "CustomerName",
      sortable: true,
      render: (row) => <td><Link to={`/customers/${row.CustomerId}`}>{row.CustomerName}</Link></td>
    }, {
      field: "Name",
      render: (row, value) => <td><span>{value}</span></td>,
      sortable: true,
      title: "Navn"
    },
    {
      field: "ArticleItems",
      alignment: Alignment.Center,
      render: (row, value) => {
        const prices = row.ArticleItems?.map(a => {
          const articleName = RootStore.LookupStore.getItem(Lookup.Articles, a.ArticleId);
          return <span title={articleName}>{a.Price.toFixed(2)}</span>;
        });

        return <td className="comma-seperated-list">{prices}</td>
      },
      sortable: false,
      hidden: () => RootStore.UIStore.getScreenSize != ScreenSize.PC,
      title: "Tilbud"
    },
    {
      field: "AppliedWeight",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right"><NumberWithPostfix number={row.AppliedWeight} post="kg" decimals={0}/></td>,
      sortable: false,
      title: "Vekt"
    },
    {
      field: "Usage",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right">{value}</td>,
      sortable: false,
      title: "Antall"
    },
    {
      field: "IsAutomated",
      alignment: Alignment.Center,
      render: (row, value) => <td className="center">{value ? "Ja" : "Nei"}</td>,
      sortable: false,
      title: "Automatisk påført"
    },
    {
      field: "StartDate",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(value)}>{toDate(value)}</span></td>,
      sortable: true,
      title: "Start"
    },
    {
      field: "EndDate",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(value)}>{toDate(value)}</span></td>,
      sortable: true,
      title: "Slutt"
    },
    {
      alignment: Alignment.Center,
      render: (row, value) => {

        const promote = async (offerId: number) => {
          const network = new Network();
          await network.getSimple(`/api/offers/promote/${offerId}`);
        }

        return <td className="center action">
          <span onClick={_ => promote(row.Id)}>^</span>
        </td>
      },
      sortable: false,
      hidden: (user, filters) => !user.isAdministrator,
      title: "Promote"
    }
  ],
  filters: [
    {
      field: "CustomerId",
      title: "Kunde",
      component: <CustomerFilter />,
      type: FilterType.Lookup
    }
  ],
  presets: [
    {
      default: true,
      sort: [{
        direction: SortDirection.Desc,
        field: "Created"
      }],
      title: "Siste først",
      filters: []
    }
  ]
}

export class OfferList extends React.Component {
  render() {
    return (
      <List configuration={config} store={RootStore.getListStore(config)} />
    )
  }
}

