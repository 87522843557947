import * as React from "react";
import { toSerialNumber, EntityType } from "../../../../core/stringHelpers";
import { Network } from "../../../../api/network";
import { observer } from "mobx-react";

interface Props {
  workOrderId: number;
  onCloseFn?: () => void;
}

interface State {
  orderId: number;
}

@observer
export class AssociateWorkOrderWithOrderDialog extends React.Component<Props, State> {

  state: State = { orderId: 0 };

  setValue(value: string) {
    if (value == "")
      this.setState({ orderId: 0 });
    else
      this.setState({ orderId: parseInt(value) });
  }

  async associate() {
    const network = new Network();
    const workOrderId = this.props.workOrderId;
    let orderId = this.state.orderId;
    if (orderId == 0)
      return;

    if (orderId > 10000)
      orderId = orderId - 10000;

    const result = await network.get<number>(`/api/workorders/associatedWithOrder/${workOrderId}/${orderId}`);
    if (result && result > 0)
      this.props.onCloseFn!();
  }

  render() {

    const workOrderId = this.props.workOrderId;

    return <div className="associate-workorder-with-order-dialog">
      <p>Her kan du koble en bestilling til arbeidsordre {toSerialNumber(EntityType.WorkOrder, workOrderId)}.</p>
      <div>
        <form className="edit-form">
          <div className="form-field">
            <div className="form-field-descripion">
              <label className="form-field-label">Bestilling</label>
              <div className="form-field-help-container">
                <span className="form-field-help-text">
                  <span>Tast inn id nummeret til bestillingen du vil koble denne arbeidsordren til.</span>
                </span>
              </div>
            </div>
            <div className="form-field-component">
              <input type="number" value={this.state.orderId == 0 ? "" : this.state.orderId.toString()} onChange={e => this.setValue(e.target.value)} />
            </div>
          </div>
          <div className="buttons footer">
            <button type="button" className="button" onClick={e => this.associate()} disabled={this.state.orderId == 0}>Koble</button>
            <button type="button" className="button" onClick={e => this.setState({ orderId: 0 })}>Tøm</button>
            <button type="button" className="button" onClick={e => this.props.onCloseFn!()}>Avbryt</button>
          </div>
        </form>
      </div>
    </div>
  }
}