import { observer } from "mobx-react";
import * as React from "react";
import { observable } from "mobx";
import { ComplaintEditModel } from "../../../models/shared/ComplaintModel";
import { FormStore } from "../../../stores/form-store";
import { Form } from "../../../components/forms/form";
import { FormActions } from "../../../components/forms/FormActions";
import { TextAreaField } from "../../../components/forms/fields/TextAreaField";
import { FilesField } from "../../../components/forms/fields/FilesField";
import RootStore from "ts/stores/root-store";
import { EnumField } from "../../../components/forms/fields/EnumField";
import { ComplaintState } from "../../../models/shared/ComplaintState";
import { Enums } from "../../../stores/lookup-store";
import { ContactsField } from "../../../components/forms/fields/ContactsField";
import { FormErrors } from "../../../components/forms/FormErrors";
import { EntityType } from "../../../core/stringHelpers";

interface Props {
  id?: number;
  workOrderId?: number;
  customerId?: number;
  onCloseFn?: () => void;
}

@observer
export class ComplaintForm extends React.Component<Props> {
  @observable store?: FormStore<ComplaintEditModel>;

  async componentDidMount() {

    this.store = new FormStore<ComplaintEditModel>({}, {
      WorkOrderId: this.props.workOrderId,
      State: ComplaintState.Received
    });

    if (this.props.id)
      this.store.loadModel("complaints", this.props.id);
  }

  render() {
    if (this.store == null)
      return null;

    if (this.props.id && !this.store.isEdit)
      return null;

    const user = RootStore.UserStore;

    return (
      <div className={this.props.onCloseFn != null ? "" : "panel form-container"}>
        <Form
          store={this.store}
          name="Reklamasjon"
          entityType={EntityType.Complaint}
          postBaseUrl="/api/complaints"
          customerId={this.props.customerId ?? this.store.values.CustomerId}
          redirectToList={true}
          onSuccess={this.props.id == null ? _ => this.props.onCloseFn?.() : undefined}
          onCancel={() => this.props.onCloseFn?.()}
          className="edit-form edit-form-padding">
          <TextAreaField<ComplaintEditModel>
            field="Reason"
            label="Beskrivelse"
            helpText="Her fyller du ut en kort beskrivelse av hvorfor du ønsker å reklamere på leveransen."
          />
          <FilesField<ComplaintEditModel>
            field="Documentation"
            label="Dokumentasjon"
            helpText="Her legger du ved bilder eller annen dokumentasjon som viser problemet."
          />
          <TextAreaField<ComplaintEditModel>
            field="RequestedSolution"
            label="Forslag til løsning"
            helpText="Her fyller du ut hvordan du ønsker problemet skal løses."
          />
          <ContactsField<ComplaintEditModel>
            field="Contacts"
            label="Kontakter"
            showOneTimeContact={false}
            helpText="Her kan du spesifisere hvilke kontaktpersoner som skal kontaktes ved spørsmål eller endringer av status på denne reklamasjonen." />
          {user.isEmployeeAdministrator && <>
            <h4>Tilstand</h4>
            <EnumField<ComplaintEditModel>
              field="State"
              required={true}
              sort={false}
              label="Status"
              helpText="Her angir du status for reklamasjonen"
              enumType={Enums.ComplaintState}
            />
            <TextAreaField<ComplaintEditModel>
              field="Resolvement"
              label="Løsning"
              helpText="Her fyller du hvordan saken ble løst."
              showIf={data => data.State == ComplaintState.Resolved || data.State == ComplaintState.Rejected || data.State == ComplaintState.Approved}
            />
          </>}
          <FormErrors type="Reklamasjonen" />
          <FormActions />
        </Form>
      </div>
    )
  }
}

