import * as React from "react";
import CameraIcon from "svg/camera.svg";
import ImageGallery from 'react-image-gallery';
import { default as RootStore } from "../../../../stores/root-store";
import { WorkOrderDetailsUnitModel } from "../../../../models/shared/WorkOrderDetailsUnitModel";
import { GenericDataStore } from "../../../../stores/genericData-store";
import { WorkOrderDetailsModel } from "../../../../models/shared/WorkOrderDetailsModel";
import ReactImageGallery from "react-image-gallery";
import { Direction } from "../../../../models/shared/Direction";
import { getImageUrl, isMobile, forward, back, setEventDragData } from "../../../../core/util";
import { ImageSize } from "../../../../models/shared/ImageSize";
import { Dialog } from "../../../../core/dialog";
import { UnitImagesDialog } from "../UnitImagesDialog";


export const ImageStack: React.FC<{ unit: WorkOrderDetailsUnitModel; store: GenericDataStore<WorkOrderDetailsModel> }> = ({ unit, store }) => {

  const stackRef = React.useRef<HTMLDivElement>(null);
  const galleryRef = React.useRef<ReactImageGallery>(null);

  const [currentImage, setCurrentImage] = React.useState(0);
  const [galleryIsVisible, setGalleryIsVisible] = React.useState(false);

  const images = [...(unit.Images || []), ...(unit.ErrorImages || []), ...(unit.ExtraWorkImages || [])];
  const hasImages = images.length > 0;

  const directionText = unit.Direction == Direction.Incoming ? "Mottatt" : "Produsert"

  const galleryImages = (unit.Images || []).map((f, index) => ({
    original: getImageUrl(f, ImageSize.Desktop),
    description: `${directionText} - bilde ${index + 1}`
  })).concat((unit.ErrorImages || []).map((f, index) => ({
    original: getImageUrl(f, ImageSize.Desktop),
    description: `Bilde ${index + 1} av ${directionText.toLocaleLowerCase()} skade eller problem`
  })));

  const onImageSelect = () => {
    if (isMobile()) {
      setGalleryIsVisible(true);
    } else {
      const dialog = Dialog.OkDialog("Bilder", <UnitImagesDialog unitId={unit.Id} selected={images[currentImage].Id} store={store} />);
      dialog.fullScreen = true;
      RootStore.UIStore.addDialog(dialog);
    }
  };

  React.useEffect(() => {
    if (galleryIsVisible && galleryRef.current) {
      galleryRef.current.slideToIndex(currentImage);
      galleryRef.current.fullScreen();
    }
  }, [galleryIsVisible]);

  React.useEffect(() => {
    const stackElement = stackRef.current;
    if (stackElement == null)
      return;

    const fn = (ev: WheelEvent) => {
      ev.preventDefault();
      const offset = ev.deltaY < 0 ? -1 : 1;
      const length = (images || []).length;

      if (length < 2)
        return;
      setCurrentImage(offset > 0 ? forward(currentImage, length) : back(currentImage, length))
    }

    stackElement.addEventListener("wheel", fn);
    return () => stackElement.removeEventListener("wheel", fn);
  }, [currentImage, images]);

  const onScreenChange = (fullScreenElement: Element | boolean) => {
    if (fullScreenElement == null || fullScreenElement === false)
      setGalleryIsVisible(false);
  }

  // Plukker opp klikk fra feilbilder og viser galleri på mobil.
  React.useEffect(() => {
    window.addEventListener("onErrorImageSelect", onErrorImageSelect);
    return () => window.removeEventListener("onErrorImageSelect", onErrorImageSelect);
  })

  const onErrorImageSelect = (ev: Event) => {
    if (unit === (ev as CustomEvent).detail.unit && galleryIsVisible === false) {
      setGalleryIsVisible(true);
      const i = images.findIndex(i => i.Id === (ev as CustomEvent).detail.id);
      setCurrentImage(i);
    }
  }

  return <>
    <div className="unitlist-images img-stack" ref={stackRef} onClick={() => hasImages && onImageSelect()}>
      {hasImages && images.map((f, i) => {
        if (f.ContentType?.toLowerCase().startsWith("video"))
          return <video key={f.Id} aria-label={f.Name} src={getImageUrl(f, ImageSize.Original)} autoPlay controls muted loop hidden={f.Id != images[currentImage].Id} draggable onDragStart={e => setEventDragData(e, images[currentImage])} />
        else
          return <div key={f.Id} role="img" style={{ backgroundImage: `url("${getImageUrl(f, ImageSize.Preview)}")` }} aria-label={f.Name} hidden={f.Id != images[currentImage].Id} draggable onDragStart={e => setEventDragData(e, images[currentImage])} />
      })}
      {!hasImages && <CameraIcon className="unitlist-images--default" />}
    </div>
    {galleryIsVisible && <ImageGallery
      items={galleryImages}
      useBrowserFullscreen={true}
      ref={galleryRef}
      showThumbnails={false}
      showPlayButton={false}
      preventDefaultTouchmoveEvent={true}
      onScreenChange={onScreenChange}
      stopPropagation={true} />}
  </>
}