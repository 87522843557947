import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";

interface OwnProps {}

type P = PropsFromWrapperToField<string> & OwnProps

class TextAreaField extends React.Component<P> {

  render() {
    const { id, setTouched, setValue, value, autoFocus } = this.props;
    return <textarea
      id={id}
      onChange={e => setValue(e.target.value)}
      onBlur={() => setTouched()}
      value={value || ""}
      autoFocus={autoFocus} />
  }
}

const wrapped = Wrap<P, string>(TextAreaField);

export {
  wrapped as TextAreaField
}