import * as React from "react";
import { Switch, RouteComponentProps, Route } from "react-router";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { TransportForm } from "./TransportForm";
import { TransportDetails } from "./TransportDetails";
import { TransportList } from "./TransportList";

const TransportContainer = (props: RouteComponentProps<any>) =>
  <>
    <Switch>
      <Route render={(r) => <WorkSpace
        singular="transportbestilling"
        plural="transportbestillinger"
        routeBasePath={props.location.pathname.split("/")[1]}
        {...props}
        editComponent={<TransportForm {...r} />}
        detailsComponent={<TransportDetails />}
        listComponent={<TransportList />}
      />} />
    </Switch>
  </>;

export { TransportContainer };