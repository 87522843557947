import * as React from "react";
import { Network } from "../../../api/network";
import type { MonthlyCostCalculationModel } from "../../../models/shared/MonthlyCostCalculationModel";
import { observer } from "mobx-react";
import { observable, action, runInAction } from "mobx";
import { Pending } from "../../../components/shared/Pending";
import { DetailsBlock } from "../../../components/details/DetailsBlock";
import { DetailValueField } from "../../../components/details/DetailValueField";
import { NumberWithPostfix } from "../../../components/shared/display";
import { sum, values } from "lodash-es";
import { TabPanel } from "../../../components/shared/TabPanel";
import { Tab } from "../../../components/shared/Tab";
import { default as RootStore } from "../../../stores/root-store";
import { Enums } from "../../../stores/lookup-store";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import PriceIcon from "svg/price.svg";
import { ArticleType } from "../../../models/shared/ArticleType";
import { OperatingCostType } from "../../../models/shared/OperatingCostType";
import { Link } from "react-router-dom";
import ArrowIcon from "svg/arrow.svg";
import EditIcon from "svg/edit.svg";
import * as moment from "moment";
import "./MonthlyCostOverview.tsx.scss";

@observer
export class MothlyCostOverview extends React.Component {

  @observable data?: MonthlyCostCalculationModel = undefined;
  @observable articles?: Array<BaseLookupModel>;
  @observable operatingConstTypes?: Array<BaseLookupModel>;
  @observable month: string = moment().startOf("month").format("YYYY-MM-DD");

  private disposer = null;

  async componentDidMount() {
    this.articles = await RootStore.LookupStore.getEnums(Enums.ArticleType, true);
    this.operatingConstTypes = await RootStore.LookupStore.getEnums(Enums.OperatingCostType, true);

    this.loadData();
  }

  @action
  async loadData() {
    this.data = undefined;
    const result = await new Network().get<MonthlyCostCalculationModel>(`/api/monthlycosts/overview/${this.month}`);
    if (result)
      runInAction(() => { this.data = result });
  }

  goBack = () => {
    this.month = moment(this.month).add(-1, "month").format("YYYY-MM-DD");
    this.loadData();
  }

  goNext = () => {
    this.month = moment(this.month).add(1, "month").format("YYYY-MM-DD");
    this.loadData();
  }

  render() {
    const { data, articles, operatingConstTypes } = this;
    if (data == null || articles == null || operatingConstTypes == null)
      return <Pending />

    const { NumberOfWorkOrders, Weight, Income } = data;

    var actualIncome = sum(values(Income!).map(v => v.Actual));
    var estimatedIncome = sum(values(Income!).map(v => v.Estimated));

    return <div className="monthly-overview">
      <div className="panel">
        <div className="monthly-header">
          <h3>Sammendrag</h3>
          <div className="montly-month-navigator">
            <button className="previous" onClick={e => this.goBack()}><ArrowIcon /></button>
            <span>{moment(data.Month).format("MMMM YYYY")}</span>
            <button className="next" onClick={e => this.goNext()}><ArrowIcon /></button>
          </div>
        </div>
        <div className="details-container">
          <div className="details-blocks details-blocks--condensed">
            <DetailsBlock title="Arbeidsordre">
              <DetailValueField title="Antall" right><NumberWithPostfix post="stk" number={NumberOfWorkOrders?.Actual} /></DetailValueField>
              <DetailValueField title="Estimert" right><NumberWithPostfix post="stk" number={NumberOfWorkOrders?.Estimated} /></DetailValueField>
              <DetailValueField title="Basert på" right><NumberWithPostfix post="dager" number={NumberOfWorkOrders?.NumberOfDays} /></DetailValueField>
              <DetailValueField title="Arbeidsdager" right><NumberWithPostfix post="dager" number={(NumberOfWorkOrders?.RemainingDays ?? 0) + (NumberOfWorkOrders?.NumberOfDays ?? 0)} /></DetailValueField>
            </DetailsBlock>
            <DetailsBlock title="Vekter">
              <DetailValueField title="Antall" right><NumberWithPostfix post="kg" number={Weight?.Actual} /></DetailValueField>
              <DetailValueField title="Estimert" right><NumberWithPostfix post="kg" number={Weight?.Estimated} /></DetailValueField>
              <DetailValueField title="Fakturert" right><NumberWithPostfix post="kg" number={data.InvoicedWeight} /></DetailValueField>
            </DetailsBlock>
            <DetailsBlock title="Så langt">
              <DetailValueField title="Inntekter" right><NumberWithPostfix post="kr" number={actualIncome} /></DetailValueField>
            </DetailsBlock>
            <DetailsBlock title="Estimert">
              <DetailValueField title="Inntekter" right><NumberWithPostfix post="kr" number={estimatedIncome} /></DetailValueField>
            </DetailsBlock>
          </div>
        </div>
      </div>
      <TabPanel>
        <Tab title="Inntekter">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th className="">Type</th>
                  <th className="right">Antall</th>
                  <th className="right">Så langt</th>
                  <th className="right">Estimert</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(Income!).map(type => ({ type, value: Income![parseInt(type) as ArticleType] })).map(v => <tr key={v.type}>
                  <td>{articles.find(a => a.Id == parseInt(v.type))?.Name}</td>
                  <td className="right">{v.value.ItemCount}</td>
                  <td className="right no-wrap"><NumberWithPostfix post="kr" number={v.value.Actual} /></td>
                  <td className="right no-wrap"><NumberWithPostfix post="kr" number={v.value.Estimated} /></td>
                </tr>)}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}></td>
                  <td className="right no-wrap"><NumberWithPostfix post="kr" number={sum(values(Income!).map(v => v.Actual))} /></td>
                  <td className="right no-wrap"><NumberWithPostfix post="kr" number={sum(values(Income!).map(v => v.Estimated))} /></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Tab>
      </TabPanel>
    </div >
  }

}