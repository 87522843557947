/**  */
export enum ArticleUnit {
  
  /** */
  PerKilo = 0,  
  /** */
  PerHour = 1,  
  /** */
  PerItem = 2,  
  /** */
  PerMeter = 3
}

