import { observer } from "mobx-react";
import * as React from "react";
import { ComplaintForm } from "../../complaint/ComplaintForm";

interface ComplaintDialogProps {
  workOrderId: number;
  customerId: number;
  onCloseFn?: () => void;
}

@observer
export class ComplaintDialog extends React.Component<ComplaintDialogProps> {
  render() {
    const { onCloseFn, workOrderId, customerId } = this.props;
    return <div className="complaint-dialog dialog">
      <ComplaintForm workOrderId={workOrderId} customerId={customerId} onCloseFn={onCloseFn} />
    </div>
  }
}