import { useLocation } from "react-router";
import * as React from "react";
import NotFoundIcon from "svg/404.svg";
import "./NotFound.tsx.scss";

function NotFound() {
  const location = useLocation();
  return <div className="not-found"><NotFoundIcon />
    <p>Ingen side tilgjenglig på <code>{location.pathname}</code></p>
  </div>;
}

export {
  NotFound
};