/**  */
export enum TransportRequestType {
  
  /** */
  Other = 0,  
  /** */
  FromCustomer = 1,  
  /** */
  ToExternalProcessing = 2,  
  /** */
  FromExternalProcessing = 3,  
  /** */
  ToCustomerOrFinalDestination = 4
}

