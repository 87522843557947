import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { faChartColumn, faChartLine, faTableCells } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { ApexOptions } from "apexcharts";
import classNames from "classnames";
import { isNumber, sum } from "lodash-es";
import moment from "moment";
import * as React from "react"
import ReactApexChart from "react-apexcharts";
import { Network } from "../../../api/network";
import { DetailsBlock } from "../../../components/details/DetailsBlock"
import { DetailsPanel } from "../../../components/details/DetailsPanel"
import { DetailValueField } from "../../../components/details/DetailValueField";
import { CustomBreadCrumb } from "../../../components/shared/BreadCrumb"
import { NumberWithPostfix } from "../../../components/shared/display";
import { Pending } from "../../../components/shared/Pending";
import './StatisticsOverview.tsx.scss';


type Groupings = "day" | "week" | "month" | "quarter" | "year";
type PresentationTypes = 'line' | 'bar' | 'table';

interface StatisticsItem {
  Label: {
    Text: string;
    Date: string;
    Group: number;
    GroupLabel?: string;
    Year: number;
  },
  Sum: number;
  Count: number;
  Average: number;
}

interface QueryResponse {
  Categories: Array<{ Key: number, Short: string, Full: string }>;
  Title: string;
  ValuePostFix: string;
  Labels: Array<string>;
  Data: Array<StatisticsItem>;
  UseAverage: boolean;
  IsGrouped: boolean;
  Grouped?: Array<{ Name: string, Values: Array<StatisticsItem> }>
}

export const StatisticsOverview: React.FC = ({ }) => {

  const [grouping, setGrouping] = React.useState<Groupings>("month");
  const [presentation, setPresentation] = React.useState<PresentationTypes>('bar');
  const [type, setType] = React.useState<string>("ProducedWeight");
  const [year, setYear] = React.useState<string>("2024");

  var query = useQuery({
    queryKey: ['graph', grouping, type],
    queryFn: async () => {
      const network = new Network();
      const result = await network.get<QueryResponse>(`/api/statistics/graphs/${type}/${grouping}`)
      return result;
    },
    staleTime: 60 * 60 * 1000
  });

  React.useEffect(() => {
    setYear("");
  }, [presentation]);

  const selectYear = presentation == "line" || presentation == 'table' || (presentation == 'bar' && grouping != 'day');
  const showChart = presentation != 'table'

  return <div className="page-statistics">
    <h1>Statistikk</h1>
    <CustomBreadCrumb links={[{ title: "Statistikk", path: "/statistics" }]} />

    <DetailsPanel>
      <DetailsBlock title="Gruppering">
        <div className="form-group">
          <RadioItem value="day" text="Dag" currentValue={grouping} setCurrentValue={e => setGrouping(e as Groupings)} />
          <RadioItem value="week" text="Uke" currentValue={grouping} setCurrentValue={e => setGrouping(e as Groupings)} />
          <RadioItem value="month" text="Måned" currentValue={grouping} setCurrentValue={e => setGrouping(e as Groupings)} />
          <RadioItem value="quarter" text="Kvartal" currentValue={grouping} setCurrentValue={e => setGrouping(e as Groupings)} />
        </div>
      </DetailsBlock>
      <DetailsBlock title="Informasjon">
        <select value={type} onChange={e => setType(e.target.value)}>
          <option value="NumberOfWorkOrders">Antall arbeidsordre</option>
          <option value="ReceivedWeight">Mottatt vekt</option>
          <option value="StartedWeight">Opphengt</option>
          <option value="ProducedWeight">Produsert</option>
          <option value="Invoiced">Fakturert</option>
          <option value="ReserveWeight">Godsreserve</option>
          <option value="MissedWeight">Over leveringstid</option>
          <option value="TotalPowerUsage">Strømforbruk</option>
          <option value="ZinkPowerUsage">Strømforbruk gryte</option>
          <option value="NewCustomers">Nye kunder</option>
          <option value="Overtime">Overtid</option>
          <option value="NumberOfCustomers">Antall kunder</option>
          <option value="NumberOfDips">Antall dupp</option>
          <option value="NumberOfRushRequests">Antall hastejobber</option>
        </select>
      </DetailsBlock>
      <DetailsBlock title="Visning">
        {selectYear && <DetailValueField title="Visningsår">
          <select value={year} onChange={e => setYear(e.target.value)}>
            <option value="">Alle</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </DetailValueField>}
      </DetailsBlock>
      <div className="presentation">
        <FontAwesomeIcon icon={faChartLine} onClick={e => setPresentation('line')} className={classNames({ 'active': presentation === 'line' })} />
        <FontAwesomeIcon icon={faChartColumn} onClick={e => setPresentation('bar')} className={classNames({ 'active': presentation === 'bar' })} />
        <FontAwesomeIcon icon={faTableCells} onClick={e => setPresentation('table')} className={classNames({ 'active': presentation === 'table' })} />
        <FontAwesomeIcon icon={faFileExcel} ></FontAwesomeIcon>
      </div>
    </DetailsPanel>

    <div className="panel">
      {showChart && query.isSuccess && query.data != null && <ShowChart chartData={query.data} presentation={presentation} grouping={grouping} year={year} />}
      {!showChart && <StatisticsTable chartData={query.data!} presentation={presentation} grouping={grouping} year={year} />}
    </div>
  </div>
}

const RadioItem: React.FC<{ value: string, text: string, currentValue: string, setCurrentValue: (value: string) => void }> = ({ value, text, currentValue, setCurrentValue }) => <label>
  <input type="radio" name="group" value={value} checked={value == currentValue} onChange={e => e.target.checked ? setCurrentValue(value) : null} />{text}
</label>


interface ShowChartProps {
  chartData: QueryResponse;
  grouping: Groupings;
  presentation: PresentationTypes;
  year?: string;
  focus?: boolean;
}

const ShowChart: React.FC<ShowChartProps> = ({ chartData, presentation, grouping, focus, year }) => {

  React.useEffect(() => {
    if (presentation === 'line') {
      if (!year || year == '')
        ApexCharts.exec('chart', 'resetSeries', true, true);
      else
        ApexCharts.exec('chart', 'zoomX', new Date(`${year}-1-1`).getTime(), new Date(`${parseInt(year) + 1}-1-1`).getTime());
    }
  }, [year]);

  const options: ApexOptions = {
    title: {
      text: chartData.Title,
      align: 'center',
      margin: 15,
      style: {
        fontFamily: "Segoe UI",
        fontSize: '18px',
        fontWeight: 'normal'
      }
    },
    tooltip: {
      x: {
        formatter: (grouping != 'week') ? undefined : (val, opts) => {
          const y = opts.w.config.series[0].name;
          const start = moment(`${y}-02-01`).day(1).isoWeek(opts.dataPointIndex + 1);
          return `${start.format('D MMM')} - ${start.add(6, 'days').format('D MMM')}`;
        }
      }
    },
    chart: {
      type: presentation == 'line' ? 'area' : 'bar',
      id: 'chart',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: presentation == 'line',
          zoom: presentation == 'line',
          pan: presentation == 'line',
          reset: true
        }
      },
      zoom: {
        autoScaleYaxis: true,
        enabled: presentation == 'line' ? true : false,
      },
      stacked: chartData.IsGrouped ? true : false,
    },
    dataLabels: {
      enabled: presentation == 'line' || (chartData.Categories?.length ?? 200) > 60 ? false : true,
      formatter: (value, opts) => {
        return value.toLocaleString('nb');
      },
      background: {
        enabled: true,
        borderColor: '#eaeaea',
        foreColor: '#000',
        opacity: 0.8,
        dropShadow: {
          enabled: false
        }
      },
      style: {
        colors: ['#fff']
      },
      offsetY: 20,
      textAnchor: 'start'
    },
    xaxis: {
      type: presentation == 'line' || grouping == 'day' ? 'datetime' : 'category',
      categories: grouping == 'day' ? [] : chartData.Categories?.map(c => grouping == 'week' ? c.Full : c.Short) ?? [],
      //tickAmount: chartData.Data?.length ?? undefined,
      labels: {
        formatter: (presentation === 'line' || grouping == 'week') ? undefined : (value, ts, opt) => {
          if (opt != null) {
            const index: number = opt.i;
            const categoryValue = chartData.Categories?.[index]?.Full;
            if (categoryValue) {
              return categoryValue;
            }
          }

          const dataPoint = (ts as any)?.dataPointIndex;
          if (chartData.Categories != null && dataPoint != null) {
            const short = chartData.Categories?.[dataPoint]?.Full;
            return short
          }

          if (isNumber(value)) {
            return moment.unix(value / 1000).format('D MMM');
          } else {
            return value;
          }
        },
        rotateAlways: grouping == 'week'
      }
    },
    stroke: {
      width: 2
    },
    fill: {
      type: presentation == 'line' ? 'gradient' : 'linear',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
    yaxis: {
      labels: {
        formatter: (value) => value == null ? '' : `${value.toLocaleString('nb')} ${chartData.ValuePostFix}`
      }
    }
  }

  let series: ApexOptions["series"];
  const intYear = (year == null || year == "") ? new Date().getFullYear() : +year;
  if (presentation == "line") {
    series = [{
      name: '',
      data: chartData.Data?.map(q => ([new Date(q.Label.Date).getTime(), chartData.UseAverage ? Math.round(q.Average) : q.Sum])) ?? []
    }] as ApexOptions["series"];
  } else if (chartData.IsGrouped) {
    series = chartData.Grouped?.map(g => ({
      name: g.Name,
      data: g.Values.map(g => g.Sum)
    }));
  } else if (chartData.Categories != null && grouping != 'day' && grouping != 'week') {
    series = chartData.Labels.filter(l => year == '' || year == l).map(l => {
      return ({
        name: l,
        data: chartData.Categories.map(g => {
          const item = chartData.Data?.find(f => f.Label.Group === g.Key && new Date(f.Label.Date).getFullYear().toString() === l);
          if (item == null)
            return 0;
          if (chartData.UseAverage)
            return Math.round(item.Average);
          return item.Sum;
        }) ?? []
      })
    });
  } else if (grouping === 'week') {
    series = [{ name: intYear.toString(), data: chartData.Categories.map(c => chartData.Data.find(i => i.Label.Group === c.Key && i.Label.Year == intYear)?.Sum ?? 0) }] as ApexOptions["series"];
  } else {
    const tmp = chartData.Data.map(d => ({
      date: new Date(d.Label.Date),
      sum: chartData.UseAverage ? Math.round(d.Average) : d.Sum
    }));

    series = [{ name: year, data: tmp.filter(d => d.date.getFullYear() == intYear).map(q => ([q.date.getTime(), q.sum])) ?? [] }] as ApexOptions["series"];
  }

  return <ReactApexChart options={options} series={series} height={450} type={options.chart!.type as any} />
}

const StatisticsTable: React.FC<ShowChartProps> = ({ chartData, grouping, year }) => {

  if (chartData == null)
    return <Pending />

  let title: string;
  switch (grouping) {
    case 'day':
      title = 'Dag';
      break;
    case 'month':
      title = "Måned";
      break;
    case 'quarter':
      title = "Kvartal";
      break;
    case 'week':
      title = "Uke";
      break;
    case 'year':
      title = "År"
      break;
  }

  let data = year == '' ? chartData.Data : chartData.Data.filter(d => d.Label.Year.toString() == year);

  const total = sum(data.map(m => m.Sum));

  return <table className="statistics-table">
    <caption>{chartData.Title}</caption>
    <thead>
      <tr>
        <th>{title!}</th>
        <th>{chartData.ValuePostFix}</th>
        <th>{chartData.ValuePostFix} (snitt)</th>
      </tr>
    </thead>
    <tbody>
      {data.map(d => <tr key={d.Label.Date}>
        <td>{d.Label.Text}</td>
        <td><NumberWithPostfix number={d.Sum} post={chartData.ValuePostFix} /></td>
        <td><NumberWithPostfix number={Math.round(d.Average)} post={chartData.ValuePostFix} /></td>
      </tr>)}
    </tbody>
    <tfoot>
      <tr>
        <td></td>
        <td><NumberWithPostfix number={total} post={chartData.ValuePostFix} /></td>
        <td></td>
      </tr>
    </tfoot>
  </table>
}