import * as React from "react";
import { default as RootStore } from "../../stores/root-store";
import { UserNotification } from "../../models/shared/UserNotification";
import { BaseLookupModel } from "../../models/shared/BaseLookupModel";
import { Enums } from "../../stores/lookup-store";
import { toJS } from "mobx";
import { NotificationType } from "../../models/shared/NotificationType";

interface NotificationsComponentProps {
  value: Array<UserNotification>;
  onChange: (values: Array<UserNotification>) => void;
  showOnlyWhenContact: boolean;
}

interface OwnState {
  options: Array<BaseLookupModel>;
}

type Options = "ViaEmail" | "ViaText" | "OnlyWhenContact";

export class NotificationsComponent extends React.Component<NotificationsComponentProps, OwnState> {
  state: OwnState = { options: [] };

  async componentDidMount() {
    const options = await RootStore.LookupStore.getEnums(Enums.NotificationType, false);
    if (options)
      this.setState({ options });
  }

  update = (id: number, type: Options, checked: boolean) => {
    const value = toJS(this.props.value);

    let row = value.find(v => v.Type == id);
    if (row == null) {
      row = { Type: id, OnlyWhenContact: false, ViaEmail: false, ViaText: false };
      value.push(row);
    }
    if (type == "ViaEmail")
      row.ViaEmail = checked;
    else if (type == "ViaText")
      row.ViaText = checked;
    else if (type == "OnlyWhenContact")
      row.OnlyWhenContact = checked;
    this.props.onChange(value);
  }

  render() {
    const { options } = this.state;
    if (options.length == 0)
      return null;

    const { value, showOnlyWhenContact } = this.props;
    return <table>
      <thead>
        <tr>
          <th>Type</th>
          <th className="center">Via Email</th>
          <th className="center">Via Tekstmelding</th>
          {showOnlyWhenContact && <th className="center">Bare når kontakt</th>}
        </tr>
      </thead>
      <tbody>
        {options.map(o => <tr key={o.Id}>
          <td>{o.Name}</td>
          <td className="center">
            <input
              type="checkbox"
              checked={value.find(v => v.Type == o.Id)?.ViaEmail ?? false}
              onChange={e => this.update(o.Id, "ViaEmail", e.currentTarget.checked)} />
          </td>
          <td className="center">
            <input
              type="checkbox"
              checked={value.find(v => v.Type == o.Id)?.ViaText ?? false}
              onChange={e => this.update(o.Id, "ViaText", e.currentTarget.checked)} />
          </td>
          {showOnlyWhenContact && o.Id !== NotificationType.Marketing &&
            <td className="center">
              <input
                type="checkbox"
                checked={value.find(v => v.Type == o.Id)?.OnlyWhenContact ?? false}
                onChange={e => this.update(o.Id, "OnlyWhenContact", e.currentTarget.checked)} />
            </td>}
        </tr>)}
      </tbody>
    </table>
  }
}