import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../../forms/wrapField";
import { InputWithFix } from "../../shared/InputWithFix";
import { CurrencyInput } from "../../shared/CurrencyInput";

interface OwnProps {
  type?: "text" | "password" | "number" | "email" | "currency";
  postfix?: string;
  formatter?: (value: any) => string;
  step?: string;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  autoComplete?: string;
}

type P = PropsFromWrapperToField<string> & OwnProps;

class InputField extends React.PureComponent<P> {
  render() {
    let { value, type, postfix, setValue, setTouched, id, autoFocus, formatter, step, disabled, inputMode, autoComplete } = this.props;

    if (inputMode == "numeric" && type == "number") {
      if (formatter == null)
        formatter = v => v != null && parseInt(v) == 0 ? "" : v;
    }

    const useCurrency = type === "currency";

    const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
      id,
      type: type == "currency" ? "text" : type || "text",
      onChange: e => {

        // .net model binding crashes if int recieves ""
        let v = e.target.value;
        if (type == "number" && (v == null || v == ""))
          v = "0";

        setValue(v);
      },
      value: (formatter ? formatter(value) : value) ?? "",
      onBlur: _ => setTouched(),
      step,
      autoFocus,
      disabled: disabled,
      inputMode,
      lang: "no",
      autoComplete
    }

    if (postfix)
      return <InputWithFix postfix={postfix} {...inputProps} useCurrencyInputField={useCurrency} />
    else if (useCurrency)
      return <CurrencyInput {...inputProps} ref={this.props.refFn} />
    else
      return <input {...inputProps} ref={this.props.refFn} />
  }
}

const A = Wrap<P, string>(InputField)
export { A as InputField };