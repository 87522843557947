import * as React from "react";
import { Switch, RouteComponentProps, Route } from "react-router";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { ComplaintForm } from "./ComplaintForm";
import { ComplaintList } from "./ComplaintList";
import { ComplaintDetailsPage } from "./ComplaintDetails";

const ComplaintContainer = (props: RouteComponentProps<any>) =>
  <>
    <Switch>
      <Route render={(r) => <WorkSpace
        singular="reklamasjon"
        plural="reklamasjoner"
        routeBasePath={props.location.pathname.split("/")[1]}
        {...props}
        editComponent={<ComplaintForm />}
        listComponent={<ComplaintList />}
        detailsComponent={<ComplaintDetailsPage />}
      />} />
    </Switch>
  </>;

export { ComplaintContainer };