import * as React from "react";
import { observer } from "mobx-react";
import { WorkOrderDetailsUnitModel } from "../../../models/shared/WorkOrderDetailsUnitModel";
import { UnitListItem } from "./UnitListItem";
import { GenericDataStore } from "../../../stores/genericData-store";
import { WorkOrderDetailsModel } from "../../../models/shared/WorkOrderDetailsModel";
import { Direction } from "../../../models/shared/Direction";

interface Props {
  units: Array<WorkOrderDetailsUnitModel>;
  store: GenericDataStore<WorkOrderDetailsModel>
  direction: Direction;
  selectedId?: number;
}

@observer
export class UnitList extends React.Component<Props> {
  render() {
    const { units, store, direction, selectedId } = this.props;
    if (units == null || units.length == 0)
      return <p>Det er ingen kolli tilknyttet denne arbeidsordren.</p>;

    return (
      <div>
        <ul>
          {units.filter(u => u.Direction == direction).map(u => <UnitListItem key={u.Id} unit={u} store={store} isSelected={u.Id === selectedId} />)}
        </ul>
      </div>);
  }
}