import ClipboardIcon from "svg/clipboard.svg";
import { useQuery } from "@tanstack/react-query";
import clipboardCopy from "clipboard-copy";
import React, { PropsWithChildren, useEffect, useState } from "react"
import { Network } from "../../../api/network";
import { CustomBreadCrumb } from "../../../components/shared/BreadCrumb"
import { CopyToClipboard } from "../../../components/shared/CopyToClipboard";
import { NumberWithPostfix } from "../../../components/shared/display";
import { Pending } from "../../../components/shared/Pending";
import { infoToast } from "../../../components/shared/Toasts";
import "./CalculateOffer.tsx.scss"

interface CalculationValues {
  ZinkPurchasePrice: number;
  AveragedSpotPrice: number;
  VariableElectricity: number;
  VariableZink_Profil: number;
  VariableZink_Dim: number;
  VariableZink_Items: number;
}

type GodsTypes = "Profil" | "HulMultiDim" | "Stykk";

export const CalculateOffer = () => {

  const [weight, setWeight] = useState<string>("");
  const [hours, setHours] = useState<string>("");
  const [godstype, setGodstype] = useState<GodsTypes>("Profil");

  const { data, refetch } = useQuery({
    queryKey: ["calculationValues"],
    queryFn: () => new Network().getSimple<CalculationValues>("/api/offers/calculationValues"),
  })

  const calculateHours = () => {
    const w = +weight;
    if (w > 0)
      setHours(Math.max((w / 8000) * 7.5, 0.5).toFixed(2));
  }

  const parsedWeight = parseFloat(weight.replace(",", "."));
  const parsedHours = parseFloat(hours.replace(",", "."));

  if (data == null)
    return <Pending />

  const sinkPrice = data.ZinkPurchasePrice;
  const electricityPerHour = 1500 / 7.5 * data.AveragedSpotPrice;

  const minimumLossTotal = parsedWeight * (sinkPrice * 0.07) + electricityPerHour * parsedHours;
  const minimumLossOvertime = minimumLossTotal + (parsedHours * 468 * 6);

  const minimumZero = 75_000 / 7.5 * parsedHours;

  const tillegg = data.VariableElectricity + (godstype === "Profil" ? data.VariableZink_Profil : 0) + (godstype === "HulMultiDim" ? data.VariableZink_Dim : 0) + (godstype === "Stykk" ? data.VariableZink_Items : 0);

  return <div className="page-employees">
    <h1>Tilbudsutregning</h1>
    <CustomBreadCrumb links={[
      { title: "Tilbud", path: '/offers' },
      { title: "Tilbudsutregning", path: 'offers/calculate' }
    ]} />
    <div className="panel form-container">
      <form className="edit-form edit-form-padding">
        <h3>Godsbeskrivelse</h3>
        <Field title="Vekt" description="Her spesifiserer du vekten på leveransen">
          <div className="flex">
            <input type="text" className="input-with-post-designator short-input" value={weight} onChange={v => setWeight(v.target.value)} onBlur={calculateHours} />
            <span className="post-input-designator">kg</span>
          </div>
        </Field>
        <Field title="Produksjonstid" description="Her spesifiserer du hvor mange timer leveransen vil oppta produksjonen i fabrikken. Ikke sammenhengende. Ca maks 8-10 bommer per dag">
          <div className="flex flex-center timer">
            <input type="text" className="input-with-post-designator short-input" value={hours} onChange={v => setHours(v.target.value)} />
            <span className="post-input-designator">t</span>
            <ul className="hour-helper">
              <li onClick={() => setHours((7.5 / 2).toFixed(1))}>½ dag</li>
              <li onClick={() => setHours((7.5).toFixed(1))}>1 dag</li>
              <li onClick={() => setHours((7.5 * 1.5).toFixed(1))}>1½ dag</li>
              <li onClick={() => setHours((7.5 * 2).toFixed(1))}>2 dager</li>
              <li onClick={() => setHours((7.5 * 3).toFixed(1))}>3 dager</li>
            </ul>
          </div>
        </Field>
        <Field title="Godstype" description="Her spesifiserer du hva slags gods leveransen går ut på - for tilleggsberegning">
          <div className="flex flex-center ">
            <select value={godstype} onChange={e => setGodstype(e.target.value as GodsTypes)}>
              <option value="Profil">Profilstål</option>
              <option value="HulMultiDim">Hulprofil eller multidimensjonal</option>
              <option value="Stykk">Stykkgods</option>
            </select>
          </div>
        </Field>
      </form>
      <div className="calculated-offer-table table-container">
        <table className="standard">
          <thead>
            <tr>
              <th>Beskrivelse</th>
              <th className="right">{`Pris uten tillegg (-${tillegg.toFixed(2)} kr)`}</th>
              <th className="right">Pris inklusiv tillegg</th>
              <th className="right">Totalpris eks mva</th>
              <th className="right" title="Fortjeneste i forhold til normalproduksjon">Gevinst</th>
            </tr>
          </thead>
          <tbody>
            <Row
              title="Minimum pris før tap ved tom for gods"
              description="Minste pris vi kan gi uten å tape penger på å produsere varen når fabrikke er tom for gods og arbeidere ikke har noe å gjøre. Dekker sink og strøm. Aldri gå under denne prisen."
              weight={parsedWeight}
              total={minimumLossTotal}
              surcharge={tillegg}
              profit={minimumLossTotal - (parsedHours * 75000 / 7.5)} />
            <Row title="Minimum pris ved overtid"
              description={`Minste pris vi kan gi uten å tape penger på å produsere varen på overtid. Dekker sink, strøm og overtid på ${parsedHours.toFixed(1)} timer overtid for seks mann.`}
              weight={parsedWeight}
              surcharge={tillegg}
              total={minimumLossOvertime}
              profit={minimumLossOvertime - (parsedHours * 75000 / 7.5)} />
            <Row title="Normalpris ved normal leveringstid"
              description="Normalpris i forhold til normalproduksjon (75 000 kr per dag). Ikke gå under denne dersom vi er fullbooket."
              weight={parsedWeight}
              surcharge={tillegg}
              total={minimumZero}
              profit={minimumZero - (parsedHours * 75000 / 7.5)} />
            <Row
              title="20% margin"
              description="Pris hvor vi tjener på at produksjonen går utover normalproduksjon, f.eks ved å skyve på andre ordre."
              surcharge={tillegg}
              weight={parsedWeight}
              total={minimumZero * 1.2}
              profit={(minimumZero * 1.2) - (parsedHours * 75000 / 7.5)} />
          </tbody>
        </table>
      </div>
      <hr />
      <div className="offer-details">
        <div>
          <Tilbudstekst tillegg={tillegg} />
        </div>
        <div><h2>Detaljer</h2>
          <div className="details">
            <span>Sinkpris innkjøp: <NumberWithPostfix post="kr/kg" number={sinkPrice} decimals={2} /></span>
            <span>Gjennomsnittlig spotpris: <NumberWithPostfix post="kr" number={data.AveragedSpotPrice} decimals={2} /></span>
            <span>Regulering strømpris: <NumberWithPostfix post="kr" number={data.VariableElectricity} decimals={2} /></span>
            <span>Regulering sink profilstål: <NumberWithPostfix post="kr" number={data.VariableZink_Profil} decimals={2} /></span>
            <span>Regulering sink hulprofil/multidimensjonal: <NumberWithPostfix post="kr" number={data.VariableZink_Dim} decimals={2} /></span>
            <span>Regulering sink stykkgods: <NumberWithPostfix post="kr" number={data.VariableZink_Items} decimals={2} /></span>
          </div>
          <div className="details">
            <p>Tilbud gitt inklusiv tilegg basert på kalkulasjoner her bør gis med kort first da de er noe sårbare for variasjoner på sink og pristillegg. Tilbud bør har forbehold om faktisk levert vekt.</p>
            <p>Transport, lakkering og ev. ekstraarbeid ikke inkludert. Utvidet leveringstid kan potensielt kompansere for lavere pris ved lav kapasitet.</p>
            <a href="/api/invoices/pricelist" target="_blank">Prisliste</a>
          </div>

        </div>
      </div>
      <div className="calculated-offer-table">
      </div>
    </div>
  </div>
}

const Tilbudstekst: React.FC<{ tillegg: number }> = ({ tillegg }) => {

  const [value, setValue] = useState<string>("10.00");
  const [isInclusive, setIsInclusinve] = useState(false);

  const priceText = parseFloat(value.replace(",", ".")).toFixed(2).replace(".", ",");

  const textIncl = `Vi kan gi et tilbud på varmforsinking til ${priceText} kr/kg på din forespørsel. Dette inkluderer variable kostnader for energi og sink.`;
  const textExcl = `Vi kan gi et tilbud på varmforsinking til ${priceText} kr/kg på din forespørsel. I tillegg kommer variable kostnader for energi og sink som per i dag er på totalt ${tillegg.toFixed(2).replace(".", ",")} kr/kg.`;
  const textTerms = "Alle priser er eksklusiv mva. Eventuelle ekstrakostnader som for eksempel hullborring eller sandblånsning kommer i tillegg. Tilbudet inkluderer ikke transport. Våre priser KPI justeres årlig.";

  const text = isInclusive ? textIncl : textExcl;
  const copyText = () => {
    clipboardCopy(text + "\r\n\r\n" + textTerms);
    infoToast(`Kopiert tilbudstekst til utklippstavlen.`);
  }

  return <>
    <h2>Tilbudstekst</h2>
    <div className="flex edit-form tilbud-selector">
      <div className="flex ">
        <input className="input-with-post-designator short-input" value={value} onChange={e => setValue(e.target.value)}></input>
        <span className="post-input-designator">kr/kg</span>
      </div>
      <div>
        <label><input type="radio" checked={!isInclusive} onChange={e => setIsInclusinve(false)} />Uten tillegg</label>
        <label><input type="radio" checked={isInclusive} onChange={e => setIsInclusinve(true)} />Inklusiv tillegg</label>
      </div>
    </div>
    <div className="offer-text" onClick={copyText}>
      <p>{text}</p>
      <p>{textTerms}</p>
      <span title="Kopier til utklippstavle" className="copyIcon"><ClipboardIcon className="icon" /></span>
    </div>
  </>
}

interface RowProps {
  title: string;
  description: string;
  profit: number;
  total: number;
  weight: number;
  surcharge: number;
}

const Row: React.FC<RowProps> = ({ title, description, total, profit, weight, surcharge }) => {
  const hasValue = !isNaN(total) && !isNaN(weight) && weight > 0;
  return <tr>
    <td>
      <span>{title}</span>
      <span className="description">{description}</span>
    </td>
    <td className="right">
      {hasValue && <NumberWithPostfix number={Math.max((total / weight) - surcharge, 0)} decimals={2} post="kr/kg" />}
    </td>
    <td className="right">
      {hasValue && <NumberWithPostfix number={total / weight} decimals={2} post="kr/kg" />}
    </td>
    <td className="right">
      {hasValue && <NumberWithPostfix number={total} decimals={0} post="kr" />}
    </td>
    <td className="right">
      {hasValue && <NumberWithPostfix number={profit} decimals={0} post="kr" />}
    </td>
  </tr>
}

const Field: React.FC<PropsWithChildren<{ title: string, description: string }>> = ({ title, description, children }) => {
  return <div className="form-field">
    <div className="form-field-descripion">
      <label className="form-field-label">{title}</label>
      <div className="form-field-help-container">
        <span className="form-field-help-text"><span>{description}</span>
        </span>
      </div>
    </div>
    <div className="form-field-component">
      {children}
    </div>
  </div>
}