/** Malingstype */
export enum PaintType {
  
  /**Ingen */
  None = 0,  
  /** */
  Powder = 1,  
  /** */
  Wet = 2,  
  /** */
  ByCustomer = 3
}

