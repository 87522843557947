import moment from "moment";
import React from "react";
import { Network } from "../../api/network";
import { Dialog } from "../../core/dialog";
import { default as RootStore } from "../../stores/root-store";

export const MissingPhonenumberDialog: React.FC = () => {

  const userStore = RootStore.UserStore;
  const isMissingPhonenumber = !userStore.requiresAuthentication && !userStore.hasPhone;

  if (!isMissingPhonenumber)
    return null;

  const lastViewed = window.localStorage.getItem("missing_phone");
  if (lastViewed != null) {
    const date = new Date(lastViewed);
    if (moment().diff(date, "days") < 90)
      return null;
  }

  const isValid = (phone?: string) => /^[49]\d{7}$/.test(phone ?? "");
  const setLastViewed = () => {
    window.localStorage.setItem("missing_phone", new Date().toISOString());
  }

  const save = async (phonenumber: string) => {
    const network = new Network();
    await network.getSimple("/api/users/setPhonenumber/" + phonenumber);
    window.flytSettings.user!.hasPhone = true;
    userStore.refresh();
    setLastViewed();
  }

  const dialog = new Dialog<{ phonenumber: string }>();
  dialog.id = "missing_phone";
  dialog.title = "Manglende mobilnummer";
  dialog.content = (state) => {
    return <div>
      <p>Vi har ikke registrert et mobiltelefonnummer på din konto. Dette er nødvendig dersom du ønsker å motta varsler via tekstmelding. Dersom du ikke ønsker tekstmeldinger kan du klikke "Hopp over" nederst.</p>
      <form className="edit-form">
        <div className="form-field">
          <div className="form-field-descripion">
            <label className="form-field-label">Mobilnummer</label>
            <div className="form-field-help-container">
              <span className="form-field-help-text">
                <span>Fyll inn mobilnummeret ditt. Åtte siffer som starter med 4 eller 9.</span>
              </span>
            </div>
          </div>
          <div className="form-field-component">
            <input value={state.phonenumber} onChange={e => state.phonenumber = e.target.value} />
          </div>
        </div>
      </form>
    </div>
  }
  dialog.closable = false;
  dialog.buttons = [
    { onClick: (model, closeFn) => { save(model.phonenumber!); closeFn(); }, text: "Lagre telefonnummer", isEnabled: (m) => isValid(m.phonenumber) },
    {
      onClick: (model, closeFn) => {
        setLastViewed();
        closeFn();
      }, text: "Hopp over"
    },
  ]
  Dialog.Show(dialog);

  return null;
}