/**  */
export enum FilterType {
  
  /** */
  String = 0,  
  /** */
  DateRange = 1,  
  /** */
  TriStateBoolean = 2,  
  /** */
  OptionList = 3,  
  /** */
  Lookup = 4,  
  /** */
  StateFilter = 5
}

