import * as React from "react";
import { observer } from "mobx-react";
import { default as RootStore } from "../../stores/root-store";
import { Lookup, Grouped } from "../../stores/lookup-store";
import { parseInt } from "lodash-es";
import "./customerSelector.tsx.scss";
import { observable, autorun, IReactionDisposer } from "mobx";

interface Props {
  value?: number;
  setValue: (id?: number) => void;
  id?: string;
  showAlphabeth?: boolean;
}

interface State {
  character?: string;
  customers?: Array<Grouped>;
}

@observer
export class CustomerSelector extends React.Component<Props, State> {

  state: State = { character: undefined };
  disposer: IReactionDisposer | undefined;

  toggleCharacter = (character: string) => {
    this.setState(state => ({ character: state.character === character ? undefined : character }));
  }

  setValue: React.ReactEventHandler<HTMLSelectElement> = event => {
    if (event.currentTarget.value == "")
      this.props.setValue(undefined);
    else
      this.props.setValue(parseInt(event.currentTarget.value));
  }

  componentDidMount() {
    // Customers set here instead of render method via computed property as it was giving error
    // about change occuring while in render.
    this.disposer = autorun(reaction => {
      const customers = RootStore.LookupStore.getGrouped(Lookup.Customers);
      this.setState({ customers });
    });    
  }

  componentWillUnmount() {
    if (this.disposer)
      this.disposer();
  }

  render() {
    const { character, customers } = this.state;
    const { value, id, showAlphabeth } = this.props;
   
    if (customers == null)
      return null;

    return (
      <div className="customer-selector" id={id}>
        {(showAlphabeth == null || showAlphabeth === true) && <ul className="alphabeth">
          {customers.map(c =>
            <li key={c.key} onClick={e => this.toggleCharacter(c.key)}>
              <span aria-selected={c.key === this.state.character}>{c.key}</span>
            </li>)}
        </ul>}
        <select onChange={this.setValue} value={value ? value.toString() : ""}>
          <option value="">-- Velg kunde --</option>
          {!character && customers.map(c =>
            <optgroup key={c.key} label={c.key}>
              {c.list.map(i => <option value={i.Id} key={i.Id}>{i.Name}</option>)}
            </optgroup>)}
          {character && customers.find(chr => chr.key === character)!.list.map(i => <option value={i.Id} key={i.Id}>{i.Name}</option>)}
        </select>
      </div>);
  }
}