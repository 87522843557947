import { default as RootStore } from "../stores/root-store";
import type { ValidationResultValue } from "../models/shared/ValidationResultValue";
import { Dialog } from "../core/dialog";
import { isClientFileReferenceModel } from "../models/ClientFileReferenceModel";
import { toJS } from "mobx";
import { v4 as guid } from "uuid";
import * as React from "react";
import { Logger } from "../core/logging";
import type { ServerExceptionModel } from "../models/shared/ServerExceptionModel";

export class Network {

  baseUrl: string;
  private static _server_version: string;

  constructor() {
    this.baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || "/";
  }

  private fillRequestHeaders(headers: Record<string, string>) {
    headers["X-RequestCorrelationID"] = guid();
    headers["X-PageCorrelationID"] = (window as any).PageCorrelationID;
    headers["X-ClientPath"] = window.location.href;
  }

  async post<TRequest, TResponse>(url: string, data: TRequest, filesAsFormData: boolean = false, controller?: AbortController): Promise<TResponse | ValidationResultValue | void> {

    // Generate a form with binary data objects
    const form = new FormData();
    const json = JSON.stringify(data);
    form.append("model", json);

    Logger.info(`Client network request ${url} with ${json}.`);

    for (let key in data) {
      const obj = toJS(data[key]);
      if (Array.isArray(obj)) {
        obj.forEach(file => {
          if (isClientFileReferenceModel(file))
            form.append("files", file.File!, file.Name);
        });
      }
    }

    const headers: Record<string, string> = {
      "Accept": "application/json",
      //  "Authorization": `Bearer ${jwt.token}`
    };

    this.fillRequestHeaders(headers)

    if (!filesAsFormData)
      headers["Content-Type"] = "application/json; charset=utf-8";

    try {
      RootStore.UIStore.addNetworkCall();
      const response = await fetch(url, {
        method: "POST",
        body: filesAsFormData ? form : JSON.stringify(data),
        credentials: "include",
        cache: "no-cache",
        headers: new Headers(headers),
        signal: controller != null ? controller.signal : undefined
      });

      this.ensureServerVersion(response);

      if (response.ok) {
        if (response.headers.has("Content-Type"))
          return await response.json() as TResponse;
        else
          return;
      }
      else if (response.status === 401) {
        RootStore.UserStore.requiresAuthentication = true;
        return Promise.resolve();
      } else if (response.status === 422)
        return await response.json() as ValidationResultValue;
      else if (response.headers.get("content-type") === "application/json") {
        const json = await response.json();
        if (isServerExceptionModel(json))
          this.showGenericError(json.Message || "Unknown error", headers);
        else
          this.showGenericError(response.statusText, headers);
      } else {
        Logger.error(`Network request unsucessfull during request for ${response.url} with message ${response.status.toString()}/${response.statusText}.`);
        this.showGenericError(response.statusText, headers);
      }

      return Promise.resolve();
    }
    catch (error) {

      if (error instanceof DOMException) {
        // Check if the request was cancled, if so, do nothing
        if (error.code === error.ABORT_ERR)
          return Promise.resolve();

        if (error != null && error.hasOwnProperty("message"))
          this.showGenericError(error.message, headers);
        else if (typeof error === "string")
          this.showGenericError(error, headers);
        else
          this.showGenericError("En ukjent feil oppstod.", headers);
      } else
        this.showGenericError("En ukjent feil oppstod.", headers);

      Logger.error(`Network error during request for ${url}.`, error as any);
    }
    finally {
      RootStore.UIStore.reduceNetworkCall();
    }

  }

  private showGenericError(message: string, headers: Record<string, string>) {
    const content = <>
      <p>{message}</p>
      <ul className="error-dialog-trace-info">
        <li><span>Time</span><span>{new Date().toString()}</span></li>
        <li><span>Page Correlation ID</span><span>{headers["X-PageCorrelationID"]}</span></li>
        <li><span>Request Correlation ID</span><span>{headers["X-RequestCorrelationID"]}</span></li>
      </ul>
    </>;

    RootStore.UIStore.addDialog(Dialog.OkDialog("Tjenestefeil", content));
  }

  async getSimple<TResponse>(url: string): Promise<TResponse> {

    const headers: Record<string, string> = {
      "Content-Type": "application/json; charset=utf-8",
      "Accept": "application/json"
    }
    this.fillRequestHeaders(headers);

    Logger.info(`Client network simple GET request ${url}.`);

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      cache: "no-cache",
      headers: new Headers(headers)
    });

    this.ensureServerVersion(response);

    if (response.ok) {
      if (response.headers.has("Content-Type"))
        return await response.json() as TResponse;
      else
        return Promise.resolve(undefined as any as TResponse);
    } else {
      Logger.error(`Network request unsucessfull during request for ${response.url} with message ${response.status.toString()}/${response.statusText}.`);
      throw response;
    }
  }

  async get<TResponse>(url: string, controller?: AbortController): Promise<TResponse | void> {

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Accept": "application/json"
    }
    this.fillRequestHeaders(headers);
    Logger.info(`Client network GET request ${url}.`);

    try {
      RootStore.UIStore.addNetworkCall();

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        headers: new Headers(headers),
        signal: controller != null ? controller.signal : undefined
      });

      this.ensureServerVersion(response);

      if (response.ok) {
        if (response.headers.has("Content-Type"))
          return await response.json() as TResponse;
        else
          return;
      }
      else if (response.status === 401) {
        RootStore.UserStore.requiresAuthentication = true;
        return Promise.resolve();
      } else if (response.status === 422) {
        const validationResult = await response.json() as ValidationResultValue;
        if (validationResult && validationResult.Message)
          this.showGenericError(validationResult.Message, headers);
        else
          this.showGenericError(response.statusText, headers);
      } else if (response.status === 404)
        this.showGenericError("Data mangler for denne forespørselen. Som regel skyldes dette at relatert informasjon har blitt slettet.", headers);
      else if (response.headers.get("content-type") === "application/json") {
        const json = await response.json();
        if (isServerExceptionModel(json))
          this.showGenericError(json.Message || "Unknown error", headers);
        else
          this.showGenericError(response.statusText, headers);
      } else {
        Logger.error(`Network request unsucessfull during request for ${response.url} with message ${response.status.toString()}/${response.statusText}.`);
        this.showGenericError(response.statusText, headers);
      }

      return Promise.resolve();
    }
    catch (error) {

      if (error instanceof DOMException) {
        // Check if the request was cancled, if so, do nothing
        if (error.code === error.ABORT_ERR)
          return Promise.resolve();
      }

      Logger.error(`Network error during request for ${url}.`, error as any);

      if (error instanceof DOMException && error != null && error.hasOwnProperty("message"))
        this.showGenericError(error.message, headers);
      else if (typeof error === "string")
        this.showGenericError(error, headers);
      else
        this.showGenericError("En ukjent feil oppstod.", headers);
    }
    finally {
      RootStore.UIStore.reduceNetworkCall();
    }
  }

  ensureServerVersion(response: Response) {
    const version = response.headers.get("X-Version");
    if (version) {
      if (Network._server_version == null)
        Network._server_version = version;
      else if (Network._server_version !== version) {
        window.location.href = "/";
      }
    }
  }
}

export function isValidationResultValue(value: ValidationResultValue | any): value is ValidationResultValue {
  return value != null && (value.hasOwnProperty("Message") || value.hasOwnProperty("Errors"));
}

export function isServerExceptionModel(obj: any): obj is ServerExceptionModel {
  return typeof obj === 'object' && 'Message' in obj;
}

