import { ListStore } from "../../stores/list-store";
import { BaseModel } from "../../models/shared/BaseModel";
import { UserStore } from "../../stores/user-store";
import { ScreenSize } from "../../core/util";

export enum Alignment {
  Left,
  Center,
  Right
}

enum SortDirection {
  Asc,
  Desc
}

export enum FilterType {
  String,
  DateRange,
  TriStateBoolean,
  OptionList,
  Lookup,
  StateFilter
}

export interface IListPreset<TModel> {
  title: string;
  default: boolean | ((user: UserStore) => boolean);
  filters: Array<{
    field: string | keyof TModel,
    value: any;
    filterType: FilterType;
  }>,
  sort: Array<{
    field: string | keyof TModel;
    direction: SortDirection;
  }>,
  hidden?: (user: UserStore) => boolean;
}

/**
 * @param id Should be the name of the controller handling the requests with an appended s. 
 */
export interface IListDefinition<TModel extends BaseModel> {
  // Id should be controller with s
  id: string;
  singular: string;
  editLink?: (row: TModel, user: UserStore) => string | null;
  createLink: (user: UserStore) => string | null;
  baseUrl?: string;
  filters: Array<IListFilter<TModel>>;
  columns: Array<IListColumn<TModel>>;
  presets: Array<IListPreset<TModel>>;
  showDelete?: (row: TModel) => boolean;
  collapsableContent?: (row: TModel, user: UserStore) => React.ReactElement<any>;
  showCollapsableContentForRow?: (row: TModel, user: UserStore) => boolean;
  footer?: (set: Array<TModel>, user: UserStore) => React.ReactElement<HTMLTableRowElement>;
  listUrlSegment?: string;
  rowCssClass?: (row: TModel, user: UserStore) => string | null;  
}

export interface IListFilter<TModel> {
  component: React.ReactElement<any>;
  title: string;
  field: keyof TModel | string;
  type: FilterType;
  hidden?: (user: UserStore) => boolean;
  group?: string;
  focus?: boolean;
}

interface IListColumn<TModel> {
  title: ((size: ScreenSize) => string) | string;
  field?: keyof TModel;
  sortable?: boolean;
  alignment?: Alignment;
  render: (row: TModel, value: any, index: number, user: UserStore) => React.ReactElement<any>;
  hidden?: (user: UserStore, filters: Map<keyof TModel, any>) => boolean;
  sortField?: keyof TModel | string;
}



export interface FilterProps<TListModel extends BaseModel> {
  store: ListStore<TListModel>;
  field: keyof TListModel;
  focus: boolean;
}