import RushIcon from "svg/rush.svg";
import * as React from "react";
import { WorkOrderDetailsModel } from "../../../models/shared/WorkOrderDetailsModel";
import { GenericDataStore } from "../../../stores/genericData-store";
import { withRouter, RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import { DateTime, Indicator, NumberWithPostfix } from "../../../components/shared/display";
import { Link } from "react-router-dom";
import { Button } from "../../../components/shared/Button";
import { UnitState } from "../../../models/shared/UnitState";
import EditIcon from "svg/edit.svg";
import MoveIcon from "svg/move.svg";
import DeleteIcon from "svg/trash.svg";
import AlertIcon from "svg/exclamation.svg";
import EmployeeIcon from "svg/employee.svg";
import ExportIcon from "svg/export.svg";
import OrderIcon from "svg/order.svg";
import PdfIcon from "svg/pdf.svg";
import TransportIcon from "svg/transport.svg";
import ComplaintIcon from "svg/complaint.svg";
import { Dialog } from "../../../core/dialog";
import { TabPanel } from "../../../components/shared/TabPanel";
import { Tab } from "../../../components/shared/Tab";
import { CustomerSelector } from "../../../components/shared/CustomerSelector";
import { default as RootStore } from "../../../stores/root-store";
import { volumForUnit, add, showRalAndGloss, downloadFile } from "../../../core/util";
import { UnitList } from "./UnitList";
import { DetailsPanel } from "../../../components/details/DetailsPanel";
import { DetailsBlock } from "../../../components/details/DetailsBlock";
import { DetailsAction } from "../../../components/details/DetailsAction";
import { DetailValueField } from "../../../components/details/DetailValueField";
import UnitIcon from "svg/unit.svg";
import { max, omit, isNumber, sum } from "lodash-es";
import { Invoice } from "./Invoices";
import { Audit } from "ts/components/shared/audit/Audit";
import { paintTypeFromEnum, corrosivityFromEnum } from "ts/core/enumHelper";
import { RushRequestDialog } from "./RushRequestDialog";
import { stringToDate, toDateWithHMShort, toDateWithHM, since, maxDate, getDeliveryDate } from "../../../core/dateHelpers";
import { CopyToClipboard } from "../../../components/shared/CopyToClipboard";
import "./WorkOrderDetailsPage.tsx.scss";
import { TransportRequestType } from "../../../models/shared/TransportRequestType";
import { checkBeforeStateChange } from "./workOrderHelpers";
import { MessageList } from "../../../components/shared/communications/MessageList";
import { AddressView } from "../../../components/shared/AddressView";
import { CorrosivityCategory } from "../../../models/shared/CorrosivityCategory";
import { Direction } from "../../../models/shared/Direction";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { Network } from "../../../api/network";
import { ExtraWorkDialog } from "./dialogs/ExtraWorkDialog";
import { PaintType } from "../../../models/shared/PaintType";
import { DocumentList } from "../../../components/shared/DocumentList";
import classNames from "classnames";
import { StateChangesTab } from "./units/StateChangesTab";
import { ListNavigator } from "./ListNavigator";
import { ExtraWorkListModel } from "../../../models/shared/ExtraWorkListModel";
import { SyncronizationTabContent } from "./SyncronizationTabContent";
import { AssociateWorkOrderWithOrderDialog } from "./dialogs/AssociateWorkOrderWithOrderDialog";
import { TransportOrderList } from "../../../components/shared/TransportOrderList";
import { WorkOrderDetailsUnitModel } from "../../../models/shared/WorkOrderDetailsUnitModel";
import { TransportOrderDialog } from "../../../components/shared/TransportOrderDialog";
import { ComplaintDialog } from "./dialogs/ComplaintDialog";
import { WorkOrderState } from "../../../models/shared/WorkOrderState";
import { ChatPageList, DialogList } from "../../../components/shared/messages/MessageDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faPrint } from "@fortawesome/free-solid-svg-icons";
import { infoToast, successToast } from "../../../components/shared/Toasts";
import queryString from "query-string";

type P = RouteComponentProps<{ id: string }, {}, { unitId?: number }>;

@observer
class WorkOrderDetailsPage extends React.Component<P> {

  store: GenericDataStore<WorkOrderDetailsModel>;
  selectedUnit?: number;

  constructor(props: any) {
    super(props);
    this.store = new GenericDataStore<WorkOrderDetailsModel>();

    const locationState = this.props.location.state;
    if (locationState != null)
      this.selectedUnit = locationState.unitId;
    else {
      const parsed = queryString.parse(location.hash);
      if (Object.hasOwn(parsed, "unit")) {
        this.selectedUnit = +parsed.unit!;
      }
    }
  }


  getChangeStateOptions(model: WorkOrderDetailsModel) {

    const verify = (state: UnitState) => {
      checkBeforeStateChange(
        this.store.entity!,
        state,
        () => this.store.changeStateAll(state),
        undefined
      );
    }

    const result: Array<{ text: string, onClick: () => void }> = [];

    if (model.Units.some(u => u.State == UnitState.Received)) {
      result.push({ text: "Alle i kontrolert", onClick: () => verify(UnitState.Checked) });
      result.push({ text: "Alle til ekstern", onClick: () => verify(UnitState.External) });
    }

    if (model.Units.some(u => u.State == UnitState.Checked || u.State == UnitState.Preparing))
      result.push({ text: "Alle i produksjon", onClick: () => verify(UnitState.Prepared) });

    if (model.Units.some(u => u.State == UnitState.Packaging))
      result.push({ text: "Alle pakket", onClick: () => verify(UnitState.Packaged) });

    if (model.Units.some(u => u.State == UnitState.Packaging || u.State == UnitState.Packaged) || model.ComputedState < WorkOrderState.Ready)
      result.push({ text: "Alle ferdig", onClick: () => verify(UnitState.Ready) });

    if (model.Units.some(u => u.State == UnitState.Ready) || model.ComputedState < WorkOrderState.Delivered)
      result.push({ text: "Alle hentet", onClick: () => verify(UnitState.Delivered) });

    return result;
  }


  async componentDidMount() {

    await this.store.loadData(parseInt(this.props.match.params.id));
    if (this.selectedUnit != null) {
      setTimeout(
        () => {
          document.getElementById(`unit_${this.selectedUnit}`)?.scrollIntoView({ behavior: "smooth" });
          const test = omit(window.history.state, ["state.unitId"]);
          window.history.replaceState(test, "");
        },
        50);
    }
  }

  editWorkOrder = () => {
    const { history } = this.props;
    const { entity: model } = this.store;
    history.push(`/workorders/${model!.Id}/edit`);
  }

  syncWithFlyt = async (name: string) => {

    const dialog = new Dialog<{}>()
    dialog.buttons = [{
      text: "OK",
      onClick: async (state, fnClose) => {

        var network = new Network();
        const result = await network.get(`/api/workorders/sync/${name}/${this.store.entity!.Id}`);
        if (result)
          successToast(`Synkroniserte arbeidsordre med ${name}.`);
        fnClose();
      }
    },
    { text: "Avbryt", onClick: (state, fnClose) => fnClose() }
    ];
    dialog.title = "Send til " + name;
    dialog.closable = true;
    dialog.content = (state) => <p>{`Er du sikker at du vil sende denne arbeidsordren til ${name}?`}</p>;
    RootStore.UIStore.addDialog(dialog);

  }

  changeCustomer = () => {

    const dialog = new Dialog<{ customerId?: number }>()
    dialog.buttons = [{
      text: "OK",
      onClick: (state, fnClose) => {
        if (state.customerId) {
          this.store.changeCustomer(state.customerId);
          fnClose();
        }
      },
      isEnabled: (state) => isNumber(state.customerId)
    },
    { text: "Avbryt", onClick: (state, fnClose) => fnClose() }
    ];
    dialog.title = "Endre kunde";
    dialog.closable = true;
    dialog.content = (state) => (
      <div className="dialog-change-customer-content">
        <p>Å flytte en arbeidsordre til en ny kunde vil fjerne eventuelle tilknyttede kontakter og tilbud.</p>
        <h4>Kunde:</h4>
        <div>
          <CustomerSelector
            setValue={cu => state.customerId = cu}
            value={state.customerId || undefined} />
        </div>
      </div>)

    RootStore.UIStore.addDialog(dialog);
  }

  changeCustomerIpoa = async (customerId: number) => {
    await this.store.changeCustomer(customerId);
    this.props.history.push("/");
  }

  delete = (model: WorkOrderDetailsModel) => {

    const { history } = this.props;

    const dialog = new Dialog<{}>()
    dialog.buttons = [{
      text: "OK",
      onClick: async (state, fnClose) => {
        const success = await this.store.deleteWorkOrder();
        if (success == true) {
          fnClose();
          history.push("/workorders");
        }
      }
    },
    { text: "Avbryt", onClick: (state, fnClose) => fnClose() }
    ];
    dialog.title = "Slette arbeidsordre";
    dialog.closable = true;
    dialog.content = (state) => <p>{`Er du sikker at du vil slette arbeidsordren ${toSerialNumber(EntityType.WorkOrder, model.Id)} fra ${model.CustomerName}?`}</p>;
    RootStore.UIStore.addDialog(dialog);
  }

  rushRequest = (model: WorkOrderDetailsModel) => {

    const startDate = max([stringToDate(model.Created), new Date()])!;

    const dialog = new Dialog<any>();
    dialog.title = "Hast";
    dialog.closable = true;
    dialog.content = <RushRequestDialog notBeforeDate={startDate} workOrderId={model.Id} />
    dialog.buttons = [];

    RootStore.UIStore.addDialog(dialog);
  }

  extraWork = () => {
    const dialog = new Dialog<any>();
    dialog.title = "Ekstraarbeid";
    dialog.closable = true;
    dialog.content = <ExtraWorkDialog workOrderId={this.store.entity?.Id!} />
    dialog.buttons = [];
    RootStore.UIStore.addDialog(dialog);
  }

  associatedWithOrder = () => {

    const dialog = new Dialog<any>();
    dialog.title = "Koble arbeidsordre med bestilling"
    dialog.content = <AssociateWorkOrderWithOrderDialog workOrderId={this.store.entity!.Id} />
    dialog.closable = true;
    dialog.buttons = [];
    Dialog.Show(dialog);
  }

  componentWillUnmount() {
    this.store.
      release();
  }

  transportAction = (action: TransportRequestType) => {
    const { history } = this.props;
    history.push(`/transport/create/?workOrderId=${this.store.entity!.Id}&type=${action}`);
  }

  getOpenTabId = (model: WorkOrderDetailsModel) => {

    if ((this.props.location.state as any)?.tab === "transport")
      return 6;

    if (this.selectedUnit != null) {
      var selectedUnit = model.Units.find(u => u.Id === this.selectedUnit);
      if (selectedUnit?.Direction == Direction.Incoming)
        return 0;
      else
        return 1;
    }

    return model.ComputedState >= 300 ? 1 : 0;
  }

  registerTransportOrder() {

    var dialog = new Dialog<any>();
    dialog.title = "Registrer transportbestilling";
    dialog.closable = true;
    dialog.content = <TransportOrderDialog workOrderId={this.store.entity?.Id} preselectedDate={this.store.entity?.DeliveryDate} />
    dialog.buttons = [];
    Dialog.Show(dialog);
  }

  createComplaint() {
    var dialog = new Dialog<any>();
    dialog.title = "Registrer reklamasjon";
    dialog.closable = true;
    dialog.content = <ComplaintDialog workOrderId={this.store.entity?.Id!} customerId={this.store.entity?.CustomerId!} />
    dialog.buttons = [];
    Dialog.Show(dialog);
  }

  sendFerdigmelding() {
    var dialog = Dialog.OkCancelDialog(
      "Sende ferdigmelding",
      "Er du sikker på at du vil sende en ferdigmelding selv om arbeidsordren ikke er ferdigstilt?",
      "Send ferdigmelding",
      async (closeFn) => {
        const network = new Network();
        await network.getSimple(`/api/workorders/sendWorkOrderDoneNotification/${this.store.entity?.Id}`);
        closeFn();
      }
    )
    Dialog.Show(dialog);
  }

  async downloadFølgeseddel() {
    await downloadFile(`/api/workorders/${this.store.entity?.Id}/docs/følgeseddel`, '');
  }

  async printLabels(units: number[]) {
    const network = new Network();
    const url = "/api/units/print?" + units.map(u => "unitIds=" + u + "&").join("");
    await network.getSimple(url);
    infoToast("Merklapper er sendt til skriver");
  }

  render() {
    const model = this.store.entity;
    if (!model)
      return null;

    const state = model.ComputedState;
    const { isCustomer, isAdministrator, isEmployeeAdministrator } = RootStore.UserStore;

    const id = toSerialNumber(EntityType.WorkOrder, model.Id);
    const rushState = model.HasPendingRushRequest ? "Forespurt" : model.IsRush ? "Ja" : "Nei";
    const isPaint = model.PaintType == PaintType.Powder || model.PaintType == PaintType.Wet;

    const externalStatus = model.AdditionalData?.Industrilakk?.CurrentState ?? model.AdditionalData?.Borgeskogen?.CurrentState;
    const externalAfterWeight = model.AdditionalData?.Borgeskogen?.AfterWeight;
    const externalDoneDate = model.AdditionalData?.Industrilakk?.EstimatedDone ?? model.AdditionalData?.Borgeskogen?.EstimatedDone;

    const externalDoneDateWarning = externalDoneDate != null && stringToDate(externalDoneDate).getTime() > stringToDate(model.DeliveryDate).getTime();

    let clipboardText = `${id}\r\n${model.CustomerName}`;
    if (model.AdditionalData?.Borgeskogen?.ExternalId != null) {
      clipboardText += `\r\nBorgeskogen: ${model.AdditionalData?.Borgeskogen?.ExternalId}`;
    }

    const incomingUnits = model.Units.filter(u => u.Direction === Direction.Incoming);
    const outgoingUnits = model.Units.filter(u => u.Direction === Direction.Outgoing);
    const sizeIn = incomingUnits.map(u => volumForUnit(u)).reduce(add, 0);
    const sizeOut = outgoingUnits.map(u => volumForUnit(u)).reduce(add, 0);

    const hasExtraWork = model.ExtraWork != null && model.ExtraWork.length > 0;

    const shouldHaveTransport = isPaint || model.Aggregate?.ControlCount?.Sandblast! > 0 || model.RequiresDelivery;

    const isDone = state == WorkOrderState.Delivered || state == WorkOrderState.Ready;

    let visibleDoneDate = getDeliveryDate(isCustomer, model.DeliveryDate, model.IsDeliveryDateConfirmed, model.EstimatedDelivery); //maxDate(model.DeliveryDate, model.EstimatedDelivery);
    if (externalDoneDateWarning && isCustomer && externalDoneDate)
      visibleDoneDate = externalDoneDate;

    const isIpoaSpiral = (model.CustomerId == 12 || model.CustomerId == 381) && model.IsInvoiced == false;

    return (<>
      <DetailsPanel cssClass={`panel details-container ${!isCustomer && model.IsRush ? "wo-details-panel-rush" : ""}`}>
        <DetailsBlock title="Informasjon">
          <DetailValueField title="Ordrenummer"><Link to={`/workorders/${model.Id}/edit`}>{id}</Link><CopyToClipboard text={clipboardText} /></DetailValueField>
          <DetailValueField title="Kunde" wrap={true}><Link to={`/customers/${model.CustomerId}`}>{model.CustomerName}</Link></DetailValueField>
          {model.OrderId && <DetailValueField title="Bestilling"><Link to={`/orders/${model.OrderId}`}>{toSerialNumber(EntityType.Order, model.OrderId)}</Link></DetailValueField>}
          <DetailValueField title="Kontakter"><ul>
            {model.Contacts && model.Contacts.map((c, i) =>
              <li key={i}>
                {c.UserId == null && <span title={c.Phone + " " + c.Email}>{c.Name}</span>}
                {c.UserId != null && <Link to={`/users/${c.UserId}`}><span title={c.Phone + " " + c.Email}>{c.Name}</span></Link>}
              </li>)}</ul>
          </DetailValueField>
          <DetailValueField title="Mottatt"><DateTime date={model.Created} /></DetailValueField>
          <DetailValueField title={`${model.IsDeliveryDateConfirmed ? 'Avtalt' : 'Antatt'} leveransedato`}>
            <DateTime date={visibleDoneDate} className={classNames({ 'wo-d-confirmed': model.IsDeliveryDateConfirmed })} />
          </DetailValueField>
          {!isCustomer && externalDoneDate && <DetailValueField title="Ekstern ferdigdato" ><DateTime date={externalDoneDate} className={classNames({ "external-to-late": externalDoneDateWarning })} /></DetailValueField>}
          {!isCustomer && externalStatus && <DetailValueField title="Ekstern status">{externalStatus}</DetailValueField>}
          <DetailValueField title="Informasjon" wrap long>{model.Information}</DetailValueField>
          <DetailValueField title="Beskrivelse" wrap long>{model.GodsDescription}</DetailValueField>
          <DetailValueField title="Haster">{rushState}</DetailValueField>
          <DetailValueField title="Transport">
            {!model.RequiresDelivery && <span>Kunde henter</span>}
            {model.RequiresDelivery && model.IsTransportOrdered && <span>Transport er bestillt</span>}
            {model.RequiresDelivery && !model.IsTransportOrdered && <span><b>Transport må bestilles</b></span>}
          </DetailValueField>
        </DetailsBlock>
        <DetailsBlock title="Mottakskontroll">
          <DetailValueField title="Mangler oppheng"><Indicator value={model.Units.some(u => u.MissingAttachmentHoles)} /></DetailValueField>
          <DetailValueField title="Mangler drenering"><Indicator value={model.Units.some(u => u.MissingDrainageHoles)} /></DetailValueField>
          <DetailValueField title="Har syrelomme"><Indicator value={model.Units.some(u => u.AcidPocket)} /></DetailValueField>
          <DetailValueField title="Dobbeldupp"><Indicator value={model.Units.some(u => u.DoubleDip)} /></DetailValueField>
          <DetailValueField title="Fjerne maling"><Indicator value={model.Units.some(u => u.RemovePaint)} /></DetailValueField>
          <DetailValueField title="Sinkavbrenning"><Indicator value={model.Units.some(u => u.ContainsZink)} /></DetailValueField>
          <DetailValueField title="Feil konstruksjon"><Indicator value={model.Units.some(u => u.ConstructionError)} /></DetailValueField>
          <DetailValueField title="Rehabilitering"><Indicator value={model.Units.some(u => u.Refurbish)} /></DetailValueField>
          <DetailValueField title="Er skadet"><Indicator value={model.Units.some(u => u.Damaged)} /></DetailValueField>
          <DetailValueField title="Sandblåses"><Indicator value={model.Units.some(u => u.Sandblast)} /></DetailValueField>
          <DetailValueField title="Annet"><Indicator value={model.Units.some(u => u.Other)} /></DetailValueField>
        </DetailsBlock>
        <DetailsBlock title="Behandling">
          <DetailValueField title="Korrosjonsklasse">{corrosivityFromEnum(model.Corrosivity)} {model.Corrosivity == CorrosivityCategory.C3 ? "" : <AlertIcon className="icon" />}</DetailValueField>
          <DetailValueField title="Lakkering">{paintTypeFromEnum(model.PaintType)}</DetailValueField>
          {showRalAndGloss(model.PaintType) && <DetailValueField title="RAL">{model.RalCode}</DetailValueField>}
          {showRalAndGloss(model.PaintType) && model.Gloss != null && model.Gloss >= 0 && <DetailValueField title="Glans">{model.Gloss}</DetailValueField>}
          <DetailValueField title="Innendørs"><Indicator value={model.IsStoreInside} /></DetailValueField>
          <DetailValueField title="Samsvarserklæring"><Indicator value={model.IsCertificate} /></DetailValueField>
          <DetailValueField title="Måleprotokoll"><Indicator value={model.IsMeasurements} /></DetailValueField>
          {model.DisableNotifications && <DetailValueField title="Varsler">Deaktivert</DetailValueField>}
          <DetailValueField title="Kundespesifik" wrap long>{model.PublicNote}</DetailValueField>
          {!isCustomer && <DetailValueField title="Interninfo" wrap long>{model.PrivateNote}</DetailValueField>}
        </DetailsBlock>
        <DetailsBlock title="Fakturakontroll">
          <DetailValueField title="Kundereferanse" wrap={true}>{model.InvoiceText}</DetailValueField>
          {model.DoNotInvoice && <DetailValueField title="Fakturert">Skal ikke faktureres</DetailValueField>}
          {!model.DoNotInvoice && <DetailValueField title="Fakturert">{model.IsInvoiced ? "Ja" : "Nei"}</DetailValueField>}
          <DetailValueField right title="Antall kolli">{`${model.Aggregate?.IncomingUnitCount}/${model.Aggregate?.OutgoingUnitCount}`}</DetailValueField>
          <DetailValueField right title="Svart vekt"><NumberWithPostfix post="kg" number={model.Aggregate?.IncomingWeight} /></DetailValueField>
          <DetailValueField right title="Blank vekt"><NumberWithPostfix post="kg" number={model.Aggregate?.OutgoingWeight} /></DetailValueField>
          {!isCustomer && externalAfterWeight && <DetailValueField right title="Ekstern vekt"><NumberWithPostfix post="kg" number={externalAfterWeight} /></DetailValueField>}
          <DetailValueField right title="Størrelse"><NumberWithPostfix post="m³" number={sizeIn} /> / <NumberWithPostfix post="m³" number={sizeOut} /></DetailValueField>
          {model.OfferId != null && model.OfferId > 0 && <DetailValueField title="Tilbud" wrap><Link to={`/offers/${model.OfferId}/edit`}>{model.OfferName}</Link></DetailValueField>}

          {!hasExtraWork && <DetailValueField right title="Ekstraarbeid"><NumberWithPostfix post="timer" number={0} /></DetailValueField>}
          {hasExtraWork && <DetailValueField right title="Ekstraarbeid" long >{showExtraWork(model.ExtraWork)}</DetailValueField>}
          {model.ActiveComplaintId && <DetailValueField title="Reklamasjon"><Link to={`/complaints/${model.ActiveComplaintId}`}>{toSerialNumber(EntityType.Complaint, model.ActiveComplaintId)}</Link></DetailValueField>}
          <DetailValueField right title="Antall paller">{model.Units.filter(u => u.MissingPallet).length}</DetailValueField>
          {!isCustomer && <DetailValueField title="Betingelser" wrap long>{model.InvoiceTerms}</DetailValueField>}
        </DetailsBlock>
        <DetailsAction>
          {model.Units.length > 0 && !isCustomer && <Button text={model.Aggregate?.State?.Label || ""} options={this.getChangeStateOptions(model)} className={`state-bg-color-${state}`}></Button>}
          {isCustomer && <Button text={model.Aggregate?.State?.Label || ""} className={`state-bg-color-${state}`} />}
          <Button text="Rediger" onClick={() => this.editWorkOrder()} icon={EditIcon}></Button>
          {!isCustomer && <Button text="Bytt kunde" onClick={() => this.changeCustomer()} icon={MoveIcon}></Button>}
          {isAdministrator && <Button text="Slett" onClick={() => this.delete(model)} icon={DeleteIcon}></Button>}
          {!isCustomer && <Button text="DuoZink" onClick={() => this.syncWithFlyt("Borgeskogen")} icon={ExportIcon}></Button>}
          {!isCustomer && isPaint && <Button text="Ind.lakkering" onClick={() => this.syncWithFlyt("Industrilakk")} icon={ExportIcon}></Button>}
          {!isDone && <Button text="Hastforespørsel" onClick={() => this.rushRequest(model)} icon={RushIcon} disabled={model.HasPendingRushRequest || model.IsRush}></Button>}
          {!isCustomer && <Button text="Ekstraarbeid" onClick={() => this.extraWork()} icon={EmployeeIcon}></Button>}
          {!isCustomer && model.OrderId == null && <Button text="Koble bestilling" onClick={() => this.associatedWithOrder()} icon={OrderIcon} />}
          {isDone && <Button text="Reklamasjon" onClick={() => this.createComplaint()} icon={ComplaintIcon} />}
          {!isCustomer && <Button text="Ferdigmelding" onClick={() => this.sendFerdigmelding()} icon={<FontAwesomeIcon icon={faPaperPlane} className="icon" />} disabled={model.IsDoneNotificationSent || isDone} />}
          {isCustomer && isIpoaSpiral && <Button text={`Flytt til ${model.CustomerId === 12 ? "Spiraltrapp" : "Ipoa"}`} onClick={() => this.changeCustomerIpoa(model.CustomerId === 12 ? 381 : 12)} icon={MoveIcon}></Button>}
          {model.AdditionalData?.Borgeskogen?.DotEntityId != null && !isCustomer && <Button text="Følgeseddel" onClick={() => this.downloadFølgeseddel()} icon={PdfIcon} />}
        </DetailsAction>
      </DetailsPanel>
      <ListNavigator model={model} />
      <TabPanel initialSelectedTab={this.getOpenTabId(model)}>
        <Tab title="Mottatte kolli" count={model.Aggregate?.IncomingUnitCount}>
          {!isCustomer && <div className="flex">
            <Link to={`/workorders/${model.Id}/units/create?direction=incoming`} className="button"><UnitIcon className="icon" /><span>Opprett mottatt kolli</span></Link>&nbsp;
            {(model.Aggregate?.IncomingUnitCount ?? 0) > 0 && <Button text="Skriv ut merkelapper" onClick={() => this.printLabels(model.Units.filter(u => u.Direction == Direction.Incoming).map(u => u.Id))} icon={<FontAwesomeIcon icon={faPrint} />}></Button>}
          </div>}
          <UnitList units={model.Units} store={this.store} direction={Direction.Incoming} selectedId={this.selectedUnit} />
          {!isCustomer && incomingUnits.length > 0 && <Link to={`/workorders/${model.Id}/units/create?direction=incoming`} className="button"><UnitIcon className="icon" /><span>Opprett mottatt kolli</span></Link>}
        </Tab>
        <Tab title="Produserte kolli" count={model.Aggregate?.OutgoingUnitCount}>
          {!isCustomer && <div className="flex">
            <Link to={`/workorders/${model.Id}/units/create?direction=outgoing`} className="button"><UnitIcon className="icon" /><span>Opprett utgående kolli</span></Link>&nbsp;
            {(model.Aggregate?.OutgoingUnitCount ?? 0) > 0 && <Button text="Skriv ut merkelapper" onClick={() => this.printLabels(model.Units.filter(u => u.Direction == Direction.Outgoing).map(u => u.Id))} icon={<FontAwesomeIcon icon={faPrint} />}></Button>}
          </div>}
          <UnitList units={model.Units} store={this.store} direction={Direction.Outgoing} selectedId={this.selectedUnit} />
          {!isCustomer && outgoingUnits.length > 0 && <Link to={`/workorders/${model.Id}/units/create?direction=outgoing`} className="button"><UnitIcon className="icon" /><span>Opprett utgående kolli</span></Link>}
        </Tab>
        {!isCustomer && <Tab title="Meldinger" count={model.MessageCount}>
          <MessageList workOrderId={model.Id} />
        </Tab>
        }
        <Tab title="Dokumenter" count={model.Documents!.length}>
          <DocumentList files={model.Documents!} />
        </Tab>
        {!isCustomer && <Tab title="Notisblokk" count={model.ChatCount}>
          <ChatPageList type="WorkOrderEntity" id={model.Id} />
        </Tab>}
        {!isCustomer && <Tab title="Historikk">
          <Audit type="Logi.Proflyt.Data.Entities.WorkOrderEntity" id={model.Id} isWorkorderRelated={true} />
        </Tab>}
        {!isCustomer && (model.IsInvoiced || !model.DoNotInvoice) && <Tab title="Fakturagrunnlag">
          <Invoice workOrderId={model.Id} />
        </Tab>}
        {shouldHaveTransport && <Tab title="Transport">

          <div className="workorder-transport-container">
            <div className="workorder-transport-details">
              <div className="details-panel-item">
                <AddressView address={model.DeliveryAddress} title="Leveringsadresse" />
                <UnitDescription units={model.Units} />
              </div>
              {!isCustomer && <div className="details-panel-item details-action-list details-right">
                <Button text="Registrer transport" onClick={() => this.registerTransportOrder()} icon={TransportIcon}></Button>
              </div>}
            </div>
            <div className="workorder-transport-list">
              <TransportOrderList workOrderId={model.Id} isCustomer={isCustomer} />
            </div>
          </div>

        </Tab>}
        {model.AdditionalData?.Borgeskogen != null && !isCustomer && <Tab title="DOT DuoZink"><SyncronizationTabContent key="duo" id={model.Id} model={model.AdditionalData?.Borgeskogen} name="Borgeskogen" /></Tab>}
        {model.AdditionalData?.Industrilakk != null && !isCustomer && <Tab title="Industrilakkering"><SyncronizationTabContent key="industri" id={model.Id} model={model.AdditionalData?.Industrilakk} name="Industrilakk" /></Tab>}
        {!isCustomer && model.AdditionalData?.LastSeen != null && <Tab title="Sett av">
          <ul className="last-list-by-list">
            {model.AdditionalData?.LastSeen.map(l => <li key={l.UserId}><Link to={`/users/${l.UserId}`}>{l.Name}</Link><span title={since(l.LastSeen)}>{toDateWithHMShort(l.LastSeen)}</span></li>)}
          </ul>
        </Tab>}
        {!isCustomer && <Tab title="Statusendringer">
          <StateChangesTab workOrderId={model.Id} />
        </Tab>
        }
      </TabPanel>
    </>
    );
  }
}

const a = withRouter(WorkOrderDetailsPage);
export {
  a as WorkOrderDetailsPage
}

const showExtraWork = (work?: Array<ExtraWorkListModel>) => {
  if (work == null)
    return null;

  return <table className="full-width">
    <tbody>
      {work.map((w, i) => <tr key={i}><td>{w.Description}</td><td><NumberWithPostfix post="t" number={w.Amount} /></td></tr>)}
    </tbody>
    <tfoot>
      <tr><td>Totalt</td><td><NumberWithPostfix number={sum(work.map(w => w.Amount))} post="t" /></td></tr>
    </tfoot>
  </table>

}

const UnitDescription: React.FC<{ units: Array<WorkOrderDetailsUnitModel> }> = ({ units }) => {

  if (units == null || units.length == 0)
    return null;

  const hasProductionUnits = units.some(u => u.Direction == Direction.Outgoing);
  const title = hasProductionUnits ? "Kolli-informasjon" : "Kolli-informasjon (mottatt)";

  const hasSize = (unit: WorkOrderDetailsUnitModel) => unit.Length! > 0 && unit.Height! > 0 && unit.Width! > 0;

  const rows = units
    .filter(u => u.Direction == (hasProductionUnits ? Direction.Outgoing : Direction.Incoming))
    .map(u => <tr key={u.Id}>
      <td>
        <NumberWithPostfix number={hasProductionUnits ? sum(u.ProductionWeights?.map(m => m.Weight)) : u.IncomingWeight} post="kg" />
      </td>
      <td>
        {hasSize(u) ? `${u.Length} cm x ${u.Width} cm x ${u.Height} cm` : "Ikke oppgitt"}
      </td>
    </tr>);

  return <div>
    <h3>{title}</h3>
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Vekt</th>
            <th>Størrelse</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  </div>

}