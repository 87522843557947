import * as React from "react";
import MarkIcon from "svg/exclamation.svg";
import CheckIcon from "svg/check.svg";
import { since, toDateWithHM, toDate } from "../../core/dateHelpers";
import { UnitState } from "../../models/shared/UnitState";
import { getStates, formatPhoneNr } from "../../core/stringHelpers";
import { Enums } from "../../stores/lookup-store";
import { observable, IObservableArray } from "mobx";
import RootStore from "ts/stores/root-store";
import { BaseLookupModel } from "../../models/shared/BaseLookupModel";
import { observer } from "mobx-react";

export const DateTime: React.FC<{ date?: string, className?: string }> = ({ date, className }) => {
  if (date == null)
    return null;
  return <span title={since(date)} className={className}>{toDateWithHM(date, true)}</span>
}

export const Date: React.FC<{ date?: string }> = ({ date }) => {
  return date == null ? null : <span title={since(date)}>{toDate(date)}</span>;
}

export const Indicator: React.FC<{ value?: boolean; type?: "Mark" | "Check" }> = ({ value, type }) => {
  if (value == null || value == false)
    return type == "Check" ? <CheckIcon className="icon indicator inactive" /> : <MarkIcon className="icon indicator inactive" />;
  else
    return type == "Check" ? <CheckIcon className="icon indicator" /> : <MarkIcon className="icon indicator" />;
}

export const NumberWithPostfix: React.FC<{ number?: number | null, post: string | React.ReactElement, className?: string, decimals?: number; empty?: string }> = ({ number, post, className, decimals, empty }) => {
  if (number == null)
    return null;

  if (number === 0 && empty)
    return <span>{empty}</span>;

  return <span className={`right ${className || ""}`}>{number.toLocaleString("nb-NO", { minimumFractionDigits: decimals, maximumFractionDigits: decimals })} {post}</span>;
}

export const stateToName = (state: UnitState): string => {
  const s = getStates().find(s => s.id == state);
  return s == null ? "Ukjent" : s.title;
}

export const StateName: React.FC<{ state: UnitState }> = ({ state }) => {
  return <span>{stateToName(state)}</span>;
}

export const PhoneLink: React.FC<{ number?: string }> = ({ number }) => number == null ? null : <a href={`tel:${number}`}>{formatPhoneNr(number)}</a>;

@observer
export class LookupValue extends React.Component<{ enum: Enums, value: number }> {

  private list: IObservableArray<BaseLookupModel> = observable([]);

  async componentDidMount() {
    const result = await RootStore.LookupStore.getEnums(this.props.enum, false);
    if (result != null)
      this.list.replace(result);
  }

  render() {
    const element = this.list.find(e => e.Id == this.props.value);
    if (element == null)
      return null;
    return <span>{element.Name}</span>
  }
}