import * as React from "react";
import { BreadCrumb } from "../../../components/shared/BreadCrumb";
import { Button } from "../../../components/shared/Button";
import OpenBoxIcon from "svg/open-box.svg";
import ClosedBoxIcon from "svg/closed-box.svg";
import { UnitState } from "../../../models/shared/UnitState";
import { default as RootStore } from "ts/stores/root-store";
import "./removal.tsx.scss";
import { ArticleLookupModel } from "../../../models/shared/ArticleLookupModel";
import { Lookup } from "../../../stores/lookup-store";
import { ArticleType } from "../../../models/shared/ArticleType";
import { observer } from "mobx-react";
import { DataStore2 } from "../../../stores/data-store";
import { BoomRemovalModel } from "../../../models/shared/BoomRemovalModel";
import { RouteComponentProps } from "react-router";
import { Network } from "../../../api/network";
import { ChangeType } from "../../../core/signalR";
import { UnitBoomModel } from "../../../models/shared/UnitBoomModel";
import { values, sum, identity } from "lodash-es";
import { AddUnitWeightModel } from "../../../models/shared/AddUnitWeightModel";
import { Link } from "react-router-dom";
import { NumberWithPostfix } from "../../../components/shared/display";
import { Pending } from "../../../components/shared/Pending";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { onEnter, getImageUrl } from "../../../core/util";
import { observable } from "mobx";
import { ImageSize } from "../../../models/shared/ImageSize";

interface P {
  route: RouteComponentProps<{ id: string }>;
}


@observer
export class BoomRemovalComponent extends React.Component<P> {

  placementId: string;
  model: DataStore2<BoomRemovalModel>;
  network: Network;

  constructor(props: P) {
    super(props);
    this.network = new Network();
    this.placementId = props.route.match.params.id;
    this.model = new DataStore2<BoomRemovalModel>(`/api/booms/package/${this.placementId}`, ch => ch.entityName == "BoomEntity" && ch.changeType != ChangeType.Deleted)
  }

  associateUnit = async (unitId: number) => {
    await this.network.get(`/api/booms/associateUnitWithPlacement/${unitId}/${this.placementId}`);
  }

  createNewUnit = async () => {
    await this.network.get(`/api/booms/addPlacementToNewUnit/${this.placementId}`);
  }

  completePlacement = async () => {
    const success = await this.network.get<boolean>(`/api/booms/completePlacement/${this.placementId}`)
    if (success)
      this.props.route.history.push("/booms");
  }

  componentWillUnmount() {
    this.model.dispose();
  }

  componentWillReceiveProps(nextProp: P) {
    if (nextProp.route.match.params.id !== this.props.route.match.params.id) {
      this.placementId = nextProp.route.match.params.id;
      this.model.dispose();
      this.model = new DataStore2<BoomRemovalModel>(`/api/booms/package/${this.placementId}`, ch => ch.entityName == "BoomEntity" && ch.changeType != ChangeType.Deleted)
    }
  }

  render() {

    const data = this.model?.data;
    if (data == null)
      return <Pending />;

    return <div className="boom-removal-page">
      <h1>Nedplukk</h1>
      <BreadCrumb
        workSpaceType="list"
        singular="Bom"
        plural="Bommer"
        routeBasePath="booms"
        additionalLinks={[{ title: "Nedplukk", path: "/booms/removal" }]} />
      <div className="panel panel-padding">
        <h3>
          <span>Bom: {data.BoomId}</span>
          <span>Kunde: {data.CustomerName}</span>
          <span>Vinge: {data.Placement?.FromWing}-{data.Placement?.ToWing}</span>
          <span>Arbeidsordre: <Link to={`/workorders/${data.WorkOrderId}`}>{toSerialNumber(EntityType.WorkOrder, data.WorkOrderId)}</Link></span>
          <span>Inkommende kolli: {toSerialNumber(EntityType.Unit, data.Placement!.IncomingUnitId)}</span>
          <span>Utveid vekt: {data.TotalPlacedWeight} kg av {data.IncomingWeight} kg </span>
        </h3>
        <h4>Plasseres på kolli</h4>
        <div className="buttons">
          <Button text="Nytt kolli" onClick={_ => this.createNewUnit()} />
          <Button text="Ferdig plukket ned" onClick={_ => this.completePlacement()} disabled={data.AssociatedUnits?.length == 0} />
        </div>
        <div>
          {data.AssociatedUnits!.map(u =>
            <PlacementUnitItem key={u.UnitId} unit={u} placementId={this.placementId} availableArticleIds={data.AvailableArticleIds} />
          )}
        </div>
        <div className="image-list">
          <ul>{data.Images?.map(i => <li><div
            role="img"
            style={{ backgroundImage: `url("${getImageUrl(i, ImageSize.Preview)}")` }} /></li>)}
          </ul>
        </div>
        {data.UnitOtherPlacements!.length > 0 && <div className="other-list">
          <h4>Gods fra kolli {toSerialNumber(EntityType.Unit, data.Placement!.IncomingUnitId)} finnes også på:</h4>
          <ul>{data.UnitOtherPlacements?.map((p, i) =>
            <li key={i}>
              <span>Bom {p.BoomId} vinge {p.FromWing}-{p.ToWing}</span>
              <Link to={`/booms/package/${p.PlacementId}`}>Plukk ned</Link>
            </li>
          )}
          </ul>
        </div>}
        {data.AvailableUnits!.length > 0 && <>
          <hr />
          <h4>Eksisterende tilgjenglige kolli for samme kunde:</h4>
          <div>
            {data.AvailableUnits!.map(u =>
              <div key={u.UnitId}>
                <div className="box">
                  <OpenBoxIcon />
                  <span>Id: {toSerialNumber(EntityType.Unit, u.UnitId)}</span>
                  <span>Fra kolli: {u.AssociatedIncomingUnits.map(u => toSerialNumber(EntityType.Unit, u)).join(", ")}</span>
                  <span>Status: Pakkes</span>
                </div>
                <Button text="Plasser på denne" onClick={e => this.associateUnit(u.UnitId)} />
              </div>
            )}
          </div>
        </>}
      </div>
    </div>
  }
}

interface PP {
  unit: UnitBoomModel
  placementId: string;
  availableArticleIds: Array<number>
}

interface SS {
  weight: string;
  currentArticle: string;
}

@observer
class PlacementUnitItem extends React.Component<PP, SS> {

  network: Network;
  inputRef: React.RefObject<HTMLInputElement>;

  @observable articles: Array<ArticleLookupModel>;

  state: SS = {
    weight: "",
    currentArticle: ""
  }

  constructor(props: PP) {
    super(props);
    this.network = new Network();
    this.inputRef = React.createRef();
    this.articles = RootStore
      .LookupStore
      .getList<ArticleLookupModel>(Lookup.Articles)
  }

  completeUnit = async (unitId: number) => {
    await this.network.get(`/api/booms/completeUnit/${unitId}`);
  }

  removeUnit = async (unitId: number) => {
    await this.network.get(`/api/booms/removeUnitFromPlacement/${unitId}/${this.props.placementId}`);
  }

  addWeight = async () => {
    await this.network.post<AddUnitWeightModel, number>(`/api/units/addWeight`, {
      UnitId: this.props.unit.UnitId,
      ArticleId: parseInt(this.state.currentArticle),
      Weight: parseInt(this.state.weight),
      IsDoubleDip: false
    }, false);

    this.setState({ weight: "" });
  }

  removeWeight = async (id: number) => {
    await this.network.get(`/api/units/removeWeight/${id}`);
  }

  onArticleChange: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined = e => {
    this.setState({ currentArticle: e.currentTarget.value });
    setTimeout(() => this.inputRef.current?.focus(), 0);
  }

  render() {

    const { unit, availableArticleIds } = this.props;
    const { weight, currentArticle } = this.state;

    if (this.articles == null)
      return null;

    const articles = this.articles
      .filter(article => article.ArticleType == ArticleType.ZinkArticle)
      .sort((a, b) => a.Id - b.Id);
    
    return (
      <div className="boom-unit">
        <div className="box unit-description">
          {unit.CurrentState == UnitState.Packaging ? <OpenBoxIcon /> : <ClosedBoxIcon />}
          <span>Id: {toSerialNumber(EntityType.Unit, unit.UnitId)}</span>
          <span>Fra kolli: {unit.AssociatedIncomingUnits.map(u => toSerialNumber(EntityType.Unit, u)).join(", ")}</span>
          <span>Status: {unit.CurrentState == UnitState.Packaged ? "Pakket" : "Pakkes"}</span>
        </div>
        <div className="unit-weight-list">
          <h4>Vekter:</h4>
          <table><tbody>
            {unit.Weights?.map(w => <tr key={w.Id}>
              <td>{articles.find(a => a.Id == w.ArticleId)?.Name}</td>
              <td className="weight"><NumberWithPostfix number={w.Weight} post="kg" /></td>
              <td><span onClick={e => this.removeWeight(w.Id!)}>Slett</span></td>
            </tr>)}
          </tbody>
            <tfoot>
              <tr><td /><td className="weight"><NumberWithPostfix number={sum(unit.Weights?.map(w => w.Weight))} post="kg" /></td><td/></tr>
            </tfoot>
          </table>
        </div>
        <div className="unit-weight-input edit-form">
          <div className="unit-articles">
            <select onChange={this.onArticleChange} value={currentArticle}>
              <option value="">-- Velg artikkel --</option>
              {articles.map(a => <option key={a.Id} value={a.Id} disabled={availableArticleIds.indexOf(a.Id) == -1}>{a.Name}</option>)}
            </select>
          </div>
          <div className="add-weight">
            <input
              type="number"
              value={weight}
              disabled={currentArticle == ""}
              ref={this.inputRef}
              onKeyDown={onEnter(e => this.addWeight())}
              onChange={e => this.setState({ weight: e.target.value })} />
            <Button
              text="Legg til vekt"
              onClick={e => this.addWeight()}
              disabled={weight == "" || currentArticle == ""} />
          </div>
        </div>
        <div className="details-action">
          <Button text="Fullfør kolli" onClick={_ => this.completeUnit(unit.UnitId)} disabled={unit.CurrentState == UnitState.Packaged} />
          <Button text="Legg til bilder" disabled={true} />
        </div>
      </div>
    )
  }
}