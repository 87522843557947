import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import { BaseLookupModel } from "ts/models/shared/BaseLookupModel";
import "./CheckBoxField.tsx.scss";

interface OwnProps {
  options: Array<BaseLookupModel>;
}

type ValueType = Array<number>
type P = PropsFromWrapperToField<ValueType> & OwnProps;

class CheckBoxField extends React.PureComponent<P> {

  toggle = (id: number, checked: boolean) => {
    const { value, setValue, setTouched } = this.props;
    setTouched();

    if (checked)
      setValue([...(value || []), id]);
    else
      setValue(value!.filter(v => v != id));
  }

  render() {
    const { options, value, id } = this.props;
    return (
      <div id={id} className="field-checkbox">
        {options.map(o => <div className="field-checkbox-item" key={o.Id}>
          <input
            type="checkbox"
            id={id + "-" + o.Id.toString()}            
            onChange={e => this.toggle(o.Id, e.target.checked)}
            checked={value != null && value.some(v => v === o.Id)} />
          <label htmlFor={id + "-" + o.Id.toString()}>{o.Name}</label>
        </div>
        )}
      </div>);
  }
}

const wrapped = Wrap<P, ValueType>(CheckBoxField);

export {
  wrapped as CheckBoxField
}