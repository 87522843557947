import { DataStore2 } from "../../../stores/data-store";
import { observer } from "mobx-react";
import * as React from "react";
import { Pending } from "../../../components/shared/Pending";
import { UserListModel } from "../../../models/shared/UserListModel";
import { DateTime, PhoneLink } from "../../../components/shared/display";
import { Link } from "react-router-dom";
import { default as RootStore } from "../../../stores/root-store";
import EditIcon from "svg/edit.svg";
import DeleteIcon from "svg/trash.svg";
import { Dialog } from "../../../core/dialog";
import { Network } from "../../../api/network";

type P = { customerId: number };

@observer
export class CustomerDetailsUserList extends React.Component<P> {
  store: DataStore2<Array<UserListModel>>;

  constructor(props: P) {
    super(props);
    this.store = new DataStore2(`/api/users/ListByCustomer/${props.customerId}`, ch => ch.entityName === "UserEntity");
  }

  componentWillUnmount() {
    this.store.dispose();
  }

  render() {

    const usr = RootStore.UserStore;

    if (this.store.data === undefined)
      return <Pending />

    if (this.store.data == null || this.store.data.length === 0)
      return <p>Ingen brukere knyttet til denne kunden.</p>;

    const list = this.store.data;

    return <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Navn</th>
            <th>E-post</th>
            <th>Telefonnummer</th>
            <th className="right">Sist pålogget</th>
            <th className="right">Opprettet</th>
            <th className="action">Rediger</th>
            {usr.isEmployeeAdministrator && <th className="action">Slett</th>}
          </tr>
        </thead>
        <tbody>
          {list.map(user => <tr key={user.Id}>
            <td><Link to={`/users/${user.Id}`}>{user.Name}</Link></td>
            <td className="no-wrap"><a href={`mailto:${user.Email}`}>{user.Email}</a></td>
            <td className="no-wrap"><PhoneLink number={user.PhoneNumber} /></td>
            <td className="right no-wrap"><DateTime date={user.LastOn} /></td>
            <td className="right no-wrap"><DateTime date={user.Created} /></td>
            <td className="action">{(!usr.isCustomer || usr.userId === user.Id) && <Link to={`/users/${user.Id}/edit`}><EditIcon className="icon" /></Link>}</td>
            {usr.isEmployeeAdministrator && <td className="action"><DeleteIcon onClick={() => this.removeUser(user.Id, user.Name!)} /></td>}
          </tr>)}
        </tbody>
      </table>
    </div>
  }

  async removeUser(id: number, name: string) {

    var dialog = Dialog.OkCancelDialog(
      `Slette ${name}?`,
      `Er du sikker på at du vil slette bruker ${id} med navn ${name}?`,
      "Slett",
      async closeFn => {
        const result = await new Network().get<boolean>(`/api/users/delete/${id}`);
        if (result)
          closeFn();
      });

    Dialog.Show(dialog);
  }
}