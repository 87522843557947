import React from "react"

interface SimpleWrappedFieldProps extends React.PropsWithChildren {
  label: string;
  help?: string;
}

export const SimpleWrappedField: React.FC<SimpleWrappedFieldProps> = ({ children, label, help }) => {
  return <div className="form-field">
    <div className="form-field-descripion">
      <label className="form-field-label">{label}</label>
      {help && <div className="form-field-help-container">
        <span className="form-field-help-text"><span>{help}</span></span>
      </div>}
    </div>
    <div className="form-field-component">
      {children}
    </div>
  </div>
}