import { BaseModel } from "../../models/shared/BaseModel";
import { ListStore } from "../../stores/list-store";
import TrashIcon from "svg/trash.svg";
import RecoverIcon from "svg/recover.svg";
import { default as RootStore } from "../../stores/root-store";
import * as React from "react";
import { Dialog } from "../../core/dialog";
import { successToast } from "../shared/Toasts";

interface P {
  row: BaseModel;
  store: ListStore<BaseModel>;
  singular: string;
  showDelete: (model: BaseModel) => boolean;
}

export class DeleteOrUndeleteCell extends React.Component<P> {

  delete = () => {
    const { store, singular, row } = this.props;

    const dialog = Dialog.OkCancelDialog(
      `Slette ${singular}?`,
      `Er du sikker på at du vil slette ${singular.toLowerCase()} ${row.Id}?`,
      "Ja",
      async (fnClose) => {
        await store.delete(row.Id);
        successToast(`Slettet ${singular.toLowerCase()} med id ${row.Id}.`);
        fnClose();
      });

    RootStore.UIStore.addDialog(dialog);
  }

  undelete = async () => {
    const { store, singular, row } = this.props;

    const dialog = Dialog.OkCancelDialog(
      `Gjenopprette ${singular}?`,
      `Er du sikker på at du vil gjenopprette ${singular.toLowerCase()} ${row.Id}?`,
      "Ja",
      async (fnClose) => {
        await store.undelete(row.Id);
        successToast(`Gjennopprettet ${singular.toLowerCase()} med id ${row.Id}.`);
        fnClose();
      });

    RootStore.UIStore.addDialog(dialog);
  }

  render() {
    const { row, showDelete } = this.props;
    const isDeleted = row.Deleted !== null;

    if (!showDelete(row))
      return <td />;

    return (<td className="center cell-action narrow">
      {!isDeleted && <span title="Slett" onClick={() => this.delete()} role="button" tabIndex={0}><TrashIcon /></span>}
      {isDeleted && <span title="Gjenopprett" onClick={() => this.undelete()} role="button" tabIndex={0}><RecoverIcon /></span>}
    </td>);
  }
}