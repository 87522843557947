import * as React from "react";
import { Button } from "ts/components/shared/Button";
import { InvoiceModel } from "ts/models/shared/InvoiceModel";
import EditIcon from "svg/edit.svg";
import DeleteIcon from "svg/trash.svg";
import AddIcon from "svg/add.svg";
import RecoverIcon from "svg/recover.svg";
import InvoiceIcon from "svg/invoice.svg";
import PriceIcon from "svg/price.svg";
import { ArticleUnit } from "ts/models/shared/ArticleUnit";
import { default as RootStore } from "../../../../stores/root-store";
import { Dialog } from "ts/core/dialog";
import { InvoiceLineEditModel } from "ts/models/shared/InvoiceLineEditModel";
import { EditInvoiceLineItemDialog } from "./EditInvoiceLineItemDialog";
import { Network } from "ts/api/network";
import { InvoiceLineModel } from "../../../../models/shared/InvoiceLineModel";
import { NumberWithPostfix } from "../../../../components/shared/display";
import "./invoicedata.tsx.scss";
import InfoIcon from "svg/exclamation.svg";
import { toDate } from "../../../../core/dateHelpers";
import { DetailValueField } from "../../../../components/details/DetailValueField";
import { observer } from "mobx-react";
import { downloadFile } from "../../../../core/util";
import { type IObservableArray, observable } from "mobx";
import { ArticleListModel } from "../../../../models/shared/ArticleListModel";

interface State {
  isDownloadingInvoice: boolean;
  reason: string;
}

interface P { data: InvoiceModel, workOrderId: number; }

@observer
export class InvoiceData extends React.Component<P, State> {

  state: State = { isDownloadingInvoice: false, reason: "" };

  @observable articles: IObservableArray<ArticleListModel>;

  constructor(props: P) {
    super(props);

    this.articles = observable([]);
  }

  downloadPriceList = async () => {
    this.setState({ isDownloadingInvoice: true });
    await downloadFile("/api/invoices/pricelist", "prisliste.pdf");
    this.setState({ isDownloadingInvoice: false });
  }

  async componentDidMount() {
    const network = new Network();
    const result = await network.get<Array<ArticleListModel>>(`/api/articles/ArticlesForWorkOrder/${this.props.workOrderId}`);
    if (result)
      this.articles.replace(result);
  }

  render() {
    const { data } = this.props;

    let canSendInvoice = data.Sum > 0 && data.Locked == false;
    const canEditInvoice = data.Locked == false;

    const state = !data.Locked ? "Kladd" : (data.RemainingAmount === 0 ? "Betalt" : "Fakturert");

    const hasPendingRequest = RootStore.UIStore.hasActiveNetworkCalls;

    const hasWarnings = data.Errors.length > 0 || data.Discrepancies.length > 0;
    if (this.state.reason.length == 0 && hasWarnings)
      canSendInvoice = false;

    return <>
      {data.Locked && <div className="invoice-state">
        <DetailValueField title="Status">{state}</DetailValueField>
        <DetailValueField title="Fakturadato">{toDate(data.InvoiceDate)}</DetailValueField>
        <DetailValueField title="Betalingsfrist">{toDate(data.DueDate)}</DetailValueField>
        <DetailValueField title="Levert">{data.IsNotDelivered ? "Nei" : "Ja"}</DetailValueField>
        <DetailValueField title="Gjennstående"><NumberWithPostfix post="kr" number={data.RemainingAmount} /></DetailValueField>
        <DetailValueField title="Totalt"><NumberWithPostfix post="kr" number={data.TotalAmount} /></DetailValueField>
        <DetailValueField title="Faktura"><a referrerPolicy="origin" target="_blank" href={`/api/invoices/pdf/${data.Id}`}>{data.TripletexId ? "Visma Tripletex" : "Visma eAccounting"}</a></DetailValueField>
        {data.TripletexId && <DetailValueField title="Tripletex"><a target="_blank" href={`${window.flytSettings.tripletexBaseUrl}/execute/invoiceMenu?invoiceId=${data.TripletexId}&contextId=${window.flytSettings.tripletexContextId}`}>Detaljer</a></DetailValueField>}        
      </div>}
      {!data.Locked && <ul className="invoice-discrepancies">
        {data.Errors.map((d, i) => <li key={i} className="error"><InfoIcon className="icon" /><span>{d}</span></li>)}
        {data.Discrepancies.map((d, i) => <li key={i} className="warning"><InfoIcon className="icon" /><span>{d}</span></li>)}
        {data.Information.map((d, i) => <li key={i} className="information"><InfoIcon className="icon" /><span>{d}</span></li>)}
      </ul>}
      <div className="workorder-invoices">
        <div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Tekst</th>
                  <th className="right">Antall</th>
                  <th className="right">Pris</th>
                  <th className="right">Rabatt</th>
                  <th className="right">Sum</th>
                  <th className="right" colSpan={2}>Veiledende</th>
                  {canEditInvoice && <th className="center">Rediger</th>}
                  {canEditInvoice && <th className="center">Slett</th>}
                </tr>
              </thead>
              <tbody>
                {data.LineItems && data.LineItems.map((l, index) => <tr key={l.Id}>
                  <td>{this.articles.find(a => a.Id === l.ArticleId)?.Title}. {l.Text}</td>
                  <td className="right">{l.Amount}</td>
                  <td className="right no-wrap"><NumberWithPostfix number={l.Price} post={this.getPostFromUnit(l.Unit)} decimals={2} /></td>
                  <td className="right no-wrap"><NumberWithPostfix number={l.Discount} post="%" /></td>
                  <td className="right no-wrap"><NumberWithPostfix number={l.Sum} post="kr" decimals={2} /></td>
                  <td className="right no-wrap subtile"><NumberWithPostfix number={l.RetailPrice} post={this.getPostFromUnit(l.Unit)} decimals={2} /></td>
                  <td className="right no-wrap subtile"><NumberWithPostfix number={l.RetailSum} post="kr" decimals={2} /></td>
                  {canEditInvoice && <td className="center action" onClick={e => this.editItem(l.Id)}><EditIcon /></td>}
                  {canEditInvoice && <td className="center action" onClick={e => this.deleteItem(l, index)}><DeleteIcon /></td>}
                </tr>)}
              </tbody>
              <tfoot>
                <tr>
                  <td>Sum</td>
                  <td colSpan={3} />
                  <td className="right no-wrap"><NumberWithPostfix number={data.Sum} post="kr" decimals={2} /></td>
                  <td />
                  <td className="right no-wrap subtile"><NumberWithPostfix number={data.RetailSum} post="kr" decimals={2} /></td>
                  <td colSpan={2} />
                </tr>
              </tfoot>
            </table>
          </div>
          {state === "Kladd" && <form className="edit-form">
            <div className="form-field">
              <div className="form-field-descripion">
                <label className="form-field-label">Årsak for overstyring av varsler</label>
                <div className="form-field-help-container">
                  <span className="form-field-help-text hidden">
                    <span>Her fyller du inn forklaring på hvorfor ordren faktureres på tross av avviksvarsler</span>
                  </span>
                </div>
              </div>
              <div className="form-field-component">
                <textarea value={this.state.reason} onChange={e => this.setState({ reason: e.target.value })} />
              </div>
            </div>
          </form>}
        </div>
        {!data.Locked && <div className="details-actions">
          <div className="details-action">
            <Button onClick={() => this.editItem()} disabled={!canEditInvoice} text="Legg til rad" icon={AddIcon} />
            <Button onClick={() => this.resetInvoice()} disabled={!canEditInvoice || hasPendingRequest} text="Reset" icon={RecoverIcon} />
            <Button onClick={() => this.downloadPriceList()} disabled={this.state.isDownloadingInvoice} text="Vis prisliste" icon={PriceIcon} />
            <br />
            <Button onClick={() => this.createInvoice()} disabled={!canSendInvoice || hasPendingRequest} text="Send faktura" icon={InvoiceIcon} />
          </div>
        </div>
        }
      </div></>;
  }

  async resetInvoice() {
    const { Id } = this.props.data;
    await new Network().get<number>(`/api/invoices/reset/${Id}`);
  }

  editItem(id?: number) {
    const dialog = new Dialog<InvoiceLineEditModel>()
    dialog.buttons = [{ onClick: (state, close) => close(), text: "Lukk" }];
    dialog.closable = true;
    dialog.content = <EditInvoiceLineItemDialog id={id} invoiceId={this.props.data.Id} workOrderId={this.props.workOrderId} />
    dialog.title = "Rediger fakturalinje"
    RootStore.UIStore.addDialog(dialog);
  }

  deleteItem(item: InvoiceLineModel, index: number) {
    var dialog = Dialog.OkCancelDialog(
      "Slette artikkellinje",
      `Er du sikker på at du vil fjerne rad ${index + 1} med tittel ${item.Text || ""}?`,
      "Slett",
      async (closeFn) => {
        await new Network().get<boolean>(`/api/invoices/deleteItem/${item.Id}`);
        closeFn();
      });

    RootStore.UIStore.addDialog(dialog);
  }

  getPostFromUnit(unit: ArticleUnit): string {
    if (unit == ArticleUnit.PerHour)
      return "kr/t";
    else if (unit == ArticleUnit.PerItem)
      return "kr/stk";
    else if (unit == ArticleUnit.PerKilo)
      return "kr/kg";
    else if (unit == ArticleUnit.PerMeter)
      return "kr/m";
    else
      return "kr";
  }

  async createInvoice() {

    const dialog = Dialog.OkCancelDialog(
      "Fakturere arbeidsordre",
      "Er du sikker på at du vil fakturere denne arbeidsordren? Du vil ikke kunne endre på fakturagrunnlaget i ettertid.",
      "Fakturer",
      async closeFn => {
        closeFn();
        const io = new Network();
        await io.post(`/api/invoices/send/${this.props.data.Id}`, this.state.reason);
      }
    );

    RootStore.UIStore.addDialog(dialog);
  }
}