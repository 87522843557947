import React from "react"
import { NumberWithPostfix } from "../../../../components/shared/display"
import { Pending } from "../../../../components/shared/Pending"
import moment from "moment"
import { useDataStore } from "../../../../core/hooks/useDataStore"
import { VariableCostModel } from "../../../../models/shared/VariableCostModel"

export const Reguleringspriser: React.FC = () => {

  const date = moment(new Date()).format("D. MMMM");

  const { data: priser, isFetched, isError, isSuccess } = useDataStore<VariableCostModel>("/api/offers/calculationValues", undefined, { refetchInterval: 1000 * 60 * 60 });

  return <div className="box w2">
    <h2>Reguleringspriser {date}</h2>
    {!isFetched && !isError && <div className="flex-center"><Pending /></div>}
    {isError && <p className="suggestion-span ">Feil ved henting av priser</p> }
    {isSuccess && priser != null &&
      <table><tbody>
        <tr><td>Energi</td><td><NumberWithPostfix post={<><sup>kr</sup>/<sub>kg</sub></>} decimals={2} number={priser.VariableElectricity} /></td></tr>
        <tr><td>Sink profilstål</td><td><NumberWithPostfix post={<><sup>kr</sup>/<sub>kg</sub></>} decimals={2} number={priser.VariableZink_Profil} /></td></tr>
        <tr><td>Sink hulprofil/multidimensjonal</td><td><NumberWithPostfix post={<><sup>kr</sup>/<sub>kg</sub></>} decimals={2} number={priser.VariableZink_Dim} /></td></tr>
        <tr><td>Sink stykkgods</td><td><NumberWithPostfix post={<><sup>kr</sup>/<sub>kg</sub></>} decimals={2} number={priser.VariableZink_Items} /></td></tr></tbody>
      </table>}
  </div>
}