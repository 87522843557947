import moment from 'moment';
import 'moment-timezone';

moment.locale("nb");

export function formatDate(date: string | null | Date, format: string): string {
  if (date == null || typeof date === "string" && date.length === 0)
    return "";
  const dt = moment(date);
  if (dt.year() == 1)
    return ""

  return dt.tz("Europe/Oslo").format(format);
}

export function toDate(date?: string): string {
  return formatDate(date || null, "DD MMMM YYYY");
}

export function toDateWithHM(date?: string, hideIfMidnight?: boolean): string {
  const str = formatDate(date || null, 'DD MMMM YYYY HH:mm');
  if (hideIfMidnight === true && str.endsWith("00:00"))
    return str.substr(0, str.length - 6);
  else
    return str;
}

export function toDateWithHMShort(date?: string): string {
  if (date == undefined)
    return "";
  return formatDate(date, 'D MMM HH:mm')
} 

export function since(date?: string): string {
  if (date == null || date.length === 0)
    return "";
  const dt = moment(date);
  if (dt.year() == 1)
    return "";

  return dt.fromNow();
}

export function stringToDate(date: string): Date {
  return new Date(date);
}

export function maxDate(date1: string, date2?: string) {
  if (date2 == null || date2.length === 0)
    return date1;

  if (stringToDate(date1) > stringToDate(date2))
    return date1;
  else
    return date2;
}

export function getDeliveryDate(isCustomer: boolean, original: string, confirmed: boolean, calculated?: string) {
  if (calculated == null || calculated.length === 0 || confirmed === true || !isCustomer)
    return original;

  if (stringToDate(original) >= new Date())
    return original;
  else
    return calculated;
}

export function addWorkDays(date: Date, numberOfDays: number) {
  let result = new Date(date);
  let cnt = 0;
  while (cnt < numberOfDays) {
    result.setDate(result.getDate() + 1);
    if (isWorkDate(result))
      cnt++;
  }
  return result;
}

export function isWorkDate(date: Date) {
  if (date.getDay() == 0 || date.getDay() == 6)
    return false;

  // check if date is vacation day
  const isVacationDay = off_days.map(row => row.data.some(data => {
    const d = new Date(data.date);
    return d.getDate() == date.getDate() && d.getMonth() == date.getMonth() && d.getFullYear() == date.getFullYear();
  })).some(r => r == true);

  if (isVacationDay)
    return false;

  return true;
}

/**
 * Returns the date as a string in YYYY-MM-DD according to iso-8601
 */
export function toISO8601Date(date: Date) {
  return `${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)}`;
}

/**
 * Pads the given number with leading zeros
 * @param num The number to pad
 * @param size The number of characters in the number
 */
function pad(num: number, size: number) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

// https://webapi.no/api/v1/holydays/2029
const off_days = [
  { "authenticated": false, "timeTaken": 0, "data": [{ "date": "2018-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2018-03-25T00:00:00", "description": "Palmesøndag" }, { "date": "2018-03-29T00:00:00", "description": "Skjærtorsdag" }, { "date": "2018-03-30T00:00:00", "description": "Langfredag" }, { "date": "2018-04-01T00:00:00", "description": "Første påskedag" }, { "date": "2018-04-02T00:00:00", "description": "Andre påskedag" }, { "date": "2018-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2018-05-10T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2018-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2018-05-20T00:00:00", "description": "Første pinsedag" }, { "date": "2018-05-21T00:00:00", "description": "Andre pinsedag" }, { "date": "2018-12-25T00:00:00", "description": "Første juledag" }, { "date": "2018-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 0, "data": [{ "date": "2019-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2019-04-14T00:00:00", "description": "Palmesøndag" }, { "date": "2019-04-18T00:00:00", "description": "Skjærtorsdag" }, { "date": "2019-04-19T00:00:00", "description": "Langfredag" }, { "date": "2019-04-21T00:00:00", "description": "Første påskedag" }, { "date": "2019-04-22T00:00:00", "description": "Andre påskedag" }, { "date": "2019-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2019-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2019-05-30T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2019-06-09T00:00:00", "description": "Første pinsedag" }, { "date": "2019-06-10T00:00:00", "description": "Andre pinsedag" }, { "date": "2019-12-25T00:00:00", "description": "Første juledag" }, { "date": "2019-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 0, "data": [{ "date": "2020-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2020-04-05T00:00:00", "description": "Palmesøndag" }, { "date": "2020-04-09T00:00:00", "description": "Skjærtorsdag" }, { "date": "2020-04-10T00:00:00", "description": "Langfredag" }, { "date": "2020-04-12T00:00:00", "description": "Første påskedag" }, { "date": "2020-04-13T00:00:00", "description": "Andre påskedag" }, { "date": "2020-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2020-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2020-05-21T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2020-05-31T00:00:00", "description": "Første pinsedag" }, { "date": "2020-06-01T00:00:00", "description": "Andre pinsedag" }, { "date": "2020-12-25T00:00:00", "description": "Første juledag" }, { "date": "2020-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 0, "data": [{ "date": "2021-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2021-03-28T00:00:00", "description": "Palmesøndag" }, { "date": "2021-04-01T00:00:00", "description": "Skjærtorsdag" }, { "date": "2021-04-02T00:00:00", "description": "Langfredag" }, { "date": "2021-04-04T00:00:00", "description": "Første påskedag" }, { "date": "2021-04-05T00:00:00", "description": "Andre påskedag" }, { "date": "2021-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2021-05-13T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2021-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2021-05-23T00:00:00", "description": "Første pinsedag" }, { "date": "2021-05-24T00:00:00", "description": "Andre pinsedag" }, { "date": "2021-12-25T00:00:00", "description": "Første juledag" }, { "date": "2021-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 8, "data": [{ "date": "2022-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2022-04-10T00:00:00", "description": "Palmesøndag" }, { "date": "2022-04-14T00:00:00", "description": "Skjærtorsdag" }, { "date": "2022-04-15T00:00:00", "description": "Langfredag" }, { "date": "2022-04-17T00:00:00", "description": "Første påskedag" }, { "date": "2022-04-18T00:00:00", "description": "Andre påskedag" }, { "date": "2022-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2022-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2022-05-26T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2022-06-05T00:00:00", "description": "Første pinsedag" }, { "date": "2022-06-06T00:00:00", "description": "Andre pinsedag" }, { "date": "2022-12-25T00:00:00", "description": "Første juledag" }, { "date": "2022-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 8, "data": [{ "date": "2023-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2023-04-02T00:00:00", "description": "Palmesøndag" }, { "date": "2023-04-06T00:00:00", "description": "Skjærtorsdag" }, { "date": "2023-04-07T00:00:00", "description": "Langfredag" }, { "date": "2023-04-09T00:00:00", "description": "Første påskedag" }, { "date": "2023-04-10T00:00:00", "description": "Andre påskedag" }, { "date": "2023-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2023-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2023-05-18T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2023-05-28T00:00:00", "description": "Første pinsedag" }, { "date": "2023-05-29T00:00:00", "description": "Andre pinsedag" }, { "date": "2023-12-25T00:00:00", "description": "Første juledag" }, { "date": "2023-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 7, "data": [{ "date": "2024-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2024-03-24T00:00:00", "description": "Palmesøndag" }, { "date": "2024-03-28T00:00:00", "description": "Skjærtorsdag" }, { "date": "2024-03-29T00:00:00", "description": "Langfredag" }, { "date": "2024-03-31T00:00:00", "description": "Første påskedag" }, { "date": "2024-04-01T00:00:00", "description": "Andre påskedag" }, { "date": "2024-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2024-05-09T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2024-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2024-05-19T00:00:00", "description": "Første pinsedag" }, { "date": "2024-05-20T00:00:00", "description": "Andre pinsedag" }, { "date": "2024-12-25T00:00:00", "description": "Første juledag" }, { "date": "2024-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 7, "data": [{ "date": "2025-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2025-04-13T00:00:00", "description": "Palmesøndag" }, { "date": "2025-04-17T00:00:00", "description": "Skjærtorsdag" }, { "date": "2025-04-18T00:00:00", "description": "Langfredag" }, { "date": "2025-04-20T00:00:00", "description": "Første påskedag" }, { "date": "2025-04-21T00:00:00", "description": "Andre påskedag" }, { "date": "2025-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2025-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2025-05-29T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2025-06-08T00:00:00", "description": "Første pinsedag" }, { "date": "2025-06-09T00:00:00", "description": "Andre pinsedag" }, { "date": "2025-12-25T00:00:00", "description": "Første juledag" }, { "date": "2025-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 8, "data": [{ "date": "2026-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2026-03-29T00:00:00", "description": "Palmesøndag" }, { "date": "2026-04-02T00:00:00", "description": "Skjærtorsdag" }, { "date": "2026-04-03T00:00:00", "description": "Langfredag" }, { "date": "2026-04-05T00:00:00", "description": "Første påskedag" }, { "date": "2026-04-06T00:00:00", "description": "Andre påskedag" }, { "date": "2026-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2026-05-14T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2026-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2026-05-24T00:00:00", "description": "Første pinsedag" }, { "date": "2026-05-25T00:00:00", "description": "Andre pinsedag" }, { "date": "2026-12-25T00:00:00", "description": "Første juledag" }, { "date": "2026-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 8, "data": [{ "date": "2027-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2027-03-21T00:00:00", "description": "Palmesøndag" }, { "date": "2027-03-25T00:00:00", "description": "Skjærtorsdag" }, { "date": "2027-03-26T00:00:00", "description": "Langfredag" }, { "date": "2027-03-28T00:00:00", "description": "Første påskedag" }, { "date": "2027-03-29T00:00:00", "description": "Andre påskedag" }, { "date": "2027-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2027-05-06T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2027-05-16T00:00:00", "description": "Første pinsedag" }, { "date": "2027-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2027-05-17T00:00:00", "description": "Andre pinsedag" }, { "date": "2027-12-25T00:00:00", "description": "Første juledag" }, { "date": "2027-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 7, "data": [{ "date": "2028-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2028-04-09T00:00:00", "description": "Palmesøndag" }, { "date": "2028-04-13T00:00:00", "description": "Skjærtorsdag" }, { "date": "2028-04-14T00:00:00", "description": "Langfredag" }, { "date": "2028-04-16T00:00:00", "description": "Første påskedag" }, { "date": "2028-04-17T00:00:00", "description": "Andre påskedag" }, { "date": "2028-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2028-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2028-05-25T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2028-06-04T00:00:00", "description": "Første pinsedag" }, { "date": "2028-06-05T00:00:00", "description": "Andre pinsedag" }, { "date": "2028-12-25T00:00:00", "description": "Første juledag" }, { "date": "2028-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 },
  { "authenticated": false, "timeTaken": 2, "data": [{ "date": "2029-01-01T00:00:00", "description": "Første nyttårsdag" }, { "date": "2029-03-25T00:00:00", "description": "Palmesøndag" }, { "date": "2029-03-29T00:00:00", "description": "Skjærtorsdag" }, { "date": "2029-03-30T00:00:00", "description": "Langfredag" }, { "date": "2029-04-01T00:00:00", "description": "Første påskedag" }, { "date": "2029-04-02T00:00:00", "description": "Andre påskedag" }, { "date": "2029-05-01T00:00:00", "description": "Offentlig høytidsdag" }, { "date": "2029-05-10T00:00:00", "description": "Kristi himmelfartsdag" }, { "date": "2029-05-17T00:00:00", "description": "Grunnlovsdag" }, { "date": "2029-05-20T00:00:00", "description": "Første pinsedag" }, { "date": "2029-05-21T00:00:00", "description": "Andre pinsedag" }, { "date": "2029-12-25T00:00:00", "description": "Første juledag" }, { "date": "2029-12-26T00:00:00", "description": "Andre juledag" }], "statusCode": 200 }
];

export function nextWorkDate(date?: Date) {
  if (date == null)
    date = new Date();

  let tmp = moment(date).startOf('day').add(1, 'day');
  while (!isWorkDate(tmp.toDate()))
    tmp = tmp.add(1, 'day');

  tmp.add(10, 'hours');
  return tmp.toDate();
}
