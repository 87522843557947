import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react";
import { DataStore } from "../../../stores/data-store";
import { Pending } from "../../../components/shared/Pending";
import { DetailsPanel } from "../../../components/details/DetailsPanel";
import { DetailsBlock } from "../../../components/details/DetailsBlock";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { DetailValueField } from "../../../components/details/DetailValueField";
import { Link } from "react-router-dom";
import { DetailsAction } from "../../../components/details/DetailsAction";
import { TabPanel } from "../../../components/shared/TabPanel";
import { Tab } from "../../../components/shared/Tab";
import { ComplaintDetailsModel } from "../../../models/shared/ComplaintDetailsModel";
import { toDateWithHM } from "../../../core/dateHelpers";
import DeleteIcon from "svg/trash.svg";
import EditIcon from "svg/edit.svg";
import { Dialog } from "../../../core/dialog";
import { default as RootStore } from "../../../stores/root-store";
import { Button } from "../../../components/shared/Button";
import { DocumentList } from "../../../components/shared/DocumentList";
import { Audit } from "../../../components/shared/audit/Audit";
import { Enums } from "../../../stores/lookup-store";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import { ComplaintState } from "../../../models/shared/ComplaintState";
import { Network } from "../../../api/network";
import { set, IObservableArray, observable, IObservableValue } from "mobx";
import { MessageList } from "../../../components/shared/communications/MessageList";

type P = RouteComponentProps<{ id: string }>;

@observer
class ComplaintDetails extends React.Component<P> {
  complaintId: number;
  store: DataStore<ComplaintDetailsModel>;
  states: IObservableArray<BaseLookupModel>;

  constructor(props: P) {
    super(props);

    this.complaintId = parseInt(this.props.match.params.id);

    this.store = new DataStore<ComplaintDetailsModel>(`/api/complaints/${this.complaintId}/details`, [{
      id: this.complaintId,
      type: "ComplaintEntity"
    }]);

    this.states = observable([]);
  }
  async componentDidMount() {
    const result = await RootStore.LookupStore.getEnums(Enums.ComplaintState, false);
    if (result)
      this.states.replace(result);
  }

  componentWillUnmount = () => this.store.dispose();

  editComplaint = () => {
    const { history } = this.props;
    history.push(`/complaints/${this.complaintId}/edit`);
  }

  deleteComplaint = () => {
    const { history } = this.props;

    const dialog = Dialog.OkCancelDialog(
      "Slette reklamasjon",
      `Er du sikker på at du vil slette reklamasjonen ${toSerialNumber(EntityType.Complaint, this.complaintId)} fra ${this.store.data!.CustomerName}?`,
      "Slett",
      async (fnClose) => {

        // attemt to delete the entity.
        const deleteWasSuccessfull = await this.store.delete("complaints", this.complaintId);

        // Close the dialog no matter what. If an error occured, another dialog will show error message.
        fnClose();

        // If the delete was successfull, navigate to the listing page.
        if (deleteWasSuccessfull === true) {
          history.push(`/complaints`);
        }
      }
    );

    RootStore.UIStore.addDialog(dialog);
  }

  setState = async (state: ComplaintState) => {

    const io = async (reason?: string) => {
      const network = new Network();
      await network.post(`/api/complaints/setState/${this.complaintId}/${state}`, reason, false);
    }

    if (state == ComplaintState.Processing || state == ComplaintState.Received)
      io(undefined);
    else {
      const d = new Dialog<{ reason: string }>();
      d.title = "Oppdater reklamasjonsstatus";
      d.content = props =>
        <div className="">
          <div>
            <form className="edit-form">
              <div className="form-field">
                <div className="form-field-descripion">
                  <label className="form-field-label">Forklaring</label>
                  <div className="form-field-help-container">
                    <span className="form-field-help-text"><span>Her kan du gi en forklaring på hvorfor du godtar/avviser denne reklamasjonen samt gi informasjon rundt hvordan saken løses. Denne teksten inkluderes i epost som sendes brukerene.</span></span>
                  </div>
                </div>
                <div className="form-field-component">
                  <textarea value={props.reason} onChange={e => props.reason = e.target.value} />
                </div>
              </div>
            </form>
          </div>
        </div>
      d.closable = true;
      d.buttons = [{
        onClick: async (state, fnClose) => {
          await io(state.reason);
          fnClose();
        },
        text: "OK"
      }];

      Dialog.Show(d);
    }

  }


  render() {
    const model = this.store.data;
    if (!model || this.states.length == 0)
      return <Pending />;

    const isAdministrator = RootStore.UserStore.isEmployeeAdministrator;
    const isCustomer = RootStore.UserStore.isCustomer;

    const options = this.states.map(o => ({ text: o.Name!, onClick: () => this.setState(o.Id) }));
    const currentOption = this.states.find(f => f.Id == model.State)?.Name;


    return (<>
      <DetailsPanel>
        <DetailsBlock title="Oversikt">
          <DetailValueField title="Reklamasjonsnummer">
            <Link to={`/complaints/${model.Id}/edit`}>{toSerialNumber(EntityType.Complaint, model.Id)}</Link>
          </DetailValueField>
          <DetailValueField title="Kunde">
            <Link to={`/customers/${model.CustomerId}`}>{model.CustomerName}</Link>
          </DetailValueField>
          {model.UserIsCustomer && <DetailValueField title="Av">
            <Link to={`/users/${model.UserId}`}>{model.UserName}</Link>
          </DetailValueField>}
          <DetailValueField title="Kontakter"><ul>
            {model.Contacts && model.Contacts.map((c, i) => <li key={i}>
              <Link to={`/users/${c.UserId}`} key={i}>{c.Name}</Link>
            </li>)}</ul>
          </DetailValueField>
          <DetailValueField title="Arbeidsordre">
            <Link to={`/workorders/${model.WorkOrderId}`}>{toSerialNumber(EntityType.WorkOrder, model.WorkOrderId)}</Link>
          </DetailValueField>
          <DetailValueField title="Dato">{toDateWithHM(model.Created)}</DetailValueField>
        </DetailsBlock>
        <DetailsBlock title="Informasjon">
          <DetailValueField title="Beskrivelse" wrap long>{model.Reason}</DetailValueField>
          <DetailValueField title="Løsningsforslag" wrap long>{model.RequestedSolution}</DetailValueField>
        </DetailsBlock>
        <DetailsBlock title="Avklaring">
          <DetailValueField title="Status">{currentOption}</DetailValueField>
          <DetailValueField title="Endret">{toDateWithHM(model.LastStateChange)}</DetailValueField>
          <DetailValueField title="Response" wrap long>{model.Resolvement}</DetailValueField>
        </DetailsBlock>
        <DetailsAction>
          <Button text={currentOption || ""} options={options}></Button>
          <Button text="Rediger" onClick={() => this.editComplaint()} icon={EditIcon}></Button>
          {isAdministrator && <Button text="Slett" onClick={() => this.deleteComplaint()} icon={DeleteIcon}></Button>}
        </DetailsAction>
      </DetailsPanel>
      <TabPanel>
        {model.Documentation && <Tab title="Dokumentasjon" count={model.Documentation!.length}>
          <DocumentList files={model.Documentation} />
        </Tab>}
        {!isCustomer && <Tab title="Meldinger">
          <MessageList entity="ComplaintEntity" entityId={model.Id} />
        </Tab>}
        {!isCustomer && <Tab title="Historikk">
          <Audit type="Logi.Proflyt.Data.Entities.ComplaintEntity" id={model.Id} isWorkorderRelated={false} />
        </Tab>}
      </TabPanel>
    </>);
  }
}


const a = withRouter(ComplaintDetails);
export {
  a as ComplaintDetailsPage
}