import { observable, action } from "mobx";
import { Network } from "../api/network";
import { UnitState } from "../models/shared/UnitState";
import { WorkOrderDetailsModel } from "../models/shared/WorkOrderDetailsModel";
import { NotificationHub, ChangeType } from "../core/signalR";
import { entityNameToControllerName } from "../core/util";
import { isNumber } from "lodash-es";
import { AddUnitWeightModel } from "ts/models/shared/AddUnitWeightModel";

export class GenericDataStore<T extends WorkOrderDetailsModel> {

  private _listener: object;

  async moveUnit(id: number, workOrderId?: number) {
    await this._network.get(`/api/units/move/${id}/${workOrderId || ""}`);
  }

  async deleteUnit(id: number) {
    await this._network.get(`/api/units/delete/${id}`);
  }

  async changeUnitState(id: number, state: UnitState, isReady: boolean) {
    await this._network.get(`/api/units/changestate/${id}/${state}/${isReady}`);
  }

  async deleteWorkOrder() {
    if (this.entity)
      return await this._network.get<boolean>(`/api/workorders/delete/${this.entity.Id}`);
  }

  async changeCustomer(customerId: number) {
    if (this.entity)
      await this._network.get(`/api/workorders/changecustomer/${this.entity.Id}/${customerId}`);
  }

  async deleteImage(unitId: number, imageId: string) {
    await this._network.get(`/api/units/deleteImage/${unitId}/${imageId}`);
  }

  /**
 * Changes the state of all units associated with the current work order.
 * @param state The target state for all units.
 */
  async changeStateAll(state: UnitState) {
    if (this.entity)
      await this._network.get(`/api/workorders/changestate/${this.entity.Id}/${state}`);
  }

  @observable entity: T | undefined;

  private _network: Network;

  constructor() {
    this._network = new Network();

    this._listener = NotificationHub.registerListener(changes => {

      if (this.entity) {
        const workorderId = this.entity.Id;

        if (changes.some(ch => entityNameToControllerName(ch.entityName) == "workorders" && ch.entityId == workorderId && ch.changeType != ChangeType.Deleted))
          this.loadData(workorderId);
        else if (changes.some(ch => entityNameToControllerName(ch.entityName) == "units" && this.entity != undefined && this.entity.Units.some(u => u.Id == ch.entityId)))
          this.loadData(workorderId);
      }
    });
  }

  public async loadData(id: number) {
    const result = await this._network.get<T>(`/api/workorders/${id}/details`);
    if (result)
      this.entity = result;
  }

  /** Call this manually before object is garbage collected.*/
  public release() {
    NotificationHub.removeListener(this._listener);
  }

  async addUnitWeight(id: number, articleId: number, weight: number, isDoubleDip: boolean): Promise<boolean> {
    const result = await this._network.post<AddUnitWeightModel, number>("/api/units/addWeight", {
      UnitId: id,
      ArticleId: articleId,
      Weight: weight,
      IsDoubleDip: isDoubleDip
    });
    if (isNumber(result) && result > 0)
      return true;
    return false;
  }

  async removeUnitWeight(weightId: number) : Promise<boolean> {
    const result = await this._network.get<boolean>(`/api/units/removeWeight/${weightId}`);
    return result || false;
  }

}