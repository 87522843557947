import * as React from "react";
import { RouteComponentProps, Switch, Route } from "react-router";
import { DataStore2 } from "../../../stores/data-store";
import { BoomDetailsModel } from "../../../models/shared/BoomDetailsModel";
import { observer } from "mobx-react";
import { Pending } from "../../../components/shared/Pending";
import "./BoomPage.tsx.scss";
import { BoomType } from "../../../models/shared/BoomType";
import { DynamicPageTitle } from "../../../components/shared/DynamicPageTitle";
import { BreadCrumb } from "../../../components/shared/BreadCrumb";
import { Button } from "../../../components/shared/Button";
import TrashIcon from "svg/trash.svg";
import UnitIcon from "svg/unit.svg";
import { DateTime } from "../../../components/shared/display";
import { Link } from "react-router-dom";
import { Network } from "../../../api/network";
import { BoomRemovalComponent } from "./Removal";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";

interface Props {
  route: RouteComponentProps<{}>;
}

export class BoomPage extends React.Component<Props> {
  render() {
    return <Switch>
      <Route path="/booms/package/:id" exact render={p => <BoomRemovalComponent route={p} />} />
      <Route path="/booms" render={p => <BoomPageOverview route={p} />} />
    </Switch>
  }
}

@observer
class BoomPageOverview extends React.Component<Props> {

  store: DataStore2<BoomDetailsModel[]>;

  constructor(props: Props) {
    super(props);
    this.store = new DataStore2<Array<BoomDetailsModel>>("/api/booms/all", ch => ch.entityName == "BoomEntity");
  }

  componentWillUnmount() {
    this.store.dispose();
  }

  render() {

    if (this.store.data === undefined)
      return <Pending />

    if (this.store.data == null)
      return <p>Ingen informasjon om bommer tilgjenglig</p>;

    const list = this.store.data;

    return <>
      <DynamicPageTitle workSpaceType="list" singular="Bom" plural="Bommer" />
      <BreadCrumb workSpaceType="list" singular="Bom" plural="Bommer" routeBasePath="/booms" />
      <ul>
        {list.map(l => <li className="panel boom-panel" key={"b_" + l.Id}><div className="boom-panel-header">
          <span>Bom: {l.Id}</span>
          <span>Antall vinger: {l.NumberOfWings}</span>
          <span>Type: {l.Type == BoomType.Standard ? "Standard" : ""}</span>
          <span>Plassering: [Hente fra kransystem]</span>
          <span>Totalvekt: [Hente fra kransystem]</span>
          <span>Sist flyttet: [Hente fra kransystem]</span>
        </div>
          <div className="boom-panel-details">
            {l.Content == null || l.Content!.length == 0 && <p>Denne bommen er tom</p>}
            {l.Content && l.Content!.length > 0 && <div><table><thead>
              <tr>
                <th className="right">Fra</th>
                <th className="right">Til</th>
                <th>Kunde</th>
                <th className="right">Arbeidsordre</th>
                <th className="right">Kolli</th>
                <th>Plassert</th>
                <th>Detaljer</th>
                <th className="action">Plukk ned</th>
                <th className="action">Slett</th>
              </tr></thead>
              <tbody>
                {l.Content.map((c, idx) => <tr key={`p_${l.Id}_${idx}`}>
                  <td className="right">{c.FromWing}</td>
                  <td className="right">{c.ToWing}</td>
                  <td>{c.CustomerName}</td>
                  <td className="right"><Link to={`workorders/${c.WorkOrderId}`}>{toSerialNumber(EntityType.WorkOrder, c.WorkOrderId)}</Link></td>
                  <td className="right"><Link to={{ pathname: `workorders/${c.WorkOrderId}`, state: { unitId: c.UnitId } }}>{toSerialNumber(EntityType.Unit, c.UnitId)}</Link></td>
                  <td><DateTime date={c.PlacementTime} /></td>
                  <td>{c.Information}</td>
                  <td className="action"><span onClick={_ => this.package(c.PlacementId)}><UnitIcon className="icon" /></span></td>
                  <td className="action"><span onClick={_ => this.removeLine(l.Id, c.FromWing)}><TrashIcon className="icon" /></span></td>
                </tr>)}</tbody></table>
              <div className="boom-panel-actions"><Button text="Tøm bom" icon={TrashIcon} onClick={() => this.removeAll(l.Id)} /></div></div>}
          </div>
        </li>)}
      </ul>
    </>;

  }

  async removeAll(Id: number) {
    await new Network().get("/api/booms/removeAllContent/" + Id);
  }

  async removeLine(Id: number, FromWing: number) {
    await new Network().get(`/api/booms/removeContent/${Id}/${FromWing}`);
  }

  package(id: number) {
    this.props.route.history.push(`/booms/package/${id}`);
  }
}
