import * as React from "react";
import { validateEmail, isNullOrWhitespace } from "../core/stringHelpers";
import { Form } from "./forms/form";
import { InputField } from "./forms/fields/InputField";
import { observer, Observer, inject } from "mobx-react";
import { FormStore } from "../stores/form-store";
import { RouterStore } from "mobx-react-router";
import { AuthenticationHelper } from "../api/authentication";
import { TokenRequest } from "../models/shared/TokenRequest";
import { FormActions } from "./forms/FormActions";
import MicrosoftLogo from "svg/microsoft-logo.svg";
import "./login.scss";

interface LoginModel {
  username: string;
  password: string;
}

const validation = {
  Email: [
    (value: any) => validateEmail(value) ? null : "Epostadressen er ikke gyldig."
  ],
  Password: [
    (value: any) => !isNullOrWhitespace(value) ? null : "Du må oppgi et passord."
  ]
}

@inject("RouterStore")
@observer
export class Login extends React.Component<Partial<{ RouterStore: RouterStore }>> {

  private store = new FormStore<TokenRequest>(validation);

  signOnSso = (provider: string) => {
    window.location.href = `/api/authentication/sso/${provider}`;
  }

  render() {

    const authenticationHelper = new AuthenticationHelper();
    const hasInvalidTenant = window.flytSettings.invalidTenant;


    const onSubmit = async () => {
      await authenticationHelper.authenticate(this.store);
    }

    return (
      <>
        <div className="login-bg" />
        <div className="login-form-container">
          <Form
            name="login"
            cancelable={false}
            store={this.store}
            className="login-form"
            onSubmit={onSubmit}>
            <h3>RåZink - Pålogging</h3>
            <InputField<TokenRequest>
              field="Email"
              label="Epost-adresse"
              type="email"
              autoComplete="email"
              helpText="Skriv epostadressen du er registrert med."
              autoFocus
            />
            <InputField<TokenRequest>
              field="Password"
              label="Passord"
              type="password"
              autoComplete="current-password"
              helpText="Fyll inn passordet ditt." />
            <FormActions showUndo={false} submitText="Logg på" />
            <div className="login-sso">
              <button className="button" type="button" onClick={e => this.signOnSso('microsoft')}><MicrosoftLogo />Logg på med Microsoft</button>
              {hasInvalidTenant && <p>Ditt firma er ikke klargjort for pålogging via Microsoft. Send en epost til post@razink.no for å aktivere tjenesten.</p>}
            </div>
          </Form>
        </div></>
    );
  }
}
