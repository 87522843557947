import React, { useEffect, useRef } from "react";
import "./VideoStream.tsx.scss";

export const VideoStream: React.FC = () => {

  const ref = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    
    let ws: WebSocket | null = null;
    const pc = new RTCPeerConnection({ iceServers: [{ urls: "stun:stun.l.google.com:19302" }] });

    if (ref.current) {
      const tracks = [pc.addTransceiver("video", { direction: "recvonly" }).receiver.track];
      ref.current.srcObject = new MediaStream(tracks);

      ref.current.addEventListener("loadedmetadata", () => {
        ref.current?.classList.add("has-video");
      });

      ws = new WebSocket('wss://streams.razink.no/api/ws?src=Outside&media=video');
      ws.addEventListener("open", async () => {
        const offer = await pc.createOffer();
        await await pc.setLocalDescription(offer);
        const msg = { type: 'webrtc/offer', value: pc.localDescription!.sdp };
        ws!.send(JSON.stringify(msg));
      });

      ws.addEventListener("message", ev => {
        const msg = JSON.parse(ev.data);
        if (msg.type === 'webrtc/candidate') {
          pc.addIceCandidate({ candidate: msg.value, sdpMid: '0' });
        } else if (msg.type === 'webrtc/answer') {
          pc.setRemoteDescription({ type: 'answer', sdp: msg.value });
        }
      });

      pc.addEventListener('icecandidate', ev => {
        if (!ev.candidate) return;
        const msg = { type: 'webrtc/candidate', value: ev.candidate.candidate };
        ws!.send(JSON.stringify(msg));
      });
    }
    return () => {
      pc.close();
      ref.current?.pause();
      ws?.close();
    }
  }, []);

  return <video className="video-stream-1" ref={ref} muted autoPlay playsInline poster="/services/videopreview.jpg"></video>
}