import * as React from "react";
import { Link } from "react-router-dom";
import CreateIcon from "svg/add.svg";
import "./listAddItemButton.tsx.scss";
import { default as RootStore } from "../../stores/root-store";
import { UserStore } from "../../stores/user-store";

export const ListAddItemButton: React.FC<{ url: (user: UserStore) => string | null, singular: string }> = ({ url, singular }) => {
  const link = url?.(RootStore.UserStore);

  if (link == null)
    return null;
  return <div className="list-add-button">
    <Link to={link} className="button button-small">
      <CreateIcon />
      <span>{`Opprett ny ${singular.toLowerCase()}`}</span>
    </Link>
  </div>;
}