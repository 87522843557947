import * as React from "react";
import { CustomerSelector } from "../../../components/shared/CustomerSelector";
import { debounce } from "lodash-es";
import { Network } from "../../../api/network";
import { FindInfoByReferenceResultModel } from "../../../models/shared/FindInfoByReferenceResultModel";
import { ActiveOrderListItemModel } from "../../../models/shared/ActiveOrderListItemModel";
import "./selectWorkOrderCustomer.tsx.scss";
import { toDateWithHM, toDate } from "../../../core/dateHelpers";
import WorkOrderIcon from "svg/workorder.svg";
import { DescriptionLookupModel } from "../../../models/shared/DescriptionLookupModel";

interface Props {
  setCustomer: (customerId: number, orderId?: number | null, offerId?: number | null) => void;
  initialCustomerId?: number;
}

interface State {
  customerId?: number;
  reference?: FindInfoByReferenceResultModel
  referenceLookupPending: boolean;
  orderLookupPending: boolean;
  offerLookupPending: boolean;
  activeOrders?: Array<ActiveOrderListItemModel>;
  activeOffers?: Array<DescriptionLookupModel>
}

export class SelectWorkOrderCustomer extends React.Component<Props, State> {

  state: State;
  checkReferenceDebounced: (reference: string) => void;

  constructor(props: Props) {
    super(props);

    const network = new Network();
    let networkController = new AbortController();

    this.checkReferenceDebounced = debounce((phrase: string) => {
      this.setState({ referenceLookupPending: true, reference: undefined }, async () => {
        try {

          // abort any pending request
          networkController.abort();

          if (phrase == null || phrase.length === 0)
            this.setState({ reference: undefined });
          else {
            // create new abort controller
            networkController = new AbortController();

            const result = await network.get<FindInfoByReferenceResultModel>(`/api/workorders/findInfoByReference/${encodeURIComponent(phrase)}`, networkController);
            if (result != null)
              this.setState({ reference: result });
          }
        } finally {
          this.setState({ referenceLookupPending: false });
        }
      })
    }, 250);
    this.state = {
      referenceLookupPending: false,
      orderLookupPending: false,
      offerLookupPending: false,
      customerId: props.initialCustomerId
    };
  }

  selectReference: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === "Enter") {
      if (this.state.reference != null) {
        const { CustomerId, OfferId, OrderId } = this.state.reference;
        this.props.setCustomer(CustomerId, OrderId, OfferId);
      }
    }
  }

  updateSelectedCustomer = (customerId: number | undefined) => {
    this.setState({ customerId, activeOffers: [], activeOrders: [] }, () => {
      this.updateOrders();
      this.updateOffers();
    });
  }

  updateOrders = () => {
    if (this.state.customerId == undefined) {
      this.setState({ activeOrders: undefined });
    } else {
      this.setState({ orderLookupPending: true }, async () => {
        const network = new Network();
        const activeOrders = await network.get<Array<ActiveOrderListItemModel>>(`/api/orders/getActiveOrdersByCustomer/${this.state.customerId}`);
        this.setState({ activeOrders: activeOrders || undefined, orderLookupPending: false })
      });
    }
  }

  updateOffers = () => {
    if (this.state.customerId == undefined)
      this.setState({ activeOffers: undefined });
    else {
      this.setState({ offerLookupPending: true }, async () => {
        const network = new Network();
        const activeOffers = await network.get<Array<DescriptionLookupModel>>(`/api/offers/availableOffersForCustomer/${this.state.customerId}`);
        this.setState({ activeOffers: activeOffers || undefined, offerLookupPending: false });
      })
    }
  }

  render() {
    const { customerId, reference, activeOrders, activeOffers, orderLookupPending, offerLookupPending } = this.state;
    const { setCustomer } = this.props;
    return (
      <div className="panel workorder-customer-selector">
        <div className="customer-selector-area">
          <div>
            <h3>Kunde</h3>
            <CustomerSelector setValue={this.updateSelectedCustomer} value={customerId} />
          </div>
          {customerId && activeOrders && activeOrders.length > 0 && <div className="content-margin-top">
            <h3>Bestillinger</h3>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Referanse</th>
                    <th>Tilbud</th>
                    <th>Beskrivelse</th>
                    <th>Informasjon</th>
                    <th className="right">Leveringsdato</th>
                    <th className="right">Antall kolli</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {activeOrders.map(ao => <tr key={ao.Id}>
                    <td>{ao.Id}</td>
                    <td>{ao.InvoiceReference}</td>
                    <td>{ao.OfferId}</td>
                    <td>{ao.Description}</td>
                    <td>{ao.Information}</td>
                    <td className="right">{toDate(ao.EstimatedDeliveryDate)}</td>
                    <td className="right">{ao.UnitCount}</td>
                    <td className="right"><span className="link create-link" tabIndex={0} onClick={e => setCustomer(customerId, ao.Id, ao.OfferId)}><WorkOrderIcon className="icon" />Opprett arbeidsordre</span></td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>
          }
          {customerId && activeOffers && activeOffers.length > 0 && <div className="content-margin-top">
            <h3>Tilbud</h3>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Tilbud</th>
                    <th>Beskrivelse</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {activeOffers.map(ao => <tr key={ao.Id}>
                    <td>{ao.Id}</td>
                    <td>{ao.Name}</td>
                    <td>{ao.Description}</td>
                    <td className="right"><span className="link create-link" tabIndex={0} onClick={e => setCustomer(customerId, undefined, ao.Id)}><WorkOrderIcon className="icon" />Opprett arbeidsordre</span></td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>}
          <div className="content-margin-top">
            <button
              type="button"
              className="button"
              onClick={e => setCustomer(customerId!)}
              disabled={customerId == null || offerLookupPending || orderLookupPending }>Opprett arbeidsordre</button>
          </div>
        </div>
        <div className="reference-selector-area">
          <div>
            <h3>Fra referanse</h3>
            <p className="suggestion-span">Lar deg søke etter kunde-id f.eks K32 eller bestillingsid f.eks B2550</p>
            <div className="edit-form">
              <input type="text" autoFocus onChange={e => this.checkReferenceDebounced(e.target.value)} onKeyDown={this.selectReference} />
            </div>
            <div>
              <button
                type="button"
                className="button"
                onClick={e => setCustomer(reference!.CustomerId, reference!.OrderId, reference!.OfferId)}
                disabled={reference == null}>Opprett arbeidsordre</button>
            </div>
            {reference && <div className="reference-description">
              <div><span>Kunde:</span><span>{reference.CustomerName}</span></div>
              {reference.Description && <div><span>Beskrivelse:</span><span>{reference.Description}</span></div>}
            </div>
            }
          </div>
        </div>
      </div>
    )
  }
}