import { GalvanizingStandard } from "ts/models/shared/GalvanizingStandard";
import { PaintType } from "ts/models/shared/PaintType";
import { CorrosivityCategory } from "../models/shared/CorrosivityCategory";

export function galvanizingStandardFromEnum(value: GalvanizingStandard) {
  switch (value) {
    case 0:
      return "Ingen";
    case 1:
      return "Normal";
    case 2:
      return "Fe/Zn 115";
    case 3:
      return "Fe/Zn 165";
    case 4:
      return "Fe/Zn 215";
    case 5:
      return "Klasse B";
    case 6:
      return "Klasse C";
    case 6:
      return "NORSOK M-501";
    default:
      return "Ukjent";
  }
}

export function paintTypeFromEnum(paintType: PaintType) {
  if (paintType == PaintType.Powder)
    return "Pulverlakk";
  else if (paintType == PaintType.Wet)
    return "Våtlakkering";
  else if (paintType == PaintType.ByCustomer)
    return "Av kunde";
  else
    return "-";
}

export function corrosivityFromEnum(corrosivity: CorrosivityCategory) {
  if (corrosivity == CorrosivityCategory.CX)
    return "CX";
  else
    return `C${corrosivity.toString()}`;
}