import { groupBy, sum } from "lodash-es";
import * as React from "react";
import { Collapse } from "react-collapse";
import { NumberWithPostfix } from "../../../../components/shared/display";
import { UnitStateEntity } from "../../../../models/shared/UnitStateEntity";
import { DayViewWorkOrder } from "./DayViewWorkOrder";
import { getWeight } from "./unitEntityHelper";

export const DayStateView: React.FC<{ title: string; data: Array<UnitStateEntity>; toggle:boolean }> = ({ title, data, toggle }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const grouped = groupBy(data, e => e.Unit?.WorkOrderId);
  const totalWeight = sum(data.map(s => getWeight(s.Unit!)))

  React.useEffect(() => {
    setIsOpen(toggle);
  }, [toggle])
  return <>
    <h2 className="day-header" onClick={e => setIsOpen(!isOpen)}>
      <span>{title}</span>
      <NumberWithPostfix number={totalWeight} post="kg" />
    </h2>
    <Collapse isOpened={isOpen}>
      {Object.keys(grouped).map(g => <DayViewWorkOrder workOrderId={g} items={grouped[g]} key={g} />)}
    </Collapse>
  </>
}
