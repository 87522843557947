/**  */
export enum HourType {
  
  /**Overtid */
  Overtime = 1,  
  /**Annet fravær med lønn */
  LeaveWithPay = 2,  
  /**Fravær uten lønn */
  LeaveWithoutPay = 3,  
  /**Ferie */
  VacationDay = 4,  
  /**Arbeid */
  Work = 5,  
  /**Sykemeldt */
  SickLeave = 6,  
  /**Egenmelding */
  SelfCertification = 7,  
  /**Sykt barn */
  SickChild = 8,  
  /**Permitert */
  Suspended = 9,  
  /**Overtid 100 */
  Overtime100 = 10,  
  /**Time utover normaltid */
  ExtraHour = 11
}

