import * as React from "react";
import { FormStore } from "ts/stores/form-store";
import { Form } from "ts/components/forms/form";
import { SelectField } from "ts/components/forms/fields/SelectField";
import { DataStore } from "ts/stores/data-store";
import { BoomDetailsModel } from "ts/models/shared/BoomDetailsModel";
import { observer } from "mobx-react";
import { InputField } from "ts/components/forms/fields/InputField";
import { autorun, IReactionDisposer, toJS } from "mobx";
import { AddBoomContentModel } from "ts/models/shared/AddBoomContentModel";
import TashIcon from "svg/trash.svg";
import { Network } from "ts/api/network";
import "./HookDialog.tsx.scss";
import { Button } from "../../../components/shared/Button";

export interface HookUnitProps {
  unitId: number;
  onCloseFn?: () => void
}

@observer
export class HookUnitDialogComponent extends React.Component<HookUnitProps> {
  formStore: FormStore<AddBoomContentModel>;
  booms: DataStore<Array<BoomDetailsModel>>;

  toDispose: IReactionDisposer;
  toRef: React.RefObject<HTMLInputElement>;

  constructor(props: HookUnitProps) {
    super(props);
    this.formStore = new FormStore<AddBoomContentModel>({}, { UnitId: this.props.unitId });
    this.booms = new DataStore("/api/booms/all", [{ type: "BoomEntity" }]);
    this.toRef = React.createRef()

    this.toDispose = autorun(() => {
      const values = this.formStore.values;
      if (values && values.BoomId != null && values.BoomId > 0) {
        if (values.FromWing == null) {
          const boom = toJS(this.booms.data!.find(b => b.Id == values.BoomId)!);
          if (boom.Content == null || boom.Content.length == 0)
            values.FromWing = 1;
          else
            values.FromWing = Math.max(...boom.Content.map(c => c.ToWing)) + 1;
          if (values.FromWing > boom.NumberOfWings)
            values.FromWing = 1;
          setTimeout(() => this.toRef.current && this.toRef.current.focus(), 0);
        }
      }

    });
  }

  async componentDidMount() {
    await this.booms.loadData();
  }

  componentWillUnmount() {
    this.booms.dispose();
    this.toDispose();
  }

  remove = async (boomId: number, fromWing: number) => {
    await new Network().get(`/api/booms/removeContent/${boomId}/${fromWing}`);
  }

  emptyBoom = async (boomId: number) => {
    await new Network().get(`/api/booms/RemoveAllContent/${boomId}`);
  }

  render() {

    if (this.booms.data == null)
      return null;

    const boom = this.booms.data.find(b => b.Id == this.formStore.values.BoomId);

    return (
      <div className="dialog-hook-unit-content">
        <Form
          name="hook"
          onSubmit={async router => {
            const id = await this.formStore.postForm(`/api/booms/addContent`, false);
            if (id) {
              if (this.formStore.values.CompletesUnit === true) {
                this.props.onCloseFn!();
              } else {
                this.formStore.reset();
              }
            }
          }}
          cancelable={false}
          store={this.formStore}>
          <SelectField<AddBoomContentModel>
            field="BoomId"
            label="Bom"
            helpText="Velg hvilken bom kolli skal legges til."
            multiple={false}>
            <option key={0} value={-1}>-- Velg bom --</option>
            {(this.booms.data || []).map(b => <option key={b.Id} value={b.Id}>{b.Id}</option>)}
          </SelectField>
          <InputField<AddBoomContentModel>
            field="FromWing"
            label="Fra vinge"
            disabled={p => p?.BoomId == null || p.BoomId == 0}
            type="number"
            inputMode="numeric"
            helpText="Indiker hvilke vinge dette kolli starter fra."
          />
          <InputField<AddBoomContentModel>
            field="ToWing"
            label="Til vinge"
            disabled={p => p?.BoomId == null || p.BoomId == 0}
            refFn={this.toRef}
            type="number"
            inputMode="numeric"
            helpText="Indiker hvilke vinge dette kolli slutter på."
          />
          {this.formStore.values.FromWing != null && <div className="buttons">
            <Button
              text="Legg til"
              disabled={this.formStore.hasValidationErrors || this.formStore.values.ToWing == null || this.formStore.isPending}
              onClick={ev => ev?.currentTarget.form?.dispatchEvent(new Event("submit", { cancelable: true }))} />
            <Button
              text="Legg til og fullfør kolli"
              disabled={this.formStore.hasValidationErrors || this.formStore.values.ToWing == null || this.formStore.isPending}
              onClick={ev => {
                this.formStore.setValue("CompletesUnit", true);
                ev && ev.currentTarget.form?.dispatchEvent(new Event("submit", { cancelable: true }));
              }} />
          </div>}
        </Form>
        {boom && boom.Content && boom.Content.length > 0 && <>
          <table className="table-container">
            <thead>
              <tr>
                <th className="right">Kolli</th>
                <th className="right">Arbeidsordre</th>
                <th>Kunde</th>
                <th className="right">Fra vinge</th>
                <th className="right">Til vinge</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {boom.Content.map((b, idx) => <tr key={idx}>
                <td className="right">{b.UnitId}</td>
                <td className="right">{b.WorkOrderId}</td>
                <td>{b.CustomerName}</td>
                <td className="right">{b.FromWing}</td>
                <td className="right">{b.ToWing}</td>
                <td className="action center"><span title="Slett" onClick={e => this.remove(boom.Id, b.FromWing)}><TashIcon /></span></td>
              </tr>)}
            </tbody>
          </table>
          <div className="buttons"><button type="button" className="button" onClick={e => this.emptyBoom(boom.Id)}>Tøm bom</button></div>
        </>
        }
      </div>
    );
  }
}