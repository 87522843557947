import * as React from "react";
import ClipboardIcon from "svg/clipboard.svg";
import copy from "clipboard-copy";
import { infoToast } from "./Toasts";


const CopyToClipboard: React.FC<{ text: string }> = ({ text }) => {
  const isSupported = document.queryCommandSupported("copy");
  if (!isSupported)
    return null;

  const _copy = async (text: string) => {
    await copy(text);
    infoToast(`Kopiert ${text} til utklippstavlen.`);
  }

  return <span
    onClick={async () => await _copy(text)}
    className="copy-to-clipboard"
    title="Kopier til utklippstavle"
    style={{ cursor: "pointer" }}>
    <ClipboardIcon className="icon" />
  </span>
}

export { CopyToClipboard };
