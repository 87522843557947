import { BaseModel } from "../../models/shared/BaseModel";
import { ListProps } from "./List";
import * as React from "react";
import classNames from "classnames";
import { SmallButton } from "../shared/SmallButton";
import TrashIcon from 'svg/trash.svg';
import FilterIcon from 'svg/filter.svg';
import FilterAndSortIcon from "svg/filter-and-sort.svg";
import { Button } from "../shared/Button";
import { observer } from "mobx-react";
import "./filters.scss";
import { ShowDeletedFilterInternal } from "./FilterComponents/ShowDeletedFilter";
import { default as RootStore } from "../../stores/root-store";
import { IListFilter } from "./IListDefinition";

interface FilterState {
  expanded: boolean;
  selectedSortingTitle?: string;
}

type P = ListProps<BaseModel & any>;

@observer
export class Filters extends React.Component<React.PropsWithChildren<P>, FilterState> {

  state = { expanded: document.documentElement != null && document.documentElement.clientWidth >= 768 };
  presetOptions?: Array<{ text: string; onClick: () => void }>;

  toggleExpanded = (ev?: React.MouseEvent<HTMLButtonElement>) => this.setState(state => ({ expanded: !state.expanded }));

  constructor(props: P) {
    super(props);

    const { configuration } = this.props;
    const { setPreset } = props.store;

    this.presetOptions = configuration.presets && configuration.presets.filter(f => f.hidden == null || !f.hidden(RootStore.UserStore)).map(preset => ({
      text: preset.title,
      onClick: () => setPreset(preset)
    }));
  }

  render() {
    const { expanded } = this.state;
    const { configuration } = this.props;
    const { hasFilters, clearFilters, activePresetTitle } = this.props.store;
    const isCustomer = RootStore.UserStore.isCustomer;

    // Litt masering av data for å kunne gruppere basert på gruppe i utlistningen under
    const filters = configuration.filters.filter(f => f.hidden == null || f.hidden(RootStore.UserStore) === false);
    const result: Array<Array<IListFilter<any>>> = [];
    const helper: Map<string, Array<IListFilter<any>>> = new Map();
    filters.forEach(f => {
      if (f.group == null)
        result.push([f]);
      else {
        if (helper.has(f.group))
          helper.get(f.group)?.push(f);
        else {
          const lst = [f];
          helper.set(f.group, lst);
          result.push(lst);
        }
      }
    });

    return (
      <div className={classNames("filter-container panel", { "expanded": expanded })} >
        <div className="filter-toggles">
          <SmallButton onClick={this.toggleExpanded}><FilterIcon className="icon" />{expanded ? "Skjul filtre" : "Vis filtre"}</SmallButton>
          {hasFilters && <SmallButton onClick={() => clearFilters()}><TrashIcon className="icon" />Tøm filtervalg</SmallButton>}
          <PresetButton activePresetTitle={activePresetTitle} options={this.presetOptions} />
        </div>
        {expanded && <div className="filters-wrapper">
          <ul className="filters">
            {result.map((filterlst, i) => (
              <li key={i} className="filter-item">
                {filterlst.map((filter, j) => (
                  <div key={j} className="filter-item-item">
                    <h4>{filter.title}</h4>
                    {React.cloneElement(filter.component, { store: this.props.store, field: filter.field, focus: filter.focus ?? false })}
                  </div>
                ))}
              </li>))}
            {!isCustomer && <li className="filter-item">
              <h4>Vis slettede</h4>
              <ShowDeletedFilterInternal store={this.props.store} field="Deleted" focus={false} /></li>}
          </ul></div>}
      </div>
    );
  }
}

const PresetButton: React.FC<{ options?: Array<{ text: string, onClick: () => void }>, activePresetTitle: string }> = ({ options, activePresetTitle }) => {
  if (options == null || options.length == 0)
    return null;
  if (options.length == 1)
    return <Button onClick={options[0].onClick} icon={FilterAndSortIcon} className="button-small" text="Tilbakestill" />
  else
    return <Button text={activePresetTitle} options={options} icon={FilterAndSortIcon} className="button-small" />
}