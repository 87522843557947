import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { nb } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, setHours, formatISO } from "date-fns";
import { isWorkDate, toISO8601Date } from "ts/core/dateHelpers";
import "./datefield.tsx.scss";
import { DateSelector } from "../../shared/DatePicker";
import classNames from "classnames";

registerLocale("nb", nb);
setDefaultLocale("nb");

interface OwnProps {
  time?: boolean;
  filterWorkDays?: boolean;
  minDate?: Date;
  openToDate?: Date;
  numberOfMonths?: number
}

type P = PropsFromWrapperToField<string> & OwnProps;

class DateField extends React.Component<P> {

  setDateTime = (date: Date | null) => {
    const { setValue, time } = this.props;

    if (date == null)
      setValue(undefined);
    else {

      // If we're dealing with datetime, store value in UTC
      // if not ignore timezone info

      if (time == true) {
        setToWorkHours(date);
        const f = formatISO(date);
        setValue(f);
      } else {

        // Date from datepicker comes with timezone adjustment, ie 22:00T-02:00
        // This attemts to fix that
        const isoDate = toISO8601Date(date);
        setValue(isoDate);
      }
    }
  }

  render() {
    const { id, autoFocus, time, setTouched, setValue, value, filterWorkDays, minDate, openToDate, numberOfMonths } = this.props;

    let v = value == null ? undefined : new Date(value);
    if (v && v.getFullYear() < 2000)
      v = undefined;

    if (openToDate)
      setToWorkHours(openToDate);

    return <DateSelector
      id={id}
      className={classNames("datepicker-input", { "datepicker-input-with-time": time == true })}
      minDate={minDate}
      showWeekNumbers
      placeholderText="Klikk for å velge dato"
      onChange={this.setDateTime}
      timeFormat="HH:mm"
      timeIntervals={30}
      timeCaption="klokke"
      selected={v}
      monthsShown={numberOfMonths}
      filterTime={t => t.getHours() >= 7 && t.getHours() <= 15 && !(t.getHours() == 15 && t.getMinutes() > 0)}
      openToDate={openToDate}
      todayButton="I dag"
      dateFormat={time ? "dd. MMMM yyyy HH:mm" : "dd. MMMM yyyy"}
      filterDate={filterWorkDays ? isWorkDate : undefined}
      onBlur={() => setTouched()}
      autoFocus={autoFocus}
      showTimeSelect={time}
    />
  }
}
const wrapped = Wrap<P, string>(DateField);

export {
  wrapped as DateField
}

function setToWorkHours(date: Date) {
  if (date.getHours() < 7)
    date.setHours(7);
  else if (date.getHours() >= 15)
    date.setHours(15, 0, 0, 0);
}