import * as React from "react";
import { inject } from "mobx-react";
import { IRootStore } from "../../stores/root-store";
import classNames from "classnames";
import "./hamburger.scss";
import MenuCloseIcon from "svg/menuClose.svg";
import MenuOpenIcon from "svg/menuOpen.svg";

interface StoreProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

@inject<IRootStore, null, StoreProps, null>(stores => ({
  isOpen: stores.UIStore.menuIsOpen,
  toggleMenu: () => {
    stores.UIStore.menuIsOpen = !stores.UIStore.menuIsOpen
  }
}))
export class Hamburger extends React.Component<Partial<StoreProps>> {
  render() {
    const isOpen = this.props.isOpen!;
    return (<span onClick={e => this.props.toggleMenu!()} className="header-menu-toggle">
      {isOpen && <MenuCloseIcon className="icon" />}
      {!isOpen && <MenuOpenIcon className="icon" />}
    </span>);
  }
}