import * as React from "react";
import { Form } from "../../../components/forms/form";
import { TextAreaField } from "../../../components/forms/fields/TextAreaField";
import { InputField } from "../../../components/forms/fields/InputField";
import { DateField } from "../../../components/forms/fields/DateField";
import { EnumField } from "../../../components/forms/fields/EnumField";
import { IValidationRules, FormStore } from "../../../stores/form-store";
import { observer } from "mobx-react";
import { Enums } from "ts/stores/lookup-store";
import { BooleanField } from "ts/components/forms/fields/BooleanField";
import { FormActions } from "ts/components/forms/FormActions";
import { CustomerField } from "ts/components/forms/fields/CustomerField";
import { Pending } from "../../../components/shared/Pending";
import { PageAlertEditModel } from "../../../models/shared/PageAlertEditModel";
import { isRequired } from "../../../core/validationHelpers";

interface Props {
  id?: number
}

@observer
export class PageAlertEditForm extends React.Component<Props> {
  store: FormStore<PageAlertEditModel>;

  constructor(props: any) {
    super(props);

    const validationRules: IValidationRules<PageAlertEditModel> = {
      AlertType: [isRequired("Du må oppgi et varseltype.")],
      Message: [isRequired("Du må oppgi en melding.")],
    };

    this.store = new FormStore<PageAlertEditModel>(validationRules, { Removable: true });

    if (this.props.id)
      this.store.loadModel("pagealerts", this.props.id);
  }


  render() {

    if (this.store.isEdit && !this.store.modelHasLoaded)
      return <Pending />;

    return (
      <Form
        store={this.store}
        name="Sidevarsel"
        redirectToList={true}
        className="edit-form edit-form-padding">
        <CustomerField<PageAlertEditModel>
          field="CustomerId"
          label="Kunde"
          helpText="Spesifiser om varselet kun skal sees av én kunde."
        />
        <InputField<PageAlertEditModel>
          field="Title"
          label="Tittel"
          helpText="Her kan om ønsklig legge til en overskrift for varselet."
        />
        <TextAreaField<PageAlertEditModel>
          field="Message"
          label="Melding"
          helpText="Teksten som skal vises i varselet." />
        <BooleanField<PageAlertEditModel>
          field="Public"
          label="På webside"
          helpText="Indikerer om varselet også skal vises på hovedwebsiden, www.råzink.no."
          showIf={() => false}
        />
        <BooleanField<PageAlertEditModel>
          field="Removable"
          label="Kan fjernes"
          helpText="Indikerer om varselet kan fjernes av brukeren ved å trykke på en lukkeknapp."
        />
        <DateField<PageAlertEditModel>
          field="Start"
          label="Start"
          time={true}
          numberOfMonths={2}
          helpText="Her fyller du inn dato for når varselet skal vises."
          minDate={new Date()}
        />
        <DateField<PageAlertEditModel>
          field="End"
          label="Slutt"
          time={true}
          numberOfMonths={2}
          helpText="Her fyller du inn dato for når varselet ikke lengre skal vises."
          minDate={new Date()}
        />
        <EnumField<PageAlertEditModel>
          field="AlertType"
          sort={false}
          enumType={Enums.PageAlertType}
          label="Varseltype"
          required={true}
          helpText="Velg type varsel."
        />
        <FormActions />
      </Form>)
  }
}
