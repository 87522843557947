import * as React from "react";
import "./tabPanel.scss";

interface Props {
  initialSelectedTab?: number;
}

interface State {
  selectedTabIndex: number;
}

export class TabPanel extends React.Component<React.PropsWithChildren<Props>, State> {

  state = { selectedTabIndex: this.props.initialSelectedTab ?? 0 };

  render() {
    const { children } = this.props;
    const { selectedTabIndex } = this.state;
    const tabs = React.Children.toArray(children);
    if (tabs.length === 0)
      return null;

    const activePanelComponent = (tabs[selectedTabIndex] as any).props.children;
    return (<div>
      <ul role="tablist">
        {tabs.map((child, index) => (
          <li
            role="tab"
            tabIndex={0}
            key={index}
            {...clickOrKey(() => this.setState({ selectedTabIndex: index }))}
            aria-selected={index === selectedTabIndex}>{child}</li>
        ))}
      </ul>
      <div role="tabpanel">
        {activePanelComponent}
      </div>
    </div>
    )
  }
}

function clickOrKey(fn: () => void): { onClick: React.MouseEventHandler<any>, onKeyPress: React.KeyboardEventHandler<any>; } {
  return ({
    onClick: e => fn(),
    onKeyPress: e => {
      if (e.key == "Enter" || e.key == " " || e.key == "Spacebar")
        fn();
    }
  });
}