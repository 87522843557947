/**  */
export enum ExtraWorkType {
  
  /** */
  DrillingHoles = 1,  
  /** */
  PaintRemoval = 2,  
  /** */
  Other = 10
}

