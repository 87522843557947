import * as React from "react";

interface Props {
  title: string;
  count?: number;
}

export class Tab extends React.Component<React.PropsWithChildren<Props>> {
  render() {
    if (this.props.count != null && this.props.count > 0) 
      return <span>{this.props.title} ({this.props.count})</span>;
    else
      return <span>{this.props.title}</span>;
  }
}