import * as React from "react";
import { v4 as guid } from "uuid";
import { BaseEditModel } from "../../models/shared/BaseEditModel";
import classNames from "classnames";
import { FormConsumer } from "./form";
import { Observer } from "mobx-react";
import { omit as _omit } from "lodash-es";
import { FormStore } from "../../stores/form-store";
import InfoIcon from "svg/info.svg";

export interface PropsFromWrapperToField<TValue> {
  value?: TValue;
  setValue: (value: TValue | undefined) => void;
  setTouched: () => void;
  id: string;
  customerId?: number;
  store: FormStore<any>
  autoFocus?: boolean;
  refFn?: React.RefObject<any>;
  disabled?: boolean;
}

interface GenericFieldProps<T, TValue> {
  field: keyof T;
  label: string;
  helpText?: string;
  showIf?: (values: Partial<T>, customerId?: number, value?: TValue) => boolean;
  disabled?: (values: Partial<T>, customerId?: number, value?: TValue) => boolean;
  autoFocus?: boolean;
  refFn?: React.RefObject<any>;
  additionalInfo?: (values: Partial<T>) => React.ReactElement | null;
  innerContent?: React.ReactNode;
}

//type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

type CompontentType<T> = React.ComponentClass<T> | React.FunctionComponent<T>;
/*
export function OldWrap<TFieldProps extends PropsFromWrapperToField<TValue>, TValue>(_wrappedComponent: CompontentType<TFieldProps>) {

  return class <TModel>
    extends React.Component<GenericFieldProps<TModel, TValue> & Omit<TFieldProps, keyof PropsFromWrapperToField<TValue>>, { showMobileInfo: boolean }> {

    private id: string = guid();
    state = { showMobileInfo: false };

    toggleMobileInfo = (visible?: boolean) => {
      this.setState(state => ({ showMobileInfo: visible ? visible : !state.showMobileInfo }));
    }

    render() {

      const { label, helpText, autoFocus, field, showIf, additionalInfo, disabled, innerContent } = this.props as GenericFieldProps<TModel, TValue>;
      const { showMobileInfo } = this.state;
      const required = false;

      // Redefine typings
      const WrappedComponent = _wrappedComponent as any as React.ComponentClass<PropsFromWrapperToField<TValue> & { [key: string]: any }>;


      const propsFromFieldToComponent = _omit(this.props, ["field", "label", "helpText", "showIf", "autoFocus", "disabled", "innerContent"]);


      return (
        <FormConsumer>
          {({ store, customerId }) =>
            <Observer>
              {() => {
                if (showIf && !showIf(store.values as Partial<TModel>, customerId, store.getValue(field)))
                  return null;

                const isDisabled = disabled == null ? undefined : disabled(store.values as Partial<TModel>, customerId, store.getValue(field));

                const errors = store.validationState[field.toString()];

                return (
                  <div className="form-field">
                    <div className={classNames("form-field-descripion", { "required": required })}>
                      <label className="form-field-label" htmlFor={this.id}>{label}</label>
                      {helpText && <div className="form-field-help-container">
                        <div
                          className="form-field-help-icon"
                          onTouchStart={(e) => this.toggleMobileInfo(true)}
                          onTouchEnd={() => this.toggleMobileInfo(false)}>
                          <InfoIcon />
                        </div>
                        <span className={classNames("form-field-help-text", { "hidden": !showMobileInfo, visible: showMobileInfo })}>
                          <span dangerouslySetInnerHTML={{ __html: helpText.toString() }} />
                        </span>
                      </div>
                      }
                    </div>
                    <div className={classNames("form-field-component", { "has-errors": errors && errors.length > 0 })}>

                      <WrappedComponent
                        id={this.id}
                        autoFocus={autoFocus}
                        refFn={this.props.refFn!}
                        customerId={customerId}
                        setTouched={() => store.setTouched(field)}
                        setValue={v => store.setValue(field, v)}
                        value={store.getValue(field)}
                        store={store as FormStore<TModel>}
                        disabled={isDisabled}
                        {...propsFromFieldToComponent}
                      />
                      <ValidationErrors<TModel> field={field} />
                      {innerContent}
                    </div>
                    <div className="form-field-information">{additionalInfo && additionalInfo(store.values)}</div>
                  </div>
                ) as any;
              }}
            </Observer>
          }
        </FormConsumer>
      );
    }
  }
}
*/
class ValidationErrors<T> extends React.Component<{ field: keyof T }> {
  render() {
    const { field } = this.props;
    return (
      <FormConsumer>
        {({ store }) => (
          <Observer>
            {() => {
              const errors = store.validationState[field.toString()];
              if (errors == null || errors.length == 0)
                return null;

              return (
                <ul role="alert" className="form-component-errors">
                  {errors.map((text, i) => <li key={i}><span>{text}</span></li>)}
                </ul>
              ) as any;
            }}
          </Observer>)}
      </FormConsumer>
    )
  }
}

export function Wrap<TFieldProps extends PropsFromWrapperToField<TValue>, TValue>(_wrappedComponent: CompontentType<TFieldProps>) {

  return function <TModel>(props: GenericFieldProps<TModel, TValue> & Omit<TFieldProps, keyof PropsFromWrapperToField<TValue>>) {

    const [showMobileInfo, setShowMobileInfo] = React.useState(false);

    const id = React.useId();
    const { label, helpText, autoFocus, field, showIf, additionalInfo, disabled, innerContent } = props;
    const propsFromFieldToComponent = _omit(props, ["field", "label", "helpText", "showIf", "autoFocus", "disabled", "innerContent"]);

    return <FormConsumer>
      {({ store, customerId }) =>
        <Observer>
          {() => {
            if (showIf && !showIf(store.values as Partial<TModel>, customerId, store.getValue(field)))
              return null;

            const isDisabled = disabled == null ? undefined : disabled(store.values as Partial<TModel>, customerId, store.getValue(field));

            const errors = store.validationState[field.toString()];
            const WrappedComponent = _wrappedComponent as any as React.ComponentClass<PropsFromWrapperToField<TValue> & { [key: string]: any }>;

            return (
              <div className="form-field">
                <div className={classNames("form-field-descripion", { "required": false })}>
                  <label className="form-field-label" htmlFor={id} id={`${id}-label`}>{label}</label>
                  {helpText && <div className="form-field-help-container">
                    <div
                      className="form-field-help-icon"
                      onTouchStart={(e) => setShowMobileInfo(true)}
                      onTouchEnd={() => setShowMobileInfo(false)}>
                      <InfoIcon />
                    </div>
                    <span className={classNames("form-field-help-text", { "hidden": !showMobileInfo, visible: showMobileInfo })}>
                      <span dangerouslySetInnerHTML={{ __html: helpText.toString() }} />
                    </span>
                  </div>
                  }
                </div>
                <div className={classNames("form-field-component", { "has-errors": errors && errors.length > 0 })}>
                  <WrappedComponent
                    id={id}
                    autoFocus={autoFocus}
                    refFn={props.refFn!}
                    customerId={customerId}
                    setTouched={() => store.setTouched(field)}
                    setValue={(v: TValue | undefined) => store.setValue(field, v)}
                    value={store.getValue(field)}
                    store={store as FormStore<TModel>}
                    disabled={isDisabled}
                    {...propsFromFieldToComponent}
                  />
                  <ValidationErrors<TModel> field={field} />
                  {innerContent}
                </div>
                <div className="form-field-information">{additionalInfo && additionalInfo(store.values)}</div>
              </div>
            )
          }}
        </Observer>
      }
    </FormConsumer>
  }
}