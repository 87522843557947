/**  */
export enum CorrosivityCategory {
  
  /** */
  C1 = 1,  
  /** */
  C2 = 2,  
  /** */
  C3 = 3,  
  /** */
  C4 = 4,  
  /** */
  C5 = 5,  
  /** */
  CX = 6
}

