import * as React from 'react';
import { default as RootStore } from "ts/stores/root-store";
import { IonIcon } from '@ionic/react';
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { PropsFromWrapperToField, Wrap } from '../../../components/forms/wrapField';
import { InputWithFix } from '../../../components/shared/InputWithFix';
import { BaseLookupModel } from '../../../models/shared/BaseLookupModel';
import { PalletWeight } from '../../../models/shared/PalletWeight';
import { Enums } from '../../../stores/lookup-store';
import "./PalletField.tsx.scss";

interface OwnProps { }
type ValueType = Array<PalletWeight>;
type PP = PropsFromWrapperToField<ValueType> & OwnProps;

const PalletField = Wrap<PP, ValueType>(({ value, setValue, id }) => {
  const [pallets, setPallets] = useState<Array<BaseLookupModel>>([]);
  const [annenValue, setAnnenValue] = useState(value?.find(f => f.Pallet == 99)?.CustomWeight?.toString() ?? "");

  useEffect(() => {
    RootStore.LookupStore.getEnums(Enums.Pallet, false).then(v => setPallets(v ?? []));
  }, []);

  const increase = (id: number) => {
    let copy = [...(value ?? []).filter(p => p.Pallet > 0)];

    let current: PalletWeight | undefined = copy.find(f => f.Pallet == id);
    if (current == null) {
      current = { Pallet: id, Count: 0 };
      copy = [...copy, current];
    }

    current.Count = current.Count + 1;
    setValue(copy);
  }

  const decrease = (id: number) => {
    let copy = [...(value ?? [])];

    let current: PalletWeight | undefined = copy.find(f => f.Pallet == id);
    if (current == null)
      return

    if (current.Count == 1) {
      setValue(copy.filter(c => c.Pallet != id));
    } else {
      current.Count = current.Count - 1;
      setValue(copy);
    }
  }

  useEffect(() => {
    const num = parseInt(annenValue);
    let id = 99;
    let copy = [...(value ?? [])];

    let current: PalletWeight | undefined = copy.find(f => f.Pallet == id);
    if (current == null) {
      current = { Pallet: id, Count: 1 };
      copy = [...copy, current];
    }
    current.CustomWeight = num;

    if (num == 0 || isNaN(num)) {
      setValue(copy.filter(c => c.Pallet != id));
    } else {
      setValue(copy);
    }
  }, [annenValue]);

  return <table className="pallet-weight-field" id={id}><tbody>
    {pallets.filter(i => i.Id != 99).map(p => <tr key={p.Id}>
      <td>{p.Name}</td>
      <td><InputWithFix postfix="stk" value={value?.find(pp => pp.Pallet === p.Id)?.Count ?? 0} readOnly type="number" /></td>
      <td><IonIcon icon={addCircleOutline} onClick={() => increase(p.Id)} size="large" className="icon" /></td>
      <td><IonIcon icon={removeCircleOutline} onClick={() => decrease(p.Id)} size="large" className="icon" /></td>
    </tr>)}
    <tr>
      <td>Annen</td>
      <td><InputWithFix
        postfix="kg" value={annenValue}
        className="short-input"
        onChange={e => setAnnenValue(e.target.value)} />
      </td>
      <td />
      <td />
    </tr>
  </tbody></table>
});

export { PalletField };