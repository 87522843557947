import React, { useEffect } from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import { UseComboboxStateChangeTypes, useCombobox } from "downshift";
import "./TypeaheadField.tsx.scss";
import { useQuery } from "@tanstack/react-query";
import { Network } from "../../../api/network";
import { useOnEntityChange } from "../../../core/hooks/useOnEntityChange";

interface OwnProps {
}

type P = PropsFromWrapperToField<string> & OwnProps;

const TypeAheadField: React.FC<P> = ({ id, customerId, value, setValue, setTouched, autoFocus, disabled }) => {

  // Hent liste over referanser for kunde
  const { data: references, refetch } = useQuery({
    queryKey: ["references_" + customerId],
    queryFn: () => new Network().getSimple<string[]>("/api/customers/" + customerId + "/references"),
    enabled: customerId != null,
    staleTime: 1000 * 60 * 5
  });

  // Oppdater liste dersom en arbeidsordre/ordre endres
  useOnEntityChange(["WorkOrderEntity", "OrderEntity"], () => refetch());

  // Filtrert oversikt over referanser som vises i dropdown
  const [items, setItems] = React.useState<string[]>([]);
  useEffect(() => setItems(references ?? []), [references]);

  const { isOpen, getInputProps, getMenuProps, getItemProps } = useCombobox({
    onInputValueChange({ inputValue }) {
      setItems(references?.filter(w => w.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) ?? []);
    },
    labelId: id + "-label",
    inputId: id,
    items,
    inputValue: value ?? "",
    onStateChange: (state) => {
      if (state.type === useCombobox.stateChangeTypes.InputChange || state.type === useCombobox.stateChangeTypes.ItemClick)
        setValue(state.inputValue ?? "");
    }
  })

  return <>
    <input
      placeholder="Faktura eller prosjektreferanse"
      onBlur={_ => setTouched()}
      autoFocus={autoFocus}
      disabled={disabled}
      lang="no"
      {...getInputProps({
        onKeyDown: event => {
          if (event.key === 'Home' || event.key === 'End')
            (event.nativeEvent as any).preventDownshiftDefault = true;
        }
      })} />
    <ul {...getMenuProps()} className={`typeahead-popup ${isOpen && items.length > 0 ? "" : "hidden"}`}>
      {isOpen && items.map((item, index) => (
        <li key={item} {...getItemProps({ item, index })}>{item}</li>
      ))}
    </ul>
  </>
}

const Wrapped = Wrap<P, string>(TypeAheadField);

export {
  Wrapped as TypeAheadField
};
