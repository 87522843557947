import { Form } from "ts/components/forms/form";
import * as React from "react";
import { FormStore } from "ts/stores/form-store";
import { InvoiceLineEditModel } from "ts/models/shared/InvoiceLineEditModel";
import { SelectField } from "ts/components/forms/fields/SelectField";
import { InputField } from "ts/components/forms/fields/InputField";
import { FormActions } from "ts/components/forms/FormActions";
import { observer } from "mobx-react";
import type { IObservableArray } from "mobx";
import { observable } from "mobx";
import { isRequired } from "ts/core/validationHelpers";
import { Network } from "../../../../api/network";
import { ArticleListModel } from "../../../../models/shared/ArticleListModel";
import { EntityType } from "../../../../core/stringHelpers";

type P = {
  id?: number;
  invoiceId: number;
  workOrderId: number;
  onCloseFn?: () => void;
}

@observer
export class EditInvoiceLineItemDialog extends React.Component<P> {

  store: FormStore<InvoiceLineEditModel>;
  @observable articles: IObservableArray<ArticleListModel>;

  constructor(props: P) {
    super(props);

    this.store = new FormStore<InvoiceLineEditModel>({
      ArticleId: [isRequired("Du må velge en artikkel.")],
      Amount: [isRequired("Du må oppgi et antall.")],
      Discount: [(v => v > 100 || v < 0 ? "Avslag må være mellom 0 og 100 prosent." : null)]
    }, {
      Discount: 0,
      InvoiceId: props.invoiceId,
      Id: props.id
    }, (field, value, values) => {
        

    });

    if (props.id)
      this.store.loadModel("invoices/item", props.id);

    this.articles = observable([]);
  }


  async componentDidMount() {
    const network = new Network();
    const result = await network.get<Array<ArticleListModel>>(`/api/articles/ArticlesForWorkOrder/${this.props.workOrderId}`);
    if (result)
      this.articles.replace(
        result
          .sort((a, b) => a.Id - b.Id)
      );
  }

  render() {
    const { id, onCloseFn } = this.props;

    if (id && !this.store.modelHasLoaded)
      return null;

    if (this.articles == null || this.articles.length === 0)
      return null;

    const isVariablePriceArticle = (articleId: number | undefined) =>
      articleId === 19 || articleId === 18 || articleId === 21 || articleId === 22 || articleId === 23 || articleId === 25 || articleId === 28;

    const postFix = (articleId?: number) => articleId ? this.articles.find(a => a.Id == articleId)!.PostFix : undefined;

    return <Form
      name=""
      cancelable={true}
      onSubmit={async (router) => {
        const id = await this.store.postForm("/api/invoices/updateLine");
        if (id)
          onCloseFn!();
      }}
      onCancel={() => onCloseFn!()}
      store={this.store}>
      <SelectField<InvoiceLineEditModel>
        field="ArticleId"
        helpText="Spesifiser type artikkel for fakturalinjen"
        label="Artikkel">
        <option value="">-- Velg artikkel --</option>
        {this.articles.map(a => <option key={a.Id} value={a.Id}>{a.Title}</option>)}
      </SelectField>
      <InputField<InvoiceLineEditModel>
        field="Text"
        label="Tekst"
        helpText="Beskrivende tekst for fakturalinjen" />
      <InputField<InvoiceLineEditModel>
        field="Amount"
        label="Antall"
        type="number"
        postfix={postFix(this.store.values.ArticleId)}
        inputMode="numeric"
        helpText="Beskrivende tekst for fakturalinjen" />
      <InputField<InvoiceLineEditModel>
        field="Discount"
        label="Rabatt"
        type="number"
        inputMode="numeric"
        postfix="%"
        helpText="Eventuell avslag i prosent" />
      <InputField<InvoiceLineEditModel>
        field="Price"
        label="Pris"
        postfix="kr"
        type="number"
        inputMode="decimal"
        showIf={(row) => isVariablePriceArticle(row.ArticleId)}
        helpText="Pris per enhet" />
      <FormActions />
    </Form>
  }
}