import * as React from "react";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { RouteComponentProps } from "react-router";
import { OfferList } from "./OfferList";
import { OfferEditForm } from "./OfferEditForm";

interface Props {
  route: RouteComponentProps<{}>;
}

export class OfferPage extends React.Component<Props> {
  render() {
    return <WorkSpace
      singular="tilbud"
      plural="tilbud"
      routeBasePath={this.props.route.location.pathname.split("/")[1]}
      {...this.props.route}
      editComponent={<OfferEditForm />}
      listComponent={<OfferList />}
    />
  }
}