/**  */
export enum MeasurementNumberOfParts {
  
  /** */
  One = 1,  
  /** */
  Two = 2,  
  /** */
  _To500 = 3,  
  /** */
  _501To1200 = 4,  
  /** */
  _1201To3200 = 5,  
  /** */
  _3201To10000 = 6,  
  /** */
  _Above10000 = 7
}

