import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { Network } from "../../api/network";
import { IChange, NotificationHub } from "../signalR";
import { useEffect } from "react";
import { control } from "leaflet";

export const useDataStore = <TResponse, TRequest = undefined>(url: string | { url: string, data: TRequest }, listner?: { entityName: string, entityId?: number }, options?: { refetchInterval?: number, enabled?: boolean }) => {

  const isGet = typeof url === "string";
  const key = isGet ? url : JSON.stringify(url);

  const queryResult = useQuery({
    queryKey: [key],
    queryFn: (settings) => {
      const network = new Network();
      const controller = settings.signal == null ? undefined : { signal: settings.signal } as AbortController;
      return (isGet ? network.get<TResponse>(url, controller) : network.post<TRequest, TResponse>(url.url, url.data, false, controller)) as Promise<TResponse>;
    },
    refetchOnWindowFocus: false,
    refetchInterval: options?.refetchInterval ?? 0,
    enabled: options?.enabled ?? true
  });

  const onChange = async (messages: Array<IChange>) => {
    if (listner == null)
      return;

    const shouldUpdate = messages.some(m => m.entityName === listner.entityName && (listner.entityId == null || listner.entityId == m.entityId));
    if (shouldUpdate)
      queryResult.refetch();
  }

  useEffect(() => {
    const key = NotificationHub.registerListener(onChange)
    return () => NotificationHub.removeListener(key);
  }, []);

  return queryResult;
}
