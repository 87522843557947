import * as React from "react";
import { observer } from "mobx-react";

type P = {}

@observer
export class TransportList extends React.Component<P> {
  render() {
    return null;
  }
}