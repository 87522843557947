import { sum } from "lodash-es";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Network } from "../../../api/network";
import { DetailsBlock } from "../../../components/details/DetailsBlock";
import { DetailsPanel } from "../../../components/details/DetailsPanel";
import { DetailValueField } from "../../../components/details/DetailValueField";
import { NumberWithPostfix } from "../../../components/shared/display";
import { Pending } from "../../../components/shared/Pending";
import { since, toDate, toDateWithHM } from "../../../core/dateHelpers";
import { EntityType, toSerialNumber } from "../../../core/stringHelpers";
import { EAccountingInvoiceModel } from "../../../models/shared/EAccountingInvoiceModel";
import "./dueinvoices.tsx.scss";
import { SentInvoiceModel } from "../../../models/shared/SentInvoiceModel";

type RemoteDataType = Array<Array<SentInvoiceModel>>;

export const DueInvoices: React.FC<{}> = ({ }) => {
  const [data, setData] = useState<RemoteDataType | null>(null);

  useEffect(() => {

    const task = async () => {
      const network = new Network();
      const result = await network.get<RemoteDataType>("/api/invoices/DueInvoices");
      if (result != null)
        setData(result);
    }
    task();
  }, []);

  if (data == null)
    return <div className="page-due-invoices"><div className="panel details-container"><Pending /></div></div>;

  const totalAmount = sum(data.flatMap(i => i.map(i => i.Amount)));


  return <div className="page-due-invoices">
    <DetailsPanel>
      <DetailsBlock title="Sammendrag">
        <DetailValueField title="Totalt" right><NumberWithPostfix post="kr" number={totalAmount} /></DetailValueField>
      </DetailsBlock>
    </DetailsPanel>
    <div className="list-area panel panel-padding">
      {data.map(g => <div className="invoice-group">
        <div className="invoice-group-header">
          <span>{g[0].CustomerName}</span>
          <span><NumberWithPostfix post="stk" number={g.length} /></span>
          <span><NumberWithPostfix post="kr" number={sum(g.map(i => i.Amount - (i.PartialPayment ?? 0)))} /></span>
        </div>
        {g.map(i => <div className="invoice-item">
          <span title={since(i.DueDate)}>{toDate(i.DueDate)}</span>
          <span className="f-grow">{i.Reference}</span>
          <span title={`Betalt ${i.PartialPayment} kr á ${i.Amount} kr`}>{i.PartialPayment! > 0 ? "Delbetalt" : ""}</span>
          <span>{i.PaymentReminderIssued ? "Purret" : ""}</span>
          <a href={i.ExternalUrl} target="_blank">{i.InvoiceNumber >= 7623 ? 'Tripletex' : 'eAccounting'} </a>
          {i.WorkOrderId && <Link to={`/workorders/${i.WorkOrderId}`}>Arbeidsordre {toSerialNumber(EntityType.WorkOrder, i.WorkOrderId)}</Link>}
          <span><NumberWithPostfix post="kr" number={i.Amount - (i.PartialPayment ?? 0)} /></span>
        </div>)}
      </div>)}
    </div>
  </div>
}