import * as React from "react";
import { WorkSpaceType } from "./WorkSpace";

interface Props {
  singular: string; plural: string; workSpaceType: WorkSpaceType
}
const DynamicPageTitle: React.FC<Props> = ({ singular, plural, workSpaceType }) => {

  let title: string = `Ukjent type: ${workSpaceType}`;

  switch (workSpaceType) {
    case "list":
      title = `Oversikt ${plural.toLowerCase()}`;
      break;
    case "create":
      title = `Opprett ${singular.toLowerCase()}`;
      break;
    case "details":
      title = `Detaljer ${singular.toLowerCase()}`;
      break;
    case "edit":
      title = `Rediger ${singular.toLowerCase()}`;
      break;
  }

  return <h1>{title}</h1>;
}

export { DynamicPageTitle };