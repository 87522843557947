import * as React from "react";
import DownShift, { ControllerStateAndHelpers } from "downshift";
import { Network } from "../../../api/network";
import "./organizationLookup.scss";
import { debounce, isArray } from "lodash-es";
import { CompanyEditInformation } from "./CustomerEditForm";

interface DataItem {
  orgId: string;
  name: string;
}

interface OwnState {
  isSearching: boolean;
  data: Array<DataItem>;
}

type OwnProps = {
  onSelect: (companyInformation: CompanyEditInformation) => void
}

class OrganizationLookup extends React.PureComponent<OwnProps, OwnState> {
  network: Network;
  networkController: AbortController;
  debouncedFn: ((phrase: string) => void);

  constructor(props: OwnProps) {
    super(props);
    this.state = { isSearching: false, data: [] };
    this.network = new Network();
    this.networkController = new AbortController();
    this.debouncedFn = debounce((phrase: string) => {
      this.setState({ isSearching: true }, async () => {
        try {
          // abort potential existing call
          this.networkController.abort();

          // create new controller
          this.networkController = new AbortController();

          const result = await this.network.get<any>(`/api/lookup/OrganizationSearch/${encodeURI(phrase)}/no`, this.networkController);
          this.setState({ data: result });
        } finally {
          this.setState({ isSearching: false });
        }
      });
    }, 250);
  }

  onChange(phrase: any) {
    this.debouncedFn(phrase);
  }

  onSelect = async (dataItem: DataItem) => {

    const result = await this.network.get<any>(`/api/lookup/OrganizationDetails/${encodeURI(dataItem.orgId)}`, this.networkController);
    if (result) {
      this.props.onSelect({
        orgId: result.orgId,
        line1: isArray(result.address) ? result.address[0] : result.address == null ? null : result.address,
        line2: isArray(result.address) && result.address.length > 1 ? result.address[1] : null,
        name: result.name,
        city: result.city,
        zip: result.zip
      });
    }
  }

  render() {
    const { isSearching, data } = this.state;
    return (
      <div className="customer-company-selector panel panel-padding">
        <h3>Finn firma</h3>
        <p>Skriv inn navn eller organisasjonsnummer på firmaet du ønsker å opprette. Velg så firma fra listen under.</p>
        <DownShift
          itemToString={(item: DataItem | null) => (item ? item.name : "")}
          onChange={(selectedItem: DataItem | null, stateAndHelpers: ControllerStateAndHelpers<DataItem>) => {
            if (selectedItem) {
              this.onSelect(selectedItem)
            }
          }
          }>
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
          }) => (
              <div>
                <input
                  {...getLabelProps()}
                  autoComplete="off"
                  autoFocus={true}
                  onChange={e => this.onChange(e.target.value)}
                />
                <ul {...getMenuProps()}>
                  {data &&
                    data
                      .map((item, index) => (
                        <li {...getItemProps({ key: item.orgId, index, item })}>
                          {`${item.name} (${item.orgId})`}
                        </li>
                      ))
                  }
                </ul>
              </div>
            )}
        </DownShift>
      </div >
    )
  }
}

export { OrganizationLookup }