import * as React from "react"
import { useEffect, useRef, useState } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components"
import { Network } from "../../../../api/network";
import { NumberWithPostfix } from "../../../../components/shared/display";
import { formatDate } from "../../../../core/dateHelpers";
import { DayPlanModel } from "../../../../models/shared/DayPlanModel";
import { WorkOrderState } from "../../../../models/shared/WorkOrderState";
import { WorkOrderPopupDetails } from "../WorkOrderPopupDetails";
import { CustomBreadCrumb } from "../../../../components/shared/BreadCrumb";
import { WorkOrderDayPlanModel } from "../../../../models/shared/WorkOrderDayPlanModel";
import { NotificationHub } from "../../../../core/signalR";
import { EntityType, toSerialNumber } from "../../../../core/stringHelpers";
import { OrderDayPlanModel } from "../../../../models/shared/OrderDayPlanModel";
import "./PlanPage.tsx.scss";
import { CorrosivityCategory } from "../../../../models/shared/CorrosivityCategory";

const Day = styled.div`  
  margin-bottom: 23px;
  background-color: #ffffff;
`;

const DayHeader = styled.div`
  background-color: #606060;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #fff;
`;

type R = Array<DayPlanModel>;

export const PlanPage: React.FC<{}> = () => {

  const [data, setData] = useState<R>([]);

  const refreshPlanData = async () => {
    const network = new Network();
    const result = await network.get<R>("/api/workorders/plan");
    if (result)
      setData(result);
  }

  useEffect(() => {
    // get data on initial load
    refreshPlanData();

    const reference = NotificationHub.registerListener(changes => {
      if (changes.some(c => c.entityName === "WorkOrderEntity" || c.entityName == "UnitEntity"))
        refreshPlanData();
    })

    return () => NotificationHub.removeListener(reference);

  }, []);


  return <div>
    <h1>Plan</h1>
    <CustomBreadCrumb links={[{ title: "Oversikt arbeidsordre", path: "/workorders" }, { title: "Plan", path: "/workorders/plan" }]} />
    {data.map(key =>
      <Day key={key.Date}>
        <DayHeader>
          <span>
            <>{key.DateText}</>
            {key.DateOffset! > 1 && <>{` (om ${key.DateOffset! - 1} arbeidsdag${key.DateOffset! > 2 ? "er" : ""})`}</>}
          </span>
          <span className="no-wrap"><NumberWithPostfix number={key.DueWeight} post="kg" /></span>
        </DayHeader>
        <List>
          {key.WorkOrders!.map(row =>
            <RowItem key={row.Id} item={row} />
          )}
          {key.Orders!.map(row => <OrderRowItem key={row.Id} item={row} />)}
        </List>
      </Day>
    )}

  </div>
}

const formatDager = (count: number) => {
  if (count === 0)
    return "I dag";
  else if (count == 1)
    return "1 dag"
  else
    return count + " dager";
}

const RowItem: React.FC<{ item: WorkOrderDayPlanModel }> = ({ item }) => {
  return <li style={{ "display": "flex", "padding": "0 10px" }} className={item.IsRush ? "row-workorder rush" : "row-workorder"}>
    <span className="item-id"><OrderId id={item.Id} /></span>
    <span className="item-name"><CustomerName item={item} /></span >
    <span className="item-invoice" title={item.InvoiceText}>{item.InvoiceText}</span >
    <span className="item-days">{formatDager(item.WorkDaysOnSite)}</span>
    <span className="item-state"><StateCell item={item} /></span>
    <span className="item-created"><Created item={item} /></span>
    <span className="item-weight"><Weight item={item} /></span>
    <span className="item-info"><span>{item.Information}</span></span>
  </li>
}

const OrderRowItem: React.FC<{ item: OrderDayPlanModel }> = ({ item }) => {
  return <li style={{ "display": "flex", "padding": "0 10px" }} className={item.IsRush ? "row-order rush" : "row-order"}>
    <span className="item-id"><Link to={`/orders/${item.Id}/edit`}>{toSerialNumber(EntityType.Order, item.Id)}</Link></span>
    <span className="item-name"><CustomerName item={item} /></span>
    <span className="item-invoice" title={item.InvoiceText}>{item.InvoiceText}</span>
    <span className="item-days">{formatDager(item.WorkDays)}</span>
    <span className="item-state"></span>
    <span className="item-created"></span>
    <span className="item-weight"><NumberWithPostfix number={item.EstimatedWeight} post="kg" /></span>
    <span className="item-info"><span>{item.Information}</span></span>
  </li>
}

const List = styled.ul`

  line-height: 1.5;
  & > :nth-child(2n) {
    background-color: rgba(234, 234, 234, 0.5);
  }
  & > :hover {
    background-color: #cdcdcd;
    color: #fff;
  }
  > li.rush {
    background-color: rgb(180 0 255 / 15%);
  }

  > li.row-order {
    background: antiquewhite;
    font-style: italic;
  }

  > li.row-order {
    margin-top: 15px;  
  }

  > li.row-order ~ .row-order {
    margin-top: 0;  
  }

`;

const Overnight = styled.span`
    background: #0cd30c;
    color: #fff;
    padding: 1px 7px 1px 5px;
    display: inline-block;
    margin: 1px;
    font-size: 0.75rem;
    border-radius: 3px;
    margin-left: 5px;
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: -0.5px
`;

const CorrosivityCategoryComponent = styled.div`
    background: #5a5a5a;
    color: #fff;
    padding: 1px 7px 1px 5px;
    display: inline-block;
    margin: 1px;
    font-size: 0.75rem;
    border-radius: 3px;
    margin-left: 5px;
`;

const DeliveryDateConfirmed = styled.div`
    background: #e53ee5;
    color: #fff;
    padding: 1px 7px 1px 5px;
    display: inline-block;
    margin: 1px;
    font-size: 0.75rem;
    border-radius: 3px;
    margin-left: 5px;
    font-weight: 500;
    letter-spacing: 0.2px;
`;

const OrderId: React.FC<{ id: any }> = ({ id }) =>
  <Link to={`/workorders/${id}/edit`}>{toSerialNumber(EntityType.WorkOrder, id)}</Link>

const CustomerName: React.FC<{ item: WorkOrderDayPlanModel | OrderDayPlanModel }> = ({ item }) =>
  <Link to={`/customers/${item.CustomerId}`}>{item.CustomerName}</Link>

const Created: React.FC<{ item: WorkOrderDayPlanModel }> = ({ item }) => {
  let text = moment(item.DeliveryDate).startOf('day').isBefore() ? null : formatDate(item.DeliveryDate, "HH:mm");
  let marker = item.AcidOverNight ? <Overnight title="Avsyres over natt">Syre natt</Overnight> : null;
  let corrosivity = item.Corrosivity === CorrosivityCategory.C3 ? null : <Corrosivity category={item.Corrosivity} />;
  let confirmed = item.IsDeliveryDateConfirmed ? <DeliveryDateConfirmed title="Avtalt med kunde">Avtalt</DeliveryDateConfirmed> : null;
  return <><span>{text}</span>{marker}{corrosivity}{confirmed}</>
}

const Corrosivity: React.FC<{ category: CorrosivityCategory }> = ({ category }) => {
  let text = category == CorrosivityCategory.CX ? "CX" : `C${category.toString()}`;
  return <CorrosivityCategoryComponent title="Korrosjonsklasse">{text}</CorrosivityCategoryComponent>
}

const WeightText = styled.span.attrs<{ percentage: number }, { percentage: number; color: string }>(props => ({
  color: "#d3d3d3",
  percentage: props.percentage
}))`
  background: ${props => `linear-gradient(270deg, ${props.color} 0%, ${props.color} ${props.percentage}%, rgba(255,255,255,0) ${props.percentage}%, rgba(255,255,255,0) 100%, rgba(255,255,255,1) 100%)`};
  display: flex;
  border-bottom: ${props => props.percentage > 0 ? `1px solid ${props.color}` : ""};
  justify-content: flex-end;
`;


const Weight: React.FC<{ item: WorkOrderDayPlanModel }> = ({ item }) => {

  if (item.Aggregate!.OutgoingWeight === 0)
    return <WeightText percentage={0}><NumberWithPostfix number={item.Aggregate!.IncomingWeight} post="kg" /></WeightText>

  const remaining = item.Aggregate!.IncomingWeight - item.Aggregate!.OutgoingWeight;
  let percentage = Math.min(100.0 / item.Aggregate!.IncomingWeight * item.Aggregate!.OutgoingWeight, 100);
  return <WeightText percentage={percentage} title={percentage.toString()}>
    <NumberWithPostfix number={remaining} post="kg" />&nbsp;av&nbsp;<NumberWithPostfix number={item.Aggregate!.IncomingWeight} post="kg" />
  </WeightText>
}

const StateCell: React.FC<{ item: WorkOrderDayPlanModel }> = ({ item }) => {

  const state = item.Aggregate!.State!;

  const [isMouseOver, setIsMouseOver] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);

  const onEnter: React.MouseEventHandler<HTMLElement> = (ev) => setIsMouseOver(true);
  const onLeave: React.MouseEventHandler<HTMLElement> = (ev) => setIsMouseOver(false);

  return <span
    className={`state-indicator state-bg-color-${state.StateType}`}
    onMouseEnter={state.StateType > WorkOrderState.Received ? onEnter : undefined}
    onMouseLeave={state.StateType > WorkOrderState.Received ? onLeave : undefined}
    ref={ref}
  >
    <Link to={`/workorders/${item.Id}`} style={{ "padding": "0 5px" }}>{state.Label}</Link>
    {isMouseOver && <WorkOrderPopupDetails workorder={item} element={ref.current} />}
  </span>
}