import * as React from "react";
import { ListProps } from "./List";
import { BaseModel } from "../../models/shared/BaseModel";
import { Alignment } from "./IListDefinition";
import { observer } from "mobx-react";
import classNames from "classnames";
import { SortDirection } from "../../models/shared/SortDirection";
import SvgSort from "svg/sort.svg";
import SvgEdit from "svg/edit.svg";
import "./table.scss";

import { DeleteOrUndeleteCell } from "./DeleteOrUndeleteCell";
import { Link } from "react-router-dom";
import { Pending } from "../shared/Pending";
import { default as RootStore } from "../../stores/root-store";
import { UserStore } from "../../stores/user-store";
import { Collapse } from "react-collapse";

import PlusIcon from "svg/plus.svg";
import MinusIcon from "svg/minus.svg";
import { observable } from "mobx";
import { getScreenSize, ScreenSize } from "../../core/util";

type P = ListProps<BaseModel>;

function getAlignmentClass(alignment?: Alignment) {
  if (alignment == null || alignment == Alignment.Left)
    return undefined
  else if (alignment == Alignment.Center)
    return "center";
  else
    return "right";
}



@observer
export class Table extends React.Component<P> {

  @observable expandState = new Map<number, boolean>();

  render() {
    const { configuration, store } = this.props;
    const rows = store.values && store.values.Result && store.values.Result ? store.values.Result : undefined;

    if (rows == null)
      return <div className="pending-request"><Pending /></div>
    else if (rows.length == 0)
      return <p className="list-no-hits">Ingen treff</p>

    const sortField = store.sortField;
    const isAdmin = RootStore.UserStore.isEmployeeAdministrator;
    const hasCollapsableContent = configuration.collapsableContent != null;
    let columnCount = configuration.columns.filter(f => f.hidden == null || f.hidden(RootStore.UserStore, store.filters) === false).length;
    if (configuration.editLink)
      columnCount++;
    if (isAdmin)
      columnCount++;

    const screenSize = getScreenSize();

    return (
      <table className="standard">
        <thead>
          <tr>
            {hasCollapsableContent && <th />}
            {configuration.columns
              .filter(f => f.hidden == null || f.hidden(RootStore.UserStore, store.filters) === false)
              .map((column, index) => <th
                key={index}
                className={classNames(
                  getAlignmentClass(column.alignment),
                  {
                    sortable: column.sortable,
                    asc: sortField != null && sortField.find(f => f.Id === getSortField(column))?.Direction == SortDirection.Asc,
                    desc: sortField != null && sortField.find(f => f.Id === getSortField(column))?.Direction == SortDirection.Desc,
                  })}
                onClick={_ => column.sortable && column.field && store.toggleSort(getSortField(column))}>
                <span title={typeof column.title === "function" ? column.title(ScreenSize.PC) : undefined}>{typeof column.title === "function" ? column.title(screenSize) : column.title}</span>
                {column.sortable && <SvgSort />}
              </th>)}
            {configuration.editLink && <th />}
            {isAdmin && <th />}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => <React.Fragment key={row.Id}><tr className={classNames({ "row-deleted": row.Deleted != null }, configuration.rowCssClass?.(row, RootStore.UserStore))}>
            {hasCollapsableContent && <ToggleIcon show={configuration.showCollapsableContentForRow && configuration.showCollapsableContentForRow(row, RootStore.UserStore)} expanded={this.expandState.get(row.Id)} onClick={e => this.expandState.set(row.Id, e)} />}
            {configuration.columns
              .filter(c => c.hidden == null || c.hidden(RootStore.UserStore, store.filters) === false)
              .map((column, index) =>
                React.cloneElement(column.render(row, column.field && row.hasOwnProperty(column.field) ? row[column.field as keyof BaseModel] : null, index, RootStore.UserStore), { key: index }))}
            <EditLinkCell editLink={configuration.editLink} row={row} />
            {isAdmin && <DeleteOrUndeleteCell row={row} store={store} singular={configuration.singular} showDelete={configuration.showDelete ?? (_ => true)} />}
          </tr>
            {hasCollapsableContent && <React.Fragment key={row.Id + "_cc"}>
              <tr></tr>
              <tr>
                <td className="collapsable-content-cell"></td>
                <td className="collapsable-content-cell" colSpan={columnCount}>
                  <Collapse isOpened={this.expandState.get(row.Id) || false}>
                    <div className="collapsable-content">
                      {configuration.collapsableContent && configuration.collapsableContent(row, RootStore.UserStore)}
                    </div>
                  </Collapse>
                </td>
              </tr>
            </React.Fragment>}
          </React.Fragment>
          )}
        </tbody>
        {configuration.footer && <tfoot>
          {configuration.footer(rows, RootStore.UserStore)}
        </tfoot>}
      </table>
    );
  }
}

const ToggleIcon: React.FC<{ show?: boolean; expanded: boolean | undefined, onClick: (state: boolean) => void }> = ({ show, expanded, onClick }) => {
  if (show == null || show === false)
    return <td />
  return <td><a className="collapsable-content-toggle" onClick={e => { e.preventDefault(); onClick(!expanded); }}>{expanded === true ? <MinusIcon /> : <PlusIcon />}</a></td>
}

const EditLinkCell: React.FC<{ editLink?: (row: BaseModel, user: UserStore) => string | null, row: BaseModel }> = ({ editLink, row }) => {
  const link = editLink?.(row, RootStore.UserStore);
  if (link == null)
    return null;
  else
    return <td className="center cell-action narrow"><Link to={link}><SvgEdit /></Link></td>;
}

const getSortField = (column: any) => column.sortField ?? column.field;