/**  */
export enum FileDocumentType {
  
  /** */
  Unknown = 0,  
  /** */
  Certificate = 1,  
  /** */
  CoatingThickness = 2,  
  /** */
  DeliveryNote = 3,  
  /** */
  Order = 4
}

