import * as React from "react";
import { FormStore } from "../../stores/form-store";
import { Form } from "../forms/form";
import { TransportOrderEditModel } from "../../models/shared/TransportOrderEditModel";
import { SelectField } from "../forms/fields/SelectField";
import { InputField } from "../forms/fields/InputField";
import { FilesField } from "../forms/fields/FilesField";
import { TransportRequestType } from "../../models/shared/TransportRequestType";
import { DateField } from "../forms/fields/DateField";
import { FormActions } from "../forms/FormActions";
import { default as RootStore } from "ts/stores/root-store";
import { Lookup, Enums } from "../../stores/lookup-store";
import { BaseLookupModel } from "../../models/shared/BaseLookupModel";
import { IObservableArray } from "mobx";
import { observer } from "mobx-react";
import { Pending } from "./Pending";
import { BooleanField } from "../forms/fields/BooleanField";
import { TextAreaField } from "../forms/fields/TextAreaField";
import { EnumField } from "../forms/fields/EnumField";
import { EntityType } from "../../core/stringHelpers";

interface Props {
  orderId?: number;
  workOrderId?: number;
  preselectedDate?: string;
  onCloseFn?: () => void;
}

interface Props { }
interface State { }

@observer
export class TransportOrderDialog extends React.Component<Props, State> {

  formStore: FormStore<TransportOrderEditModel>;
  carriers: IObservableArray<BaseLookupModel>;

  constructor(props: Props) {
    super(props);
    this.formStore = new FormStore<TransportOrderEditModel>({
      "CarrierId": [
        value => value === -2 ? "Velg transportør" : null
      ],
      "TransportType": [
        (value, values) => value == null && this.props.workOrderId ? "Du må anngi type transportbestilling" : null
      ]
    },
      {
        TransportType: props.orderId != null ? TransportRequestType.FromCustomer : undefined,
        TransportationDate: this.props.preselectedDate,
        WorkOrderId: this.props.workOrderId,
        OrderId: this.props.orderId,
        ReInvoice: true,
        CarrierId: -2
      });

    this.carriers = RootStore.LookupStore.getList(Lookup.Carriers);
  }


  render() {

    if (this.carriers == null || this.carriers.length == 0)
      return <Pending />

    return <div>
      <Form
        name="transport registering"
        entityType={EntityType.TransportOrder}
        cancelable={true}
        postBaseUrl="/api/transportOrders"
        onSuccess={_ => this.props.onCloseFn?.()}
        onCancel={_ => this.props.onCloseFn?.()}
        store={this.formStore}>
        <SelectField<TransportOrderEditModel>
          field="CarrierId"
          label="Transportør"
          helpText="Her velger du hvilken transportør som bestillingen er gjort hos.">
          <option value="-2">-- Velg transportør --</option>
          {this.carriers.map(c => <option key={c.Id} value={c.Id}>{c.Name}</option>)}
          <option value="">Annen</option>
        </SelectField>
        {this.props.workOrderId != null && <EnumField<TransportOrderEditModel>
          field="TransportType"
          label="Type"
          enumType={Enums.TransportRequestType}
          sort={true}
          required={true}
          helpText="Her velger du type transport." />}
        <InputField<TransportOrderEditModel>
          field="WayBillId"
          label="Fraktbrevnummer"
          helpText="Her fyller du inn fraktbrevnummeret." />
        <InputField<TransportOrderEditModel>
          field="CarrierReference"
          label="Referanse"
          helpText="Her fyller du transportørens referanse om oppgitt." />
        <BooleanField<TransportOrderEditModel>
          field="ReInvoice"
          label="Viderefakturere"
          helpText="Her krysser du av hvis transportkostnaden skal viderefaktureres kunde" />
        <DateField<TransportOrderEditModel>
          field="TransportationDate"
          label="Dato for transport"
          numberOfMonths={2}
          helpText="Her fyller du ut datoen transporten er bestilt til." />
        <InputField<TransportOrderEditModel>
          field="AgreedPrice"
          label="Avtalt pris"
          postfix="kr"
          inputMode="numeric"
          type="number"
          helpText="Her fyller du inn pris eks mva dersom den er forhåndsavtalt. Vil ikke automatisk bli fordelt utover fler bestillinger/ordre." />
        <TextAreaField<TransportOrderEditModel>
          field="Information"
          label="Informasjon"
          helpText="Eventuell informasjon som er viktig ifm transporten" />
        <FormActions submitText="Registrer" right />
      </Form>
    </div>
  }
}