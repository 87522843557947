/**  */
export enum RushRequestState {
  
  /** */
  Received = 0,  
  /** */
  Processing = 1,  
  /** */
  Completed = 2
}

