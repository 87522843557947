import { observable, computed, action, flow, runInAction, IObservableArray } from "mobx";
import { CustomerLookupModel } from "../models/shared/CustomerLookupModel";
import { Network } from "../api/network";
import { BaseLookupModel } from "../models/shared/BaseLookupModel";
import { IRootStore } from "./root-store";
import { NotificationHub } from "ts/core/signalR";

export interface Grouped {
  key: string;
  list: Array<BaseLookupModel>
}

export enum Lookup {
  Customers = "Customers",
  Articles = "Articles",
  Carriers = "Carriers"
}

export enum Enums {
  CommunicationType = "Logi.Proflyt.Data.Types.CommunicationType",
  GalvanizingStandard = "Logi.Proflyt.Data.Types.GalvanizingStandard",
  PaintType = "Logi.Proflyt.Data.Types.PaintType",
  AddressType = "Logi.Proflyt.Data.Types.AddressType",
  ArticleType = "Logi.Proflyt.Data.Types.ArticleType",
  ArticleUnit = "Logi.Proflyt.Data.Types.ArticleUnit",
  BoomType = "Logi.Proflyt.Data.Types.BoomType",
  NotificationType = "Logi.Proflyt.Data.Types.NotificationType",  
  TransportRequestType = "Logi.Proflyt.Data.Types.TransportRequestType",
  TransportUnitType = "Logi.Proflyt.Data.Types.TransportUnitType",
  CorrosivityCategory = "Logi.Proflyt.Data.Types.CorrosivityCategory",
  UnitState = "Logi.Proflyt.Data.Types.UnitState",
  ExtraWorkType = "Logi.Proflyt.Data.Types.ExtraWorkType",
  PageAlertType = "Logi.Proflyt.Data.Types.PageAlertType",
  OperatingCostType = "Logi.Proflyt.Data.Types.OperatingCostType",
  MeasurementGodsThickness = "Logi.Proflyt.Data.Types.MeasurementGodsThickness",
  MeasurementNumberOfParts = "Logi.Proflyt.Data.Types.MeasurementNumberOfParts",
  ComplaintState = "Logi.Proflyt.Data.Types.ComplaintState",
  Pallet = "Logi.Proflyt.Data.Types.Pallet",
  HourType = "Logi.Proflyt.Data.Types.HourType",
  Country = "Logi.Proflyt.Data.Types.Country",
  CardUsageType = "Logi.Proflyt.Models.CardUsageType",
  DeliveryType = "Logi.Proflyt.Data.Types.DeliveryType"
}

class LookupItem {
  items: IObservableArray<BaseLookupModel>;

  constructor(items: Array<BaseLookupModel>) {
    this.items = observable(this.internalSort(items));
  }

  replace(items: Array<BaseLookupModel>) {
    this.items.replace(this.internalSort(items));
  }

  private internalSort = (items: Array<BaseLookupModel>) => items.sort((a, b) => (a.Name || "").localeCompare((b.Name || ""), "nb-NO"));

  @computed get grouped(): Array<Grouped> {

    const lookup = new Map<string, Array<BaseLookupModel>>();

    for (var i = 0; i < this.items.length; i++) {
      const c = this.items[i];

      if (c.Name == null)
        continue;

      let char = c.Name[0].toUpperCase();
      if (char === 'A') {
        if (c.Name[1].toUpperCase() === 'A')
          char = 'Å';
      }

      if (!lookup.has(char))
        lookup.set(char, [c]);
      else
        lookup.get(char)!.push(c);
    }

    const alphabeth = Array.from(lookup.keys()).sort((a, b) => a.localeCompare(b, "nb-NO"));

    return alphabeth.map(a => ({
      key: a,
      list: lookup.get(a)!
    }));

  }

}

export class LookupStore {
  private _map: Map<Lookup, LookupItem>; // list of stuff where content can change, contacts etc.
  private _enums: Map<Enums, Array<BaseLookupModel>>; // fixed enums from server

  private _network: Network;
  private _initialized: boolean = false;

  constructor() {
    this._network = new Network();

    // Create a map of observable arrays which can be returned and monitored
    this._map = new Map();
    for (let key in Lookup)
      this._map.set(Lookup[key as Lookup], new LookupItem([]));

    this._enums = new Map();
    this.refreshOnChanges();
  }

  public async initialize(root: IRootStore) { return Promise.resolve(); }

  private initializeLookups = flow(function* (this: LookupStore) {
    this._initialized = true;

    for (let key in Lookup) {

      const lookupType: Lookup = Lookup[key as Lookup];
      const result = yield this._network.get<Array<BaseLookupModel>>(`/api/lookup/${lookupType}`)
      if (result != null) {
        const lookupItem = this._map.get(Lookup[key as Lookup])!;
        lookupItem.replace(result);
      }
    }
  });

  private async updateLookup(lookup: Lookup) {
    const result = await this._network.get<Array<BaseLookupModel>>(`/api/lookup/${lookup}`)
    if (result != null)
      runInAction(() => this._map.get(lookup)!.replace(result));
  }

  private refreshOnChanges() {

    const lookupEntities = [{
      entity: "CustomerEntity",
      lookup: Lookup.Customers
    }, {
      entity: "ArticleEntity",
      lookup: Lookup.Articles
    }, {
      entity: "CarrierEntity",
      lookup: Lookup.Carriers
    }];

    NotificationHub.registerListener((changes) => {
      lookupEntities.forEach(l => {
        if (changes.some(ch => ch.entityName === l.entity))
          this.updateLookup(l.lookup);
      });
    });
  }

  private getItems(lookup: Lookup) {

    if (!this._initialized)
      this.initializeLookups();

    const values = this._map.get(lookup)!;
    return values;
  }

  public async getEnums(_enum: Enums, sort = true) {
    if (this._enums.has(_enum))
      return this._enums.get(_enum);

    const result = await this._network.get<Array<BaseLookupModel>>(`/api/lookup/enum/${_enum}`);
    if (result != null) {
      if (sort)
        this._enums.set(_enum, result.sort((a, b) => (a.Name || "").localeCompare((b.Name || ""), "nb-NO")));
      else
        this._enums.set(_enum, result);
      return this._enums.get(_enum);
    }
    return undefined;
  }

  public getList<T extends BaseLookupModel = BaseLookupModel>(lookup: Lookup) {
    const lookupItem = this.getItems(lookup);
    return lookupItem.items as IObservableArray<T>;

  }

  public getGrouped(lookup: Lookup) {
    const lookupItem = this.getItems(lookup);
    return lookupItem.grouped;
  }

  public getItem(lookup: Lookup, id: number) {
    const store = this.getItems(lookup)
    const item = store.items.find(i => i.Id == id);
    if (item)
      return item.Name;
  }
}
