/**  */
export enum WorkOrderState {
  
  /** */
  Received = 100,  
  /** */
  Checked = 110,  
  /** */
  Production = 150,  
  /** */
  Stop = 200,  
  /** */
  Canceled = 210,  
  /** */
  Sandblast = 220,  
  /** */
  External = 260,  
  /** */
  Ready = 320,  
  /** */
  Delivered = 330,  
  /** */
  Paint = 360,  
  /** */
  Complaint = 380
}

