import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";

interface OwnProps {
  multiple?: boolean;
}

type P = React.PropsWithChildren<PropsFromWrapperToField<number | string> & OwnProps>;

class SelectField extends React.PureComponent<P> {

  // sets undefined or a number if possible
  setValue: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const value = e.target.value;
    const { setValue } = this.props;
    if (value == "" || value == "-1")
      setValue(undefined);
    else {
      var number = Number(value.replace(",", "."));
      if (isNaN(number))
        setValue(value);
      else
        setValue(number);
    }
  }

  render() {
    const { id, autoFocus, multiple, setTouched, value } = this.props;
    return (
      <select
        id={id}
        onChange={this.setValue}
        onBlur={_ => setTouched()}
        value={value != undefined ? value.toString() : ""}
        multiple={multiple}
        autoFocus={autoFocus}>
        {this.props.children}
      </select>
    );
  }
}

const wrapped = Wrap<P, number | string>(SelectField);

export {
  wrapped as SelectField,
  SelectField as SelectFieldInternal
}