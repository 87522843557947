import * as React from "react";
import { default as RootStore } from "ts/stores/root-store";
import { observer } from "mobx-react";
import { OfferEditModel } from "../../../models/shared/OfferEditModel";
import { FormStore, IValidationRules } from "../../../stores/form-store";
import { observable } from "mobx";
import type { IObservableArray } from "mobx";
import { Form } from "../../../components/forms/form";
import { CustomerField } from "../../../components/forms/fields/CustomerField";
import { InputField } from "../../../components/forms/fields/InputField";
import { TextAreaField } from "../../../components/forms/fields/TextAreaField";
import { DateField } from "../../../components/forms/fields/DateField";
import { BooleanField } from "../../../components/forms/fields/BooleanField";
import { FormActions } from "../../../components/forms/FormActions";
import { RepeatField } from "../../../components/forms/fields/RepeatField";
import { OfferItem } from "../../../models/shared/OfferItem";
import { InputWithFix } from "../../../components/shared/InputWithFix";
import { Lookup } from "../../../stores/lookup-store";
import { isNullOrWhitespace, toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { Button } from "../../../components/shared/Button";
import { CustomerLookupModel } from "../../../models/shared/CustomerLookupModel";
import { ArticleListModel } from "../../../models/shared/ArticleListModel";
import { Network } from "../../../api/network";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import { FormErrors } from "../../../components/forms/FormErrors";

interface Props {
  id?: number
}

interface State {
  fixedPrice?: number;
}

@observer
export class OfferEditForm extends React.Component<Props, State> {
  store: FormStore<OfferEditModel>;
  state: State = { fixedPrice: undefined };
  @observable articles: IObservableArray<BaseLookupModel>;

  constructor(props: any) {
    super(props);

    const validationRules: IValidationRules<OfferEditModel> = {
      "CustomerId": [(value) => value ? null : "Du må oppgi en kunde for å opprette et tilbud."],
      "Name": [(value) => isNullOrWhitespace(value) ? "Du må spesifisere et navn på tilbudet." : null],
      "ArticleItems": [value => (value == null || value.length == 0) ? "Du må sette pris på minst en artikkel i tilbudet." : null]
    };

    this.store = new FormStore<OfferEditModel>(validationRules, {
      AppliesToAll: false
    }, async (field, value, values) => {
      if (field == "CustomerId")
        await this.updateArticles(value);
    });

    this.articles = observable([]);
  }

  async componentDidMount() {
    if (this.props.id) {
      await this.store.loadModel("offers", this.props.id);
      await this.updateArticles(this.store.values.CustomerId!);
    }
  }

  async updateArticles(customerId: number) {
    const network = new Network();
    const result = await network.get<Array<ArticleListModel>>(`/api/articles/ArticlesForCustomer/${customerId}`);
    if (result)
      this.articles.replace(
        result
          .sort((a, b) => a.Id - b.Id)
          .map<BaseLookupModel>(a => ({ Id: a.Id, Name: a.Title }))
      );
  }

  setFixedPrice() {
    const price = this.state.fixedPrice;

    if (price == null || price == 0)
      return;

    var articles = this.store.values.ArticleItems || [];

    [1, 2, 3, 4, 5, 6, 7, 27].forEach(v => {
      articles = articles.filter(a => a.ArticleId != v);
      articles.push({ ArticleId: v, Price: price });
    });

    this.store.values.ArticleItems = articles;
    this.setState({ fixedPrice: undefined });
  }

  render() {

    const customers = RootStore
      .LookupStore
      .getList<CustomerLookupModel>(Lookup.Customers);


    let header = "Tilbud";
    if (this.props.id) {
      header += ` ${toSerialNumber(EntityType.Offer, this.props.id)}`;
      if (customers) {
        let customerId = this.store.values?.CustomerId;
        if (customerId != null) {
          let customerName = customers.find(c => c.Id == customerId)?.Name;
          if (customerName)
            header += ` - ${customerName}`;
        }
      }
    }

    const isCustomer = RootStore.UserStore.isCustomer;

    return (
      <div className="panel form-container">
        <Form
          store={this.store}
          name="Tilbud"
          entityType={EntityType.Offer}
          redirectToList={true}
          customerId={this.store.values.CustomerId}
          className="edit-form edit-form-padding">
          <h3>{header}</h3>
          <CustomerField<OfferEditModel>
            field="CustomerId"
            label="Kunde"
            helpText="Spesifiser kunde for dette tilbudet."
            showIf={(value) => !isCustomer && value.Id == null} />
          <InputField<OfferEditModel>
            field="Name"
            label="Navn"
            helpText="Beskrivende navn på tilbudet. Vises på faktura." />
          <TextAreaField<OfferEditModel>
            field="Description"
            label="Informasjon"
            helpText="Intern informasjon om tilbudet." />
          <DateField<OfferEditModel>
            field="StartDate"
            numberOfMonths={2}
            label="Oppstart"
            helpText="Dato fra når tilbudet skal være aktivt."
            time={false} />
          <DateField<OfferEditModel>
            field="EndDate"
            numberOfMonths={2}
            label="Avsluttes"
            helpText="Siste dato tilbudet skal være aktivt."
            time={false} />
          <BooleanField<OfferEditModel>
            field="AppliesToAll"
            label="Alle ordre"
            helpText="Indikerer om tilbudet automatisk skal påføres alle kundens ordre."
          />
          <InputField<OfferEditModel>
            field="CustomerReferencePattern"
            label="Kundereferansemønster"
            showIf={e => e.AppliesToAll === false}
            helpText="Tilbudet påføres automatisk alle kundens ordre hvor kundereferansen inneholder dette feltet." />
          <RepeatField<OfferEditModel>
            field="ArticleItems"
            addText="Ny artikkel"
            container={repeater =>
              <table>
                <thead>
                  <tr>
                    <th>Artikkel</th>
                    <th>Pris</th>
                    <th>Fra og med vekt</th>
                    <th>Til Vekt</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{repeater}</tbody>
              </table>
            }
            label="Artikler">
            {(item: OfferItem, onChange: (field: keyof OfferItem, value: any) => void, addComponent) => (
              <tr key={item.ArticleId}>
                <td><select value={item.ArticleId} onChange={e => onChange("ArticleId", e.target.value)}>
                  <option value="">-- Velg artikkel --</option>
                  {this.articles.map(a => <option key={a.Id} value={a.Id}>{a.Name}</option>)}
                </select>
                  {this.store.values.CustomerId == null && <ul role="alert" className="form-component-errors">
                    <li><span>Du må velge en kunde før du velger artikler</span></li>
                  </ul>}
                </td>
                <td><InputWithFix postfix="kr" value={item.Price || ""} useCurrencyInputField={true} onChange={e => onChange("Price", e.target.value == null ? 0 : parseFloat(e.target.value))} /></td>
                <td><InputWithFix type="number" value={item.FromWeight || ""} onChange={e => onChange("FromWeight", e.target.value)} postfix="kg" /></td>
                <td><InputWithFix type="number" value={item.ToWeight || ""} onChange={e => onChange("ToWeight", e.target.value)} postfix="kg" /></td>
                <td>{addComponent}</td>
              </tr>
            )}
          </RepeatField>
          <div className="form-field">
            <div className="form-field-descripion"></div>
            <div className="form-field-component">
              <div>
                <InputWithFix
                  postfix="kr"
                  value={this.state.fixedPrice || ""}
                  useCurrencyInputField={true}
                  onChange={e => this.setState({ fixedPrice: parseFloat(e.target.value) })} /><br />
                <Button text="Sett fastpris" onClick={e => this.setFixedPrice()} disabled={this.state.fixedPrice == null || this.state.fixedPrice == 0} />
              </div>
            </div>
          </div>
          <FormErrors type="Tilbudet" />
          <FormActions />
        </Form>
      </div>)
  }
}