import React from "react";
import { toast } from "react-hot-toast";
import "./Toasts.tsx.scss";

const successToast = (message: React.ReactNode) => {
  toast(t => <div className="toast toast-info">{message}</div>, { className: "toast success-toast", duration: 4000 });
}

const errorToast = (message: React.ReactNode) => {
  toast(t => <div className="toast toast-info">{message}</div>, { className: "toast error-toast", duration: 8000 });
}

const warningToast = (message: React.ReactNode) => {
  toast(t => <div className="toast toast-info">{message}</div>, { className: "toast warning-toast", duration: 6000 });
}

const infoToast = (message: React.ReactNode) => {
  toast(t => <div className="toast toast-info">{message}</div>, { className: "toast info-toast", duration: 4000 });
}

export { successToast, errorToast, warningToast, infoToast }