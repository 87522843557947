import * as React from "react";
import { FormStore } from "../../stores/form-store";
import { RouterStore } from "mobx-react-router";
import { inject } from "mobx-react";
import { BaseEditModel } from "../../models/shared/BaseEditModel";
import "./form.scss";
import { trimEnd } from "lodash-es";
import { successToast } from "../shared/Toasts";
import { EntityType, toSerialNumber } from "../../core/stringHelpers";

interface FormProps {
  store: FormStore<any>;
  onSubmit?: (router: RouterStore) => void;
  cancelable?: boolean
  cancelUrl?: string;
  name: string;
  entityType?: EntityType,
  customerId?: number
  /** Defaults to edit-form */
  className?: string;
  /** Indicates if the user should be redirected to the list view after submitting */
  redirectToList?: boolean;
  onCancel?: (router: RouterStore) => void;
  /** Lets you set the post url. Defaults to /api/[first-segment]/[add|update] */
  postBaseUrl?: string;
  onSuccess?: (router: RouterStore, id: number) => void;
}

export interface ContextProps {
  store: FormStore<BaseEditModel & any>;
  cancelable: boolean;
  onCancel: () => void;
  name: string;
  customerId?: number;
}

const { Provider, Consumer } = React.createContext<ContextProps>({} as ContextProps);
export { Consumer as FormConsumer };

type P = React.PropsWithChildren<FormProps & Partial<{ RouterStore: RouterStore }>>;

@inject("RouterStore")
export class Form extends React.Component<P> {

  constructor(props: P) {
    super(props);
    if (props.customerId)
      props.store.setValue("CustomerId", props.customerId);
  }

  onSubmit: React.FormEventHandler<any> = async e => {
    e.preventDefault();

    const router = this.props.RouterStore!;
    const { onSubmit, store, redirectToList, onSuccess } = this.props;

    if (onSubmit)
      onSubmit(router);
    else {

      // Create the post url
      const post = store.isEdit ? "update" : "add";
      const parts = router.location.pathname.split("/");
      let url;
      if (this.props.postBaseUrl != null)
        url = `${trimEnd(this.props.postBaseUrl, "/")}/${post}`;
      else if (parts.length > 1) {
        url = `/api/${parts[1]}/${post}`;
      } else
        return;

      const id = await store.postForm(url);
      if (id) {
        if (onSuccess != null)
          onSuccess(router, id);
        else if (redirectToList == undefined || !redirectToList)
          router.push(`/${parts[1]}/${id}`);
        else
          router.push(`/${parts[1]}`);
        successToast(`${store.isEdit ? "Oppdaterte" : "La til "} ${this.props.name.toLowerCase()} med id ${toSerialNumber(this.props.entityType, id)}.`);
      }
    }
  }

  onCancel = () => {
    const router = this.props.RouterStore!;
    const { cancelUrl, onCancel } = this.props;

    if (onCancel)
      onCancel(router)
    else if (cancelUrl)
      router.push(cancelUrl);
    else {
      const parts = router.location.pathname.split("/");
      if (parts.length > 1) {
        router.push(`/${parts[1]}`);
      }
    }
  }

  render() {

    const parameters: ContextProps = {
      store: this.props.store,
      cancelable: this.props.cancelable === undefined ? true : this.props.cancelable,
      onCancel: this.onCancel,
      name: this.props.name,
      customerId: this.props.customerId
    }

    return (
      <form onSubmit={this.onSubmit} className={this.props.className || "edit-form"}>
        <Provider value={parameters}>
          {this.props.children}
        </Provider>
      </form>
    )
  }
}
