import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import { NumberWithPostfix } from "../../shared/display";
import TrashIcon from "svg/trash.svg";
import CameraIcon from "svg/camera.svg";
import FileIcon from "svg/file.svg";
import PdfIcon from "svg/pdf.svg";
import EmailIcon from "svg/email.svg";
import classNames from "classnames";
import { ClientFileReferenceModel } from "../../../models/ClientFileReferenceModel";
import "./filesField.scss";
import { getImageUrl } from "../../../core/util";
import { ImageSize } from "../../../models/shared/ImageSize";
import { FileReferenceModel } from "../../../models/shared/FileReferenceModel";
import { FileDocumentType } from "../../../models/shared/FileDocumentType";

type C = Array<ClientFileReferenceModel>
interface P extends PropsFromWrapperToField<C> {
  multiple?: boolean
}

class FilesField extends React.Component<P, { mouseIsOverZone: boolean }> {

  state = { mouseIsOverZone: false };

  removeFile = (index: number) => {
    const { value, setValue } = this.props;
    if (value) {
      setValue([
        ...value.slice(0, index),
        ...value.slice(index + 1)
      ]);
    }
  }

  drop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
    const { value, setValue } = this.props;
    this.setState({ mouseIsOverZone: false });

    if (this.props.multiple === false && value != null && value.length >= 1)
      return;

    const tmp: Array<ClientFileReferenceModel> = [];

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i];
        if (item.kind === "file") {
          const file = e.dataTransfer.items[i].getAsFile();
          if (file) {
            tmp.push({ Name: file.name, Size: file.size, ContentType: file.type, File: file, DocumentType: FileDocumentType.Unknown });
          }
        } else if (item.kind === "string" && item.type === "text/uri-list") {
          item.getAsString(cb => {
            if (cb.toLowerCase().startsWith("http"))
              setValue([...(value || []), { Url: cb, Size: 0, DocumentType: FileDocumentType.Unknown }]);
          })
        }
      }
      if (tmp.length > 0)
        setValue([...(value || []), ...tmp]);
    }
  }

  select = (files: FileList | null) => {
    const { setTouched, setValue, value } = this.props;
    if (files) {
      const lst: Array<ClientFileReferenceModel> = [];
      for (var i = 0; i < files.length; i++) {
        lst.push({
          Name: files[i].name,
          ContentType: files[i].type,
          Size: files[i].size,
          File: files[i],
          DocumentType: FileDocumentType.Unknown
        });
      }
      setValue([...(value || []), ...lst]);
      setTouched();
    }
  }

  render() {
    const { id, autoFocus, setValue, value } = this.props;
    const multiple = this.props.multiple == null || this.props.multiple === true;

    return (
      <div className="field-files" id={id}>
        <ul>
          {value && value.map((file, index) =>
            <FileItem key={index} file={file} removeFile={() => this.removeFile(index)} />)}
        </ul>
        <div
          className={classNames("field-files-zone", { "is-over": this.state.mouseIsOverZone })}
          onDragOver={e => { e.preventDefault(); this.setState({ mouseIsOverZone: true }) }}
          onDragLeave={e => this.setState({ mouseIsOverZone: false })}
          onDragEnter={e => { e.dataTransfer.dropEffect = "copy" }}
          onDrop={this.drop}>
          Dra og slipp {multiple ? "filer" : "en fil"} her
        </div>
        <input type="file" multiple={multiple} onChange={e => this.select(e.target.files)} />
      </div>)

  }
}

const wrapped = Wrap<P, C>(FilesField);

export {
  wrapped as FilesField
}

const FileItem: React.FC<{
  file: ClientFileReferenceModel;
  removeFile: () => void;
}> = ({ file, removeFile }) => {
  return (
    <li>
      <FileIconComponent file={file} />
      <div>
        <span className="filename">{file.Name}</span>
        <NumberWithPostfix number={Math.round(file.Size / 1024)} post="kB" className="size" />
      </div>
      <span onClick={e => removeFile()} className="remove"><TrashIcon /></span>
    </li>);
}

export const FileIconComponent: React.FC<{ file: ClientFileReferenceModel | FileReferenceModel }> = ({ file }) => {
  const mime = file.ContentType?.toLowerCase();

  if (mime?.startsWith("image")) {
    if (file.Id)
      return <img src={getImageUrl(file, ImageSize.Preview)} alt={file.Name} />;
    else
      return <CameraIcon />;
  } 

  if (mime?.endsWith("pdf"))
    return <PdfIcon />;

  if (mime == "application/vnd.ms-outlook")
    return <EmailIcon />;

  return <FileIcon />;
}