import React from "react"
import { useDataStore } from "../../../../core/hooks/useDataStore"
import { default as RootStore } from "../../../../stores/root-store";
import { ListQuery } from "../../../../models/shared/ListQuery";
import { Pending } from "../../../../components/shared/Pending";
import { ListQueryResponse } from "../../../../models/shared/ListQueryResponse";
import { EntityType, toSerialNumber } from "../../../../core/stringHelpers";
import { OrderListModel } from "../../../../models/shared/OrderListModel";
import { NumberWithPostfix } from "../../../../components/shared/display";
import { toDate } from "../../../../core/dateHelpers";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { FilterType } from "../../../../models/shared/FilterType";

export const AktiveBestillinger: React.FC = () => {

  const { data: bestillinger, isFetching, isSuccess, isError } = useDataStore<ListQueryResponse<OrderListModel>, ListQuery>(
    { url: "/api/orders/list", data: { Filters: [{ Field: "HasWorkOrders", Value: false, FilterType: FilterType.TriStateBoolean}], Count: 10, Offset: 0, SortField: [{ Id: "Created", Direction: 1 }] } },
    { entityName: "OrderEntity" });

  return <div className="box w4">
    <h2>Aktive bestillinger</h2>
    {isError && <p>Feil ved henting av bestillinger</p>}
    {isFetching && <Pending />}
    {isSuccess && bestillinger?.Result != null &&
      <div className="table-container"><table className="full-width table-hand">
        <thead>
          <tr>
            <th>Id</th>
            <th>Referanse</th>
            <th className="right">Vekt</th>
            <th>Frakt</th>
            <th className="right">Dato</th>
          </tr>
        </thead>
        <tbody>
          {bestillinger.Result.map(b =>
            <tr key={b.Id} onClick={() => RootStore.RouterStore.push(`/orders/${b.Id}`) }>
              <td>{toSerialNumber(EntityType.Order, b.Id)}</td>
              <td>{b.InvoiceReference}</td>
              <td className="right"><NumberWithPostfix post="kg" decimals={0} number={b.EstimatedWeight} /></td>
              <td>{b.RequiresPickup ? "RåZink henter" : "Kunde leverer"}</td>
              <td className="right">{toDate(b.EstimatedDeliveryDate)}</td>
            </tr>)}
        </tbody>
      </table></div>}
    <NavLink className="info" to="/orders">Se alle bestillinger</NavLink>
  </div>
}
