/**  */
export enum PageAlertType {
  
  /** */
  Warning = 0,  
  /** */
  Information = 1,  
  /** */
  FirstUse = 2
}

