/**  */
export enum ImageSize {
  
  /** */
  Original = 0,  
  /**1440 x 1080 */
  Desktop = 1,  
  /**400 x 300 */
  Preview = 2
}

