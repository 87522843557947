import { NotificationHub } from "./signalR";

export enum LogLevel {
  Info,
  Warning,
  Error
}

export interface LogData {
  message: string;
  level: LogLevel;
  data: object;
}

export class Logger {
  static log(level: LogLevel, message: string, data?: object) {
    const result = Object.assign(data ?? {}, {
      PageCorrelationID: (window as any).PageCorrelationID,
      ClientPath: window.location.href
    })

    NotificationHub.sendLogData({
      message,
      level,
      data: result
    })
  }

  static info(message: string, data?: object) {
    Logger.log(LogLevel.Info, message, data);
  }
  static warn(message: string, data?: object) {
    Logger.log(LogLevel.Warning, message, { StackTrace: new Error().stack, ...(data ?? {}) });
  }
  static error(message: string, data?: object) {
    Logger.log(LogLevel.Error, message, { StackTrace: new Error().stack, ...(data ?? {}) });
  }
}

window.addEventListener("error", ev => {
  Logger.error(ev.message, ({
    Column: ev.colno,
    Line: ev.lineno,
    File: ev.filename,
    StackTrace: ev.error?.message +"\r\n" + ev.error?.stack
  }));
})

window.addEventListener("unhandledrejection", ev => {
  Logger.error(ev.reason);
})