import * as React from "react";
import "./detailvaluefield.scss";
import classNames from "classnames";

interface Props { title: string; right?: boolean, wrap?: boolean; long?: boolean, className?: string }

export const DetailValueField: React.FC<React.PropsWithChildren<Props>> = ({ title, children, right, wrap, long, className }) =>
  <div className={classNames("details-key-value-field", { "long": long }, className)}>
    <span className="field-title">{title}</span>
    <span className={classNames("field-value", { "right": right, "wrap": wrap })}>{children}</span>
  </div>;