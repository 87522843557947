import * as React from "react";
import { Observer, observer } from "mobx-react";
import { default as RootStore } from "../../../stores/root-store";
import { ContactModel } from "../../../models/shared/ContactModel";
import TrashIcon from "svg/trash.svg";
import { Dialog } from "../../../core/dialog";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import "./contactsField.tsx.scss";
import { SmallButton } from "ts/components/shared/SmallButton";
import { NotificationsComponent } from "../../shared/NotificationSelector";
import { UserNotification } from "../../../models/shared/UserNotification";
import { toJS } from "mobx";
import { Network } from "../../../api/network";

interface AddDialogState {
  name?: string;
  email?: string;
  phone?: string;
  notifications?: Array<UserNotification>
}
interface OwnState {
  availableContacts?: Array<ContactModel>;
}

interface OwnProps {
  showOneTimeContact?: boolean;
}

type P = PropsFromWrapperToField<Array<ContactModel>> & OwnProps;

@observer
class ContactsField extends React.Component<P, OwnState> {

  state: OwnState = { availableContacts: undefined };

  async componentDidMount() {
    const availableContacts = await new Network().get<Array<ContactModel>>(`/api/customers/GetAvailableContactsForCustomer/${this.props.customerId}`);
    if (availableContacts)
      this.setState({ availableContacts: availableContacts });
    else
      this.setState({ availableContacts: [] })
  }

  private toggleContact = (userId?: number | null, contactId?: number | null) => {
    const { value = [], setValue } = this.props;
    if (contactId != null) {
      if (value.some(v => v.ContactId == contactId))
        setValue(value.filter(v => v.ContactId != contactId));
      else
        setValue([...value, { ContactId: contactId} as ContactModel]);

    } else if (userId != null) {
      if (value.some(v => v.UserId == userId))
        setValue(value.filter(v => v.UserId != userId));
      else {
        const contact = this.state.availableContacts!.find(c => c.UserId == userId);
        setValue([...value, { UserId: userId, Name: contact?.Name } as ContactModel]);
      }
    }
  }

  private removeContact = (model: ContactModel) => {
    const { value = [], setValue } = this.props;
    setValue(value.filter(v => v != model));
  }

  private addContact = (model: ContactModel) => {
    const { value = [], setValue } = this.props;
    setValue([...value, model]);
  }

  private showDialog = () => {

    const dialog = new Dialog<AddDialogState>()
    dialog.title = "Opprett kontakt";
    dialog.content = AddContactDialog;
    dialog.buttons = [{
      text: "Legg til",
      isEnabled: ({ name, email, phone }) => (
        name != null
        && name.length > 2
        && (phone == null || phone.length == 0 || phone.match("^[4,9]\\d{7}$") != null)
        && (email == null || email.length == 0 || email.match("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?") != null)
        && ((phone != null && phone.length > 0) || (email != null && email.length > 0))
      ),
      onClick: ({ name, email, phone, notifications }, close) => {
        this.addContact({ Email: email!, Name: name!, Phone: phone!, Notifications: notifications, IsDefault: false });
        close();
      }
    }, { text: "Avbryt", onClick: (state, close) => close() }
    ]

    RootStore.UIStore.addDialog(dialog);
  }

  render() {
    const { value = [], showOneTimeContact } = this.props;
    const { availableContacts } = this.state;
    if (availableContacts == null)
      return null;

    return <div className="contacts-field">
      <ul className="contacts-field-global-contacts">
        {availableContacts.map((availableContact, index) =>
          <li key={index}>
            <label>
              <input
                type="checkbox"
                onChange={_ => this.toggleContact(availableContact.UserId, availableContact.ContactId)}
                checked={value.some(v => (v.ContactId != null && v.ContactId === availableContact.ContactId) || (v.UserId != null && v.UserId === availableContact.UserId))} />
              <span>{availableContact.Name}</span>
            </label>
          </li>)}
      </ul>
      <ul className="contacts-field-local-contacts">
        {value.filter(v => v.ContactId == null && v.UserId == null).map((c, index) => <li key={index}>
          <span>{c.Name}</span><TrashIcon onClick={() => this.removeContact(c)} />
        </li>)}
      </ul>
      {showOneTimeContact !== false && <SmallButton
        onClick={_ => this.showDialog()}
        onKeyPress={e => e.key === "Enter" ? this.showDialog() : null}
        tabIndex={0}
        className="contacts-field-add">Legg til engangs-kontakt</SmallButton>}
    </div>;
  }
}

const wrapped = Wrap<P, Array<ContactModel>>(ContactsField);

export {
  wrapped as ContactsField
}

@observer
class AddContactDialog extends React.Component<{ dialogState: Partial<AddDialogState> }> {

  render() {
    const { dialogState } = this.props;
    return (
      <div>
        <p>Her kan du anngi kontaktinformasjon til en person som vil bli tilknyttet denne spesifike arbeidsordren.
                Du kan også opprette permanente og gjenbruksbare kontaktpersoner under kundens innstillinger.</p>
        <div className="edit-form">
          <div className="form-component">
            <h4>Navn</h4>
            <input type="text" value={dialogState.name || ""} onChange={e => dialogState.name = e.target.value} />
          </div>
          <div className="form-component">
            <h4>Mobilnummer</h4>
            <input type="text" inputMode="tel" value={dialogState.phone || ""} onChange={e => dialogState.phone = e.target.value} />
          </div>
          <div className="form-component">
            <h4>Epost</h4>
            <input type="email" inputMode="email" value={dialogState.email || ""} onChange={e => dialogState.email = e.target.value} />
          </div>
          <div className="form-component">
            <h4>Varsler</h4>
            <NotificationsComponent
              value={toJS(dialogState.notifications || [])}
              showOnlyWhenContact={false}
              onChange={val => dialogState.notifications = [...val]} />
          </div>
        </div>
      </div>
    );
  }
}