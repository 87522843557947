import * as React from "react";
import { DataStore2 } from "../../../../stores/data-store";
import { StateHistoryItemModel } from "../../../../models/shared/StateHistoryItemModel";
import { Pending } from "../../../../components/shared/Pending";
import classNames from "classnames";
import { observer } from "mobx-react";
import { toSerialNumber, EntityType } from "../../../../core/stringHelpers";
import { toDateWithHM } from "../../../../core/dateHelpers";
import "./StateChangesTab.tsx.scss";

interface P {
  workOrderId: number;
}

@observer
export class StateChangesTab extends React.Component<P> {

  store: DataStore2<StateHistoryItemModel[]>;

  constructor(props: P) {
    super(props);

    this.store = new DataStore2<Array<StateHistoryItemModel>>(
      `/api/workorders/stateHistory/${this.props.workOrderId}`,
      ch => ch.entityName === "WorkOrderEntity");
  }

  componentWillUnmount() {
    this.store.dispose();
  }

  render() {    
    if (this.store.data == null)
      return <Pending />;

    return <div className="state-changes-tab">
      {this.store.data.map((d, i) =>
        <div key={i} className={classNames([d.UnitId == null ? "workorder-change" : "unit-change"])}>
          <span className="state-change-unit">{toSerialNumber(EntityType.Unit, d.UnitId)}</span>
          <span className="state-change-from">{d.FromText}</span>
          <span className="state-change-to">{d.ToText}</span>
          <span className="state-change-date">{toDateWithHM(d.ChangeDate)}</span>
        </div>)}
    </div>
  }
}