import { FileReferenceModel } from "./shared/FileReferenceModel";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export interface ClientFileReferenceModel extends Omit<FileReferenceModel, "Id"> {
  File?: File;
  Id?: string;
}

export function isClientFileReferenceModel(obj: any): obj is ClientFileReferenceModel {
  return ["File", "Name", "ContentType"].every(p => Object.prototype.hasOwnProperty.call(obj, p));
}

export function isClientFileReferenceModelArray(arr: any): arr is Array<ClientFileReferenceModel> {
  return Array.isArray(arr) && arr.every(a => isClientFileReferenceModel(a));
}