import { RepeatField } from "./RepeatField";
import * as React from "react";
import { BaseEditModel } from "../../../models/shared/BaseEditModel";
import { TransportUnit } from "../../../models/shared/TransportUnit";
import { TransportUnitType } from "../../../models/shared/TransportUnitType";
import { InputWithFix } from "../../shared/InputWithFix";
import "./UnitsForTransportField.tsx.scss";
interface Props<T> {
  field: keyof T;
  showIf?: (values: Partial<T>, customerId?: number, value?: any) => boolean;
}

export function UnitsForTransportField<T extends BaseEditModel>(props: Props<T>) {
  return (
    <RepeatField<T>
      field={props.field}
      showIf={props.showIf}
      label="Kolli"
      addText="Nytt kolli"
      container={(repeater, values) => <table className="field-units-for-transport">
        <thead><tr>
          <th>Type</th>
          <th>Vekt</th>
          {values && values.some(u => u.Type == TransportUnitType.Other) && <th>Størrelse</th>}
        </tr></thead><tbody>{repeater}</tbody>
      </table>}
    >
      {(item: TransportUnit, onChange: (field: keyof TransportUnit, value: any) => void, removeComponent, index) => (
        <tr className="transport-unit-row" key={index}>
          <td>
            <select value={item.Type} onChange={e => onChange("Type", e.target.value)} className="short-select">
              <option value={TransportUnitType.Pallet}>Pall</option>
              <option value={TransportUnitType.HalfPallet}>Halv-Pall</option>
              <option value={TransportUnitType.Other}>Stykkgods</option>
            </select>
          </td>
          <td className="input-list">
            <div>
              <InputWithFix
                postfix="kg"
                type="number"
                value={item.Weight}
                onChange={e => onChange("Weight", e.target.value)}
                placeholder="Vekt" />
                {item.Type == TransportUnitType.Other && <>
                  <InputWithFix
                    postfix="cm"
                    type="number"
                    placeholder="Lengde"
                    value={item.Length}
                    onChange={e => onChange("Length", e.target.value)}
                    disabled={item.Type != TransportUnitType.Other} />
                  <InputWithFix
                    postfix="cm"
                    type="number"
                    placeholder="Bredde"
                    value={item.Width}
                    onChange={e => onChange("Width", e.target.value)}
                    disabled={item.Type != TransportUnitType.Other} />
                  <InputWithFix
                    postfix="cm"
                    type="number"
                    placeholder="Høyde"
                    value={item.Height}
                    onChange={e => onChange("Height", e.target.value)}
                    disabled={item.Type != TransportUnitType.Other} />
                </>}
            </div>
          </td>
          <td>{removeComponent}</td>
        </tr>
      )}
    </RepeatField>
  )
}