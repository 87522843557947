import React, { useEffect, useRef, useState } from "react"
import { SmallButton } from "../../../../components/shared/SmallButton"
import "./SendSms.tsx.scss";
import { errorToast, infoToast, successToast, warningToast } from "../../../../components/shared/Toasts";
import { Network } from "../../../../api/network";

export const SendSms: React.FC = () => {

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState("");
  const [disabled, setDisabled] = useState(false);

  const onSend = async () => {
    setDisabled(true);

    try {
      const network = new Network();
      const result = await network.post("/api/users/sendSms", { message: text }, false);

      if (result === undefined)
        throw new Error();
      successToast("Meldingen har blitt sendt.");
      setText("");
    } catch {
      errorToast("Noe gikk galt med sending av melding - prøv igjen eller send via epost til post@razink.no.");
    } finally {
      setTimeout(() => setDisabled(false), 1000 * 60);
    }
  }

  useEffect(() => {
    const e = textAreaRef.current!;

    const fn = () => {
      e.style.height = "0px";
      e.style.height = e.scrollHeight + "px"
    }

    e.addEventListener("input", fn);
    return () => e.removeEventListener("input", fn);
  }, []);

  return <div className="box w2 send-sms">
    <h2>Send oss en melding</h2>
    <span className="length">{text.length} tegn</span>
    <textarea placeholder="Skriv tekst her" value={text} onChange={e => setText(e.target.value)} ref={textAreaRef}></textarea>
    <SmallButton disabled={disabled || text.length === 0} onClick={onSend}>Send</SmallButton>
  </div>
}