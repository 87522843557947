import { Network } from "ts/api/network";
import { observable } from "mobx";
import { NotificationHub, IChange, ChangeType } from "ts/core/signalR";

type ObservableChange = { type: string, id?: number | null };

export class DataStore<T> {

  @observable data: T | undefined = undefined;

  private url: string;
  private observableChanges: Array<ObservableChange>;
  private _network: Network;

  constructor(url: string, observableChanges: Array<ObservableChange>) {
    this.url = url;
    this.observableChanges = observableChanges;
    this._network = new Network();


    this.loadData();

    NotificationHub.registerListener(this.onChange, this)
  }

  dispose() {
    NotificationHub.removeListener(this);
  }

  onChange = async (messages: Array<IChange>) => {    
    // Update data if an entity changes, but do NOT attempt to reload data if the entity has been deleted
    const shouldUpdate = messages.some(m =>
      this.observableChanges.some(oc => oc.type == m.entityName && (oc.id == undefined || oc.id == m.entityId) && m.changeType !== ChangeType.Deleted));
    if (shouldUpdate)
      await this.loadData();
  }

  loadData = async () => {
    const result = await this._network.get<T>(this.url);
    if (result)
      this.data = result;
    else
      this.data = undefined;
  }

  async delete(controller: string, id: number) {
    return await this._network.get<boolean>(`/api/${controller}/delete/${id}`);
  }

}

export class DataStore2<T> {

  @observable data: T | undefined | null;
  private _network: Network;
  private _url: string;
  private _onChangeFn: (change: IChange, data: T | null | undefined) => boolean;
  private _callbackFn?: (result: T) => void;

  constructor(url: string, onChangeFn: (change: IChange, data: T | undefined | null) => boolean, callbackFn?: (result: T) => void) {
    this._network = new Network();
    this._url = url;
    this._onChangeFn = onChangeFn;
    this._callbackFn = callbackFn;
    this.loadOrRefreshData();
    NotificationHub.registerListener(this.onNotificationMessage, this)
  }

  private async loadOrRefreshData() {    
    const result = await this._network.get<T>(this._url);
    if (result) {      
      this.data = result;
      this._callbackFn?.(result);
    }
    else
      this.data = null;
  }

  private onNotificationMessage = async (messages: Array<IChange>) => {    
    const shouldUpdate = messages.some(m => this._onChangeFn(m, this.data));
    if (shouldUpdate)
      this.loadOrRefreshData();
  }

  public dispose() {
    this._callbackFn = undefined;    
    NotificationHub.removeListener(this);
  }
}