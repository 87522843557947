import { Network } from "ts/api/network";
import { InvoiceModel } from "ts/models/shared/InvoiceModel";
import * as React from "react";
import { observer } from "mobx-react";
import { Button } from "ts/components/shared/Button";
import { default as RootStore } from "../../../stores/root-store";
import { InvoiceData } from "./invoices/InvoiceData";
import { DataStore2 } from "../../../stores/data-store";
import "./invoices.tsx.scss";
import { Pending } from "../../../components/shared/Pending";

type P = {
  workOrderId: number;
};

@observer
export class Invoice extends React.Component<P> {

  //@observable invoiceData: InvoiceModel | null | undefined = undefined;
  store: DataStore2<InvoiceModel>;

  constructor(props: P) {
    super(props);

    const url = `/api/invoices/detailsForWorkorder/${this.props.workOrderId}`;
    this.store = new DataStore2<InvoiceModel>(url,
      (change, data) => {

        // If we have a invoice, check for changes to it
        if (data != null)
          return change.entityName == "InvoiceEntity" && data.Id === change.entityId;

        // If we don't have an invoice, check for changes to the workorder, incase and invoice has been added
        return change.entityName === "WorkOrderEntity" && change.entityId == this.props.workOrderId;
      }
    );
  }

  componentWillUnmount() {
    this.store.dispose();
  }

  render() {

    const { data } = this.store;

    // Data is pending
    if (data === undefined)
      return <Pending />;

    // No data is available
    if (data === null)
      return <Button
        onClick={() => this.createDraft()}
        disabled={RootStore.UIStore.hasActiveNetworkCalls}
        text="Opprett fakturagrunnlag" />;

    return <InvoiceData data={data} workOrderId={this.props.workOrderId} />;
  }

  async createDraft() {
    await new Network().get(`/api/invoices/createDraftForWorkorder/${this.props.workOrderId}`);
  }
}