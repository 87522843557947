import { HubConnectionBuilder, HubConnectionState, HubConnection } from "@microsoft/signalr";
import { autorun } from "mobx";
import { LogData } from "./logging";
import { IRootStore } from "../stores/root-store";

export enum ChangeType {
  Add,
  Modified,
  Deleted
}


export interface IChange {
  changeType: ChangeType;
  entityName: string;
  entityId: number;
}

export interface NavigationData {
  pathname: string;
  search: string;
  state: unknown;
  pageCorrelationId: string;
  agent: string;
  language: string;
  darkMode: boolean;
}

type ListenerFn = (messages: Array<IChange>) => void;

class NotificationHub {
  private eventListeners: Map<object, ListenerFn>;
  private hubConnection: HubConnection | undefined;

  constructor() {
    this.eventListeners = new Map<object, ListenerFn>();
  }

  public async sendLogData(logData: LogData) {
    if (this.hubConnection != null && this.hubConnection.state == HubConnectionState.Connected) {
      await this.hubConnection.send("ClientLog", logData.level, logData.message, logData.data);      
    }
  }

  public async sendNavigationData(navigationData: NavigationData) {
    if (this.hubConnection != null && this.hubConnection.state == HubConnectionState.Connected) {
      await this.hubConnection.send("NavigationData", navigationData);
    }
  }

  public registerListener = (fn: ListenerFn, objRef: object | undefined = undefined): object => {
    const obj = objRef || new Object();
    this.eventListeners.set(obj, fn);
    return obj;
  }

  public removeListener = (obj: object) => {
    this.eventListeners.delete(obj);
  }

  public connection = () => this.hubConnection;

  public initialize(store: IRootStore) {

    const connection = new HubConnectionBuilder()
      .withUrl("/hub")
      .build();

    connection.on("changes", async (messages: Array<IChange>) => {
      this.eventListeners.forEach(el => el(messages));
    });

    connection.on("navigateTo", async (route: string) => {
      store.RouterStore.push(route);
    });

    connection.onclose(error => {
      store.UserStore.isConnected = false;
    });

    this.hubConnection = connection;


    autorun(async view => {
      if (store.UserStore.requiresAuthentication && connection.state == HubConnectionState.Connected) {
        await connection.stop();        
      }
    });

    let isPendingReconnect = false;
    setInterval(async () => {
      if (connection.state === HubConnectionState.Disconnected && !store.UserStore.requiresAuthentication && !isPendingReconnect) {
        try {
          isPendingReconnect = true;
          await connection.start();
          store.UserStore.isConnected = true;
          isPendingReconnect = false;
        } catch {
          isPendingReconnect = false;
        }
      }
    }, 1000);

  }
}
const hub = new NotificationHub();

export { hub as NotificationHub };