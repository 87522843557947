/**  */
export enum ArticleType {
  
  /**Knyttes til utveing */
  ZinkArticle = 1,  
  /**Linjefelt på en faktura */
  LineItem = 2,  
  /** */
  Measurement = 3,  
  /** */
  ZinkAdjustment = 4,  
  /** */
  Minimum = 5,  
  /** */
  Rush = 6,  
  /** */
  Paint = 7,  
  /** */
  Transport = 8,  
  /** */
  FreeText = 9,  
  /** */
  ExtraWork = 10,  
  /** */
  Pallets = 11,  
  /** */
  Sandblast = 12,  
  /** */
  Mellomlegg = 13,  
  /** */
  ElectricityAdjustment = 14
}

