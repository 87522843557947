import * as React from "react";
import { FilterProps } from "../IListDefinition";
import { observer } from "mobx-react";
import { BaseModel } from "../../../models/shared/BaseModel";

interface OwnProps {
  true: string;
  false: string;
  null: string;
}

type P = FilterProps<BaseModel> & OwnProps;

@observer
class BooleanFilter extends React.Component<P>{

  fromValue = (value: boolean | undefined) => {
    if (value === true)
      return "true";
    else if (value === false)
      return "false"
    else
      return "null";
  }

  setValue = (value: string) => {
    const { store, field } = this.props;
    if (value == "true")
      store.setFilter(field, true);
    else if (value == "false")
      store.setFilter(field, false);
    else
      store.setFilter(field, null);
  }

  render() {
    const { store, field } = this.props;
    const value = store.getFilterValue<boolean>(field);
    const t = this.fromValue(value);
    return <select
      onChange={e => this.setValue(e.target.value)}
      value={t}>
      <option value="true">{this.props.true}</option>
      <option value="false">{this.props.false}</option>
      <option value="null">{this.props.null}</option>
    </select>
  }
}

const A = BooleanFilter as any as React.ComponentClass<OwnProps>;

export {
  A as BooleanFilter,
  BooleanFilter as BooleanFilterInternal
};