import * as React from "react";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { RouteComponentProps } from "react-router";
import { OrderList } from "./OrderList";
import { OrderEditForm } from "./OrderEditForm";
import { OrderDetailsPage } from "./OrderDetails";

interface Props {
  route: RouteComponentProps<{}>;
}

export class OrderPage extends React.Component<Props> {
  render() {
    return <WorkSpace
      singular="bestilling"
      plural="bestillinger"
      routeBasePath={this.props.route.location.pathname.split("/")[1]}
      {...this.props.route}
      editComponent={<OrderEditForm />}
      listComponent={<OrderList />}
      detailsComponent={<OrderDetailsPage />}
    />
  }
}