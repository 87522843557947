import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"
import { faKey, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons"
import "./OfficeLockBox.tsx.scss";
import { SmallButton } from "../../../components/shared/SmallButton";
import { NotificationHub } from "../../../core/signalR";
import { default as RootStore } from '../../../stores/root-store';

export const OfficeLockBox = () => {

  const [isOfficeLocked, setIsOfficeLocked] = useState<boolean | null>(null);
  const [isOfficePending, setIsOfficePending] = useState<boolean>(false);
  const [isFactoryLocked, setIsFactoryLocked] = useState<boolean | null>(null);
  const [isFactoryPending, setIsFactoryPending] = useState<boolean>(false);

  const hub = useRef(NotificationHub.connection());

  useEffect(() => {
    var connection = hub.current;
    connection?.on("LockState", (state) => {
      setIsOfficeLocked(state.isOfficeLocked ?? false);
      setIsFactoryLocked(state.isFactoryLocked ?? false);
      setIsFactoryPending(false);
      setIsOfficePending(false);
    });

    return () => {
      connection?.off("LockState");
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (RootStore.UserStore.isConnected) {
        const state = await hub.current?.invoke("SendLockState");
        setIsOfficeLocked(state.isOfficeLocked ?? false);
        setIsFactoryLocked(state.isFactoryLocked ?? false);
      }
    })();
  }, [RootStore.UserStore.isConnected])

  if (isFactoryLocked === null || isOfficeLocked === null)
    return null;

  if (window.flytSettings.user?.canUnlockFactory !== true && RootStore.UserStore.isCustomer)
    return null;

  const ToggleOffice = () => {
    setIsOfficePending(true);
    hub.current?.send("ToggleOfficeLock")
  }

  const ToggleFactory = () => {
    setIsFactoryPending(true);
    hub.current?.send("ToggleFactoryLock")
  }

  return <div className="office-lock-box">
    <FontAwesomeIcon icon={faKey} />
    <div>
      {false && <SmallButton disabled={isFactoryPending} onClick={ToggleFactory}><FontAwesomeIcon icon={isFactoryLocked ? faLock : faLockOpen} />{isFactoryLocked ? "Lås opp fabrikk" : "Lås fabrikk"}</SmallButton>}
      <SmallButton onClick={ToggleOffice} disabled={isOfficePending}>
        <FontAwesomeIcon icon={isOfficeLocked ? faLock : faLockOpen} />{isOfficeLocked ? "Lås opp kontor" : "Lås kontor"}
      </SmallButton>
    </div>
  </div>
}