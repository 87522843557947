import { default as RootStore } from "../stores/root-store";
import type { TokenRequest } from "../models/shared/TokenRequest";
import type { ValidationResultValue } from "../models/shared/ValidationResultValue";
import type { FormStore } from "../stores/form-store";
import { Dialog } from "../core/dialog";
import { Network } from "./network";

export class AuthenticationHelper {

  async authenticate(store: FormStore<TokenRequest>): Promise<boolean> {
    const url = "/api/authentication";

    try {
      RootStore.UIStore.addNetworkCall();
      store.isPending = true;
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        body: JSON.stringify(store.values),
        headers: new Headers({
          "Content-Type": "application/json; charset=utf-8",
          "Accept": "application/json",
        })
      });

      if (response.ok) {
        window.location.href = "/";
      } else if (response.status === 422) {
        const errors = await response.json() as ValidationResultValue;
        if (errors.Errors)
          errors.Errors.forEach(e => store.setServerError(e.Field as keyof TokenRequest, e.Message));
      }
      else
        this.showGenericError(response.statusText);
    }
    catch (error) {
      if (error instanceof Error) {
        this.showGenericError(error.message);
      }
    }
    finally {
      RootStore.UIStore.reduceNetworkCall();
      store.isPending = false;
    }

    return false;
  }

  async logout() {

    var network = new Network();
    const success = await network.get<boolean>("/api/authentication/logout");
    if (success)
      window.location.href = "/";
  }

  private showGenericError(message: string) {
    RootStore.UIStore.addDialog(Dialog.OkDialogWithMessage("Feil", message));
  }
}
