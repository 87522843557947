import * as React from "react";
import classNames from "classnames";

interface P extends React.InputHTMLAttributes<HTMLInputElement> {}

const currency_regex = /^\d*(?:\.\d{0,2})?$/;

export class CurrencyInput extends React.Component<P, { value: string }> {
  constructor(props: P) {
    super(props);
    this.state = { value: this.format(this.props.value) };
  }

  change = (e: React.ChangeEvent<HTMLInputElement>) => {
    let v = e.target.value;
    v = v.replace(",", ".");

    if (v.length > 0 && !currency_regex.test(v))
      return;

    this.setState({ value: v });
    if (v != ".") {
      e.target.value = v;
      this.props.onChange?.(e);
    }
  }

  blur = (e: React.FocusEvent<HTMLInputElement>) => {
    let v = e.target.value;
    if (v === ".")
      v = "";
    else if (v.length > 0)
      v = parseFloat(v).toFixed(2);
    this.setState({ value: v });
    this.props.onChange?.(e);
    this.props.onBlur?.(e);
  }

  format = (value: any) => {
    if (value == null)
      return "";
    else if (typeof value == "number")
      return value.toFixed(2);
    else if (typeof value == "string") {
      if (value.length == 0)
        return ""
      else
        return parseFloat(value).toFixed(2);
    }

    return value;
  }
    
  componentDidUpdate(prevProps: P) {    
    if (this.props.value == this.state.value)
      return;

    if (this.props.value != null) {
      if (this.props.value != parseFloat(this.state.value))
        this.setState({ value: this.format(this.props.value) });
    }

  }

  render() {
    return <input
      type="text"
      inputMode="decimal"
      {...this.props}
      onChange={e => this.change(e)}
      className={classNames(this.props.className, "input-currency")}
      onBlur={e => this.blur(e)}
      value={this.state.value} />
  }
}