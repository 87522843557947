import { FileReferenceModel } from "../../models/shared/FileReferenceModel";
import * as React from "react";
import { FileIconComponent } from "../forms/fields/FilesField";
import { NumberWithPostfix } from "./display";
import { getImageUrl } from "../../core/util";
import { ImageSize } from "../../models/shared/ImageSize";
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import "./DocumentList.tsx.scss";
import classNames from "classnames";

export const DocumentList: React.FC<{ files: Array<FileReferenceModel> }> = ({ files }) => {

  if (files == null || files.length == 0)
    return <p>Ingen dokumenter funnet.</p>;

  const galleryRef = React.useRef<ImageGallery>(null);

  const [currentImage, setCurrentImage] = React.useState(0);
  const [galleryIsVisible, setGalleryIsVisible] = React.useState(false);

  const onImageSelect = (id: string) => {
    setCurrentImage(galleryImages.findIndex(i => i.id == id));
    setGalleryIsVisible(true);
  };

  React.useEffect(() => {
    if (galleryIsVisible && galleryRef.current) {
      galleryRef.current.slideToIndex(currentImage);
      galleryRef.current.fullScreen();
    }
  }, [galleryIsVisible]);

  const onScreenChange = (fullScreenElement: Element | boolean) => {
    if (fullScreenElement == null || fullScreenElement === false)
      setGalleryIsVisible(false);
  }

  const galleryImages = files
    .filter(f => f.ContentType?.toLowerCase().startsWith("image"))
    .map<ReactImageGalleryItem & { id: string }>((f, index) => ({
      id: f.Id,
      original: getImageUrl(f, ImageSize.Desktop),
      thumbnail: getImageUrl(f, ImageSize.Preview),
      fullscreen: getImageUrl(f, ImageSize.Original),
      originalAlt: f.Name,
      description: f.Name
    }));

  return <div className="file-list">
    {files.map(f => <FileItem file={f} key={f.Id} onImageSelect={onImageSelect} />)}
    {galleryIsVisible && <ImageGallery
      items={galleryImages}
      ref={galleryRef}
      lazyLoad={true}
      useBrowserFullscreen={true}
      onScreenChange={onScreenChange}
      preventDefaultTouchmoveEvent={true} />}
  </div>;
}

const FileItem: React.FC<{ file: FileReferenceModel, onImageSelect: (id: string) => void }> = ({ file, onImageSelect }) => {
  const isImage = file.ContentType?.toLowerCase().startsWith("image");
  const isAudio = file.ContentType?.toLowerCase().startsWith("audio");

  return <div className="file-list-item">
    <div className={classNames("file-list-preview", { "file-list-preview-image": isImage })} onClick={e => isImage && onImageSelect(file.Id)}>
      <FileIconComponent file={file} />
    </div>
    <div className="file-list-details">
      <a className="file-list-name" href={getImageUrl(file, ImageSize.Original)} download> {file.Name}</a>
      <NumberWithPostfix number={Math.round(file.Size / 1024)} post="kB" className="file-list-size" />
    </div>
    {isAudio && <audio controls preload="metadata" >
      <source src={getImageUrl(file, ImageSize.Original)} type={file.ContentType} />
    </audio>
    }
  </div>;
}