import * as React from "react";
import { ListProps } from "./List";
import { BaseModel } from "../../models/shared/BaseModel";
import { observer } from "mobx-react";

type P = ListProps<BaseModel>;

@observer
export class Summary extends React.Component<P> {
  render() {
    const { store } = this.props;
    if (store.values == null || store.values.TotalCount == 0)
      return null;

    const currentStartIndex = store.values.Offset + 1;
    const totalNumberOfItems = store.values.TotalCount;
    const currentEndIndex = Math.min(store.pageSize + store.values.Offset, totalNumberOfItems);

    return (
      <div className="count">
        <span>Viser {currentStartIndex} til {currentEndIndex} av {totalNumberOfItems} linjer</span>
      </div>);
  }
}