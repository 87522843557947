import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren } from "react";
import { Welcome } from "..";
import { VideoStream } from "../../../../components/VideoStream";
import { AbsoluteCenterPending, } from "../../../../components/shared/Pending";
import { Dialog } from "../../../../core/dialog";
import { useDataStore } from "../../../../core/hooks/useDataStore";
import { CustomerDetailsModel } from "../../../../models/shared/CustomerDetailsModel";
import { AktiveBestillinger } from "./AktiveBestillinger";
import { AktiveArbeidsordre } from "./AktiveOrdre";
import { CustomerInfo } from "./CustomerInfo";
import { DataSummary } from "./DataSummary";
import { GraphLeveranser } from "./GraphLeverianser";
import { Reguleringspriser } from "./Reguleringspriser";
import { SendSms } from "./SendSms";
import "./dashboard.tsx.scss";
import { NavLink } from "react-router-dom";
import InvoiceIcon from "svg/invoice.svg";
import OrderIcon from "svg/order.svg";
import DokumentasjonIcon from "svg/pdf.svg";
import { isUpToPC } from "../../../../core/util";

export const Dashboard: React.FC = () => {

  const customerId = window.flytSettings.user?.customerId;
  if (customerId == null || isUpToPC())
    return <Welcome />;

  const { data: customerDetails } = useDataStore<CustomerDetailsModel>(`/api/customers/${customerId}/details`, {
    entityId: customerId,
    entityName: "CustomerEntity"
  });

  if (!customerDetails)
    return <AbsoluteCenterPending />;

  return <div className="dashboard-page">
    <div className="header">
      <h1>Kundeoversikt - {customerDetails!.Name}</h1>
      <div className="link-area">
        <div><OrderIcon /><NavLink to="/orders/create">Opprett bestilling</NavLink></div>
        <div><InvoiceIcon /><NavLink to={{ pathname: `/customers/${customerId}`, state: { tab: "Fakturaer" } }}>Se fakturaer</NavLink></div>
        <div><DokumentasjonIcon /><a href="https://www.razink.no/dokumentasjon" target="_blank">Dokumentasjon</a></div>
      </div>
    </div>
    <div className="dashboard-main">
      <div>
        <VideoStream />
        <GraphLeveranser />
      </div>
      <div>
        <div className="side-area">
          <CustomerInfo info={customerDetails} />
          <SendSms />
          <Reguleringspriser />
          <DataSummary />
          <AktiveBestillinger />
          <AktiveArbeidsordre />
        </div>
      </div>
    </div>
  </div >
}

const QuickAction: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="link">
    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />{children}
  </div>
}

const BestillingDialogOpener: React.FC = () => {

  var dialog = new Dialog();
  dialog.content = <BestillingDialog />;
  dialog.closable = true;
  dialog.title = "Opprett bestilling";
  dialog.buttons = [{ text: "Opprett bestilling", onClick: (state, closeFn) => closeFn() }, { text: "Avbryt", onClick: (state, fnClose) => fnClose() }]

  const openDialog = () => {
    Dialog.Show(dialog);
  }

  return <span onClick={() => openDialog()}>Opprett bestilling</span>
}

const BestillingDialog: React.FC = () => {

  return <div>
  </div>
}