import * as React from "react";
import { WorkSpaceType } from "./WorkSpace";
import { NavLink, Link } from "react-router-dom";
import "./Breadcrumb.scss";

const BreadCrumb: React.FC<{
  singular: string;
  plural: string;
  workSpaceType: WorkSpaceType,
  routeBasePath: string;
  id?: string,
  additionalLinks?: Array<{ title: string, path: string }>
}> = ({ singular, plural, workSpaceType, id, routeBasePath, additionalLinks }) => {
  const stack: Array<{ title: string, path: string }> = [];
  stack.push({ title: 'RåLink', path: "/" });
  stack.push({ title: `Oversikt ${plural}`, path: `/${routeBasePath}` });
  if (workSpaceType === "details")
    stack.push({ title: `Detaljer ${singular} ${id || ""}`, path: `/${routeBasePath}/${id}` });
  else if (workSpaceType === "create")
    stack.push({ title: `Opprett ${singular}`, path: `/${routeBasePath}/create` });
  else if (workSpaceType === "edit")
    stack.push({ title: `Rediger ${singular} ${id || ""}`, path: `/${routeBasePath}/${id}/edit` });

  if (additionalLinks)
    additionalLinks.forEach(a => stack.push(a));

  return (<ol className="breadcrumb" aria-label="Brødsmulesti" role="navigation">
    {stack.map((link, index) => <li key={index}>
      {index < (stack.length - 1) && <NavLink
        to={link.path}
        isActive={() => true}>{link.title}</NavLink>}
      {index == (stack.length - 1) && <span>{link.title}</span>}
    </li>)
    }
  </ol >);
}

const CustomBreadCrumb: React.FC<{ links: Array<{ title: string, path: string | null }> }> = ({ links }) => {

  links = [{ title: "RåLink", path: "/" }, ...links];

  return (<ol className="breadcrumb" aria-label="Brødsmulesti" role="navigation">
    {links.map((link, index) => <li key={index}>
      {index < (links.length - 1) && link.path != null && <NavLink to={link.path} isActive={() => true}>{link.title}</NavLink>}
      {(index == (links.length - 1) || link.path == null) && <span>{link.title}</span>}
    </li>)
    }
  </ol >);

}

export { BreadCrumb, CustomBreadCrumb };