import * as React from "react";
import { RouteComponentProps } from "react-router";
import { FormStore, IValidationRules } from "ts/stores/form-store";
import { ArticleGroupModel } from "ts/models/shared/ArticleGroupModel";
import { observer } from "mobx-react";
import { Network } from "ts/api/network";
import { omit } from "lodash";
import { Form } from "ts/components/forms/form";
import { DateField } from "ts/components/forms/fields/DateField";
import { observable } from "mobx";
import { InputWithFix } from "ts/components/shared/InputWithFix";
import { RepeatField } from "ts/components/forms/fields/RepeatField";
import { ArticleGroupPriceModel } from "ts/models/shared/ArticleGroupPriceModel";
import { FormActions } from "ts/components/forms/FormActions";
import { ArticleType } from "ts/models/shared/ArticleType";
import { InputField } from "ts/components/forms/fields/InputField";

interface Props extends RouteComponentProps<RouteParams> {
}

interface RouteParams {
  articleId: string;
  groupId?: string;
  templateId: string;
}

@observer
export class ArticlePriceGroupEdit extends React.Component<Props> {

  @observable store?: FormStore<ArticleGroupModel>;

  async componentDidMount() {

    const validationRules: IValidationRules<ArticleGroupModel> = {
      "Start": [(value) => value ? null : "Startdato er påkrevet"]
    }

    const { articleId, groupId, templateId } = this.props.match.params;

    let template: Partial<ArticleGroupModel> | undefined = undefined;
    if (templateId) {
      const network = new Network();
      const tmp = await network.get<ArticleGroupModel>(`/api/articlegroups/${templateId}`);
      if (tmp)
        template = omit(tmp, ["ArticleId", "Start", "Id", "Created"]);
    }

    this.store = new FormStore<ArticleGroupModel>(validationRules, ({ ArticleId: parseInt(articleId), ...template }));

    if (groupId) {
      await this.store.loadModel("articlegroups", parseInt(groupId))
    }
  }


  render() {
    if (this.store == null)
      return null;

    const hasWeight = this.store.values.ArticleType == ArticleType.ZinkArticle;
    const { articleId } = this.props.match.params;

    return (
      <div className="panel form-container">
        <Form
          store={this.store!}
          name="Priser"
          redirectToList={true}
          onSubmit={async router => {
            const id = await this.store!.postForm(`/api/articlegroups/${this.store!.isEdit ? "update" : "add"}`);
            if (id)
              router.push(`/articles/${articleId}/prices`);
          }}
          className="edit-form edit-form-padding">
          <h3>Priser</h3>
          <DateField<ArticleGroupModel>
            field="Start"
            label="Start"
            helpText="Dato fra når denne prisen skal gjelde fra."
            time={false}
          />
          {hasWeight && <RepeatField<ArticleGroupModel>
            field="Prices"
            addText="Ny pris"
            container={repeater => <table><thead><tr><th>Pris</th><th>Fra vekt</th></tr></thead><tbody>{repeater}</tbody></table>}
            label="Priser">
            {(item: ArticleGroupPriceModel, onChange: (field: keyof ArticleGroupPriceModel, value: any) => void, addComponent, index) => (
              <tr key={index}>
                <td><InputWithFix postfix="kr" value={item.Price} useCurrencyInputField={true} onChange={e => onChange("Price", e.target.value.length == 0 ? "0" : parseFloat(e.target.value))} /></td>
                <td><InputWithFix type="number" value={item.MinimumWeight ?? undefined} onChange={e => onChange("MinimumWeight", e.target.value)} postfix="kg" /></td>
                <td>{addComponent}</td>
              </tr>
            )}
          </RepeatField>}
          {!hasWeight &&
            <InputField<ArticleGroupModel>
              postfix="kr"
              field="Price"
              label="Pris"
              helpText="Pris som skal faktureres per enhet"
              type="currency"
              step="0.01"
            />}
          <FormActions />
        </Form>
      </div>
    );
  }
}