import * as React from "react";
import { BreadCrumb } from "../../../components/shared/BreadCrumb";
import { GroupedDayModel } from "../../../models/shared/GroupedDayModel";
import { UnitDayItemModel } from "../../../models/shared/UnitDayItemModel";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { NumberWithPostfix } from "../../../components/shared/display";
import { isEmpty, sum } from "lodash-es";
import "./UnitDayPage.tsx.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Enums } from "../../../stores/lookup-store";
import { default as RootStore } from "../../../stores/root-store";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import PaintIcon from "svg/paint.svg";
import RushIcon from "svg/rush.svg";
import { PaintType } from "../../../models/shared/PaintType";
import { toDateWithHM } from "../../../core/dateHelpers";
import { useDataStore } from "../../../core/hooks/useDataStore";

type DataType = Array<GroupedDayModel<UnitDayItemModel>>;

export const UnitDayPage: React.FC = () => {

  const [hoverId, setHoverId] = React.useState<number | null>(null);
  const [options, setOptions] = React.useState<Array<BaseLookupModel>>([]);
  const [selectedOption, setSelectedOption] = React.useState<string>("");

  const { data } = useDataStore<DataType>(`/api/units/UnitsByDate/${selectedOption}`, { entityName: "WorkOrderEntity" }, { enabled: !isEmpty(selectedOption) });

  React.useEffect(() => {
    const fn = async () => {
      const result = await RootStore.LookupStore.getEnums(Enums.UnitState, false);
      if (result)
        setOptions(result);
    }
    fn();
  }, []);

  return <>
    <h1>Statusoversikt</h1>
    <BreadCrumb
      workSpaceType="list"
      singular="Dagsoversikt"
      plural="Arbeidsordre"
      additionalLinks={[{ title: "Statusoversikt", path: "" }]}
      routeBasePath="workorders" />
    <div className="filter-container panel">
      <div className="filters-wrapper">
        <ul className="filters">
          <li className="filter-item">
            <h4>Status</h4>
            <select onChange={e => setSelectedOption(e.target.value)} value={selectedOption}>
              <option value="">-- Velg status --</option>
              {options.map(o => <option value={o.Id} key={o.Id}>{o.Name}</option>)}
            </select>
          </li>
        </ul>
      </div>
    </div>
    {data && <div className="list-area panel day-overview-list">
      {data.map(d => <div key={d.Date} className="day-container">
        <div className="day-header">
          <span className="day-header-date">{d.DayText}</span>
          <NumberWithPostfix number={d.Items?.length} post="stk" className="day-header-count" />
          <NumberWithPostfix number={sum(d.Items?.map(i => i.Weight))} post="kg" className="day-header-weight" />
        </div>
        {d.Items?.map(i =>
          <div
            key={i.Id}
            className={classNames("day-item", { "day-item-hover": hoverId === i.WorkOrderId })}
            onMouseEnter={e => setHoverId(i.WorkOrderId)}
            onMouseLeave={e => setHoverId(null)}>
            <Link to={{ state: { unitId: i.Id }, pathname: `/workorders/${i.WorkOrderId}` }} className="day-overview-serial">{toSerialNumber(EntityType.Unit, i.Id)}</Link>
            <Link to={`/workorders/${i.WorkOrderId}`} className="day-overview-serial">{toSerialNumber(EntityType.WorkOrder, i.WorkOrderId)}</Link>
            <span className={classNames("day-overview-customer", { "day-overview-customer-rush": i.IsRush })}>{i.CustomerName}</span>
            <NumberWithPostfix number={i.Weight} post="kg" className="day-overview-weight" />
            <span className="day-overview-delivery">{toDateWithHM(i.DeliveryDate)}</span>
            <span className="day-overview-icons">
              {i.IsRush && <RushIcon className="icon" />}
              {(i.PaintType === PaintType.Powder || i.PaintType === PaintType.Wet) && <PaintIcon className="icon" />}
            </span>
          </div>)}
      </div>)}
    </div>}
  </>;
}