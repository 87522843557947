import { RouteComponentProps, Route, } from "react-router";
import * as React from "react";
import { DynamicPageTitle } from "./DynamicPageTitle";
import { BreadCrumb } from "./BreadCrumb";
import { PageRoutes, PageRoutesProps } from "./PageRoutes";


export type WorkSpaceType = "create" | "edit" | "details" | "list";

interface Props extends RouteComponentProps<any>, PageRoutesProps {
  singular: string;
  plural: string;
  additionalRoutes?: Array<{
    path: string,
    component: React.ComponentClass<any> | React.FC<any>
  }>
}

export class WorkSpace extends React.Component<Props> {

  private getWorkSpaceType(): WorkSpaceType {
    const { pathname } = this.props.location;
    const { params } = this.props.match;

    if (pathname.toLowerCase().indexOf("/create") >= 0)
      return "create";
    else if (pathname.toLowerCase().indexOf("/edit") >= 0)
      return "edit";
    else if (/\/\d+/g.test(pathname))
      return "details"
    else
      return "list";
  }


  render() {
    const workSpaceType = this.getWorkSpaceType();
    const { singular, plural, routeBasePath, detailsComponent, listComponent, editComponent, additionalRoutes } = this.props;    
    return (
      <>
        <DynamicPageTitle workSpaceType={workSpaceType} singular={singular} plural={plural} />
        <BreadCrumb workSpaceType={workSpaceType} singular={singular} plural={plural} routeBasePath={routeBasePath} />
        <PageRoutes routeBasePath={routeBasePath} editComponent={editComponent} detailsComponent={detailsComponent} listComponent={listComponent} additionalRoutes={additionalRoutes} />
      </>
    )
  }
}



