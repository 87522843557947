import * as React from "react";
import { FilterProps } from "../IListDefinition";
import { observer } from "mobx-react";
import { BaseModel } from "../../../models/shared/BaseModel";

interface OwnProps {}

type P = FilterProps<BaseModel> & OwnProps;

@observer
class TextFilter extends React.Component<P> {
  render() {
    const { store, field, focus } = this.props;
    const value = store.getFilterValue<string>(field) || "";
    return (
      <input
        className="filter-text"
        type="text"
        autoFocus={focus}
        value={value}
        onChange={e => store.setFilter(field, e.target.value)} />
    )
  }
}

const A = TextFilter as any as React.ComponentClass<OwnProps>;

export { A as TextFilter };
