import { RouteComponentProps } from "react-router";
import * as React from "react";
import { IListDefinition, FilterType, Alignment } from "../../../components/lists/IListDefinition";
import { RushRequestListModel } from "../../../models/shared/RushRequestListModel";
import { CustomerFilter } from "../../../components/lists/FilterComponents/CustomerFilter";
import { Link } from "react-router-dom";
import { List } from "../../../components/lists/List";
import { default as RootStore } from "../../../stores/root-store";
import { DynamicPageTitle } from "../../../components/shared/DynamicPageTitle";
import { BreadCrumb } from "../../../components/shared/BreadCrumb";
import { toDateWithHM } from "../../../core/dateHelpers";
import { BooleanFilter } from "../../../components/lists/FilterComponents/BooleanFilter";
import { SortDirection } from "../../../models/shared/SortDirection";
import { Network } from "../../../api/network";
import { Dialog } from "../../../core/dialog";
import "./RushRequestsList.tsx.scss";
import { RushRequestRejectedModel } from "../../../models/shared/RushRequestRejectedModel";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { RushRequestState } from "../../../models/shared/RushRequestState";
import EyeIcon from "svg/eye.svg";
import { SmallButton } from "../../../components/shared/SmallButton";
import { useRef, useState } from "react";
import { WorkOrderPopupDetails } from "../workorders/WorkOrderPopupDetails";

interface Props {
  route: RouteComponentProps<any>;
}

const config: IListDefinition<RushRequestListModel> = {
  id: "rushrequests",
  singular: "Hasteforespørsler",
  createLink: _ => null,
  filters: [
    {
      title: "Kunde",
      field: "CustomerId",
      component: <CustomerFilter />,
      type: FilterType.Lookup,
      hidden: user => user.isCustomer
    },
    {
      title: "Behandlet",
      field: "#State",
      component: <BooleanFilter true="Bare behandlede" false="Kun ubehandlede" null="Alle" />,
      type: FilterType.TriStateBoolean
    },

  ],
  columns: [
    {
      field: "Id",
      title: "Id",
      sortable: true,
      alignment: Alignment.Right,
      render: (row) => <td className="right">{row.Id}</td>
    },
    {
      title: "Kunde",
      field: "CustomerName",
      sortable: true,
      render: (row) => <td className="no-wrap"><Link to={`/customers/${row.CustomerId}`}>{row.CustomerName}</Link></td>,
      hidden: user => user.isCustomer
    },
    {
      title: "Bruker",
      field: "UserName",
      sortable: true,
      render: (row, value, index, user) => <td className="no-wrap">{user.isCustomer ? row.UserName : <Link to={`/users/${row.UserId}`}>{row.UserName}</Link>}</td>
    },
    {
      title: "Tilknyttet",
      field: "WorkOrderId",
      alignment: Alignment.Right,
      sortable: true,
      render: (row) => <AssociatedCell row={row} />
    },
    {
      title: "Leveransedato",
      field: "EstimatedDeliveryDate",
      sortable: false,
      render: (row) => <td className="no-wrap">{toDateWithHM(row.EstimatedDeliveryDate, true)}</td>
    },
    {
      title: "Forespurt tid",
      field: "RequestedDate",
      sortable: true,
      render: (row) => <td className="no-wrap">{toDateWithHM(row.RequestedDate, true)}</td>
    },
    {
      title: "Begrunnelse",
      field: "Reason",
      sortable: false,
      render: (row) => <td>{row.Reason}</td>
    },
    {
      title: "Godtar overtid",
      field: "CustomerAcceptsOvertime",
      sortable: false,
      render: (row) => <td className="center">{row.CustomerAcceptsOvertime ? "Ja" : "Nei"}</td>
    },
    {
      title: "Handling",
      sortable: false,
      alignment: Alignment.Center,
      hidden: user => user.isCustomer,
      render: (row) => {
        if (row.State === RushRequestState.Received)
          return <td className="flex-center"><SmallButton onClick={e => markAsProcessing(row.Id)}><EyeIcon /><span>Indiker behandles</span></SmallButton></td>
        else if (row.State === RushRequestState.Processing) {
          return <td><select onChange={e => setRushRequestState(row.Id, e.target.value)}>
            <option value="">-- Velg handling --</option>
            <option value="approve">Godkjenn</option>
            <option value="reject">Avvis</option>
            <option value="processed">Avklart</option>
          </select></td>
        } else
          return <td className="center"><span >{row.Approved === true ? "Godkjent" : (row.Approved === false ? "Avvist" : "Avklart")}</span></td>
      }
    },
    {
      title: "Opprettet",
      field: "Created",
      sortable: true,
      render: (row) => <td className="no-wrap">{toDateWithHM(row.Created)}</td>
    },
    {
      title: "Status",
      sortable: false,
      hidden: user => !user.isCustomer,
      render: (row) => {
        if (row.State === RushRequestState.Received)
          return <td><span>Til behandling</span></td>
        else if (row.State === RushRequestState.Processing)
          return <td><span>Behandles</span></td>
        else
          return <td><span>{row.Approved === true ? "Godkjent" : (row.Approved === false ? "Avvist" : "Behandlet")}</span></td>
      }
    },
    {
      title: "Begrunnelse",
      sortable: false,
      field: "RejectReason",
      render: (row) => <td><span>{row.RejectReason}</span></td>
    }
  ],
  presets: [
    {
      default: true,
      filters: [{ field: "#State", filterType: FilterType.TriStateBoolean, value: false }],
      title: "Ubehandlede",
      sort: [{ direction: SortDirection.Desc, field: "Id" }]
    }
  ]
}

async function setRushRequestState(id: number, action: string) {
  const network = new Network();
  if (action === "approve")
    await network.get(`/api/rushrequests/${id}/approve`);
  else if (action === "processed")
    await network.get(`/api/rushrequests/${id}/processed`);
  else if (action === "reject")
    showRejectReasonDialog(id, network);
}

async function markAsProcessing(id: number) {
  const network = new Network();
  await network.get(`/api/rushrequests/${id}/processing`);
}

function showRejectReasonDialog(id: number, network: Network) {

  const dialog = new Dialog<{ reason: string }>();
  dialog.title = "Avvis hasteforespørsel";
  dialog.content = (props => <div><label>Begrunnelse:</label><br /><textarea className="rush-reject-dialog-text" value={props.reason} onChange={e => props.reason = e.target.value} /></div>);
  dialog.buttons = [
    {
      onClick: async (state, closeFn) => {
        await network.post<RushRequestRejectedModel, {}>("/api/rushrequests/reject", {
          Id: id,
          Reason: state.reason
        }, false);
        closeFn();
      },
      text: "Avvis forespørsel"
    }, {
      onClick: (_, close) => close(),
      text: "Avbryt"
    }
  ];

  Dialog.Show(dialog);
}

export class RushRequestsList extends React.Component<Props> {
  render() {
    return <>
      <DynamicPageTitle workSpaceType="list" singular="Hasteforespørsel" plural="Hasteforespørsler" />
      <BreadCrumb workSpaceType="list" singular="Hasteforespørsel" plural="Hasteforespørsler" routeBasePath="/rushrequests" />
      <List configuration={config} store={RootStore.getListStore(config)} />
    </>
  }
}

const AssociatedCell: React.FC<{ row: RushRequestListModel }> = ({ row }) => {

  const [isMouseOver, setIsMouseOver] = useState(false);
  const ref = useRef<HTMLTableCellElement>(null);

  const onEnter: React.MouseEventHandler<HTMLTableCellElement> = (ev) => setIsMouseOver(true);
  const onLeave: React.MouseEventHandler<HTMLTableCellElement> = (ev) => setIsMouseOver(false);

  const isWorkOrder = row.WorkOrderId != null;

  return <td className="right" onMouseEnter={isWorkOrder ? onEnter : undefined} onMouseLeave={isWorkOrder ? onLeave : undefined} ref={ref}>
    {row.WorkOrderId && <Link to={`/workorders/${row.WorkOrderId}`}>{toSerialNumber(EntityType.WorkOrder, row.WorkOrderId)}</Link>}
    {row.OrderId && <Link to={`/orders/${row.OrderId}`}>{toSerialNumber(EntityType.Order, row.OrderId)}</Link>}
    {isMouseOver && <WorkOrderPopupDetails workorder={({ Id: row.WorkOrderId! })} element={ref.current} />}
  </td>
}