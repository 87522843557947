import * as moment from "moment"
import * as React from "react"
import { Link } from "react-router-dom"
import { EntityType, toSerialNumber } from "../../../../core/stringHelpers"
import { UnitStateEntity } from "../../../../models/shared/UnitStateEntity"
import { DayViewUnitItem } from "./DayViewUnitItem"

export const DayViewWorkOrder: React.FC<{ workOrderId: string, items: Array<UnitStateEntity> }> = ({ items, workOrderId }) => {

  return <div className="day-wo-item">
    <Link to={`/workorders/${workOrderId}`}>{toSerialNumber(EntityType.WorkOrder, parseInt(workOrderId))}</Link>
    <Link to={`/customers/${items[0].Unit?.WorkOrder?.Customer?.Id}`}>{items[0].Unit?.WorkOrder?.Customer?.Name}</Link>
    <span>{moment(items[0].Unit?.WorkOrder?.Created).format("DD. MMM HH:mm")}</span>
    <span>{moment(items[0].Unit?.WorkOrder?.DeliveryDate).format("DD. MMM HH:mm")}</span>
    <div className="day-unit-list">
      {items.map(u => <DayViewUnitItem created={u.Created} unit={u.Unit!} workOrderId={workOrderId} key={u.UnitId} />)}
    </div>
  </div>
}
