import { isEmpty } from "lodash-es";

export function isRequired(message: string) {
  return (value: any, values: any) => {
    if (value == null)
      return message;
    if (typeof value == "string" && value.length == 0)
      return message;
    if (typeof value == "number")
      return null;
    if (!isEmpty(value))
      return null;
    return message;
  }  
}