import * as React from "react";
import { IListDefinition, Alignment, FilterType } from "../../../components/lists/IListDefinition";
import { List } from "../../../components/lists/List";
import { default as RootStore } from "../../../stores/root-store";
import { Link } from "react-router-dom";
import { since, toDateWithHM, toDate } from "ts/core/dateHelpers";
import { PageAlertListModel } from "../../../models/shared/PageAlertListModel";
import { SortDirection } from "../../../models/shared/SortDirection";


const config: IListDefinition<PageAlertListModel> = {
  id: "pagealerts",
  singular: "Sidevarsel",
  editLink: (row) => `/pagealerts/${row.Id}/edit`,
  createLink: _ => "/pagealerts/create",
  columns: [
    {
      field: "Id",
      sortable: true,
      title: "Id",
      render: (row, value) => row.Deleted == null ? <td>
        <Link to={`/pagealerts/${row.Id}/edit`}>{value}</Link></td> : <td>{value}</td>
    },
    {
      field: "Created",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(value)}>{toDateWithHM(value)}</span></td>,
      sortable: true,
      title: "Opprettet"
    }, {
      title: "Kunde",
      field: "CustomerName",
      sortable: true,
      render: (row) => <td>{row.CustomerId && <Link to={`/customers/${row.CustomerId}`}>{row.CustomerName}</Link>}</td>,
    }, {
      title: "Start",
      field: "Start",
      sortable: true,
      render: (row, value) => <td className="right no-wrap"><span title={since(row.Start)}>{toDateWithHM(value)}</span></td>,
    }, {
      title: "Stopp",
      field: "End",
      sortable: true,
      render: (row, value) => <td className="right no-wrap"><span title={since(row.End)}>{toDateWithHM(row.End)}</span></td>,
    }, {
      field: "Title",
      render: (row, value) => <td>{row.Title} {row.Message}</td>,
      title: "Beskjed"
    },
    {
      field: "Public",
      alignment: Alignment.Center,
      render: (row, value) => <td className="center">{value ? "Ja" : "Nei"}</td>,
      sortable: false,
      title: "Offentlig"
    },
    {
      field: "Removable",
      alignment: Alignment.Center,
      render: (row, value) => <td className="center">{value ? "Ja" : "Nei"}</td>,
      sortable: false,
      title: "Kan fjernes"
    },
  ],
  filters: [],
  presets: [{
    default: true,
    title: "Seneste",
    filters: [],
    sort: [{
      field: "Created",
      direction: SortDirection.Desc
    }]
  }]
}

export class PageAlertList extends React.Component {
  render() {
    return (
      <List configuration={config} store={RootStore.getListStore(config)} />
    )
  }
}

