import * as React from "react";
import { FormStore } from "../../../../stores/form-store";
import { toSerialNumber, EntityType } from "../../../../core/stringHelpers";
import { DataStore2 } from "../../../../stores/data-store";
import { Pending } from "../../../../components/shared/Pending";
import { toDateWithHM } from "../../../../core/dateHelpers";
import TrashIcon from "svg/trash.svg";
import { Form } from "../../../../components/forms/form";
import { ExtraWorkListModel } from "../../../../models/shared/ExtraWorkListModel";
import { EnumField } from "../../../../components/forms/fields/EnumField";
import { FormActions } from "../../../../components/forms/FormActions";
import { Enums } from "../../../../stores/lookup-store";
import { InputField } from "../../../../components/forms/fields/InputField";
import { ExtraWorkType } from "../../../../models/shared/ExtraWorkType";
import { ExtraWorkEditModel } from "../../../../models/shared/ExtraWorkEditModel";
import { Network } from "../../../../api/network";
import { observer } from "mobx-react";

interface ExtraWorkDialogProps {
  workOrderId: number;
  onCloseFn?: () => void;
}

@observer
export class ExtraWorkDialog extends React.Component<ExtraWorkDialogProps> {

  store: FormStore<ExtraWorkEditModel>;
  list: DataStore2<Array<ExtraWorkListModel>>;

  constructor(props: ExtraWorkDialogProps) {
    super(props);
    this.store = new FormStore<ExtraWorkEditModel>(
      { Amount: [value => value % 1 === 0 || value % 1 === 0.5 ? null : "Antall må være et heltal eller .5"] },
      { WorkOrderId: this.props.workOrderId });

    this.list = new DataStore2(`/api/extrawork/list/${props.workOrderId}`, ch => ch.entityName == "ExtraWorkEntity");
  }

  componentWillUnmount() {
    this.list.dispose();
  }

  removeRow(row: number) {
    const network = new Network();
    network.get(`/api/extrawork/delete/${row}`);
  }

  render() {
    const { onCloseFn, workOrderId } = this.props;

    const { data } = this.list;

    if (data == undefined)
      return <Pending />

    return <div className="rush-request-dialog">
      <p>Her kan du registrere ekstraarbeid for arbeidsordre {toSerialNumber(EntityType.WorkOrder, workOrderId)}.</p>

      {data != null && <div className="table-container">
        <table className="no-side-padding">
          <thead>
            <tr>
              <th>Beskrivelse</th>
              <th className="right">Antall</th>
              <th className="right">Dato</th>
              <th>Registrert av</th>
              <th className="center">Slett</th>
            </tr>
          </thead>
          <tbody>
            {data.map(row => <tr key={row.Id!}>
              <td>
                {row.Description}
              </td>
              <td className="right">
                <span>{row.Amount} {row.Amount > 1 ? "timer" : "time"}</span>
              </td>
              <td className="right">
                {toDateWithHM(row.Created)}
              </td>
              <td>
                {row.UserName}
              </td>
              <td className="center action" onClick={e => this.removeRow(row.Id!)}>
                <TrashIcon />
              </td>
            </tr>)}
          </tbody>
        </table></div>}
        <hr />
      <div>
        <Form
          name=""
          cancelable={true}
          onSubmit={async (router) => {
            const id = await this.store.postForm("/api/extrawork/add");
            if (id)
              onCloseFn!();
          }}
          onCancel={() => onCloseFn!()}
          store={this.store}>
          <EnumField<ExtraWorkEditModel>
            enumType={Enums.ExtraWorkType}
            field="WorkType"
            label="Arbeidstype"
            helpText="Her spesifiserer du type ekstraarbeid som har blitt utført"
            required={true}
            sort={false}
          />
          <InputField<ExtraWorkEditModel>
            field="Description"
            helpText="Beskrivelse av arbeidet du har utført"
            label="Beskrivelse"
            showIf={e => e.WorkType == ExtraWorkType.Other}
          />
          <InputField<ExtraWorkEditModel>
            field="Amount"
            label="Antall timer"
            helpText="Du kan føre halve timer, f.eks 2.5 timer"
            step="0.5"
            inputMode="decimal"
            type="number" />
          <FormActions asFooter={true} />
        </Form>
      </div>

    </div>
  }
}