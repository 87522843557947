import { observer } from "mobx-react";
import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Form } from "../../../components/forms/form";
import { FormStore, IValidationRules } from "../../../stores/form-store";
import { WorkOrderEditModel } from "../../../models/shared/WorkOrderEditModel";
import { InputField } from "../../../components/forms/fields/InputField";
import { FormActions } from "../../../components/forms/FormActions";
import { default as RootStore } from "../../../stores/root-store";
import { TextAreaField } from "../../../components/forms/fields/TextAreaField";
import { SelectField } from "../../../components/forms/fields/SelectField";
import { BooleanField } from "../../../components/forms/fields/BooleanField";
import { DateField } from "../../../components/forms/fields/DateField";
import { ContactsField } from "../../../components/forms/fields/ContactsField";
import { Lookup, Enums } from "../../../stores/lookup-store";
import { RalColorField, isValidRalColor } from "ts/components/forms/fields/RalColorField";
import { Network } from "../../../api/network";
import { OrderEditModel } from "../../../models/shared/OrderEditModel";
import { observable, toJS } from "mobx";
import { addWorkDays, formatDate } from "../../../core/dateHelpers";
import { showRalAndGloss } from "../../../core/util";
import { EnumField } from "../../../components/forms/fields/EnumField";
import { AddressField } from "../../../components/forms/fields/AddressField";
import { AddressType } from "../../../models/shared/AddressType";
import { Pending } from "../../../components/shared/Pending";
import { DescriptionLookupModel } from "../../../models/shared/DescriptionLookupModel";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { FilesField } from "../../../components/forms/fields/FilesField";
import { FormErrors } from "../../../components/forms/FormErrors";
import { MeasurementsField } from "../../../components/forms/fields/MeasurementsField";
import { TypeAheadField } from "../../../components/forms/fields/TypeaheadField";
import { DeliveryType } from "../../../models/shared/DeliveryType";

const validationRules: IValidationRules<WorkOrderEditModel> = {
  "RalCode": [(value, data) => !showRalAndGloss(data.PaintType) || isValidRalColor(value) ? null : `${value} er ikke en gyldig ral kode.`]
}

type P = {} & RouteComponentProps<{ id?: string, customerId?: string, orderId?: string, offerId?: string }>;

@observer
class WorkOrderEdit extends React.Component<P> {

  @observable store?: FormStore<WorkOrderEditModel>;
  @observable offers?: Array<DescriptionLookupModel>;
  type: "Create" | "Edit";

  constructor(props: P) {
    super(props);

    if (this.props.location.pathname.toLowerCase().indexOf("/edit") >= 0)
      this.type = "Edit";
    else
      this.type = "Create";

    this.initialize();
  }

  async initialize() {
    const network = new Network();
    const { customerId, orderId, offerId } = this.props.match.params;

    const defaultDeliveryDate = await network.getSimple<string>("/api/Lookup/AddWorkDays/5");

    let defaults: Partial<WorkOrderEditModel> = {
      Standard: 1,
      Corrosivity: 3,
      PaintType: 0,
      DeliveryDate: formatDate(defaultDeliveryDate, "YYYY-MM-DD") + "T15:00:00",
      DeliveryType: DeliveryType.CustomerPickup,
      OfferId: offerId != null && parseInt(offerId) > 0 ? parseInt(offerId) : undefined,
    };

    let cid = customerId == null ? null : parseInt(customerId);
    if (this.type == "Edit" && this.props.match.params.id) {
      this.store = new FormStore<WorkOrderEditModel>(validationRules, defaults);
      await this.store.loadModel("workorders", parseInt(this.props.match.params.id));
      cid = this.store.values.CustomerId!;
    } else if (this.type == "Create" && orderId) {
      const oid = parseInt(orderId);
      if (oid > 0) {
        const orderData = await network.get<OrderEditModel>(`/api/orders/${oid}`);
        if (orderData) {
          defaults = {
            ...defaults,
            InvoiceText: orderData.InvoiceReference,
            Standard: orderData.Standard,
            RalCode: orderData.RalCode,
            Information: orderData.Information,
            PaintType: orderData.Paint,
            Gloss: orderData.PaintGloss,
            DeliveryDate: orderData.WorkOrderDeliveryDate,
            OrderId: orderData.Id,
            Contacts: orderData.Contacts,
            GodsDescription: orderData.Description,
            CreateUnitCount: orderData.UnitCount,
            DeliveryAddress: orderData.DeliveryAddress,
            DeliveryType: orderData.DeliveryType,
            OfferId: orderData.OfferId,
            IsMeasurements: orderData.IsMeasurements,
            Measurement: {
              AboveTwoSquareMeters: orderData.AboveTwoSquareMeters !== 1,
              NumberOfParts: orderData.NumberOfParts
            },
            IsCertificate: orderData.IsCertificate,
            IsRush: orderData.IsRushRequest
          }
        }
        this.store = new FormStore<WorkOrderEditModel>(validationRules, defaults);
      }
      else
        this.store = new FormStore<WorkOrderEditModel>(validationRules, defaults);
    } else
      this.store = new FormStore<WorkOrderEditModel>(validationRules, defaults);

    let offerUrl = this.props.match.params.id !== undefined ? `/api/offers/availableOffersForCustomer/${cid}/${this.props.match.params.id}` : `/api/offers/availableOffersForCustomer/${cid}`
    const offers = await network.get<Array<DescriptionLookupModel>>(offerUrl);
    this.offers = offers || [];
  }

  render() {
    if (this.store == null || this.offers == null)
      return <Pending />;

    const isEdit = this.type === "Edit";

    if (isEdit && !this.store.modelHasLoaded)
      return <Pending />;

    const workOrderId = this.props.match.params.id;
    const customerId = isEdit ? this.store.values.CustomerId! : parseInt(this.props.match.params.customerId!);
    const customerName = RootStore.LookupStore.getItem(Lookup.Customers, customerId);

    const { isCustomer, isEmployeeAdministrator } = RootStore.UserStore;

    return (
      <div className="panel form-container">
        <Form
          store={this.store}
          name="Arbeidsordre"
          entityType={EntityType.WorkOrder}
          customerId={customerId}
          className="edit-form edit-form-padding">
          <h3>{`Arbeidsordre - ${customerName} ${workOrderId == null ? "" : (" - " + toSerialNumber(EntityType.WorkOrder, parseInt(workOrderId)))}`}</h3>
          {!isCustomer && <DateField<WorkOrderEditModel>
            field="DeliveryDate"
            label="Estimert leveringsdato"
            filterWorkDays={true}
            time={true}
            numberOfMonths={2}
            minDate={isEmployeeAdministrator ? undefined : new Date()}
          />}
          {!isCustomer && <BooleanField<WorkOrderEditModel>
            field="IsDeliveryDateConfirmed"
            label="Leveringsdato bekreftet"
            helpText="Indikerer om leveringsdatoen har blitt avtalt med kunden."
            showIf={values => values.IsRush === false}
          />}
          <EnumField<WorkOrderEditModel>
            field="DeliveryType"
            label="Transport etter varmforsinking"
            enumType={Enums.DeliveryType}
            required
            sort={false}
            useCheckBoxes
          />
          <AddressField<WorkOrderEditModel>
            field="DeliveryAddress"
            selectedCustomerId={this.store.values == null ? undefined : this.store.values.CustomerId}
            addressType={AddressType.Delivery}
            showNameField={true}
            showContactFields={true}
            label="Leveringsadresse"
            showIf={values => values.DeliveryType === DeliveryType.RåZinkDelivery}
          />
          <ContactsField<WorkOrderEditModel>
            field="Contacts"
            label="Kontakter"
            helpText="Her kan du spesifisere hvilke kontaktpersoner som skal kontaktes om denne ordren." />
          {!isCustomer && <TextAreaField<WorkOrderEditModel>
            field="Information"
            label="Informasjon"
            helpText="Informasjon om denne arbeidsordren" />}
          <FilesField<OrderEditModel>
            field="Documents"
            label="Filer"
            helpText="Her kan du legge ved dokumenter og tegninger knyttet til denne arbeidsordren."
          />
          <EnumField<WorkOrderEditModel>
            field="Corrosivity"
            sort={false}
            enumType={Enums.CorrosivityCategory}
            label="Korrosjonsklasse"
            required={true}
            helpText="Velg korrosjonsklasse i henhold til dine krav. Hvis du er usikker, benytt standard C3."
          />
          <EnumField<WorkOrderEditModel>
            field="PaintType"
            sort={false}
            enumType={Enums.PaintType}
            label="Lakkering"
            required={true}
            helpText="Spesifiser om det skal lakkeres etter varmforsinking."
          />
          <RalColorField<WorkOrderEditModel>
            field="RalCode"
            label="RAL kode"
            showIf={(values) => values.PaintType == 1 || values.PaintType == 2}
            helpText="Her spesifiserer du fargekode hvis du ønsker våt eller pulverlakkering." />
          <SelectField<WorkOrderEditModel>
            field="Gloss"
            label="Glans"
            showIf={(values) => values.PaintType == 1 || values.PaintType == 2}
            helpText="Her spesifiserer glans for våt eller pulverlakkering.">
            <option value="0">Ingen glans</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="60">60</option>
            <option value="70">70</option>
            <option value="80">80</option>
            <option value="90">90</option>
          </SelectField>
          <TypeAheadField<WorkOrderEditModel>
            field="InvoiceText"
            label="Fakturareferanse"
            helpText="Her kan du spesifisere din fakturareferanse, f.eks prosjektnummer e.l." />
          {!isCustomer && <TextAreaField<WorkOrderEditModel>
            field="GodsDescription"
            label="Beskrivelse av gods"
            helpText="Fyll inn informasjon om leveransen." />}
          <BooleanField<WorkOrderEditModel>
            field="IsCertificate"
            label="Samsvarserklæring"
            helpText="Ønsker samsvarserklæring (tidl. sertifikat)." />
          <BooleanField<WorkOrderEditModel> field="IsMeasurements" label="Måleprotokoll" helpText="Ønsker måleprotokoll." />
          <MeasurementsField<WorkOrderEditModel>
            field="Measurement"
            label="Måleprotokoll"
            helpText="Tekst"
            showIf={(values) => values.IsMeasurements === true}
          />
          <BooleanField<WorkOrderEditModel> field="IsStoreInside" label="Lagres innendørs" helpText="Varer må lagres innendørs." />
          {!isCustomer && <BooleanField<WorkOrderEditModel> field="IsRush" label="Haster" helpText="Indikerer at arbeidsordren har blitt godkjent som hastejobb. Settes vanligvis ved å godkjenne en hasteforespørsel." />}
          {!isCustomer && <InputField<WorkOrderEditModel>
            field="CreateUnitCount"
            label="Antall kolli"
            type="number"
            inputMode="numeric"
            showIf={() => !this.store!.isEdit || this.store?.values.UnitCount == 0}
            helpText="Oppretter oppgitt antall tomme kolli." />}
          <SelectField<WorkOrderEditModel>
            field="OfferId"
            label="Tilbud"
            helpText="Knytter arbeidsordren opp mot en kundes tilbud eller avtale">
            <option value="0">-- Velg tilbud eller avtale --</option>
            {this.offers.map(o => <option key={o.Id} value={o.Id}>{o.Name}</option>)}
          </SelectField>
          {isEmployeeAdministrator && <DateField<WorkOrderEditModel>
            field="Created"
            label="Mottatt"
            numberOfMonths={2}
            helpText="Lar administrator sette mottatt til et annet tidspunkt"
            time={true}
          />}
          {isEmployeeAdministrator && <BooleanField<WorkOrderEditModel>
            field="DisableNotifications"
            label="Skru av varsler"
            helpText="Skrur av varsler for denne arbeidsordren. Nyttig for f.eks etterregistrering eller feilhåndtering."
          />}
          {isEmployeeAdministrator && <>
            <BooleanField<WorkOrderEditModel>
              field="DoNotInvoice"
              label="Skal ikke faktureres"
              helpText="Fjerner varsler og mulighet for å fakturere denne jobben."
            />
            <TextAreaField<WorkOrderEditModel>
              field="DoNotInvoiceReason"
              label="Årsak for ingen faktura"
              helpText="Her fyller du inn årsaken til hvorfor denne arbeidsordren ikke skal faktureres"
              showIf={m => m.DoNotInvoice === true}
            />
          </>
          }
          <FormErrors type="Arbeidsordren" />
          <FormActions />
        </Form>
      </div>
    )
  }
}

const component = withRouter(WorkOrderEdit)

export {
  component as WorkOrderEditPage
}
