import { faAngleDown, faAngleUp, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import { set, sum } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import { default as RootStore } from "ts/stores/root-store";
import { Network } from "../../../api/network";
import { Button } from "../../../components/shared/Button";
import { InputWithFix } from "../../../components/shared/InputWithFix";
import { SmallButton } from "../../../components/shared/SmallButton";
import { NumberWithPostfix } from "../../../components/shared/display";
import { toDate } from "../../../core/dateHelpers";
import { useDataStore } from "../../../core/hooks/useDataStore";
import { BankReconciliationEntity } from "../../../models/shared/BankReconciliationEntity";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import { CardUsageType } from "../../../models/shared/CardUsageType";
import { FileDocumentType } from "../../../models/shared/FileDocumentType";
import { Enums } from "../../../stores/lookup-store";
import "./CardUsagePage.tsx.scss";
import { AbsoluteCenterPending, CenterPending, Pending } from "../../../components/shared/Pending";

export const CardUsagePage: React.FC = ({ }) => {

  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const { data, isLoading } = useDataStore<Array<BankReconciliationEntity>>("/api/economy/cardUsage", { entityName: "BankReconciliationEntity" });

  return <div className="page-card-usage">
    {isLoading && <div className="panel"><CenterPending /></div>}
    {!isLoading && data && <div className="panel">
      {data.length === 0 && <p className="flex flex-center">Det er ingen korttransaksjoner å registrere</p>}
      {data.map((item, i) => <div key={item.BankTransactionId} className="item">
        <div className="header" onClick={() => setOpenIndex(openIndex === i ? null : i)}>
          <span>{toDate(item.PostedDate)}</span>
          <span className="item-amount"><NumberWithPostfix post="kr" decimals={2} number={item.Amount} /></span>
          <span className="item-description">{item.Description}</span>
          <FontAwesomeIcon icon={openIndex === i ? faAngleUp : faAngleDown} />
        </div>
        <Collapse isOpened={openIndex === i}>
          <CardUsageBody {...item} />
        </Collapse>
      </div>)}
    </div>}
  </div>
}

interface Posting {
  type: CardUsageType;
  amount: string;
  vat: number;
}

const CardUsageBody: React.FC<BankReconciliationEntity> = ({ Amount, BankTransactionId }) => {

  const [description, setDescription] = useState("");
  const [postings, setPostings] = useState<Array<Partial<Posting>>>([{ amount: Amount.toFixed(2).replace(".", ",") }]);
  const inputFile = React.createRef<HTMLInputElement>();
  const [isPending, setIsPending] = useState(false);

  const [cardUsageTypes, setCardUsageTypes] = useState<Array<BaseLookupModel>>([]);

  useEffect(() => {
    const fn = async () => {
      const result = await RootStore.LookupStore.getEnums(Enums.CardUsageType, true);
      setCardUsageTypes(result ?? []);
    }
    fn();
  }, []);

  const onSubmit = async () => {
    const network = new Network();
    try {
      setIsPending(true)
      await network.post("/api/economy/addCardUsage", {
        BankTransactionId: BankTransactionId,
        Description: description,
        Items: postings.map(p => ({ type: p.type, amountInclVat: getAmount(p.amount), vat: +(p.vat!) })),
        Documents: Array.from(inputFile.current?.files ?? []).map(f => ({
          Name: f.name,
          ContentType: f.type,
          Size: f.size,
          File: f,
          DocumentType: FileDocumentType.Unknown
        }))
      }, true);
    } finally {
      setIsPending(false);
    }
  }

  const onRemove = async () => {
    const network = new Network();
    await network.getSimple("/api/economy/ignoreCardTransaction/" + BankTransactionId);
  }

  const hasVat: Array<CardUsageType> = [CardUsageType.Driftsmidler, CardUsageType.Rekvesita, CardUsageType.Reisekostnader];

  const getAmount = (amount?: string) => {
    if (amount === undefined)
      return 0;
    amount = amount.replace(",", ".");
    if (Number.isNaN(amount))
      return 0;
    return +amount;
  }

  const total = sum(postings.map(p => getAmount(p.amount)));
  const diff = Amount - total;

  return <div className="body">
    <form className="edit-form">
      <div>
        <label>Beskrivelse til regnskapsfører (hva, formål, hvem)</label>
        <textarea value={description} onChange={v => setDescription(v.target.value)} />
      </div>
      <div className="poster" >
        <label>Poster</label>
        {postings.map((p, i) => <div className="post" key={i}>
          <select value={p.type ?? ""} onChange={e => setPostings(set([...postings], `[${i}].type`, e.target.value))} className="posting-type">
            <option value="">-- Velg kjøpstype --</option>
            {cardUsageTypes.map(u => <option value={u.Id} key={u.Id}>{u.Name}</option>)}
          </select>
          <InputWithFix postfix="kr" value={p.amount} onChange={e => setPostings(set([...postings], `[${i}].amount`, e.target.value))} className="posting-amount" />
          {hasVat.some(f => p.type && f === +p.type) && <select className="short-select posting-tax" value={p.vat ?? ""} onChange={e => setPostings(set([...postings], `[${i}].vat`, e.target.value))} >
            <option>-- Moms --</option>
            <option value="1">25%</option>
            <option value="11">15%</option>
            <option value="13">12%</option>
          </select>}
          {i > 0 && <SmallButton onClick={() => setPostings([...postings].filter((_, index) => i !== index))} className="posting-delete"><FontAwesomeIcon icon={faTrash} /></SmallButton>}
        </div>
        )}
        <div className="add">
          <div><SmallButton onClick={() => setPostings([...postings, { amount: diff.toFixed(2).replace(".", ",") }])}><FontAwesomeIcon icon={faPlus} />Legg til ny rad</SmallButton></div>
          {diff != 0 && <span>Avvik: {diff.toFixed(2).replace(".", ",")} kr</span>}
        </div>
      </div>
      <div className="attachment2">
        <label>Kvittering</label>
        <input type="file" ref={inputFile} />
      </div>
      <div className="actions">
        <Button text="Bokfør" onClick={onSubmit} className="primary" disabled={isPending}></Button>
        <Button text="Fjern" onClick={onRemove}></Button>
      </div>
    </form>
  </div>

} 