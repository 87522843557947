import * as React from "react";
import { useEffect, useState } from "react";
import { MeasurementData } from "../../../models/shared/MeasurementData";
import { MeasurementNumberOfParts } from "../../../models/shared/MeasurementNumberOfParts";
import { PropsFromWrapperToField, Wrap } from "../../forms/wrapField";
import { InputWithFix } from "../../shared/InputWithFix";
import "./MeasurementsField.tsx.scss";
interface OwnProps {
}

interface MeasurementValue {
  value: string;
  hasError: boolean;
}

type P = PropsFromWrapperToField<MeasurementData> & OwnProps;
type TGrid = Array<Array<Array<MeasurementValue>>>;

const MeasurementsField: React.FC<P> = ({ value, setValue, setTouched }) => {


  const mapped = (value?.Measurements ?? []).map(a => a.map(r => r.map(s => ({ value: s.toString(), hasError: false }))));
  const [grid, setGrid] = useState<TGrid>(mapped);
  const [numberOfArticleSelection, setNumberOfArticleSelection] = useState(value?.NumberOfParts ?? MeasurementNumberOfParts._To500);
  const [isAboveTwoSqmSelection, setIsAboveTwoSqmSelection] = useState(value?.AboveTwoSquareMeters ?? true);

  useEffect(() => {

    let numberOfArticles = getNumberOfArticles(numberOfArticleSelection);
    let numberOfReferenceAreasPerArticle = isAboveTwoSqmSelection ? 3 : 1;
    let numberOfSamplesInAReferenceArea = 5;

    let updatedGrid = [...Array(numberOfArticles)].map((_, articleNum) =>
      [...Array(numberOfReferenceAreasPerArticle)].map((_, referenceAreaNum) =>
        [...Array(numberOfSamplesInAReferenceArea)].map((_, sampleNum) => {
          let existing = grid[articleNum]?.[referenceAreaNum]?.[sampleNum];
          let fromValue = value?.Measurements?.[articleNum]?.[referenceAreaNum]?.[sampleNum];
          return existing ?? fromValue ?? { value: "", hasError: false }
        })
      ));

    setGrid(updatedGrid);
    __setValue(updatedGrid);
  }, [numberOfArticleSelection, isAboveTwoSqmSelection]);

  const _setValue: TSetValue = (articleId, referenceId, measurementId, value) => {
    let values = [...grid];
    values[articleId][referenceId][measurementId] = { value, hasError: false };
    setGrid(values);

    setTouched();

    __setValue(values);
  }

  const __setValue = (_grid: TGrid) => {
    let v = {
      AboveTwoSquareMeters: isAboveTwoSqmSelection,
      NumberOfParts: numberOfArticleSelection,
      Measurements: _grid.map(a => a.map(r => r.map(s => ~~parseInt(s.value)))) // ~~ ensures nan converts to 0 - google it.
    };    
    setValue(v);
  }

  // for each article(taken separately) in the control sample,
  // the mean coating thickness within the reference areas shall be equal to or greater than the mean coating thickness values in Table 3.

  let averagesPerArticle = value?.Measurements?.map(a => a.map(r => calculateAverage(r))) ?? [];
  let allAverages = averagesPerArticle.flat();
  let calculatedAverageAll = calculateAverage(allAverages);
  let minAverageForArticle = Math.min(...averagesPerArticle.map(a => calculateAverage(a)));
  let minAverageForAll = Math.min(...averagesPerArticle.flatMap(a => a));


  return <div className="field-measurements">
    <div className="field-measurements-options">
      <div className="form-field-component">
        <label>Antall artikler</label>
        <select className="short-select" value={numberOfArticleSelection} onChange={e => setNumberOfArticleSelection(parseInt(e.target.value))}>
          <option value="1">En</option>
          <option value="2">To</option>
          <option value="3">Færre enn 500</option>
          <option value="4">Mellom 500 og 1200</option>
          <option value="5">Mellom 1200 og 3200</option>
          <option value="6">Mellom 3200 og 10 000</option>
          <option value="7">Over 10 000</option>
        </select>
      </div>
      <div className="form-field-component">
        <label>
          <input type="checkbox" checked={isAboveTwoSqmSelection} onChange={e => setIsAboveTwoSqmSelection(e.target.checked)} />Har overflate over 2 m<sup>2</sup>
        </label>
      </div>
    </div>
    <div className="field-measurements-articles">
      {grid.map((article, i) => <MeasurementArticle key={i} article={article} id={i} setValue={_setValue} />)}
    </div>
    <div>
      <span>Målt lokal: <b>{minAverageForAll} μm</b></span><br />
      <span>Målt gjennomsnitt: <b>{isAboveTwoSqmSelection ? minAverageForArticle : calculatedAverageAll} μm</b></span>
    </div>
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Type og tykkelse</th>
            <th className="right">Krav lokal (minimum)</th>
            <th className="right">Krav gjennomsnitt (minimum)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Stål {">"} 6 mm</td>
            <td className="right">70 μm</td>
            <td className="right">85 μm</td>
          </tr>
          <tr>
            <td>Stål {">"} 3 mm til {'\u2264'} 6 mm</td>
            <td className="right">55 μm</td>
            <td className="right">70 μm</td>
          </tr>
          <tr>
            <td>Stål {'\u2265'} 1.5 mm til {'\u2264'} 3 mm</td>
            <td className="right">45 μm</td>
            <td className="right">55 μm</td>
          </tr>
          <tr>
            <td>Stål {"<"} 1.5 mm</td>
            <td className="right">35 μm</td>
            <td className="right">45 μm</td>
          </tr>
          <tr>
            <td>Støpt {'\u2265'} 6 mm</td>
            <td className="right">70 μm</td>
            <td className="right">80 μm</td>
          </tr>
          <tr>
            <td>Støpt {"<"} 6 mm</td>
            <td className="right">60 μm</td>
            <td className="right">70 μm</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}
type TSetValue = (articleId: number, referenceId: number, measurementId: number, value: string) => void;

const MeasurementArticle: React.FC<{ article: Array<Array<MeasurementValue>>; id: number; setValue: TSetValue }> = ({ article, id, setValue }) => {

  const averages = article.map(r => getAverageForReferenceAreas(r));
  const totalAverage = averages.some(a => a == null) ? null : calculateAverage(averages as Array<number>);

  return <div className="field-measurements-article">
    <span><b>Artikkel {id + 1}</b></span>
    {article.map((reference, i) => {
      return <div className="field-measurements-referenceArea" key={i}>
        <span>Referanseområde {i + 1}</span>
        {reference.map((sample, n) =>
          <ReferencePoint
            reference={sample}
            onChange={v => setValue(id, i, n, v)}
            key={n} />)}
        <InputWithFix postfix="μm" value={getAverageForReferenceAreas(reference) ?? ""} readOnly disabled className="short-input" />
      </div>
    })}
    <div className="field-measurements-referenceArea sum-article-average">
      <InputWithFix postfix="μm" value={totalAverage ?? ""} readOnly disabled className="short-input" />
    </div>
  </div>
}

const ReferencePoint: React.FC<{ reference: MeasurementValue, onChange: (v: string) => void }> = ({ reference, onChange }) => {
  return <InputWithFix postfix="μm" type="number" value={reference.value} onChange={e => onChange(e.target.value)} max={999} />
}

function getAverageForReferenceAreas(references: Array<MeasurementValue>) {
  if (!references.every(r => r?.value != null && r.value.length > 0))
    return null;
  return calculateAverage(references.map(r => parseInt(r.value)));
}

function getNumberOfArticles(numberOfParts: MeasurementNumberOfParts) {
  switch (numberOfParts) {
    case MeasurementNumberOfParts.One:
      return 1;
    case MeasurementNumberOfParts.Two:
      return 2;
    case MeasurementNumberOfParts._To500:
      return 3;
    case MeasurementNumberOfParts._501To1200:
      return 5;
    case MeasurementNumberOfParts._1201To3200:
      return 8;
    case MeasurementNumberOfParts._3201To10000:
      return 13;
    default:
      return 20;
  }
}

function calculateAverage(values: Array<number>) {
  if (values.some(v => v == 0))
    return 0;
  const sum = values.reduce((a, b) => a + b, 0);
  return Math.round(sum / values.length);
}

const A = Wrap<P, MeasurementData>(MeasurementsField)
export { A as MeasurementsField };