import * as React from "react";
import LoadingSvg from "svg/loading.svg";
import "./Pending.tsx.scss";

export function Pending() {
  return <LoadingSvg style={{ "width": 30 }} />;
}

export const AbsoluteCenterPending: React.FC = () => {
  return (
    <div className="full-center-pending">
      <Pending />
    </div>)
}

export const CenterPending = () => {
  return (<div className="center-pending">
    <Pending />
  </div>)
}