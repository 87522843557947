/**  */
export enum UnitState {
  
  /** */
  Received = 100,  
  /** */
  Checked = 110,  
  /** */
  Preparing = 120,  
  /** */
  Prepared = 130,  
  /** */
  Stop = 200,  
  /** */
  Canceled = 210,  
  /** */
  Sandblast = 220,  
  /** */
  External = 260,  
  /** */
  Packaging = 300,  
  /** */
  Packaged = 310,  
  /** */
  Ready = 320,  
  /** */
  Delivered = 330,  
  /** */
  Paint = 360
}

