import * as React from "react";
import "./detailsblock.scss";

interface Props {
  title: string;
}

export class DetailsBlock extends React.Component<React.PropsWithChildren<Props>> {
  static typeName = "DetailsBlock";

  render() {
    return (
      <div className="details-block">
        <h4>{this.props.title}</h4>
        {this.props.children}
      </div>)
  }
}