import * as React from "react";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { RouteComponentProps } from "react-router";
import { UserList } from "./UserList";
import { UserEdit } from "./UserEdit";
import { UserDetailsPage } from "./UserDetails";
import { ChangePassword } from "./ChangePassword";

interface Props {
  route: RouteComponentProps<{}>;
}

export class Users extends React.Component<Props> {
  render() {
    return <WorkSpace
      singular="bruker"
      plural="brukere"
      routeBasePath={this.props.route.location.pathname.split("/")[1]}
      {...this.props.route}
      editComponent={<UserEdit />}
      listComponent={<UserList />}
      additionalRoutes={[{ path: "/users/changePassword", component: ChangePassword }]}
      detailsComponent={<UserDetailsPage />}
    />
  }
}