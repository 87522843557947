import moment from "moment";
import React, { useEffect, useState } from "react";
import { Network } from "../../../api/network";
import { CustomBreadCrumb } from "../../../components/shared/BreadCrumb";
import { MonthNavigator } from "../../../components/shared/MonthNavigator";
import { CenterPending } from "../../../components/shared/Pending";
import { NumberWithPostfix } from "../../../components/shared/display";
import { PayslipModel } from "../../../models/shared/PayslipModel";
import { PayslipResultModel } from "../../../models/shared/PayslipResultModel";
import "./Payment.tsx.scss";
import { sum } from "lodash-es";
import { useOnEntityChange } from "../../../core/hooks/useOnEntityChange";
import { Button } from "../../../components/shared/Button";
import { SalaryTransactionModel } from "../../../models/shared/SalaryTransaction";

export const EmployeePayment: React.FC = () => {
  const [month, setMonth] = React.useState<Date>(moment().startOf('month').toDate());
  const [payslips, setPayslips] = useState<PayslipResultModel>();
  const [transactions, setTransaction] = useState<Array<SalaryTransactionModel>>([]);
  const [isSubmitPending, setIsSubmitPending] = useState(false);

  const loadPayslips = async () => {
    const client = new Network();
    const result = await client.getSimple<PayslipResultModel>(`/api/salary/draft/${month.getFullYear()}/${month.getMonth() + 1}`);
    if (result)
      setPayslips(result);

    const result2 = await client.getSimple<Array<SalaryTransactionModel>>(`/api/salary/transactions/${month.getFullYear()}/${month.getMonth() + 1}`);
    if (result2)
      setTransaction(result2);

  }

  const onCreatePayslips = async () => {
    const client = new Network();
    try {
      setIsSubmitPending(true);
      await client.get<number>(`/api/salary/submit/${month.getFullYear()}/${month.getMonth() + 1}`);
    } finally {
      setIsSubmitPending(false);
    }
  }

  useEffect(() => { loadPayslips() }, [month]);
  useOnEntityChange("SalaryItemEntity", () => loadPayslips());

  return <div className="page-employees-payment">
    <h1>Lønnsutbetaling</h1>
    <CustomBreadCrumb links={[{ title: "Økonomi", path: null }, { title: "Lønnsutbetaling", path: null }]} />
    <div className="panel form-container panel-padding">
      <MonthNavigator month={month} onChange={setMonth} />
      {payslips == null && <CenterPending />}
      {payslips != null && (
        <div className="payslip-container">
          <div className="payslips">
            {payslips!.Payslips?.map(p => <Payslip payslip={p} key={p.Employee!.Id} />)}
          </div>
          <div className="details">
            <h4>Informasjon</h4>
            <table>
              <tbody>
                <Row title="Fra" content={moment(payslips.From).format("DD. MMMM yy")} />
                <Row title="Til" content={moment(payslips.To).format("DD. MMMM yy")} />
                <Row title="Ansatte" content={<NumberWithPostfix post="stk" number={payslips.Payslips?.length} />} />
                <Row title="Arbeidsdager" content={<><NumberWithPostfix post="dager" number={payslips.WorkDays} /> (<NumberWithPostfix post="timer" number={(payslips.WorkDays ?? 0) * 7.5} />)</>} />
              </tbody>
            </table>
            {transactions.length > 0 && <div>
              <b>Utbetalinger:</b><br />
              <table>
                {transactions.map(t => <tr>
                  <td><a href={`${window.flytSettings.tripletexBaseUrl}/execute/salary?salaryTransactionId=${t.Id}&contextId=${window.flytSettings.tripletexContextId}`} target="_blank">Tripletex</a></td>
                  <td className="right">{t.Date}</td>
                  <td className="right"><NumberWithPostfix post="kr" decimals={0} number={t.Total} /></td>
                </tr>)}
              </table>
            </div>}
            <Button text="Opprett lønnslipper" onClick={() => onCreatePayslips()} disabled={isSubmitPending}></Button>
          </div>
        </div>
      )}
    </div>
  </div>
}

const Row: React.FC<{ title: string, content: React.ReactElement | string }> = ({ title, content }) => {
  return <tr>
    <td>{title}:</td>
    <td>{content}</td>
  </tr>
}

const Payslip: React.FC<{ payslip: PayslipModel }> = ({ payslip }) => {

  const deducibleHours = sum(payslip.DeductibleHours!.map(d => d.Hours));

  return <div className="payslip">
    <h4>{payslip.Employee?.Name} - <EmployeeType payslip={payslip} /></h4>
    <hr></hr>
    <ul className="notifications">
      {payslip.Notifications.map((n, i) => <li key={i}>{n}</li>)}
    </ul>
    {!payslip.HasPayments && <p>Ingen utbetaling</p>}
    {payslip.HasPayments && <div>
      {payslip.Employee!.IsMonthly && payslip.SkipMonthlyPayment && <PayslipRow title="Månedslønn" hours={0} reason="Skal ikke utbetales" />}
      {payslip.DeductibleHours?.map((d, i) => <PayslipRow
        key={i}
        title={`Trekk`}
        hours={d.Hours * -1}
        reason={d.Reason}
        style={payslip.Employee?.IsMonthly ? "normal" : "italic"}
        showZero={false} />)}
      {!payslip.Employee!.IsMonthly && <PayslipRow title="Arbeidstimer" hours={payslip.WorkHours - deducibleHours} reason={`(${payslip.WorkHours}t - ${deducibleHours}t)`} />}
      {(payslip.CustomHours ?? []).map(h => <PayslipRow title="Ekstratimer" hours={h.Hours} reason={h.Reason} />) }
      <PayslipRow title="Overtid" hours={payslip.Overtid} />
      <PayslipRow title="Overtid (100%)" hours={payslip.Overtid100} />
    </div>}
  </div>
}

interface PayslipRowProps {
  title: string;
  hours: number;
  reason?: string;
  style?: "normal" | "sum" | "italic";
  showZero?: boolean;
}

const PayslipRow: React.FC<PayslipRowProps> = ({ title, hours, reason, style, showZero }) => {

  if (showZero === false && hours === 0)
    return null;

  return <div className={`payslip-row payslip-row-${style ?? "normal"}`}>
    <span>{title}</span>
    <span className="right"><NumberWithPostfix post="timer" number={hours} decimals={1} /></span>
    <span>{reason}</span>
  </div>
}

const EmployeeType: React.FC<{ payslip: PayslipModel }> = ({ payslip }) => {
  if (payslip.Employee?.IsMonthly)
    return "Månedslønnet";
  else if (payslip.Employee?.IsPartTime)
    return "Tilkallingshjelp";
  else if (payslip.Employee?.HasCustomHours)
    return "Fører egne timer";
  else
    return "Timeslønnet";
}