import * as React from "react";
import { RouteComponentProps, Switch, Route } from "react-router";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { List } from "../../../components/lists/List";
import { IListDefinition, Alignment, FilterType } from "../../../components/lists/IListDefinition";
import { default as RootStore } from "../../../stores/root-store";
import { WorkOrderListModel } from "../../../models/shared/WorkOrderListModel";
import { Link } from "react-router-dom";
import { MaxLengthString } from "../../../components/shared/MaxLengthString";
import { since, stringToDate, formatDate, maxDate, getDeliveryDate } from "../../../core/dateHelpers";
import { TextFilter } from "../../../components/lists/FilterComponents/TextFilter";
import { DateRangeFilter } from "../../../components/lists/FilterComponents/DateRangeFilter";
import { StateFilter } from "../../../components/lists/FilterComponents/StateFilter";
import { SortDirection } from "../../../models/shared/SortDirection";
import * as moment from "moment";
import { CustomerFilter } from "../../../components/lists/FilterComponents/CustomerFilter";
import { WorkOrderDetailsPage } from "./WorkOrderDetailsPage";
import "./workorderpage.tsx.scss";
import { EditUnitPage } from "../units/EditUnitPage";
import { WorkOrderEditRouter } from "./WorkOrderEditRouter";
import EyeIcon from "svg/eye.svg";
import PaintIcon from "svg/paint.svg";
import TransportIcon from "svg/transport.svg";
import SyncIcon from "svg/sync.svg";
import InvoiceIcon from "svg/invoice.svg";
import PhoneIcon from "svg/phone-call.svg";
import CrossIcon from "svg/menuClose.svg";
import classNames from "classnames";
import { ControlCountAggregate } from "../../../models/shared/ControlCountAggregate";
import { isWeightPossiblyInvalid } from "./workOrderHelpers";
import { SimpleBooleanFilter } from "../../../components/lists/FilterComponents/SimpleBooleanFilter";
import { NumberWithPostfix } from "../../../components/shared/display";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { PaintType } from "../../../models/shared/PaintType";
import { paintDescription, ScreenSize } from "../../../core/util";
import { BooleanFilter } from "../../../components/lists/FilterComponents/BooleanFilter";
import { UnitDayPage } from "../units/UnitDayPage";
import { SelectFilter } from "../../../components/lists/FilterComponents/SelectFilter";
import { WorkOrderState } from "../../../models/shared/WorkOrderState";
import { MessageListCell } from "../../../components/shared/messages/MessageDialog";
import { WorkOrderPopupDetails } from "./WorkOrderPopupDetails";
import { useRef, useState } from "react";
import { PlanPage } from "./planner/PlanPage";
import { DayView } from "./dayView/DayView";
import { Earnings } from "./earnings/Earnings";
import { DialogListPopupDialog } from "../../../components/dialogs/DialogListPopupDialog";
import { DeliveryType } from "../../../models/shared/DeliveryType";

interface Props {
  route: RouteComponentProps<any>;
}

const config: IListDefinition<WorkOrderListModel> = {
  id: "workorders",
  singular: "Arbeidsordre",
  createLink: _ => null,
  filters: [
    {
      title: "Periode",
      field: "Created",
      component: <DateRangeFilter />,
      type: FilterType.DateRange
    },
    {
      title: "Kunde",
      field: "CustomerId",
      component: <CustomerFilter />,
      type: FilterType.Lookup,
      hidden: user => user.isCustomer
    },
    {
      title: "Status",
      field: "ComputedState",
      component: <StateFilter />,
      type: FilterType.StateFilter
    },
    {
      title: "Id",
      field: "#Id",
      component: <TextFilter />,
      type: FilterType.String,
      group: "Text"
    },
    {
      title: "Referanse",
      field: "InvoiceText",
      component: <TextFilter />,
      type: FilterType.String,
      group: "Text"
    },
    {
      title: "Lakkering",
      field: "#Paint",
      component: <SimpleBooleanFilter label="" />,
      type: FilterType.TriStateBoolean,
      group: "Boolean",
      hidden: user => user.isCustomer
    },
    {
      title: "Transport",
      field: "#Transport",
      component: <SelectFilter>
        <option value=""></option>
        <option value="missingorder">Mangler bestilling</option>
        <option value="transport">Skal transporteres</option>
      </SelectFilter>,
      type: FilterType.String,
      hidden: user => user.isCustomer,
      group: 'select1'
    },
    {
      title: "Faktura",
      field: "#MissingInvoice",
      component: <BooleanFilter true="Fakturert" false="Mangler faktura" null="" />,
      type: FilterType.TriStateBoolean,
      hidden: user => !user.isEmployeeAdministrator,
      group: 'select1'
    },
  ],
  columns: [
    {
      field: "Id",
      title: "Id",
      sortable: true,
      alignment: Alignment.Right,
      render: (row) => <td className="right"><Link to={`/workorders/${row.Id}/edit`}>{toSerialNumber(EntityType.WorkOrder, row.Id)}</Link></td>
    },
    {
      title: "Kunde",
      field: "CustomerName",
      sortable: true,
      render: (row) => {
        const customerHasSeen = row.AdditionalData?.LastSeen?.some(r => r.CustomerId != null) === true;
        const isSynced = row.AdditionalData?.Borgeskogen?.SyncTime != null;
        return (<td className={classNames({ "is-rush": row.IsRush })}>
          <Link to={`/customers/${row.CustomerId}`}>{row.CustomerName}</Link>
          {customerHasSeen && <EyeIcon className="eye icon" />}
          {isSynced && <SyncIcon className="sync-icon icon" />}
        </td>);
      },
      hidden: user => user.isCustomer
    },
    {
      title: "Refereanse",
      field: "InvoiceText",
      render: (row) => <td><MaxLengthString text={row.InvoiceText} length={18} /></td>
    },
    {
      title: "Mottatt",
      field: "Created",
      sortable: true,
      alignment: Alignment.Right,
      render: (row) => {
        return <td className="right"><span title={since(row.Created)}>{formatDate(row.Created, "DD.MM.YYYY")}</span></td>;
      }
    },
    {
      title: "Status",
      render: (row, value, index, user) => {

        const state = row.Aggregate!.State!;
        let externalStatus = null;
        if (state?.StateType == WorkOrderState.External && !user.isCustomer) {
          externalStatus = row.AdditionalData?.Borgeskogen?.CurrentState;
        }
        if (state?.StateType == WorkOrderState.Paint && !user.isCustomer)
          externalStatus = row.AdditionalData?.Industrilakk?.CurrentState;

        return <RowStatusIndicator row={row} externalStatus={externalStatus} />
      }
    },
    {
      title: "",
      sortable: false,
      field: "IsMissingTransportOrder",
      alignment: Alignment.Right,
      render: (row) => {

        const content = () => {
          if (row.IsMissingTransportOrder) {
            const isDone = row.Aggregate?.State?.StateType === WorkOrderState.Ready || row.Aggregate?.State?.StateType === WorkOrderState.Delivered;
            return <Link to={{ pathname: `/workorders/${row.Id}`, state: { tab: "transport" } }}>
              <PhoneIcon className={classNames("icon", { "icon-warning": isDone })} />
            </Link>;
          }
          else
            return <TransportIcon disabled={row.DeliveryType == DeliveryType.CustomerPickup} className="icon mirror" />
        }

        return <td className="center narrow">{content()}</td>
      },
      hidden: user => user.isCustomer
    },
    {
      title: "",
      sortable: false,
      field: "HasMessages",
      alignment: Alignment.Center,
      render: (row) => <Messages row={row} />,
      hidden: user => user.isCustomer
    },
    {
      title: "",
      sortable: false,
      field: "PaintType",
      alignment: Alignment.Center,
      render: (row) => <td className="center narrow"><span title={paintDescription(row.PaintType, row.RalCode)}><PaintIcon className="icon" disabled={row.PaintType == PaintType.None} /></span></td>
    },
    {
      title: "Indikatorer",
      render: (row) => <td>{generateStateIcons(row.Aggregate!.ControlCount!)}</td>
    },
    {
      title: size => size === ScreenSize.Laptop ? "Est. levering" : "Estimert levering",
      field: "DeliveryDate",
      sortable: true,
      alignment: Alignment.Right,
      render: (row, value, index, user) => {

        if (row.Aggregate?.State?.StateType === WorkOrderState.Canceled)
          return <td />

        const localDate = stringToDate(row.DeliveryDate);
        const hasPassed = localDate.getTime() < Date.now();

        let externalEstimatedDone = null;
        if (row.Aggregate?.State?.StateType == WorkOrderState.External && !user.isCustomer) {
          externalEstimatedDone = row.AdditionalData?.Borgeskogen?.EstimatedDone;
        }

        let dateToShow = getDeliveryDate(user.isCustomer, row.DeliveryDate, row.IsDeliveryDateConfirmed, row.EstimatedDelivery); //maxDate(row.DeliveryDate, row.EstimatedDelivery);

        // For customer, show the latest estimated delivery date
        if (row.Aggregate?.State?.StateType == WorkOrderState.External && user.isCustomer) {
          if (row.AdditionalData?.Borgeskogen?.EstimatedDone != null) {
            const external = row.AdditionalData?.Borgeskogen?.EstimatedDone;
            if (stringToDate(external) > localDate)
              dateToShow = external;
          }
        }

        const isToLate = externalEstimatedDone != null && localDate < stringToDate(externalEstimatedDone);

        let color = undefined;
        if (isToLate)
          color = "#e3acfd";
        else if (hasPassed)
          color = "#FFFF99";

        const date = stringToDate(dateToShow);
        const isMidnight = date.getHours() === 0 && date.getMinutes() === 0;
        const format = isMidnight ? "DD.MM.YYYY" : "DD.MM.YYYY HH:mm";

        return <td className={classNames("right", "no-wrap", { "confirmed": row.IsDeliveryDateConfirmed })} style={{ backgroundColor: color }}>
          <span title={since(row.DeliveryDate)}>{formatDate(date, format)}</span>
        </td>
      }
    },
    {
      title: size => size === ScreenSize.Laptop ? "" : "Kolli",
      field: "Aggregate",
      sortable: true,
      alignment: Alignment.Right,
      sortField: "Aggregate.IncomingUnitCount",
      render: (row) => <td className="right">{`${row.Aggregate!.IncomingUnitCount}/${row.Aggregate!.OutgoingUnitCount}`}</td>
    },
    {
      title: "Innveid",
      field: "Aggregate",
      sortField: "Aggregate.IncomingWeight",
      sortable: true,
      alignment: Alignment.Right,
      render: (row) => <td className="right"><NumberWithPostfix number={row.Aggregate!.IncomingWeight} post="kg" /></td>
    },
    {
      title: "Utveid",
      sortable: true,
      field: "Aggregate",
      sortField: "Aggregate.OutgoingWeight",
      alignment: Alignment.Right,
      render: (row, value, index, user) => {
        let { OutgoingWeight, IncomingWeight } = row.Aggregate!;
        const warning = OutgoingWeight != null && isWeightPossiblyInvalid(IncomingWeight, OutgoingWeight);

        let italic = false;
        if (OutgoingWeight == 0 && !user.isCustomer) {
          const afterWeight = row.AdditionalData?.Borgeskogen?.AfterWeight;
          if (afterWeight != null && afterWeight > 0) {
            OutgoingWeight = afterWeight;
            italic = true;
          }
        }

        return <td className={classNames("right", { "italic": italic })} style={{ color: warning ? "red" : undefined }}><NumberWithPostfix number={OutgoingWeight} post="kg" /></td>
      }
    },
    {
      title: "",
      sortable: false,
      field: "HasInvoice",
      alignment: Alignment.Center,
      render: (row) => {
        if (row.DoNotInvoice === true)
          return <td className="center narrow no-invoice">{<CrossIcon className="icon" />}</td>
        else
          return <td className="center narrow">{<InvoiceIcon disabled={!row.HasInvoice} className="icon" />}</td>
      },
      hidden: (user, filters) => !user.isEmployeeAdministrator
    }
  ],

  presets: [
    {
      title: "Id",
      default: user => user.isCustomer,
      filters: [
        {
          field: "Created",
          value: {
            from: moment().add(-6, "month").startOf("month").format("YYYY-MM-DD")
          },
          filterType: FilterType.DateRange
        }
      ],
      sort: [{ field: "Id", direction: SortDirection.Desc }]
    },
    {
      title: "Levering",
      default: user => !user.isCustomer,
      filters: [
        {
          field: "Created",
          value: { from: moment().add(-1, "month").startOf("month").format("YYYY-MM-DD") },
          filterType: FilterType.DateRange
        },
        {
          field: "ComputedState",
          value: [
            WorkOrderState.Received,
            WorkOrderState.Checked,
            WorkOrderState.Production,
            WorkOrderState.External,
            WorkOrderState.Sandblast,
            WorkOrderState.Stop,
            WorkOrderState.Canceled,
            WorkOrderState.Paint,
          ],
          filterType: FilterType.StateFilter
        }
      ],
      sort: [{ field: "DeliveryDate", direction: SortDirection.Desc }, { field: "Id", direction: SortDirection.Desc }]
    },
    {
      title: "Fakturering",
      default: false,
      filters: [
        {
          field: "ComputedState",
          value: [
            WorkOrderState.Ready,
            WorkOrderState.Delivered,
          ],
          filterType: FilterType.StateFilter
        }, {
          field: "#MissingInvoice",
          value: false,
          filterType: FilterType.TriStateBoolean
        }
      ],
      sort: [{ field: "DeliveryDate", direction: SortDirection.Asc }],
      hidden: user => !user.isEmployeeAdministrator
    }
  ]
}

export class WorkOrderPage extends React.Component<Props> {
  render() {
    const routeBasePath = this.props.route.location.pathname.split("/")[1];

    return (
      <>
        <Switch>
          <Route path="/workorders/:workOrderId/units/create" exact component={EditUnitPage} />
          <Route path="/workorders/:workOrderId/units/:id/edit" exact component={EditUnitPage} />
          <Route path="/workorders/state" exact component={UnitDayPage} />
          <Route path="/workorders/days" exact component={DayView} />
          <Route path="/workorders/plan" exact component={PlanPage} />
          <Route path="/workorders/earnings" exact component={Earnings} />
          <Route render={(r) => <WorkSpace
            singular="arbeidsordre"
            plural="arbeidsordre"
            routeBasePath={this.props.route.location.pathname.split("/")[1]}
            {...this.props.route}
            editComponent={<WorkOrderEditRouter {...r} />}
            detailsComponent={<WorkOrderDetailsPage />}
            listComponent={<List configuration={config} store={RootStore.getListStore(config)} />}
            additionalRoutes={[{ path: "/workorders/create/:customerId/:orderId/:offerId", component: WorkOrderEditRouter }]}
          />} />
        </Switch>


      </>
    );
  }
}

function generateStateIcons(row: ControlCountAggregate) {
  const icons = [];
  icons.push(getIcon(row.AcidPocket, "L", "Syrelomme"));
  icons.push(getIcon(row.ConstructionError, "K", "Feil konstruksjon"));
  icons.push(getIcon(row.ContainsZink, "Z", "Sinkavbrenning"));
  icons.push(getIcon(row.Damaged, "Ø", "Skadet"));
  icons.push(getIcon(row.DoubleDip, "D", "Dobbeldupp"));
  icons.push(getIcon(row.MissingAttachmentHoles, "H", "Mangler opphengshull"));
  icons.push(getIcon(row.MissingDrainageHoles, "V", "Mangler dreneringshull"));
  icons.push(getIcon(row.Degrease, "F", "Avfetting"));
  icons.push(getIcon(row.RemovePaint, "M", "Maling"));
  icons.push(getIcon(row.Sandblast, "S", "Sandblåsing"));
  icons.push(getIcon(row.Refurbish, "R", "Rehabilitering"));
  icons.push(getIcon(row.Other, "A", "Annet"));
  return <span className="indicator-icons">{icons}</span>;
}

function getIcon(count: number, icon: string, title: string) {
  return <span key={icon} title={`${title} - ${count} kolli`} className={count > 0 ? "enabled" : "disabled"}>{icon}</span>;
}

const RowStatusIndicator: React.FC<{ row: WorkOrderListModel, externalStatus: string | null | undefined }> = ({ row, externalStatus }) => {

  const [isMouseOver, setIsMouseOver] = useState(false);
  const ref = useRef<HTMLTableCellElement>(null);

  const state = row.Aggregate!.State!;

  const onEnter: React.MouseEventHandler<HTMLTableCellElement> = (ev) => setIsMouseOver(true);
  const onLeave: React.MouseEventHandler<HTMLTableCellElement> = (ev) => setIsMouseOver(false);

  return <td className={`state-indicator state-bg-color-${state.StateType}`} onMouseEnter={state.StateType > WorkOrderState.Received ? onEnter : undefined} onMouseLeave={state.StateType > WorkOrderState.Received ? onLeave : undefined} ref={ref}>
    <Link to={`workorders/${row.Id}`}>
      <span>{row.Aggregate!.State!.Label}</span>
      {externalStatus && <span className="external" title={externalStatus}>{externalStatus}</span>}
    </Link>
    {isMouseOver && <WorkOrderPopupDetails workorder={row} element={ref.current} />}
  </td>
}

const Messages: React.FC<{ row: WorkOrderListModel }> = ({ row }) => {

  const [isMouseOver, setIsMouseOver] = useState(false);
  const ref = useRef<HTMLTableCellElement>(null);

  const onEnter: React.MouseEventHandler<HTMLTableCellElement> = (ev) => setIsMouseOver(true);
  const onLeave: React.MouseEventHandler<HTMLTableCellElement> = (ev) => setIsMouseOver(false);

  return <td className="center narrow" ref={ref} onMouseOver={row.HasMessages ? onEnter : undefined} onMouseLeave={row.HasMessages ? onLeave : undefined}>
    <MessageListCell id={row.Id} type="WorkOrderEntity" hasMessages={row.HasMessages} />
    {isMouseOver && <DialogListPopupDialog id={row.Id} element={ref.current} type="WorkOrderEntity" />}
  </td>
}