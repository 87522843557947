import * as React from "react";
import { FilterProps } from "../IListDefinition";
import { observer } from "mobx-react";
import { BaseModel } from "../../../models/shared/BaseModel";

interface OwnProps {
  label: string;
}

type P = FilterProps<BaseModel> & OwnProps;

@observer
class SimpleBooleanFilter extends React.Component<P>{

  setValue = (checked: boolean) => {
    const { store, field } = this.props;
    store.setFilter(field, checked);
  }

  render() {
    const { store, field, label } = this.props;
    const value = store.getFilterValue<boolean>(field);

    return <label>{label}<input type="checkbox" checked={value ?? false} onChange={e => this.setValue(e.target.checked)} /></label>

  }
}

const A = SimpleBooleanFilter as any as React.ComponentClass<OwnProps>;

export {
  A as SimpleBooleanFilter,
  SimpleBooleanFilter as SimpleBooleanFilterInternal
};