import * as React from "react";
import { Form } from "../../../components/forms/form";
import { FormStore } from "../../../stores/form-store";
import { DateField } from "../../../components/forms/fields/DateField";
import { TextAreaField } from "../../../components/forms/fields/TextAreaField";
import { RushRequestEditModel } from "ts/models/shared/RushRequestEditModel";
import { isRequired } from "../../../core/validationHelpers";
import { FormActions } from "../../../components/forms/FormActions";
import { nextWorkDate } from "../../../core/dateHelpers";
import "./RushRequestDialog.tsx.scss";
import { BooleanField } from "../../../components/forms/fields/BooleanField";
import { EntityType } from "../../../core/stringHelpers";

interface RushRequestProps {
  workOrderId?: number;
  notBeforeDate: Date;
  onCloseFn?: () => void;
}

export class RushRequestDialog extends React.Component<RushRequestProps> {

  store: FormStore<RushRequestEditModel>;

  constructor(props: RushRequestProps) {
    super(props);
    this.store = new FormStore<RushRequestEditModel>({
      RequestedDate: [isRequired("Du må oppgi en dato for fremskyndelsen.")]
    }, { WorkOrderId: this.props.workOrderId, CustomerAcceptsOvertime: true });
  }

  render() {
    const { onCloseFn, notBeforeDate } = this.props;

    return <div className="rush-request-dialog">
      <p>Her kan du be om en fremskyndet leveringsdato for din ordre.
        Gunnet annen produksjon er vi ikke i stand til å fremskynde alle ordre.
        Du vil motta en epost om din forespørsel har blitt akseptert eller ikke.
        Se gjeldende prisliste for kostnader knyttet til hastejobber.</p>
      <div>
        <Form
          name=""
          entityType={EntityType.RushRequest}
          cancelable={true}
          onSubmit={async (router) => {
            const id = await this.store.postForm("/api/rushrequests/add");
            if (id)
              onCloseFn!();
          }}
          onCancel={() => onCloseFn!()}
          store={this.store}>
          <DateField<RushRequestEditModel>
            time={true}
            filterWorkDays={true}
            label="Ønsket ferdigstillelsetid"
            minDate={notBeforeDate}
            numberOfMonths={2}
            field="RequestedDate"
            openToDate={nextWorkDate()}
            helpText="Her spesifiserer du når du ønsker jobben ferdigstillt."
          />
          <TextAreaField<RushRequestEditModel>
            field="Reason"
            label="Informasjon"
            helpText="Eventuell informasjon for hasteforespørselen f.eks årsak til fremskyndelsen e.l."
          />
          <BooleanField<RushRequestEditModel>
            field="CustomerAcceptsOvertime"
            label="Overtid"
            helpText="Det godtas at RåZink jobber overtid for å nå ønsket ferdigstillelsetid. Dette medfører økte kostnader, se gjeldene prisliste."
          />
          <FormActions asFooter={true} />
        </Form>
      </div>

    </div>
  }
}