import React from "react";
import { NavLink } from "react-router-dom";
import { Pending } from "../../../../components/shared/Pending";
import { toDate } from "../../../../core/dateHelpers";
import { useDataStore } from "../../../../core/hooks/useDataStore";
import { EntityType, toSerialNumber } from "../../../../core/stringHelpers";
import { ListQuery } from "../../../../models/shared/ListQuery";
import { ListQueryResponse } from "../../../../models/shared/ListQueryResponse";
import { WorkOrderListModel } from "../../../../models/shared/WorkOrderListModel";
import { default as RootStore } from "../../../../stores/root-store";
import { NumberWithPostfix } from "../../../../components/shared/display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { ControlCountAggregate } from "../../../../models/shared/ControlCountAggregate";
import { warning } from "ionicons/icons";
import { SortDirection } from "../../../../models/shared/SortDirection";

export const AktiveArbeidsordre: React.FC = () => {

  const { data: ordre, isFetching, isSuccess, isError } = useDataStore<ListQueryResponse<WorkOrderListModel>, ListQuery>(
    { url: "/api/workorders/list", data: { Filters: [{ Field: "ComputedState", Value: [100, 150, 200, 380, 220, 360, 260, 110, 320], FilterType: 5 }], Count: 100, Offset: 0, SortField: [{ Id: "DeliveryDate", Direction: SortDirection.Desc }] } },
    { entityName: "WorkOrderEntity" });

  return <div className="box w4">
    <h2>Aktive ordre</h2>
    {isError && <p>Feil ved henting av ordre</p>}
    {isFetching && <Pending />}
    {isSuccess && ordre?.Result != null &&
      <div className="table-container"><table className="full-width table-hand">
        <thead>
          <tr>
            <th>Id</th>
            <th>Referanse</th>
            <th>Status</th>
            <th></th>
            <th className="right">Vekt</th>
            <th className="right">Estimert ferdig</th>
          </tr>
        </thead>
        <tbody>
          {ordre.Result.map(wo => {
            const warnings = hasWarning(wo.Aggregate!.ControlCount!);
            return (
              <tr key={wo.Id} onClick={() => RootStore.RouterStore.push(`/workorders/${wo.Id}`)}>
                <td>{toSerialNumber(EntityType.WorkOrder, wo.Id)}</td>
                <td>{wo.InvoiceText}</td>
                <td>{wo.Aggregate!.State!.Label}</td>
                <td>{warnings.length > 0 ? <span title={warnings.join("\r\n")}><FontAwesomeIcon icon={faTriangleExclamation} /></span> : null}</td>
                <td className="right no-wrap"><NumberWithPostfix number={Math.max(wo.Aggregate?.IncomingWeight ?? 0, wo.Aggregate?.OutgoingWeight ?? 0)} post="kg" /></td>
                <td className="right no-wrap">{toDate(wo.DeliveryDate)}</td>
              </tr>)
          })}
        </tbody>
      </table></div>}
    <NavLink className="info" to="/workorders">Se alle ordre</NavLink>
  </div>
}

function hasWarning(count: ControlCountAggregate) {
  const errors = [];
  if (count.AcidPocket > 0)
    errors.push("Syrelomme");
  if (count.ConstructionError > 0)
    errors.push("Produksjonsfeil");
  if (count.ContainsZink > 0)
    errors.push("Inneholder sink");
  if (count.Damaged > 0)
    errors.push("Skadet");
  if (count.Degrease > 0)
    errors.push("Krever avfetting");
  if (count.MissingAttachmentHoles > 0)
    errors.push("Mangler opphengshull");
  if (count.MissingDrainageHoles > 0)
    errors.push("Mangler dreneringshull");
  if (count.Other > 0)
    errors.push("Annet");
  if (count.Refurbish > 0)
    errors.push("Krever rehabilitering");
  if (count.RemovePaint > 0)
    errors.push("Inneholder maling");
  if (count.Sandblast > 0)
    errors.push("Må sandblåses");

  return errors;
}