import * as React from "react";
import classNames from "classnames";
import CaretIcon from "svg/caret.svg";
import "./button.scss";

interface Props {
  text: string;
  onClick?: (ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: any;
  options?: Array<{ text: string, onClick: () => void }>
  className?: string;
  title?: string;
  disabled?: boolean;
}

interface State {
  expanded: boolean;
}

export class Button extends React.Component<Props, State> {

  state = { expanded: false };

  onClick: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.preventDefault();
    if (this.props.options)
      this.setState(state => ({ expanded: !state.expanded }));
    else if (this.props.onClick)
      this.props.onClick(ev);
  }

  onBlur: React.FocusEventHandler<HTMLButtonElement> = ev => this.setState({ expanded: false });

  onItemClick = (fn: () => void) => {
    this.setState({ expanded: false });
    fn && fn();
  }

  render() {

    const { title, text, icon: Icon, options, className, disabled } = this.props;
    const { expanded } = this.state;
    
    return (
      <div className="dropdown-button">
        <button
          type="button"
          disabled={disabled}
          className={classNames("button", className)}
          aria-expanded={expanded}
          title={title}
          onClick={this.onClick}
          onBlur={this.onBlur}>
          {!React.isValidElement(Icon) && <span>{Icon && <Icon className="icon" />}{text}</span>}
          {React.isValidElement(Icon) && <span>{React.cloneElement(Icon, { className: 'icon'} as any)}{text}</span>}
          {options && options.length > 0 && <CaretIcon className={classNames("icon", "carret", { "expanded": expanded })} />}
        </button>
        {options && expanded && <ul>
          {options.map((o, i) => <li key={i} onMouseDown={e => this.onItemClick(o.onClick)}>{o.text}</li>)}
        </ul>}
      </div>

    )
  }
}