import * as React from "react";
import "./WorkOrderPopupDetails.tsx.scss";
import { useEffect, useRef } from "react";
import { createPopper, Instance, right } from '@popperjs/core';
import { Network } from "../../../api/network";
import { WorkOrderDetailsModel } from "../../../models/shared/WorkOrderDetailsModel";
import { Direction } from "../../../models/shared/Direction";
import { FileReferenceModel } from "../../../models/shared/FileReferenceModel";
import { getImageUrl } from "../../../core/util";
import { ImageSize } from "../../../models/shared/ImageSize";

interface P {
  workorder: { Id: number };
  element: HTMLElement | null;
  unitId?: number;
}

export const WorkOrderPopupDetails: React.FC<P> = (props) => {
  const { workorder } = props;
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [images, setImages] = React.useState<Array<Array<FileReferenceModel>>>([]);

  useEffect(() => {
    let popper: Instance | null = null;

    if (props.element && popupRef.current && images.length > 0) {
      popper = createPopper(props.element, popupRef.current, {
        placement: 'right',
        modifiers: [
          { name: 'offset', options: { offset: [0, 10] } },
          { name: 'preventOverflow' },
        ]
      });
      popupRef.current.style.display = "block";
    }
    return () => popper ? popper.destroy() : undefined;
  }, [images]);

  useEffect(() => {
    let controller = new AbortController();
    let fn = async () => {
      const network = new Network();
      const result = await network.get<WorkOrderDetailsModel>(`/api/workorders/${workorder.Id}/details`, controller);
      if (result) {
        setImages(result.Units
          .filter(u => u.Direction == Direction.Incoming || props.unitId != null)
          .filter(u => u.Id === props.unitId || props.unitId == null)
          .map(u => (u.Images ?? []).slice(0, 3)));
      }
    }
    fn();

    return () => controller.abort();
  }, []);


  const isVideo = (model: FileReferenceModel) => model.ContentType?.toLowerCase().startsWith("video");

  return <div ref={popupRef} className="wo-preview">
    {images.map((unit, i) => <div className="wo-preview-unit" key={i}>
      {unit.map(img => {
        if (isVideo(img))
          return <video aria-label={img.Name} src={getImageUrl(img, ImageSize.Original)} loop autoPlay muted />
        else
          return <div key={img.Id} role="img" style={{ backgroundImage: `url("${getImageUrl(img, ImageSize.Preview)}")` }} aria-label={img.Name}></div>
      })}
    </div>)}
  </div>;
}