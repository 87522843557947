/**  */
export enum TransportUnitType {
  
  /** */
  Pallet = 0,  
  /** */
  HalfPallet = 1,  
  /** */
  Other = 2
}

