import * as React from "react";
import { ListProps } from "./List";
import { observer } from "mobx-react";
import { BaseModel } from "../../models/shared/BaseModel";
import { compute as computeScrollIntoView } from 'compute-scroll-into-view';
import { canUseKeyboard } from "../../core/util";

type P = ListProps<BaseModel>;

@observer
export class Pagination extends React.Component<P> {

  setPage(page: number | string) {
    if (typeof page == "number") {
      this.props.store.setOffset((page - 1) * this.props.store.pageSize);
      const node = document.querySelector<HTMLDivElement>('.list-area');
      if (node) {
        const actions = computeScrollIntoView(node, {
          scrollMode: 'if-needed',
          block: 'start',
          inline: 'nearest',
        });
        actions.forEach(({ el, top, left }) => {
          el.scrollTo({
            top,
            left,
            behavior: "smooth"
          });
        })
      }
    }
  }

  constructor(props: P) {
    super(props);
    document.addEventListener("keydown", this.keyboardEvent);
  }

  keyboardEvent = (event: KeyboardEvent) => {
    if (canUseKeyboard(event)) {

      const { store } = this.props;

      if (store.values == null)
        return;

      const totalNumberOfItems = store.values.TotalCount;
      const pageSize = store.pageSize;
      const startIndex = store.values.Offset;

      const numberOfPages = Math.ceil(totalNumberOfItems / pageSize);
      const currentPage = Math.ceil(startIndex / pageSize) + 1;

      if (event.key == "ArrowLeft") {
        if (currentPage > 1)
          this.setPage(currentPage - 1);
      }
      if (event.key == "ArrowRight") {
        if (currentPage < numberOfPages)
          this.setPage(currentPage + 1);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyboardEvent);
  }

  render() {
    const { store } = this.props;

    if (store.values == null)
      return null;

    const totalNumberOfItems = store.values.TotalCount;
    const pageSize = store.pageSize;
    const startIndex = store.values.Offset;

    const numberOfPages = Math.ceil(totalNumberOfItems / pageSize);
    const currentPage = Math.ceil(startIndex / pageSize) + 1;

    const pages = paginationItems(currentPage, numberOfPages);

    return (
      <nav className="paging" role="navigation" aria-label="Tabellnavigering">
        <ul>
          {pages.length > 1 && (
            <>
              <li aria-disabled={currentPage == 1} onClick={e => currentPage > 1 && this.setPage(currentPage - 1)} tabIndex={0}>&#171;</li>
              {pages.map((page, idx) =>
                <li
                  key={idx}
                  aria-disabled={typeof page === "string"}
                  onClick={e => page != currentPage && this.setPage(page)}
                  tabIndex={0}
                  aria-selected={page == currentPage}>
                  <span>{page}</span>
                </li>)}
              <li aria-disabled={currentPage == numberOfPages} onClick={e => currentPage != numberOfPages && this.setPage(currentPage + 1)} tabIndex={0}>&#187;</li>
            </>)
          }
        </ul>
      </nav>
    )
  }
}

// https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
function paginationItems(currentPage: number, numberOfPages: number): Array<string | number> {
  let delta = 3,
    range = [],
    rangeWithDots = [],
    l;

  range.push(1);

  if (numberOfPages <= 1) {
    return range;
  }

  for (let i = currentPage - delta; i <= currentPage + delta; i++) {
    if (i < numberOfPages && i > 1) {
      range.push(i);
    }
  }
  range.push(numberOfPages);

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}
