import type { ListQueryResponse } from "../models/shared/ListQueryResponse";
import type { IObservableArray } from "mobx";
import { observable, computed, action, reaction, toJS } from "mobx";
import { SortField } from "../models/shared/SortField";
import type { IListDefinition, IListPreset } from "../components/lists/IListDefinition";
import { FilterType } from "../components/lists/IListDefinition";
import { BaseModel } from "../models/shared/BaseModel";
import { SortDirection } from "../models/shared/SortDirection";
import { Network, isValidationResultValue } from "../api/network";
import { ListQuery } from "../models/shared/ListQuery";
import { NotificationHub } from "../core/signalR";
import { entityNameToControllerName } from "../core/util";

export class ListStore<TListModel extends BaseModel> {
  @observable values?: ListQueryResponse<TListModel>;
  @observable offset: number;
  @observable pageSize: number;
  @observable filters: Map<keyof TListModel, any>; //Array<{ field: string; value: any }>;
  @observable sortField: IObservableArray<SortField>;
  @observable activePreset?: IListPreset<TListModel>;
  @observable isLoading: boolean;

  private configuration: IListDefinition<TListModel>;
  private _network: Network;
  private _releaseObject: any;
  private _abortController: AbortController;

  constructor(configuration: IListDefinition<TListModel>) {

    this.pageSize = parseInt(this.getStorageProperty(`${configuration.id}_pagesize`) || "10");
    this.offset = 0;
    this.filters = new Map();
    this.configuration = configuration;
    this.isLoading = false;
    this._network = new Network();
    this.sortField = observable([]);
    this._abortController = new AbortController();

    // set default preset
    const lastPreset = this.getStorageProperty(`${this.configuration.id}_preset`);
    let preset = this.configuration.presets.find(preset => preset.title === lastPreset);
    if (preset == null)
      preset = this.configuration.presets.find(preset => preset.default);
    this.setPreset(preset);

    reaction(
      () => [this.pageSize, [...this.filters.values()], toJS(this.sortField), this.activePreset],
      _ => this.loadData(),
      /*{ fireImmediately: true }*/
    );

    this._releaseObject = NotificationHub.registerListener((changes => {
      if (changes.some(ch => entityNameToControllerName(ch.entityName) == this.configuration.id)) {
        this.loadData();
      } else if (changes.some(ch => (ch.entityName === "UserEntity" || ch.entityName === "WorkOrderEntity")
        && this.configuration.id === "customers"))
        this.loadData(); // list of customers shows associated users and workorders

      if (this.configuration.id === "addresses" && changes.some(ch => ch.entityName === "AddressEntity"))
        this.loadData();

      if (this.configuration.id === "monthlycosts" && changes.some(ch => ch.entityName === "MonthlyOperatingCostEntity"))
        this.loadData();
    }));

  }

  @computed get hasFilters() {
    return this.filters.size > 0;
  }

  @action.bound clearFilters() {
    this.filters.clear();
    this.activePreset = undefined;

    // hack for å gjøre det mindre forvirrende for Leif
    if (this.configuration.presets && this.configuration.presets.length > 0) {
      this.sortField.replace(this.configuration.presets[0].sort.map(s => ({ Id: s.field as string, Direction: s.direction })));
    }
  }

  @action.bound setFilter(field: keyof TListModel, value: any) {
    if (value === null || value === undefined)
      this.filters.delete(field);
    else
      this.filters.set(field, value);

    // When changing a filter, reset paging to 0
    this.offset = 0;

    // Also remove set preset, as the current filtering no longer matches the preset
    this.setPreset(undefined);
  }

  getFilterValue<T>(field: keyof TListModel) {
    return this.filters.get(field) as T | undefined;
  }

  @action.bound
  toggleSort(field: keyof TListModel | string, direction: SortDirection | undefined = undefined) {
    const f = this.sortField.find(f => f.Id == field);
    if (f == null)
      this.sortField.replace([{ Id: field, Direction: direction ?? SortDirection.Asc } as SortField]);
    else {
      f.Direction = f.Direction == SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
      this.sortField.replace([f]);
    }
    this.offset = 0;
    this.setPreset(undefined);
  }

  @action.bound
  setPreset(preset?: IListPreset<TListModel>) {
    if (preset == null)
      this.activePreset = undefined;
    else {
      this.clearFilters();
      preset.filters.forEach(filter => this.setFilter(filter.field as keyof TListModel, filter.value));
      this.sortField.replace(preset.sort.map(s => ({ Id: s.field as string, Direction: s.direction })));
      this.offset = 0;
      this.activePreset = preset;
    }

    this.setStorageProperty(`${this.configuration.id}_preset`, this.activePreset?.title ?? "");
  }

  @computed get activePresetTitle() {
    const p = this.activePreset ? this.activePreset.title : "Ingen";
    return p;
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.setStorageProperty(`${this.configuration.id}_pagesize`, pageSize.toString());
  }

  @action.bound async setOffset(offset: number) {
    this.offset = offset;
    await this.loadData(); // do this here so we don't end up in a loop
  }

  private async loadData() {
    this.isLoading = true;

    this._abortController.abort();
    this._abortController = new AbortController();

    const baseUrl = this.configuration.baseUrl || `/api/${this.configuration.id}`;
    const listSegment = this.configuration.listUrlSegment ?? "list";
    const result = await this._network.post<ListQuery, ListQueryResponse<TListModel>>(`${baseUrl}/${listSegment}`, {
      Count: this.pageSize,
      Filters: Array.from(this.filters.keys()).map(key => {
        const f = this.configuration.filters.find(f => f.field === key);
        return {
          Field: key.toString(),
          Value: this.filters.get(key),
          FilterType: key.toString() == "Deleted" || key.toString() == "DoNotInvoice" ? FilterType.TriStateBoolean : (f != null ? f.type : FilterType.Lookup)
        };
      }
      ),
      Offset: this.offset,
      SortField: this.sortField
    }, false, this._abortController);
    this.isLoading = false;
    if (!isValidationResultValue(result) && result != null) {
      this.values = result;
      this.offset = result.Offset;
    }
  }

  async delete(id: number) {
    const baseUrl = this.configuration.baseUrl || `/api/${this.configuration.id}`;
    await this._network.get<number>(`${baseUrl}/delete/${id}`);
  }

  async undelete(id: number) {
    const baseUrl = this.configuration.baseUrl || `/api/${this.configuration.id}`;
    await this._network.get<number>(`${baseUrl}/undelete/${id}`);
  }

  async refresh() {
    await this.loadData();
  }

  release() {
    if (this._releaseObject)
      NotificationHub.removeListener(this._releaseObject)
  }

  getStorageProperty(property: string) {
    return localStorage.getItem(property);
  }

  setStorageProperty(property: string, value: string) {
    localStorage.setItem(property, value);
  }
}