import * as React from "react";
import { FilterProps } from "../IListDefinition";
import { xor } from "lodash-es";
import IconArrowDouble from "svg/arrow-double.svg";
import { observer } from "mobx-react";
import { BaseModel } from "../../../models/shared/BaseModel";
import "./statefilter.tsx.scss";
import { WorkOrderState } from "../../../models/shared/WorkOrderState";

type P = FilterProps<BaseModel>;

@observer
class StateFilter extends React.Component<P>{

  render() {
    const { store, field } = this.props;
    const value = store.getFilterValue<Array<WorkOrderState>>(field) || [];
    return (
      <ul className="filter-state">
        <StateFilterItem label="Mottatt" states={[WorkOrderState.Received]} value={value} setValue={v => store.setFilter(field, v)} />
        <StateFilterItem label="Kontrollert" states={[WorkOrderState.Checked]} value={value} setValue={v => store.setFilter(field, v)} />
        <StateFilterItem label="I produksjon" states={[WorkOrderState.Production]} value={value} setValue={v => store.setFilter(field, v)} />
        <StateFilterItem label="Ferdig" states={[WorkOrderState.Ready]} value={value} setValue={v => store.setFilter(field, v)} />
        <StateFilterItem label="Hentet" states={[WorkOrderState.Delivered]} value={value} setValue={v => store.setFilter(field, v)} />
        <StateFilterItem label="Ekstern" states={[WorkOrderState.Sandblast, WorkOrderState.Paint, WorkOrderState.External]} value={value} setValue={v => store.setFilter(field, v)} />
        <StateFilterItem label="Stopp" states={[WorkOrderState.Canceled, WorkOrderState.Stop, WorkOrderState.Complaint]} value={value} setValue={v => store.setFilter(field, v)} />
      </ul>
    )
  }
}

const A = StateFilter as any as React.ComponentClass<{}>;

export { A as StateFilter };

const StateFilterItem: React.FC<{ label: string, states: Array<WorkOrderState>, value: Array<WorkOrderState>, setValue: (value: Array<WorkOrderState>) => void }> =
  ({ label, states, value, setValue }) =>
    <li>
      <label>
        <input type="checkbox" onChange={_ => setValue(xor(value, states))} checked={value.some(v => states.includes(v))} />
        {label}
      </label>
      <IconArrowDouble onClick={() => setValue(states)} />
    </li>;
