import * as React from "react";
import { RouteComponentProps, Switch, Route } from "react-router";
import { DynamicPageTitle } from "../../../components/shared/DynamicPageTitle";
import { PrivilegedRoute } from "../../main";
import { CustomBreadCrumb } from "../../../components/shared/BreadCrumb";
import { MothlyCostOverview } from "./MonthlyCostOverview";
import { DueInvoices } from "./DueInvoices";
import { CardUsagePage } from "./CardUsagePage";

interface Props {
  route: RouteComponentProps<{}>;
}

export class FinancePage extends React.Component<Props> {
  render() {
    return <>
      <Switch>
        <PrivilegedRoute path="/finance/monthly" exact>
          <DynamicPageTitle workSpaceType="details" singular="Månedlige oversikt" plural="Månedlige kostnader" />
          <CustomBreadCrumb links={[{ title: "Månedlig oversikt", path: null }]} />
          <MothlyCostOverview />
        </PrivilegedRoute>
        <PrivilegedRoute path={["/finance/dueInvoices"]} exact>
          <DynamicPageTitle workSpaceType="list" singular="Forfalte fakturaer" plural="Forfalte fakturaer" />
          <CustomBreadCrumb links={[
            { title: "Forfalte fakturaer", path: null }]} />
          <DueInvoices />
        </PrivilegedRoute>
        <PrivilegedRoute path={["/finance/cardusage"]} exact>
          <DynamicPageTitle workSpaceType="list" singular="Kortbruk" plural="Kortbruk" />
          <CustomBreadCrumb links={[
            { title: "Kortbruk", path: null }]} />
          <CardUsagePage />
        </PrivilegedRoute>
      </Switch>
    </>
  }
}