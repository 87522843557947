import * as React from "react";
import { RouteComponentProps } from "react-router";
import { SelectWorkOrderCustomer } from "./SelectWorkOrderCustomer";
import { WorkOrderEditPage } from "./WorkOrderEditPage";

export interface WorkOrderEditRouteParameters {
  id?: string,
  customerId?: string,
  orderId?: string,
  offerId?: string 
}

interface Props extends RouteComponentProps<WorkOrderEditRouteParameters> {}

export class WorkOrderEditRouter extends React.Component<Props> {
  render() {

    const isEdit = this.props.location.pathname.toLowerCase().indexOf("/edit") >= 0;
    const customerId = this.props.match.params.customerId;

    var matches = window.location.pathname.match(/\/workorders\/create\/(\d+)/i);
    const initialCustomerId = matches && matches?.length > 1 ? parseInt(matches[1]) : undefined;

    if (!isEdit && customerId == null)
      return <SelectWorkOrderCustomer initialCustomerId={initialCustomerId}  setCustomer={(customerId, orderId, offerId) =>
        this.props.history.push(`/workorders/create/${customerId}/${orderId || 0}/${offerId || 0}`)} />
    else
      return <WorkOrderEditPage {...this.props} />
  }
}