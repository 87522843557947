import * as React from "react";
import { TransportEditModel } from "../../../models/shared/TransportEditModel";
import { FormStore, IValidationRules } from "../../../stores/form-store";
import { Form } from "../../../components/forms/form";
import { FormActions } from "../../../components/forms/FormActions";
import { observer } from "mobx-react";
import { AddressField } from "../../../components/forms/fields/AddressField";
import { DateField } from "../../../components/forms/fields/DateField";
import { SelectField } from "../../../components/forms/fields/SelectField";
import { TextAreaField } from "../../../components/forms/fields/TextAreaField";
import { RouteComponentProps } from "react-router";
import "./TransportForm.tsx.scss";
import { UnitsForTransportField } from "../../../components/forms/fields/UnitsForTransportField";
import { Network } from "../../../api/network";
import queryString from "query-string";
import { observable } from "mobx";
import { EntityType } from "../../../core/stringHelpers";

interface RouteProps {
  id?: string;
  orderId?: string;
  workOrderId?: string;
  action?: string;
}

interface OwnProps {
  id?: number;
  workOrderId?: number;
  orderId?: number;
}
type P = OwnProps & RouteComponentProps<RouteProps>;

const validationRules: IValidationRules<TransportEditModel> = {
}


@observer
export class TransportForm extends React.Component<P> {
  @observable store?: FormStore<TransportEditModel>;

  async componentDidMount() {

    const { id } = this.props;
    const parsed = queryString.parse(this.props.location.search, { parseNumbers: true });

    const workOrderId = typeof parsed.workOrderId == "number" ? parsed.workOrderId : undefined;
    const orderId = typeof parsed.orderId == "number" ? parsed.orderId : undefined;
    const type = parsed.type;

    const defaults: Partial<TransportEditModel> = {
      Id: id,
      WorkOrderId: workOrderId,
      OrderId: orderId
    };

    var network = new Network();

    if (workOrderId) {
      const model = (await network.get<TransportEditModel>(`/api/transport/GetTransportModelForWorkOrder/${orderId}/${type}`)) || undefined;
      if (model) {
        Object.assign(defaults, model);
      }
    } else if (orderId) {
      const model = await network.get<TransportEditModel>(`/api/transport/GetTransportModelForOrder/${orderId}`);
      if (model) {
        Object.assign(defaults, model);
      }
    }

    this.store = new FormStore<TransportEditModel>(validationRules, defaults);
    if (this.props.id)
      this.store.loadModel("transport", this.props.id);
  }

  render() {

    if (this.store == null)
      return null;

    return (
      <div className="panel form-container">
        <Form
          store={this.store}
          entityType={EntityType.TransportOrder}
          name="Transportbestilling"
          redirectToList={true}
          className="edit-form edit-form-padding">

          <h4>Transport</h4>
          <SelectField<TransportEditModel>
            field="TransportCompany"
            label="Transportør">
            <option value="1">Moss Transportforum (MTF)</option>
          </SelectField>
          <TextAreaField<TransportEditModel>
            field="AdditionalInformation"
            label="Ytterligere informasjon" />

          <h4>Avsender</h4>
          <AddressField<TransportEditModel>
            field="PickupAddress"
            label="Adresse"
            showNameField={true}
            showInformation={false}
            showContactFields={true}
            showPicker={false}
          />
          <DateField<TransportEditModel>
            field="PickupTime"
            time={true}
            label="Hentetidspunkt"
          />

          <h4>Mottaker</h4>
          <AddressField<TransportEditModel>
            field="DeliveryAddress"
            label="Adresse"
            showNameField={true}
            showInformation={false}
            showContactFields={true}
            showPicker={false}
          />
          <DateField<TransportEditModel>
            field="DeliveryDate"
            time={false}
            label="Leveringsdato"
          />
          <h4>Godsbeskrivelse</h4>
          <TextAreaField<TransportEditModel>
            field="GodsDescription"
            label="Godsbeskrivelse" />
          <UnitsForTransportField<TransportEditModel>
            field="Units"
          />
          <FormActions />
        </Form>
      </div>
    )
  }
}