import { sum } from "lodash-es";
import { ArticleType } from "../../../../models/shared/ArticleType";
import { UnitEntity } from "../../../../models/shared/UnitEntity";

export function getWeight(unit: UnitEntity): number {

  const out = sum(unit.UnitWeights?.filter(w => w.Article?.ArticleType == ArticleType.ZinkArticle)?.map(u => u.Weight))
  if (out > 0)
    return out;
  else
    return unit.IncomingWeight;
}
