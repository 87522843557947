import { RouterStore } from "mobx-react-router";
import { observer, inject, Observer } from "mobx-react";
import * as React from "react";
import { FormConsumer } from "./form";
import InfoIcon from "svg/info.svg";
import classnames from "classnames";

interface Props {
  type: string;
}

export class FormErrors extends React.Component<Props> {
  render() {
    return (
      <FormConsumer>
        {({ store, cancelable, onCancel }) => {
          return (
            <Observer>{
              () => {
                if (!store.hasValidationErrors)
                  return null;

                return <div className="tilbakemelding tilbakemelding--varsel">
                  <div className="tilbakemelding--icon"><InfoIcon /></div>
                  <div>
                    <span className="tilbakemelding--title">Feil ved utfylling</span>
                    <p>Et eller fler av feltene i skjemaet over mangler eller har ugyldig verdi. {this.props.type} har <b>ikke</b> blitt sendt inn.</p>
                  </div>
                </div>
              }
            }
            </Observer>
          )
        }
        }
      </FormConsumer>);
  }
}