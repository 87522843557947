import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../../forms/wrapField";
import "./addressField.tsx.scss";
import { AddressWithInformation } from "ts/models/shared/AddressWithInformation";
import { Network } from "ts/api/network";
import { AddressEditModel } from "ts/models/shared/AddressEditModel";
import { parseInt } from "lodash-es";
import { AddressType } from "ts/models/shared/AddressType";
import { AddressWithNameAndContact } from "../../../models/shared/AddressWithNameAndContact";

interface OwnProps extends React.PropsWithChildren {
  showPicker?: boolean;
  showInformation?: boolean
  showContactFields: boolean;
  showNameField: boolean;
  selectedCustomerId?: number;
  addressType?: AddressType;
  informationText?: string;
  initialCountryCode?: string;
}

interface OwnState {
  selectIndex: string;
  addresses: Array<AddressEditModel>;
}

type P = PropsFromWrapperToField<AddressWithNameAndContact> & OwnProps;

class AddressField extends React.Component<P, OwnState> {

  state: OwnState = { selectIndex: "-1", addresses: [] };

  mergeValue(value: Partial<AddressWithNameAndContact>) {

    if (this.props.value?.Country == null)
      value.Country = "no";

    var result = Object.assign({}, this.props.value, value) as AddressWithNameAndContact;
    this.setState({ selectIndex: "-1" });
    this.props.setValue(result);
  }

  onZipCodeBlur: React.FocusEventHandler<HTMLInputElement> = async ev => {

    this.props.setTouched();

    const zip = ev.currentTarget.value;
    const { value } = this.props;
    if (value == null || (value.City != null && value.City.length > 0))
      return;

    const lookupFn = async (zip: string, country: string) => {
      try {

        var location = await new Network().getSimple<string>(`/api/lookup/postnummer/${zip}/${country ?? "no"}`);

        if (location && typeof location == "string" && (value.City == null || value.City.length == 0))
          this.mergeValue({
            City: location
          });

      } catch {
      }
    }

    // Check for norwegian 
    if (zip && value && (value.Country == null || value.Country == "no") && new RegExp("^\\d{4}$").test(zip.trim())) {
      await lookupFn(zip.trim(), "no");
    }
    else if (value.Country == "se") {
      var parsed = zip.trim().replace(/\D/g, "");
      if (parsed != zip)
        this.props.setValue({ ...value, PostalCode: parsed });

      if (parsed.length == 5)
        await lookupFn(parsed, "se");
    }
  }

  async componentDidMount() {
    if (this.props.selectedCustomerId != null)
      await this.refreshAddresses(this.props.selectedCustomerId);
  }

  async componentDidUpdate(prevProps: P, prevState: OwnState) {
    const { selectedCustomerId } = this.props;

    if (selectedCustomerId != prevProps.selectedCustomerId && selectedCustomerId != null)
      await this.refreshAddresses(selectedCustomerId);
  }

  refreshAddresses = async (customerId: number) => {
    const addressType = this.props.addressType;
    var result = await new Network().get<Array<AddressEditModel>>(`/api/addresses/byCustomer/${customerId}`);
    if (result != null) {
      this.setState({ addresses: result.filter(a => addressType === undefined || (a.AddressType == null || a.AddressType == addressType)) });
    }
  }

  setAddress = (value: string) => {
    const address = this.state.addresses.find(a => a.Id == parseInt(value));
    if (address) {
      this.mergeValue({
        Name: address.Name,
        Line1: address.Line1,
        Line2: address.Line2,
        PostalCode: address.PostalCode,
        City: address.City,
        Country: address.Country,
        Information: address.Information,
        ContactName: address.ContactName,
        ContactPhone: address.ContactPhone
      });
    }
  }

  render() {
    const {
      value,
      setTouched,
      id,
      autoFocus,
      showPicker = true,
      showInformation = true,
      showContactFields,
      showNameField,
      informationText = "Eventuell informasjon til transportør"
    } = this.props;

    const { addresses } = this.state;
    return (
      <div className="field-address" id={id}>
        {this.props.children}
        {showPicker && <select className="field-address-picker" onChange={e => this.setAddress(e.target.value)}>
          <option value="-1">-- Velg en predefinert adresse --</option>
          {(addresses || []).map(a => <option value={a.Id ?? undefined} key={a.Id}>{a.Name}</option>)}
        </select>}
        {showNameField && <input
          type="text"
          placeholder="Navn på firma"
          autoFocus={autoFocus && showNameField}
          className="field-address-line"
          value={value?.Name ?? ""}
          onChange={e => this.mergeValue(({ Name: e.target.value }))}
          onBlur={e => setTouched()}
        />}
        <input
          type="text"
          placeholder="Adresselinje 1"
          autoFocus={autoFocus && !showNameField}
          className="field-address-line"
          value={value?.Line1 ?? ""}
          onChange={e => this.mergeValue(({ Line1: e.target.value }))}
          onBlur={e => setTouched()}
        />
        <input
          type="text"
          placeholder="Adresselinje 2"
          className="field-address-line"
          value={value?.Line2 ?? ""}
          onChange={e => this.mergeValue(({ Line2: e.target.value }))}
          onBlur={e => setTouched()}
        />
        <div className="field-address-zip-city">
          <input
            type="text"
            placeholder="Postnummer"
            className="field-address-zip"
            value={value?.PostalCode ?? ""}
            onChange={e => this.mergeValue(({ PostalCode: e.target.value }))}
            onBlur={this.onZipCodeBlur}
          />
          <input type="text" placeholder="Poststed" className="field-address-city"
            value={value?.City ?? ""}
            onChange={e => this.mergeValue(({ City: e.target.value }))}
            onBlur={e => setTouched()}
          />
        </div>
        <select className="field-address-country"
          onBlur={e => setTouched()}
          value={value?.Country ?? this.props.initialCountryCode ?? "no"}
          onChange={e => this.mergeValue({ Country: e.target.value })}>
          <option value="no">Norge</option>
          <option value="se">Sverige</option>
          <option value="dk">Danmark</option>
        </select>
        {showInformation && <textarea placeholder={informationText}
          value={value?.Information ?? ""}
          onChange={e => this.mergeValue(({ Information: e.target.value }))}
          onBlur={e => setTouched()}
        />}
        {showContactFields && <>
          <h4>Kontakt</h4>
          <div className="field-address-contact">
            <input
              type="text"
              placeholder="Kontakt navn"
              className="field-address-contact-name"
              value={value?.ContactName ?? ""}
              onChange={e => this.mergeValue(({ ContactName: e.target.value }))}
              onBlur={this.onZipCodeBlur}
            />
            <input type="text"
              placeholder="Kontakt telefon"
              className="field-address-contact-phone short-input"
              value={value?.ContactPhone ?? ""}
              onChange={e => this.mergeValue(({ ContactPhone: e.target.value }))}
              onBlur={e => setTouched()}
            />
          </div></>}
      </div>
    )

  }
}

const A = Wrap<P, AddressWithInformation>(AddressField)
export { A as AddressField };