import * as React from "react";
import { omit } from "lodash-es";
import classNames from "classnames";
import "./inputWithFix.tsx.scss";
import { CurrencyInput } from "./CurrencyInput";

//interface P extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
interface P extends React.InputHTMLAttributes<HTMLInputElement> {
  prefix?: string
  postfix?: string;
  useCurrencyInputField?: boolean
}

export class InputWithFix extends React.Component<P> {
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: P) {
    super(props);
    this.inputRef = React.createRef();
  }

  focusInput: React.MouseEventHandler<HTMLSpanElement> = (ev) => {
    ev.preventDefault();
    this.inputRef.current && this.inputRef.current.focus();
  }
  

  render() {
    const { prefix, postfix } = this.props;
    const inputProps = omit(this.props, ["postfix", "prefix", "className", "useCurrencyInputField"]);
    const inputClass = classNames({ "input-with-post-designator": postfix, "input-with-pre-designator": prefix }, this.props.className);
    const useCurreny = this.props.useCurrencyInputField ?? false;

    return (
      <div className={classNames("flex", { "input-with-fix--disabled": inputProps.disabled })}>
        {prefix && <span className="pre-input-designator" onClick={this.focusInput}>{prefix}</span>}
        {useCurreny && <CurrencyInput className={inputClass} {...inputProps} />}
        {!useCurreny && <input ref={this.inputRef} className={inputClass} {...inputProps} />}
        {postfix && <span className="post-input-designator" onClick={this.focusInput}>{postfix}</span>}
      </div>);
  }
}