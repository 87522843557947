import * as React from "react";
import classNames from "classnames";
import "./changePassword.tsx.scss";
import { Network } from "../../../api/network";
import { RouteComponentProps } from "react-router";
import { default as RootStore } from "../../../stores/root-store";


interface Props extends RouteComponentProps<{}> {
}

interface State {
  first: string;
  second: string;
  hasPendingNetworkCall: boolean;
}

export class ChangePassword extends React.Component<Props, State> {

  state = { first: "", second: "", hasPendingNetworkCall: false };

  changePassword = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const network = new Network();
    this.setState({ hasPendingNetworkCall: true });
    const success = await network.post<string, boolean>("/api/authentication/changePassword", this.state.first);
    this.setState({ hasPendingNetworkCall: false });

    // Log the user out
    if (success === true) {
      this.props.history.push("/");
      RootStore.UserStore.requiresAuthentication = true;
    }
  }

  render() {
    const { first, second, hasPendingNetworkCall } = this.state;

    const isEmpty = first.length == 0 || second.length == 0;
    const isValid = isEmpty || first == second;

    return <div className="change-password-component panel panel-padding">
      <div>
        <h3>Bytt passord</h3>
        <p>Fyll inn ditt nye passord to ganger og klikk på knappen under. Etter bytte av passord må du logge inn på nytt.</p>
        <form className="edit-form">
          <div className={classNames("field-address", { "has-errors": !isValid })}>
            <input type="text" required minLength={6} value={first} onChange={e => this.setState({ first: e.target.value })} autoFocus autoComplete="new-password" />
            <input type="text" required minLength={6} value={second} onChange={e => this.setState({ second: e.target.value })} autoComplete="new-password" />
          </div>
          <div role={isValid ? undefined : "alert"}><span>{isValid ? "" : "Passordet må være det samme i de to tekstboksene"}</span></div>
          <button className="button" disabled={isEmpty || !isValid || hasPendingNetworkCall} onClick={this.changePassword}>Oppdater nytt passord</button>
        </form>
        <br />
        <h4>Tips</h4>
        <p>Å lage et sterkt passord er en enkel jobb. Utfordringen er å huske flere ulike sterke passord.
          Spesielt når man skal ha et unikt passord for hver enkelt nettside.
          For å gjøre det enklere å huske, anbefaler vi å bruke fraser eller hele setninger som passord.
          Setninger er mye enklere å huske enn vilkårlige kombinasjoner av enkelte bokstaver og tall.</p><p>
          Setninger inneholder naturlig mellomrom og symboler, som gjør passordet sterkere.
          Lag deg en huskeregel for passord som gjør at du kan lage variasjoner over en frase som sikrer unike passord for hver enkelt tjeneste.
          Da blir det enklere å huske passordet for deg, men unngå å bruke referanser direkte til tjenesten som passordet benyttes til.</p>
        <p><b>Passordfrasen bør:</b></p>
        <ul>
          <li>være så langt som mulig, minst 5 ord eller 16 tegn</li>
          <li>være unikt for hver enkelt nettside/brukerkonto</li>
          <li>inneholde både tall, symboler, mellomrom og store/små bokstaver</li>
          <li>helst ikke inkludere ord/tall som kan assosieres med deg eller tjenesten passordet gjelder for.</li>
        </ul>
        <p>Fra <a href="https://nettvett.no/passord/">nettvett.no</a></p>
      </div>
    </div>
  }
}