import * as React from "react";
import { default as RootStore } from "../../../stores/root-store";
import { IListDefinition, Alignment } from "../../../components/lists/IListDefinition";
import { EAccountingInvoiceModel } from "../../../models/shared/EAccountingInvoiceModel";
import { ListStore } from "../../../stores/list-store";
import { List } from "../../../components/lists/List";
import { toDate } from "../../../core/dateHelpers";
import PdfIcon from "svg/pdf.svg";
import { Link } from "react-router-dom";
import { NumberWithPostfix } from "../../../components/shared/display";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { SortDirection } from "../../../models/shared/SortDirection";

interface Props {
  customerId: number;
  wrapInPanel?: boolean
}

export class CustomerInvoiceList extends React.Component<Props> {

  config: IListDefinition<EAccountingInvoiceModel>;
  store: ListStore<EAccountingInvoiceModel>;

  constructor(props: Props) {
    super(props);

    this.config = {
      baseUrl: `/api/customers/invoices/${props.customerId}`,
      createLink: _ => null,
      id: 'invoices',
      showDelete: () => false,
      singular: 'Kundefakturaer',
      rowCssClass: (row, user) => row.IsOverDue ? "overdue" : "",
      columns: [
        {
          field: "InvoiceNumber",
          render: (row, value, index, user) => <td>
            <span>{value}</span>
          </td>,
          sortable: false,
          title: "Fakturanr."
        },
        {
          field: "YourReference",
          render: (row, value) => <td>{value}</td>,
          sortable: false,
          title: "Din referanse"
        },
        {
          field: "WorkOrderId",
          alignment: Alignment.Right,
          render: (row, value) => <td className="right"><Link to={`/workorders/${value}`}>{toSerialNumber(EntityType.WorkOrder, value)}</Link></td>,
          sortable: false,
          title: "Arbeidsordre"
        },
        {
          field: "IsCreditInvoice",
          render: (row, value) => <td>{value ? "Kreditnota" : "Faktura"}</td>,
          sortable: false,
          title: "Type"
        },
        {
          field: "InvoiceDate",
          render: (row, value) => <td>{toDate(value)}</td>,
          sortable: false,
          title: "Fakturadato"
        },
        {
          field: "DueDate",
          render: (row, value) => <td>{toDate(value)}</td>,
          sortable: false,
          title: "Forfallsdato"
        },
        {
          field: "TotalAmount",
          alignment: Alignment.Right,
          render: (row, value) => <td className="right"><NumberWithPostfix number={value} post="kr" /></td>,
          sortable: false,
          title: "Beløp"
        },
        {
          field: "RemainingAmount",
          alignment: Alignment.Right,
          render: (row, value) => <td className="right"><NumberWithPostfix number={value} post="kr" /></td>,
          sortable: false,
          title: "Ubetalt beløp",
        },
        {
          field: "Guid",
          alignment: Alignment.Center,
          render: (row, value) => <td className="action">
            {row.InvoiceNumber! >= 7627 && <a href={`/api/invoices/pdf/${row.Id}/${row.InvoiceNumber}`} download={`Faktura ${row.InvoiceNumber}.pdf`}><PdfIcon /></a>}
            {row.InvoiceNumber! < 7627 && <a href={`/api/invoices/pdf/${value}/${row.InvoiceNumber}`} download={`Faktura ${row.InvoiceNumber}.pdf`}><PdfIcon /></a>}
          </td>,
          sortable: false,
          title: "Faktura"
        },

      ],
      filters: [],
      presets: [
        {
          default: true,
          sort: [{
            field: "InvoiceNumber",
            direction: SortDirection.Desc
          }],
          filters: [],
          title: "Nyeste faktura"
        }]
    }
    this.store = new ListStore(this.config);
  }

  render() {
    return <>
      <List configuration={this.config} store={this.store} wrapInPanel={this.props.wrapInPanel} />
    </>
  }

}
