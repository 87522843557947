import * as React from "react";

export const MaxLengthString: React.FC<{ text?: string, length: number }> = ({ text, length }) => {
  if (text == null || text.length <= length)
    return <span>{text}</span>;
  else {
    return <span title={text}>{text.substr(0, length)}&hellip;</span>
  }
}

