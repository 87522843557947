import { ComplaintListModel } from "../../../models/shared/ComplaintListModel";
import { IListDefinition, Alignment, FilterType } from "../../../components/lists/IListDefinition";
import * as React from "react";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { List } from "../../../components/lists/List";
import RootStore from "ts/stores/root-store";
import { LookupValue } from "../../../components/shared/display";
import { Enums } from "../../../stores/lookup-store";
import { Link } from "react-router-dom";
import { toDate } from "../../../core/dateHelpers";
import { DateRangeFilter } from "../../../components/lists/FilterComponents/DateRangeFilter";
import { CustomerFilter } from "../../../components/lists/FilterComponents/CustomerFilter";

const config: IListDefinition<ComplaintListModel> = {
  id: "complaints",
  singular: "Reklamasjoner",
  createLink: _ => null,
  editLink: (row) => `/complaints/${row.Id}/edit`,
  columns: [
    {
      title: "Id",
      sortable: true,
      field: "Id",
      render: (row, value) => <td>{toSerialNumber(EntityType.Complaint, row.Id)}</td>
    },
    {
      title: "Kunde",
      sortable: true,
      field: "CustomerId",
      render: (row, value) => <td className="no-wrap"><Link to={`/customers/${row.CustomerId}`}>{row.CustomerName}</Link></td>
    },
    {
      title: "Registrert av",
      sortable: true,
      field: "UserId",
      render: (row, value) => <td className="no-wrap">{row.UserName}</td>
    },
    {
      title: "Opprettet",
      sortable: true,
      field: "UserId",
      render: (row, value) => <td className="no-wrap">{toDate(row.Created)}</td>
    },
    {
      title: "Status",
      sortable: true,
      field: "State",
      render: (row, value) => <td>
        <Link to={`complaints/${row.Id}`}>
          <LookupValue enum={Enums.ComplaintState} value={value} />
        </Link>
      </td>
    },
    {
      title: "Arbeidsordre",
      sortable: true,
      field: "WorkOrderId",
      render: (row, value) => <td><Link to={{ pathname: `workorders/${row.WorkOrderId}`, state: { tab: "complaints" } }}>{toSerialNumber(EntityType.WorkOrder, row.WorkOrderId)}</Link></td>
    },
    {
      title: "Beskrivelse",
      sortable: true,
      field: "Reason",
      render: (row, value) => <td>{row.Reason}</td>
    },
  ],
  filters: [{
    title: "Periode",
    field: "Created",
    component: <DateRangeFilter />,
    type: FilterType.DateRange
  },
  {
    title: "Kunde",
    field: "CustomerId",
    component: <CustomerFilter />,
    type: FilterType.Lookup,
    hidden: user => user.isCustomer
  }],
  presets: []
}

export class ComplaintList extends React.Component {
  render() {
    return <List configuration={config} store={RootStore.getListStore(config)} />
  }
}