import * as React from "react";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale, DatePickerProps } from "react-datepicker";
import { nb } from "date-fns/locale";
import CalendarIcon from "svg/calendar.svg";
import { omit } from "lodash";

registerLocale("nb", nb);
setDefaultLocale("nb");

interface DateSelectorProps {
  containerClass?: string;
}

type P = DatePickerProps & DateSelectorProps;

const DateSelector: React.FC<P> = props => {
  return <DatePicker
    {...props}
    customInput={<Wrapped containerClass={props.containerClass} />}
  />
}

const DatePickerCustomInput = (props: any, _ref: any) => {

  const inputProps = omit(props, ["containerClass"]);

  return <div className={props.containerClass}>
    <input {...inputProps} />
    <CalendarIcon onClick={props.onClick} style={{ cursor: "pointer", marginLeft: "5px" }} className="icon" />
  </div>
}

const Wrapped = forwardRef(DatePickerCustomInput)


export { DateSelector }