import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { UserTypeSelector } from "./UserTypeSelector";
import { UserEditForm } from "./UserEditForm";
import { default as RootStore } from "../../../stores/root-store";

// /customers/32/edit/
// /customers/create

export type UserType = "employee" | "customer-user" | "customer-contact";
type P = RouteComponentProps<{ id?: string }, {}, { type: UserType, customerId?: number } | undefined>;

class UserEdit extends React.Component<P> {

  setUserType = (type: UserType) => {    
    const { history, location } = this.props;
    history.push(location.pathname, ({ type }));
  }

  render() {
    const { location } = this.props;

    const isEdit = location.pathname.toLowerCase().indexOf("/edit") >= 0;
    const userType = location.state != null ? location.state.type : undefined;
    const customerId = location.state != null ? location.state.customerId : undefined;

    if (!isEdit) {
      if (RootStore.UserStore.isCustomer)
        return <UserEditForm type="customer-contact" />
      else if (userType == undefined)
        return <UserTypeSelector onSelect={userType => this.setUserType(userType)} />
      else
        return <UserEditForm type={userType} customerId={customerId} />
    } else {
      return <UserEditForm id={this.props.match.params.id == null ? undefined : parseInt(this.props.match.params.id)} />
    }
  }
}

const a = withRouter(UserEdit);
export {
  a as UserEdit
}