import { FlytSyncData } from "../../../models/shared/FlytSyncData";
import { FlytOrderInfoModel } from "../../../models/shared/FlytOrderInfoModel";
import { useState, useEffect } from "react";
import { Network } from "../../../api/network";
import { DetailValueField } from "../../../components/details/DetailValueField";
import { Pending } from "../../../components/shared/Pending";
import * as React from "react";
import { NumberWithPostfix } from "../../../components/shared/display";
import { toDate, toDateWithHM } from "../../../core/dateHelpers";
import { DetailsBlock } from "../../../components/details/DetailsBlock";
import "./SyncronizationTabContent.tsx.scss";

const SyncronizationTabContent: React.FC<{ model: FlytSyncData, name: string, id: number }> = ({ model, name, id }) => {

  const [data, setData] = useState<FlytOrderInfoModel>();
  useEffect(() => {

    const network = new Network();
    const fetchData = async () => {
      const result = await network.get<FlytOrderInfoModel>(`/api/workorders/externalDetails/${name}/${model.ExternalId}`);
      if (result)
        setData(result);
    }

    if (model.ExternalId > 0)
      fetchData();
  }, []);

  if (data == null && model.ExternalId > 0)
    return <Pending />;

  const images = data?.Images?.filter(i => i.startsWith("A_")) || [];

  return (
    <div className="external-order-information">
      <div className="external-order-information-panel details-blocks details-block-3">
        <DetailsBlock title="Informasjon">
          <DetailValueField title="Ordrenummer">{data?.InternalId ?? model?.DotOrderId}</DetailValueField>
          {model.DotEntityId != null && model.DotEntityId > 0 && <DetailValueField title="DOT Id">{model.DotEntityId}</DetailValueField>}
          <DetailValueField title="Utveid Vekt"><NumberWithPostfix post="kg" number={data?.AfterWeight} /></DetailValueField>
          {model.ExternalId > 0 && <DetailValueField title="Url">
            <a href={`/api/workorders/redirctToExternalDetails/${name}/${model.ExternalId}`} rel="noreferrer noopener" target="_blank">
              {name}
            </a>
          </DetailValueField>}
        </DetailsBlock>
        <DetailsBlock title="Tilstand">
          {model.CurrentState && <DetailValueField title="Siste status">{model.CurrentState}</DetailValueField>}
          {model.EstimatedDone && <DetailValueField title="Estimert ferdig">{toDate(model.EstimatedDone)}</DetailValueField>}
          {data?.StateChanges && <DetailValueField title="Historikk" className="external-order-information-status">
            <table>
              <tbody>
                {data.StateChanges.map((s, i) => <tr key={i}><td>{s.State}</td><td>{toDateWithHM(s.ChangeDate)}</td></tr>)}
              </tbody>
            </table>
          </DetailValueField>}
        </DetailsBlock>
        <DetailsBlock title="Arbeid">
          <DetailValueField title="Ekstraarbeid" wrap>{data?.ExtraWork}</DetailValueField>
          {data?.InvoiceAmount != null && data.InvoiceAmount > 0 && <DetailValueField title="Fakturasum"><NumberWithPostfix post="kr" number={data.InvoiceAmount} /></DetailValueField>}
          {model.Description && <DetailValueField title="Godsbeskrivelse" wrap>{model.Description}</DetailValueField>}
        </DetailsBlock>
      </div>
      {images.length > 0 && <div className="image-list">
        <ul>{images.map((i, x) => <li key={x}><div
          role="img"
          style={{ backgroundImage: `url("/api/workorders/externalImage/${name}/${id}/${i}")` }} /></li>)}
        </ul>
      </div>}
    </div>);
}

export { SyncronizationTabContent };