import { FormStore, IValidationRules } from "../../../stores/form-store";
import * as React from "react";
import { Form } from "../../../components/forms/form";
import { InputField } from "../../../components/forms/fields/InputField";
import { FormActions } from "../../../components/forms/FormActions";
import { observer } from "mobx-react";
import { UserEditModel } from "../../../models/shared/UserEditModel";
import { TextAreaField } from "../../../components/forms/fields/TextAreaField";
import { CustomerField } from "../../../components/forms/fields/CustomerField";
import { NotificationsField } from "ts/components/forms/fields/NotificationsField";
import { isRequired } from "../../../core/validationHelpers";
import { toSerialNumber, EntityType, isNullOrWhitespace } from "../../../core/stringHelpers";
import { default as RootStore } from "../../../stores/root-store";
import { UserType } from "./UserEdit";
import { FormErrors } from "../../../components/forms/FormErrors";
import emailSpellChecker from '@zootools/email-spell-checker';
import { BooleanField } from "../../../components/forms/fields/BooleanField";
import { PhoneNumberField } from "../../../components/forms/fields/PhoneNumberField";

interface Props {
  type?: UserType;
  id?: number;
  customerId?: number;
}
type P = Props;

@observer
export class UserEditForm extends React.Component<P> {
  store: FormStore<UserEditModel>;

  constructor(props: P) {
    super(props);

    const user = RootStore.UserStore;

    const validationRules: IValidationRules<UserEditModel> = {
      "CustomerId": [
        (value) => this.props.type !== "employee" && value == null ? "Du må oppgi en kunde som brukeren skal knyttes til." : null,
      ],
      "Name": [
        isRequired("Du må oppgi et navn på brukeren.")
      ],
      PhoneNumber: [
        (value, values) => !values.CanLogOn ? (isNullOrWhitespace(values.Email) && isNullOrWhitespace(values.PhoneNumber) ? "Du må oppgi en epost-adresse eller telefonnummer på kontakten." : null) : null
      ],
      Email: [
        (value, values) => !values.CanLogOn ? (isNullOrWhitespace(values.Email) && isNullOrWhitespace(values.PhoneNumber) ? "Du må oppgi en epost-adresse eller telefonnummer på kontakten." : null) : null
      ]
    }

    const canLogOn = this.props.type !== "customer-contact";
    this.store = new FormStore<UserEditModel>(validationRules, {
      "Notifications": [
        { "Type": 1, "OnlyWhenContact": true, "ViaEmail": true, "ViaText": false },
        { "Type": 3, "OnlyWhenContact": true, "ViaEmail": true, "ViaText": false },
        { "Type": 4, "OnlyWhenContact": true, "ViaEmail": true, "ViaText": false },
        { "Type": 5, "OnlyWhenContact": true, "ViaEmail": true, "ViaText": false },
        { "Type": 7, "OnlyWhenContact": true, "ViaEmail": true, "ViaText": false },
        { "Type": 8, "OnlyWhenContact": true, "ViaEmail": true, "ViaText": false },
        { "Type": 9, "OnlyWhenContact": true, "ViaEmail": true, "ViaText": false },
        { "Type": 10, "OnlyWhenContact": false, "ViaEmail": true, "ViaText": false }

      ],
      CanLogOn: canLogOn,
      CustomerId: user.isCustomer ? user.customerId : props.customerId
    }, (field, value) => {
      if (field === "Email")
        this.validateEmail(field, value);
    });

    if (this.props.id)
      this.store.loadModel("users", this.props.id);
  }

  validateEmail = (field: string, value: string) => {
    const r = {} as any;
    const suggestedEmail = emailSpellChecker.run({
      email: value,
      domains: [...emailSpellChecker.POPULAR_DOMAINS, 'online.no'],
      topLevelDomains: ['com', 'org', 'no', 'se'], // replaces existing TLDs
    });
    if (suggestedEmail) {
      r[field + "_suggestion"] = `Mener du ${suggestedEmail.full}?`;
      this.setState(old => ({ ...old, ...r }));
    } else {
      r[field + "_suggestion"] = null;
      this.setState(old => ({ ...old, ...r }));
    }
  }


  render() {
    const { type, id } = this.props;
    const isCustomer = RootStore.UserStore.isCustomer;
    const isAdmin = RootStore.UserStore.isAdministrator;

    if (id && !this.store.modelHasLoaded)
      return null;

    const isEmployee = (id && this.store.getValue("CustomerId") == null) || type == "employee";

    const title = type === "customer-contact" || this.store?.values?.CanLogOn === false ? "Kontakt" : "Bruker";
    const uTypen = type === "customer-contact" || this.store?.values?.CanLogOn === false ? "kontakten" : "brukeren";

    return (
      <div className="panel form-container">
        <Form
          store={this.store}

          name={title}
          entityType={EntityType.User}
          redirectToList={false}
          className="edit-form edit-form-padding">
          <h3>{title} {id ? toSerialNumber(EntityType.User, id) : ""}</h3>
          {!isEmployee && !isCustomer &&
            <CustomerField<UserEditModel>
              field="CustomerId"
              label="Kunde"
              helpText={`Her spesifiserer du hvilken kunde denne ${uTypen} er tilknyttet.`}
              showIf={(value) => value.Id == null}
            />}
          <InputField<UserEditModel>
            field="Name"
            type="text"
            label="Navn"
            helpText={`Her spesifiserer du navnet på ${uTypen}.`} />
          <InputField<UserEditModel>
            field="Email"
            type="text"
            inputMode="email"
            innerContent={<span className="suggestion-span">{(this.state as any)?.["Email_suggestion"]}</span>}
            label="E-post"
            helpText={`Her kan du skrive inn epost-adressen til ${uTypen}.`} />
          <PhoneNumberField<UserEditModel>
            field="PhoneNumber"
            label="Mobilnummer"
            helpText={`Her kan du fylle inn mobilnummeret til ${uTypen}. Dette er nødvendig for varsling via sms.`} />
          <InputField<UserEditModel>
            field="Title"
            label="Tittel"
            helpText={`Her kan du spesifisere hvilken tittel denne ${uTypen} har.`} />
          <TextAreaField<UserEditModel>
            field="Description"
            label="Informasjon"
            showIf={_ => !RootStore.UserStore.isCustomer}
            helpText={`Fyll inn eventuell intern informasjon om denne ${uTypen}.`} />
          {isAdmin && title === "Bruker" && <BooleanField<UserEditModel>
            field="CanUnlockFactory"
            label="Kan låse opp"
            helpText="Indikerer om brukeren kan låse opp fabrikken via RåLink" />}
          <NotificationsField<UserEditModel>
            field="Notifications"
            label="Varsler"
            showOnlyWhenContact={true}
            showIf={_ => !isEmployee}
            helpText={`Indiker hvilke varsler ${uTypen} ønsker tilsendt.`} />
          <FormErrors type="Brukeren" />
          <FormActions />
        </Form>
      </div>
    )
  }
}