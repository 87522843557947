import * as React from "react";
import { FormStore, IValidationRules } from "../../../stores/form-store";
import { observer } from "mobx-react";
import { Form } from "../../../components/forms/form";
import { FormActions } from "../../../components/forms/FormActions";
import { InputField } from "../../../components/forms/fields/InputField";
import { CustomerField } from "ts/components/forms/fields/CustomerField";
import { default as RootStore } from "../../../stores/root-store";
import { AddressEditModel } from "ts/models/shared/AddressEditModel";
import { SelectField } from "ts/components/forms/fields/SelectField";
import { EnumField } from "ts/components/forms/fields/EnumField";
import { Enums } from "ts/stores/lookup-store";
import { TextAreaField } from "ts/components/forms/fields/TextAreaField";
import { isNumber, isEmpty } from "lodash-es";
import { Network } from "../../../api/network";
import { FormErrors } from "../../../components/forms/FormErrors";
import { EntityType } from "../../../core/stringHelpers";
interface Props {
  id?: number
}
@observer
export class AddressForm extends React.Component<Props> {
  store: FormStore<AddressEditModel>;
  isCustomer: boolean;

  constructor(props: Props) {
    super(props);

    const validationRules: IValidationRules<AddressEditModel> = {
      CustomerId: [(value) => isNumber(value) && value > 0 ? null : "Du må oppgi en kunde før du kan lagre adressen."],
      Name: [(value) => !isEmpty(value) ? null : "Du må oppgi en tittel for adressen."],
      Line1: [(value) => !isEmpty(value) ? null : "Du må oppgi en verdi for dette feltet."],
      PostalCode: [(value) => !isEmpty(value) ? null : "Du må oppgi et postnummer ."],
      City: [(value) => !isEmpty(value) ? null : "Du må et sted."],
    }

    this.isCustomer = RootStore.UserStore.isCustomer;

    this.store = new FormStore(validationRules, {
      CustomerId: RootStore.UserStore.customerId,
      Country: "no"
    }, async (field, value, values) => {
      if (field == "PostalCode") {
        if (values.Country === "no" && new RegExp("^\\d{4}$").test(value.trim())) {
          try {
            var location = await new Network().getSimple<string>(`/api/lookup/postnummer/${value}`);
            values.City = location;
          } catch {
          }
        }
      }
    });

    if (this.props.id)
      this.store.loadModel("addresses", this.props.id);
  }

  render() {
    const { id } = this.props;

    if (id && !this.store.modelHasLoaded)
      return null;

    return (
      <div className="panel form-container">
        <Form
          store={this.store}
          name="Address"
          entityType={EntityType.Address}
          redirectToList={true}
          className="edit-form edit-form-padding">
          <h3>Addresse</h3>
          <CustomerField<AddressEditModel>
            field="CustomerId"
            helpText="Spesifiser kunden denne addressen er knyttet til."
            showIf={(value) => !this.isCustomer && value.Id == null}
            label="Kunde" />
          <InputField<AddressEditModel>
            field="Name"
            helpText="Tittel på adressen slik at du kan kjenne den igjen senere."
            label="Tittel" />
          <EnumField<AddressEditModel>
            field="AddressType"
            sort={true}
            required={false}
            enumType={Enums.AddressType}
            helpText="Her kan du velge en spesifik type adresse."
            label="Adressetype" />
          <InputField<AddressEditModel>
            field="Line1"
            helpText="Her fyller du første linje i adressefeltet."
            label="Linje 1" />
          <InputField<AddressEditModel>
            field="Line2"
            helpText="Her fyller du andre linje i adressefeltet."
            label="Linje 2" />
          <InputField<AddressEditModel>
            field="PostalCode"
            helpText="Her fyller du postnummeret."
            label="Postnummer" />
          <InputField<AddressEditModel>
            field="City"
            helpText="Her fyller du stedet knyttet til postnummeret."
            label="By/Sted" />
          <SelectField<AddressEditModel>
            field="Country"
            helpText="Her fyller du landet adressen er tilknyttet."
            label="Land">
            <option value="no">Norge</option>
            <option value="se">Sverige</option>
            <option value="dk">Danmark</option>
          </SelectField>
          <TextAreaField<AddressEditModel>
            field="Information"
            helpText="Informasjon knyttet til adressen"
            label="Informasjon" />
          <FormErrors type="Adressen" />
          <FormActions />
        </Form>
      </div>
    )
  }
}