import * as React from "react";
import { Link } from "react-router-dom";
import { default as RootStore } from "../../../stores/root-store";
import OrderIcon from "svg/order.svg";
import WorkOrderIcon from "svg/workorder.svg";
import CustomerIcon from "svg/customer.svg";
import "./index.tsx.scss";
import { VideoStream } from "../../../components/VideoStream";
import { OfficeLockBox } from "./OfficeLockBox";

const Welcome: React.FC<{}> = _ => {

  const { isCustomer, customerId } = RootStore.UserStore;

  return <div>
    <h1>RåLink</h1>
    <p>Velkommen til RåZinks ordrebehandingssystem.</p>
    <div className="hero-buttons">
      {isCustomer && <Link to="/orders/create">
        <OrderIcon />
        <h2>Opprett bestilling</h2>
        <span>Opprett ny bestilling.</span>
      </Link>}
      <Link to="/workorders">
        <WorkOrderIcon />
        <h2>Arbeidsordre</h2>
        <span>Se status på aktive jobber. Her finner du også dokumentasjon på tidligere jobber.</span>
      </Link>
      <Link to="/orders">
        <OrderIcon />
        <h2>Se bestillinger</h2>
        <span>Se eksisterende bestillinger.</span>
      </Link>
      <Link to={isCustomer ? `/customers/${customerId}` : "/customers"}>
        <CustomerIcon />
        <h2>Kundeprofil</h2>
        <span>Se brukere, fakturainformasjon og varsel.</span>
      </Link>
      <VideoStream />
      <OfficeLockBox />
    </div>
    <p>Opplever du noen feil eller har spørsmål, ta <a href="mailto:post@razink.no">kontakt</a>.</p>
  </div>
}

export { Welcome };