import moment from "moment";
import React from "react";
import ArrowIcon from "svg/arrow.svg";
import "./MonthNavigator.tsx.scss";
interface Props {
  month: Date;
  onChange: (m: Date) => void;
}

export const MonthNavigator: React.FC<Props> = ({ month, onChange }) => {

  const goBack = () => onChange(moment(month).add(-1, "month").toDate());
  const goNext = () => onChange(moment(month).add(1, "month").toDate());

  return (
    <div className="month-navigator">
      <button className="previous" onClick={e => goBack()}><ArrowIcon /></button>
      <span>{moment(month).format("MMMM YYYY")}</span>
      <button className="next" onClick={e => goNext()}><ArrowIcon /></button>
    </div>
  );
}