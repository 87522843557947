import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react";
import { DataStore } from "../../../stores/data-store";
import { CustomerDetailsModel } from "../../../models/shared/CustomerDetailsModel";
import { DetailsPanel } from "../../../components/details/DetailsPanel";
import { DetailsBlock } from "../../../components/details/DetailsBlock";
import { TabPanel } from "../../../components/shared/TabPanel";
import { DetailsAction } from "../../../components/details/DetailsAction";
import { Tab } from "../../../components/shared/Tab";
import { Audit } from "../../../components/shared/audit/Audit";
import { DetailValueField } from "../../../components/details/DetailValueField";
import { Indicator, NumberWithPostfix } from "../../../components/shared/display";
import { Button } from "../../../components/shared/Button";
import EditIcon from "svg/edit.svg";
import DeleteIcon from "svg/trash.svg";
import WorkOrderIcon from "svg/workorder.svg";
import ContactIcon from "svg/user-with-notification.svg";
import { Dialog } from "../../../core/dialog";
import { default as RootStore } from "../../../stores/root-store";
import { CustomerDetailsUserList } from "./CustomerDetailsUserList";
import { CustomerInvoiceList } from "./CustomerInvoiceList";
import { toSerialNumber, EntityType, formatOrgNr } from "../../../core/stringHelpers";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "../../../components/shared/CopyToClipboard";
import { isEmpty } from "lodash-es";

type P = RouteComponentProps<{ id: string }>;

@observer
class CustomerDetailsPage extends React.Component<P> {
  store: DataStore<CustomerDetailsModel>;

  constructor(props: P) {
    super(props);

    const id = parseInt(this.props.match.params.id);

    this.store = new DataStore<CustomerDetailsModel>(`/api/customers/${id}/details`, [{
      id: id,
      type: "CustomerEntity"
    }]);
  }

  componentWillUnmount = () => this.store.dispose();

  edit = () => {
    const { history } = this.props;
    const id = this.store.data!.Id;
    history.push(`/customers/${id}/edit`);
  }

  associateWithOffer = () => {

  }

  delete = () => {
    const { history } = this.props;
    const { Id, Name } = this.store.data!;


    const dialog = Dialog.OkCancelDialog(
      "Slette kunde",
      `Er du sikker på at du vil slette kunden ${Name} med kundenummer ${Id}?`,
      "Slett",
      async (fnClose) => {

        // attemt to delete the entity.
        const deleteWasSuccessfull = await this.store.delete("customers", Id);

        // Close the dialog no matter what. If an error occured, another dialog will show error message.
        fnClose();

        // If the delete was successfull, navigate to the listing page.
        if (deleteWasSuccessfull === true) {
          history.push(`/customers`);
        }
      }
    );

    RootStore.UIStore.addDialog(dialog);
  }

  createWorkOrder = () => {
    const { history } = this.props;
    const id = this.store.data!.Id;
    history.push(`/workorders/create/${id}/0/0`);
  }

  listWorkOrders = () => {
    const { history } = this.props;
    const id = this.store.data!.Id;
    history.push(`/workorders`, { filters: { CustomerId: [id] } });
  }

  render() {

    const model = this.store.data;
    if (!model)
      return null;

    const { isCustomer, isAdministrator, isEmployeeAdministrator } = RootStore.UserStore;
    const showEaccountingLink = isAdministrator && model.TripletexId != null;
    const email = model.Email ?? model.NotificationEmail;
    return (<>
      <DetailsPanel>
        <DetailsBlock title="Detaljer">
          <DetailValueField title="Kundenummer">{toSerialNumber(EntityType.Customer, model.Id)}</DetailValueField>
          <DetailValueField title="Navn" wrap>
            {!isEmpty(model.WebUrl) && <a href={model.WebUrl} target="_blank">{model.Name}</a>}
            {isEmpty(model.WebUrl) && <span>{model.Name}</span>}
            <CopyToClipboard text={model.Name!} />
          </DetailValueField>
          {model.OrganizationId &&
            <DetailValueField title="Organisasjonsnummer">
              <span x-ms-format-detection="none">{formatOrgNr(model.OrganizationId, model.Country)}</span>
              <CopyToClipboard text={model.OrganizationId} />
            </DetailValueField>}
          {!isCustomer && <DetailValueField title="Ekskluder statistikk"><Indicator value={model.ExcludeFromStatistics} type="Check" /></DetailValueField>}
        </DetailsBlock>
        <DetailsBlock title="Faktura">
          <DetailValueField title="Krever referanse"><Indicator value={model.RequiresInvoiceReference} type="Check" /></DetailValueField>
          <DetailValueField title="Forfallsdager">{model.InvoiceDueDays} dager</DetailValueField>
          <DetailValueField title="E-Post">{model.InvoiceEmail}</DetailValueField>
          {showEaccountingLink && <DetailValueField title="Regnskap"><a href={`${window.flytSettings.tripletexBaseUrl}/execute/customerMenu?customerId=${model.TripletexId}&contextId=${window.flytSettings.tripletexContextId}`} target="_blank">Tripletex</a></DetailValueField>}
          {!isCustomer && model.DiscountPercentage > 0 && <DetailValueField title="Avslag"><NumberWithPostfix post="%" number={model.DiscountPercentage} /></DetailValueField>}
        </DetailsBlock>
        <DetailsBlock title="Informasjon">
          <DetailValueField title="E-Post">
            {!isEmpty(email) && <a href={`mailto:${email}`}>{email}</a>}
          </DetailValueField>
          <DetailValueField title="Detaljer" wrap long>{model.PublicNote}</DetailValueField>
          {!isCustomer && <DetailValueField title="Intern" wrap long>{model.PrivateNote}</DetailValueField>}
          {!isCustomer && <DetailValueField title="Betingelser" wrap long>{model.InvoiceTerms}</DetailValueField>}
        </DetailsBlock>
        <DetailsAction>
          <Button text="Rediger" onClick={() => this.edit()} icon={EditIcon}></Button>
          {!isCustomer && <Button text="Ny Arbeidsordre" onClick={() => this.createWorkOrder()} icon={WorkOrderIcon}></Button>}
          <Button text="Se Arbeidsordre" onClick={() => this.listWorkOrders()} icon={WorkOrderIcon}></Button>
          {isEmployeeAdministrator && <Button text="Slett" onClick={() => this.delete()} icon={DeleteIcon}></Button>}
        </DetailsAction>
      </DetailsPanel>
      <TabPanel initialSelectedTab={(this.props.location.state as any)?.tab === "Fakturaer" ? 1 : undefined }>
        <Tab title="Brukere og kontakter">
          {!isCustomer && <Link to={{
            state: {
              type: "customer-contact",
              customerId: model.Id
            },
            pathname: "/users/create"
          }} className="button"><ContactIcon className="icon" /><span>Opprett kundekontakt</span></Link>}
          <CustomerDetailsUserList customerId={model.Id} />
        </Tab>
        <Tab title="Fakturaer">
          <CustomerInvoiceList customerId={model.Id} wrapInPanel={false} />
        </Tab>
        {!isCustomer && <Tab title="Historikk">
          <Audit customerId={model.Id} isWorkorderRelated={false} />
        </Tab>}
      </TabPanel>
    </>);

  }
}

const tmp = withRouter(CustomerDetailsPage);
export {
  tmp as CustomerDetailsPage
}
