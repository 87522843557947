import * as React from "react";
import { IListDefinition, Alignment, FilterType } from "../../../components/lists/IListDefinition";
import { List } from "../../../components/lists/List";
import { default as RootStore } from "../../../stores/root-store";
import { Link } from "react-router-dom";
import { since, toDateWithHM, toDate, stringToDate } from "ts/core/dateHelpers";
import { CustomerFilter } from "ts/components/lists/FilterComponents/CustomerFilter";
import { OrderListModel } from "ts/models/shared/OrderListModel";
import { PaintType } from "ts/models/shared/PaintType";
import WorkOrderIcon from "svg/workorder.svg";
import { BooleanFilter } from "../../../components/lists/FilterComponents/BooleanFilter";
import { SortDirection } from "../../../models/shared/SortDirection";
import TransportIcon from "svg/transport.svg";
import { NumberWithPostfix } from "../../../components/shared/display";
import { EntityType, toSerialNumber } from "../../../core/stringHelpers";
import { ScreenSize } from "../../../core/util";
import PhoneIcon from "svg/phone-call.svg";
import "./OrderList.tsx.scss";
import { LocationDescriptorObject } from "history";
import classNames from "classnames";
import { useRef, useState } from "react";
import { MessageListCell } from "../../../components/shared/messages/MessageDialog";
import { DialogListPopupDialog } from "../../../components/dialogs/DialogListPopupDialog";

const paintText = (paintType: PaintType) => {
  if (paintType == null || paintType == PaintType.None)
    return "Nei";
  if (paintType == PaintType.ByCustomer)
    return "Av kunde";
  return "Ja"
}

const config: IListDefinition<OrderListModel> = {
  id: "orders",
  singular: "Bestilling",
  editLink: (row) => `/orders/${row.Id}/edit`,
  createLink: _ => "/orders/create",
  rowCssClass: (row) => row.HasWorkOrders ? "completed" : null,
  columns: [
    {
      field: "Id",
      sortable: true,
      title: "Id",
      render: (row, value) => row.Deleted == null ? <td className={classNames({ "active-marker": stringToDate(row.EstimatedDeliveryDate) > new Date() })}>
        <Link to={`/orders/${row.Id}`}>{toSerialNumber(EntityType.Order, value)}</Link></td> : <td>{toSerialNumber(EntityType.Order, value)}</td>
    },
    {
      field: "Created",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(value)}>{toDateWithHM(value)}</span></td>,
      sortable: true,
      title: "Opprettet"
    },
    {
      title: "Kunde",
      field: "CustomerName",
      sortable: true,
      render: (row) => <td><Link to={`/customers/${row.CustomerId}`}>{row.CustomerName}</Link></td>,
      hidden: user => user.isCustomer
    },
    {
      title: "Opprettet av",
      field: "CreatedBy",
      sortable: true,
      render: (row) => <td><Link to={`/users/${row.CreatedById}`}>{row.CreatedBy}</Link></td>,
      hidden: user => user.isCustomer
    },
    {
      title: "Referanse",
      field: "InvoiceReference",
      sortable: false,
      render: (row, value) => <td>{value}</td>
    }, {
      field: "EstimatedDeliveryDate",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(row.Created)}>{toDate(value)}</span></td>,
      sortable: true,
      title: (size) => size == ScreenSize.PC ? "Estimert Mottak" : "Mottak",
    },
    {
      field: "RequestedCompletionDate",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(row.Created)}>{toDate(value)}</span></td>,
      sortable: true,
      title: (size) => size == ScreenSize.PC ? "Forespurt Ferdig" : "Ca. Ferdig",
    },
    {
      title: "",
      sortable: false,
      field: "HasMessages",
      alignment: Alignment.Center,
      render: (row) => <Messages row={row} />,
      hidden: user => user.isCustomer
    },
    {
      field: "EstimatedWeight",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><NumberWithPostfix number={value} post="kg" /></td>,
      sortable: true,
      title: "Vekt"
    },
    {
      field: "Paint",
      alignment: Alignment.Center,
      render: (row, value) => <td className="center">{paintText(value)}</td>,
      sortable: true,
      title: "Lakkeres"
    },
    {
      field: "RequiresPickup",
      alignment: Alignment.Center,
      render: (row, value) => {

        const wrapInLink = (content: JSX.Element) => <Link to={{ pathname: `/orders/${row.Id}`, state: { tab: "transport" } }}>{content}</Link>;

        const content = () => {
          if (row.IsMissingPickupOrder)
            return wrapInLink(<PhoneIcon />);
          else if (row.RequiresPickup)
            return wrapInLink(<TransportIcon />)
          else
            return <TransportIcon disabled />
        }

        return <td className="center narrow">{content()}</td>
      },
      sortable: true,
      title: "Hentes"
    },
    {
      field: "HasWorkOrders",
      alignment: Alignment.Center,
      render: (row, value, index, user) => {

        let content = null;
        if (!row.HasWorkOrders) {
          if (!user.isCustomer) {
            const title = `Opprett arbeidsordre for bestilling ${toSerialNumber(EntityType.Order, row.Id)}`;
            content = <Link to={`/workorders/create/${row.CustomerId}/${row.Id}/0`} title={title}>Opprett</Link>
          }
        }
        else if (row.WorkOrderIds.length == 1) {
          const title = `Arbeidsordre ${toSerialNumber(EntityType.WorkOrder, row.WorkOrderIds[0])}`;
          content = <Link to={`/workorders/${row.WorkOrderIds[0]}`} title={title}><WorkOrderIcon /></Link>
        } else {
          const title = `${row.WorkOrderIds.length} arbeidsordre for bestilling ${toSerialNumber(EntityType.Order, row.Id)}`;
          const link: LocationDescriptorObject = {
            pathname: "/workorders",
            state: {
              filters: { OrderId: [row.Id] }
            }
          }
          content = <Link to={link} title={title} className="workorder-collection"><WorkOrderIcon /><WorkOrderIcon /></Link>
        }

        return <td className="center">{content}</td>;
      },

      sortable: true,
      title: "Arbeidsordre"
    }
  ],
  filters: [
    {
      field: "CustomerId",
      title: "Kunde",
      component: <CustomerFilter />,
      type: FilterType.Lookup,
      hidden: user => user.isCustomer
    }, {
      field: "HasWorkOrders",
      title: "Arbeidsordre",
      component: <BooleanFilter true="Med arbeidsordre" false="Uten arbeidsordre" null="Alle" />,
      type: FilterType.TriStateBoolean
    }, {
      field: "IsMissingPickupOrder",
      title: "Transportbestilling",
      component: <BooleanFilter true="Uten bestilling" false="Med bestilling" null="Alle" />,
      type: FilterType.TriStateBoolean,
      hidden: user => user.isCustomer
    }, {
      field: "RequiresPickup",
      title: "Transport",
      component: <BooleanFilter true="Må hentes" false="Leveres av kunde" null="Alle" />,
      type: FilterType.TriStateBoolean
    }
  ],
  presets: [
    {
      default: true,
      sort: [{
        field: "Created",
        direction: SortDirection.Desc
      }],
      title: "Siste først",
      filters: []
    }, {
      default: false,
      title: "Mangler bestilling",
      sort: [{
        field: "Created",
        direction: SortDirection.Desc
      }],
      filters: [{
        field: "IsMissingPickupOrder",
        filterType: FilterType.TriStateBoolean,
        value: true
      },
      {
        field: "RequiresPickup",
        filterType: FilterType.TriStateBoolean,
        value: true
      },
      ]
    }]
}

export class OrderList extends React.Component {
  render() {
    return (
      <List configuration={config} store={RootStore.getListStore(config)} />
    )
  }
}

const Messages: React.FC<{ row: OrderListModel }> = ({ row }) => {

  const [isMouseOver, setIsMouseOver] = useState(false);
  const ref = useRef<HTMLTableCellElement>(null);

  const onEnter: React.MouseEventHandler<HTMLTableCellElement> = (ev) => setIsMouseOver(true);
  const onLeave: React.MouseEventHandler<HTMLTableCellElement> = (ev) => setIsMouseOver(false);

  return <td className="center narrow" ref={ref} onMouseOver={row.HasMessages ? onEnter : undefined} onMouseLeave={row.HasMessages ? onLeave : undefined}>
    <MessageListCell id={row.Id} type="OrderEntity" hasMessages={row.HasMessages} />
    {isMouseOver && <DialogListPopupDialog id={row.Id} element={ref.current} type="OrderEntity" />}
  </td>
}