import { observable, computed, values, action, toJS, runInAction } from "mobx";
import { Network } from "../api/network";
import { ValidationResultValue } from "../models/shared/ValidationResultValue";
import { Dialog } from "../core/dialog";
import { default as RootStore } from './root-store';
import { isEqual as _isEqual } from "lodash-es";

export type IValidationRules<TEditModel> = {
  [name in keyof TEditModel]?: Array<(value: any, values: Partial<TEditModel>) => string | null>
}

interface IValidationState {
  [key: string]: Array<string>;
}

function isValidationResultValue(obj: any): obj is ValidationResultValue {
  return obj != null && obj.hasOwnProperty("Message") && obj.hasOwnProperty("Errors");
}

export class FormStore<TEditModel> {
  @observable values: Partial<TEditModel>;
  @observable private doFullValidation: boolean = false;
  @observable private formMetadata: {
    [key: string]: {
      touched: boolean;
      serverError?: string;
    }
  } = {};

  @observable private original?: TEditModel;
  @observable isPending: boolean;
  private defaultValues?: Readonly<Partial<TEditModel>>;
  private validationRules: IValidationRules<TEditModel>
  private networkHelper: Network;
  private onSetValueFn?: (field: keyof TEditModel, value: any, values: Partial<TEditModel>) => void;

  constructor(validationRules: IValidationRules<TEditModel>, defaultValues: Partial<TEditModel> = {}, onSetValue?: (field: keyof TEditModel, value: any, values: Partial<TEditModel>) => void) {
    this.validationRules = validationRules;
    this.networkHelper = new Network();
    this.defaultValues = defaultValues;
    this.values = Object.assign({}, defaultValues);
    this.isPending = false;
    this.onSetValueFn = onSetValue;
  }

  // #region Validation
  @computed get validationState(): IValidationState {
    let result: IValidationState = {};

    // client validation
    for (let field in this.validationRules) {
      if (this.doFullValidation || field in this.formMetadata && this.formMetadata[field].touched) {
        const value = field in this.values ? this.values[field as keyof TEditModel] : undefined;
        const rulesForField = this.validationRules[field];
        if (rulesForField) {
          rulesForField.forEach(rule => {
            const validationError = rule(value, this.values);
            if (validationError) {
              if (!(field in result))
                result[field] = [];
              result[field].push(validationError);
            }
          });
        }
      }
    }

    // server errors
    for (let field in this.formMetadata) {
      const serverError = this.formMetadata[field].serverError;
      if (serverError) {
        if (!(field in result))
          result[field] = [];
        result[field].push(serverError);
      }
    }

    return result;
  }

  @computed get hasValidationErrors(): boolean {
    return Object.keys(this.validationState).length > 0;
  }
  // #endregion

  @action.bound
  setValue(field: keyof TEditModel, value: any) {
    this.values[field] = value;

    // Remove any existing server error
    const meta = this.formMetadata[field as string];
    if (meta && meta.serverError)
      meta.serverError = undefined;

    if (this.onSetValueFn != null)
      this.onSetValueFn(field, value, this.values);

  }

  @action.bound
  setTouched(field: keyof TEditModel) {
    if (this.formMetadata[field as string])
      this.formMetadata[field as string].touched = true;
    else
      this.formMetadata[field as string] = { touched: true };
  }

  @action.bound
  setServerError(field: keyof TEditModel, error?: string) {
    if (this.formMetadata[field as string])
      this.formMetadata[field as string].serverError = error;
    else
      this.formMetadata[field as string] = { touched: false, serverError: error };
  }

  /**
  * Indicates if the store contains an original model. Will only be true after model has loaded.
  */
  @computed get isEdit(): boolean {
    return this.original != undefined;
  }

  @computed get canReset(): boolean {
    return this.isEdit ? !_isEqual(toJS(this.values), this.original) : Object.keys(this.values).length > 0;
  }

  /** Resets the model to its default values */
  @action.bound
  reset() {
    this.values = Object.assign({}, this.original || this.defaultValues);
    this.formMetadata = {};
  }

  getValue<T>(field: keyof TEditModel): T | undefined {
    const value = this.values[field];
    if (value == null)
      return undefined;
    else
      return value as any as T;
  }

  @action.bound
  async postForm(url: string, asMultipart: boolean = true): Promise<number | void> {

    this.doFullValidation = true;
    if (this.hasValidationErrors)
      return;

    this.isPending = true;
    const result = await this.networkHelper.post<Partial<TEditModel>, number>(url, this.values, asMultipart);
    this.isPending = false;

    if (result != null && isValidationResultValue(result)) {

      // Deal with field errors
      if (result.Errors) {
        result.Errors.forEach(error => {
          this.setServerError(error.Field as keyof TEditModel, error.Message);
        });
      }

      // Deal with dialog error
      if (result.Message)
        RootStore.UIStore.addDialog(Dialog.OkDialogWithMessage("Valideringsfeil", result.Message));

    } else
      return result;
  }

  @action.bound
  async loadModel(url: string, id: number) {
    const result = await this.networkHelper.get<TEditModel>(`/api/${url}/${id}`);
    if (result) {
      runInAction(() => {
        this.original = result;
        this.values = Object.assign({}, result);
        this.doFullValidation = true;
        this.formMetadata = {};
      });
    }
  }

  @computed get modelHasLoaded(): boolean {
    return this.original != null;
  }

}