import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import { UserNotification } from "ts/models/shared/UserNotification";
import "./NotificationsField.tsx.scss";
import { NotificationsComponent } from "../../shared/NotificationSelector";

interface OwnProps {
  showOnlyWhenContact: boolean;
}

type T = Array<UserNotification>;
type P = PropsFromWrapperToField<T> & OwnProps;

class NotificationsField extends React.Component<P> {

  update = (value: T) => {
    this.props.setValue(value);
    this.props.setTouched();
  }

  render() {
    const { id, value, showOnlyWhenContact } = this.props;

    return <div className="field-notifications" id={id}>
      <NotificationsComponent value={value || []} onChange={v => this.update(v)} showOnlyWhenContact={showOnlyWhenContact} />
    </div>
  }
}

const wrapped = Wrap<P, T>(NotificationsField);

export {
  wrapped as NotificationsField
}