import * as React from "react";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { RouteComponentProps } from "react-router";
import { ArticleList } from "./ArticleList";
import { ArticleForm } from "./ArticleForm";
import { ArticlePrices } from "./ArticlePricing";
import { ArticlePriceGroupEdit } from "./ArticlePriceGroupEdit";


interface Props {
  route: RouteComponentProps<{}>;
}

export class ArticlePage extends React.Component<Props> {
  render() {
    return <WorkSpace
      singular="artikkel"
      plural="artikler"
      routeBasePath={this.props.route.location.pathname.split("/")[1]}
      {...this.props.route}
      editComponent={<ArticleForm />}
      listComponent={<ArticleList />}
      additionalRoutes={[
        { path: "/articles/:articleId/prices", component: ArticlePrices },
        { path: "/articles/:articleId/prices/create", component: ArticlePriceGroupEdit },
        { path: "/articles/:articleId/prices/:groupId/edit", component: ArticlePriceGroupEdit },
        { path: "/articles/:articleId/prices/create/:templateId", component: ArticlePriceGroupEdit },
      ]}
    />
  }
}

