import * as React from "react";
import "./detailspanel.scss";

interface Props {
  cssClass?: string;
}

export class DetailsPanel extends React.Component<React.PropsWithChildren<Props>> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const children = React.Children.toArray(this.props.children) as Array<React.ReactElement<any>>;

    const blocks = children.filter(p => (p.type as any).typeName === "DetailsBlock");
    const actions = children.filter(p => (p.type as any).typeName === "DetailsAction");
    const other = children.filter(f => blocks.indexOf(f) == -1 && actions.indexOf(f) == -1);

    return (
      <div className={this.props.cssClass ?? "panel details-container"}>
        {other}
        <div className="details-blocks">
          {blocks}
        </div>
        <div className="details-actions">
          {actions}
        </div>
      </div>)
  }
}