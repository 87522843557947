import * as React from "react";
import "./SearchBox.tsx.scss";
import { default as RootStore } from "ts/stores/root-store";
import { observer } from "mobx-react";

@observer
class SearchBox extends React.Component {

  setPhrase(value: string): void {

    const router = RootStore.RouterStore;
    if (router.location.pathname.startsWith("/search"))
      router.replace(`/search/${encodeURI(value)}`);
    else
      router.push(`/search/${encodeURI(value)}`);

  }

  checkKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == "ArrowDown") {
      const firstResultAction = document.querySelector<HTMLLinkElement>(".result-action");
      if (firstResultAction != null)
        firstResultAction.focus();
    }
  }

  render() {

    const search = RootStore.RouterStore.location.pathname;
    const regex = /^\/search\/(.*)$/i;
    var result = regex.exec(search);

    let phrase = "";
    if (result != null && result.length > 1)
      phrase = result[1];

    return <input
      id="search-box"
      className="search-box"
      placeholder="Søk"
      type="search"
      value={phrase || ''}
      onKeyDown={e => this.checkKeyDown(e)}
      onChange={e => this.setPhrase(e.currentTarget.value)} />
  }

}

export { SearchBox };