import * as React from "react";
import { WorkOrderDetailsModel } from "../../../models/shared/WorkOrderDetailsModel";
import { default as RootStore } from "../../../stores/root-store";
import ArrowIcon from "svg/arrow.svg";
import "./ListNavigator.tsx.scss";
import { ListStore } from "../../../stores/list-store";
import { WorkOrderListModel } from "../../../models/shared/WorkOrderListModel";

interface State {
  index: number;
  listData: ListStore<WorkOrderListModel>;
  currentPage: number;
  result: Array<WorkOrderListModel>;
  pageSize: number;
  hasPrevious: boolean;
  hasNext: boolean;
};


export const ListNavigator: React.FC<{ model: WorkOrderDetailsModel }> = ({ model }) => {

  const [list, _setList] = React.useState<State | undefined>();

  const stateRef = React.useRef(list);
  const setList = (data: State) => {
    stateRef.current = data;
    _setList(data);
  }


  React.useEffect(() => {

    const listData = RootStore.ListStores.get("workorders") as ListStore<WorkOrderListModel> | undefined;
    if (listData?.values?.Result != null) {

      const result = listData?.values?.Result;
      const index = result.findIndex(i => i.Id === model.Id);

      if (index >= 0) {

        const totalNumberOfItems = listData.values.TotalCount;
        const pageSize = listData.pageSize;
        const startIndex = listData.values.Offset;
        const numberOfPages = Math.ceil(totalNumberOfItems / pageSize);
        const currentPage = Math.ceil(startIndex / pageSize) + 1;
        const hasPrevious = index > 0 || currentPage > 1;
        const hasNext = index < (result.length - 1) || currentPage < numberOfPages;

        setList({
          index,
          listData,
          currentPage,
          result: listData?.values?.Result,
          pageSize,
          hasPrevious,
          hasNext
        });
      }
    }
  }, [model.Id]);

  React.useEffect(() => {

    const handleKeyboardNavigation = (ev: KeyboardEvent) => {
      if (ev.altKey) {
        if (ev.key.toUpperCase() == "ARROWUP") {
          navigateToPrevious(null);
        } else if (ev.key.toUpperCase() == "ARROWDOWN") {
          navigateToNext(null);
        }
      }
    }

    document.addEventListener("keydown", handleKeyboardNavigation);
    return () => document.removeEventListener("keydown", handleKeyboardNavigation)
  }, [model.Id]);


  const navigateToPrevious = async (event: React.MouseEvent<HTMLAnchorElement> | null) => {
    event?.preventDefault();

    const list = stateRef.current;
    if (list == null || !list.hasPrevious)
      return;

    let woid: number;
    if (list.index > 0)
      woid = list.result[list.index - 1].Id;
    else {
      // load next page
      await list.listData.setOffset((list.currentPage - 2) * list.pageSize);
      const result = list.listData?.values?.Result;
      if (result != null)
        woid = result[result.length - 1].Id;
      else
        return;
    }

    RootStore.RouterStore.push(`/workorders/${woid}`);
  }

  const navigateToNext = async (event: React.MouseEvent<HTMLAnchorElement> | null) => {
    event?.preventDefault();

    const list = stateRef.current;
    if (list == null || !list.hasNext)
      return;

    let woid: number;
    if (list.index < (list.pageSize - 1))
      woid = list.result[list.index + 1].Id;
    else {
      // load next page
      await list.listData.setOffset((list.currentPage) * list.listData.pageSize);
      const result = list.listData?.values?.Result;
      if (result != null)
        woid = result[0].Id;
      else
        return;
    }

    RootStore.RouterStore.push(`/workorders/${woid}`);
  }

  if (list == null)
    return null;

  if (!list.hasNext && !list.hasPrevious)
    return null;

  return <div className="list-navigator">
    <ArrowNavigator clickFn={navigateToPrevious} active={list.hasPrevious} className="up icon" />
    <ArrowNavigator clickFn={navigateToNext} active={list.hasNext} className="down icon" />
  </div>
}

const ArrowNavigator: React.FC<{ clickFn: (event: React.MouseEvent<HTMLAnchorElement>) => void; active: boolean; className: string }> = ({ clickFn, active, className }) => {
  return <div className="list-navigator-item">
    {active && <a href="#" onClick={clickFn}><ArrowIcon className={className} /></a>}
    {!active && <span><ArrowIcon className={className} /></span>}
  </div>
}