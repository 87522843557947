import React from "react";
import { CustomerDetailsModel } from "../../../../models/shared/CustomerDetailsModel";
import { DetailValueField } from "../../../../components/details/DetailValueField";
import { formatOrgNr } from "../../../../core/stringHelpers";
import { since, toDate } from "../../../../core/dateHelpers";
import { Pending } from "../../../../components/shared/Pending";
import { UserListModel } from "../../../../models/shared/UserListModel";
import { useDataStore } from "../../../../core/hooks/useDataStore";
import "./CustomerInfo.tsx.scss";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

export const CustomerInfo: React.FC<{ info: CustomerDetailsModel }> = ({ info }) => {

  const { data: brukere } = useDataStore<Array<UserListModel>>(`/api/users/ListByCustomer/${info.Id}`, { entityName: 'CustomerEntity', entityId: info.Id });

  return <div className="box w2 customer-info-box">
    <h2>Kundedetaljer</h2>
    <DetailValueField title="Navn"><NavLink to={`/customers/${info.Id}` }>{info.Name} ({formatOrgNr(info.OrganizationId)})</NavLink></DetailValueField>
    <DetailValueField title="Epost">{info.Email ?? info.NotificationEmail}</DetailValueField>
    <DetailValueField title="Opprettet"><span title={since(info.Created)}>{toDate(info.Created)}</span></DetailValueField>
    <DetailValueField title="Brukere" wrap>
      {!brukere && <Pending />}
      {brukere !== undefined && <ul className={classNames({"wide": brukere.length > 3}) } >{brukere.map(b => <li key={b.Id}>{b.Name}</li>)}</ul>}
    </DetailValueField>
    <span className="info">Ta kontakt med <a href="mailto:post@razink.no">post@razink.no</a> ved feil</span>
  </div>
}