/**  */
export enum Role {
  
  /** */
  Administrator = 0,  
  /** */
  Employee = 1,  
  /** */
  EmployeeAdmin = 2,  
  /** */
  Customer = 3,  
  /** */
  CustomerAdministrator = 4
}

