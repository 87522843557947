import ArrowIcon from "svg/arrow.svg";
import * as moment from "moment";
import * as React from "react";
import styled from "styled-components";

interface MonthNavigatorProps {
  day: Date;
  onChange: (m: Date) => void;
}

export const DayNavigator: React.FC<MonthNavigatorProps> = ({ day, onChange }) => {

  const Container = styled.div`
        display: flex;
        align-items: center;

        span {
          margin: 0 20px;
        font-size: 1.17em;
        font-weight: bold;
    }

        button {
        background-color: transparent;
        border: 1px solid #a5a4a4;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        cursor: pointer;

        &:hover {
          background-color: #ececec;
      }

        &.previous svg {
          transform: rotate(180deg);
      }
    }
        `;

  const goBack = () => onChange(moment(day).add(-1, "day").toDate());
  const goNext = () => onChange(moment(day).add(1, "day").toDate());

  return (<Container className="day-navigator">
    <button className="previous" onClick={e => goBack()}><ArrowIcon className="icon" /></button>
    <span>{moment(day).format("dddd DD. MMMM YYYY")}</span>
    <button className="next" onClick={e => goNext()}><ArrowIcon className="icon" /></button>
  </Container>
  );
}
