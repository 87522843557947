import * as React from "react";
import { default as RootStore } from "../../../stores/root-store";
import EmployeeIcon from "svg/employee.svg";
import UserWithLogonIcon from "svg/user-with-login.svg";
import UserWithNotificationIcon from "svg/user-with-notification.svg";
import "./userTypeSelector.scss";
import { UserType } from "./UserEdit";

interface Props {
  onSelect: (type: UserType) => void;
}

export class UserTypeSelector extends React.PureComponent<Props> {
  render() {

    const isAdmin = RootStore.UserStore.isEmployeeAdministrator;

    return <div className="user-type-selector panel panel-padding">
      <h3>Velg brukertype</h3>
      <div className="user-type-selector-items">
        {isAdmin && <div onClick={e => this.props.onSelect("employee")} className="user-type-selector-item">
          <EmployeeIcon />
          <h4>Ansatt</h4>
        </div>}
        <div onClick={e => this.props.onSelect("customer-user")} className="user-type-selector-item">
          <UserWithLogonIcon />
          <h4>Kunde med pålogging</h4>
        </div>
        <div onClick={e => this.props.onSelect("customer-contact")} className="user-type-selector-item">
          <UserWithNotificationIcon />
          <h4>Kundekontakt</h4>
        </div>
      </div>
    </div>
  }
}