import * as moment from "moment"
import * as React from "react";
import { Link } from "react-router-dom";
import { NumberWithPostfix } from "../../../../components/shared/display";
import { EntityType, toSerialNumber } from "../../../../core/stringHelpers";
import { UnitEntity } from "../../../../models/shared/UnitEntity";
import { WorkOrderPopupDetails } from "../WorkOrderPopupDetails";
import { getWeight } from "./unitEntityHelper";

export const DayViewUnitItem: React.FC<{ unit: UnitEntity, workOrderId: string; created: string }> = ({ unit, workOrderId, created }) => {
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const onEnter: React.MouseEventHandler<HTMLElement> = (ev) => setIsMouseOver(true);
  const onLeave: React.MouseEventHandler<HTMLElement> = (ev) => setIsMouseOver(false);
  return <>
    <div onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      ref={ref}>
      <Link to={{ pathname: `/workorders/${unit.WorkOrderId}`, state: { unitId: unit.Id } }} >{toSerialNumber(EntityType.Unit, unit.Id)}</Link>
      <span className="right">{moment(created).format("HH:mm")}</span>
      <NumberWithPostfix number={getWeight(unit)} post="kg" />
    </div>
    {isMouseOver && <WorkOrderPopupDetails workorder={{ Id: parseInt(workOrderId) }} element={ref.current} unitId={unit.Id} />}
  </>
}
