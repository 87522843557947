import * as React from "react";
import { FilterProps } from "../IListDefinition";
import { observer } from "mobx-react";
import { BaseModel } from "../../../models/shared/BaseModel";

interface OwnProps { }

type P = FilterProps<BaseModel> & OwnProps;

@observer
class ShowDeletedFilter extends React.Component<P>{
  render() {
    const { store, field } = this.props;
    const value = store.getFilterValue<string>(field) || "exclude";
    return <select
      onChange={e => store.setFilter(field, e.target.value)}
      value={value}>
      <option value="exclude">Ekskluder slettede</option>
      <option value="include">Inkluder slettede</option>
      <option value="only">Kun slettede</option>
    </select>
  }
}

const A = ShowDeletedFilter as any as React.ComponentClass<OwnProps>;

export {
  A as ShowDeletedFilter,
  ShowDeletedFilter as ShowDeletedFilterInternal
};