/**  */
export enum NotificationType {
  
  /** */
  WorkOrderDone = 1,  
  /** */
  WorkOrderInProduction = 3,  
  /** */
  WorkOrderRecieved = 4,  
  /** */
  RushRequest = 5,  
  /** */
  ExtraWork = 7,  
  /** */
  MissingReference = 8,  
  /** */
  Complaints = 9,  
  /** */
  Marketing = 10
}

