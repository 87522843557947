/**  */
export enum ComplaintState {
  
  /** */
  Received = 1,  
  /** */
  Processing = 2,  
  /** */
  Approved = 3,  
  /** */
  Rejected = 4,  
  /** */
  Resolved = 5
}

