import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../../forms/wrapField";
import "./volumeField.scss";
import { InputWithFix } from "../../shared/InputWithFix";

interface OwnProps {
}

interface Value {
  Length?: number;
  Width?: number;
  Height?: number
}
type P = PropsFromWrapperToField<Value> & OwnProps;

class VolumeField extends React.PureComponent<P> {

  private setValue = (value: Partial<Value>) => {
    this.props.setValue({ ...(this.props.value || {}), ...value });    
  }

  render() {
    const { value, setTouched, id, autoFocus } = this.props;
    return (
      <div id={id} className="field-volume">
        <InputWithFix
          type="number"
          inputMode="numeric"
          placeholder="Lengde"
          onChange={e => this.setValue({ Length: parseInt(e.target.value) })}
          onBlur={e => setTouched()}
          value={value == null ? "" : value.Length || ""}
          postfix="cm"
        />
        <InputWithFix
          type="number"
          inputMode="numeric"
          placeholder="Bredde"
          onChange={e => this.setValue({ Width: parseInt(e.target.value) })}
          onBlur={e => setTouched()}
          postfix="cm"
          value={value == null ? "" : value.Width || ""} />
        <InputWithFix
          type="number"
          inputMode="numeric"
          postfix="cm"
          placeholder="Høyde"
          onChange={e => this.setValue({ Height: parseInt(e.target.value) })}
          onBlur={e => setTouched()}
          value={value == null ? "" : value.Height || ""} />

      </div>
    )
  }
}

const A = Wrap<P, Value>(VolumeField)
export { A as VolumeField };