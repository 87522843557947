import { DataStore, DataStore2 } from "../../../stores/data-store";
import * as React from "react";
import { CommunicationItemModel } from "../../../models/shared/CommunicationModel";
import { observer } from "mobx-react";
import { toDateWithHMShort } from "../../../core/dateHelpers";
import "./MessageList.tsx.scss";

interface Props {
  workOrderId?: number;
  userId?: number;
  entity?: string;
  entityId?: number | null;
  updateCount?: (count: number) => void;
}

@observer
export class MessageList extends React.Component<Props> {

  store: DataStore2<Array<CommunicationItemModel>>

  constructor(props: Props) {
    super(props);

    let url;
    if (props.entity != null && props.entityId != null)
      url = `/api/lookup/GetCommunicationForEntity/${props.entity}/${props.entityId}`;
    else
      url = `/api/lookup/GetCommunicationFor${props.userId != null ? "User" : "WorkOrder"}/${props.userId ? props.userId : props.workOrderId}`;

    const type = props.entity ? props.entity : props.userId ? "UserEntity" : "WorkOrderEntity";
    const id = props.entityId ? props.entityId : props.userId ? props.userId : props.workOrderId;

    this.store = new DataStore2<Array<CommunicationItemModel>>(url, ch =>
      (ch.entityName == type && ch.entityId == id) || ch.entityName == "CommunicationEntity",
      result => this.props.updateCount?.(result.length)
    );
  }

  render() {
    const { data } = this.store;
    if (data == null)
      return null;

    if (data.length == 0)
      return <p>Ingen epost eller tekstmeldinger sendt knyttet til bruker.</p>;

    return <div className="table-container message-list-table"><table>
      <thead>
        <tr>
          <th>Dato</th>
          <th>Form</th>
          <th>Type</th>
          <th>Mottaker</th>
          <th>Innhold</th>
          <th>Tilstand</th>
          <th>Sendt</th>
        </tr>
      </thead>
      <tbody>
        {data.map(i =>
          <tr key={i.Id}>
            <td className="no-wrap">{toDateWithHMShort(i.Created)}</td>
            <td>{i.Type == 0 ? "Epost" : "Sms"}</td>
            <td>{i.MessageClass}</td>
            <td>{i.Recipient}</td>
            <td className="message-list-content">{i.Type == 0 && i.Content ? <div dangerouslySetInnerHTML={{ __html: i.Content }} /> : i.Content}</td>
            <td>{i.EndpointResponse}</td>
            <td>{i.Success === true ? "Sendt" : i.Success === false ? "Avvist" : "Avventer"}</td>
          </tr>)}
      </tbody>
    </table></div>

  }

  componentWillUnmount() {
    this.store.dispose();
  }
}
